
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Note } from "@/types/Note"
import { mapActions, mapGetters } from "vuex"
import { formatRelative, parseISO } from "date-fns"
import CardTemplate from "@/components/CardTemplate.vue"
import NoteDetails from "@/components/NoteDetails.vue"

class NoteReturnType {
  data: { meta: { pagination: { pages: number } } }
}

@Component({
  computed: { ...mapGetters("notes", ["getBy"]) },
  methods: mapActions({ loadBy: "notes/loadBy" }),
  components: { CardTemplate, NoteDetails }
})
export default class NotesList extends Vue {
  @Prop(Number) notableId!: number
  @Prop(String) notableType!: string
  private page = 1
  private perPage = 3
  private pages = 2222

  formatRelative(date: Date) {
    return formatRelative(date, new Date())
  }

  parseISO(dateString: string) {
    return parseISO(dateString)
  }

  getBy!: ({
    notable_id,
    notable_type
  }: {
    notable_id: number
    notable_type: string
  }) => Note[]

  loadBy!: ({
    notable_id,
    notable_type,
    page,
    per_page,
    order,
    order_direction
  }: {
    notable_id: number
    notable_type: string
    page: number
    per_page: number
    order: string
    order_direction: string
  }) => Promise<NoteReturnType>

  @Watch("page")
  onPageChange() {
    this.loadBy({
      notable_id: this.notableId,
      notable_type: this.notableType,
      page: this.page,
      per_page: this.perPage,
      order: "created_at",
      order_direction: "DESC"
    }).then(data => {
      this.pages = data?.data?.meta?.pagination?.pages
        ? data.data.meta.pagination.pages
        : 2222
    })
  }

  @Watch("notableId")
  onNotes() {
    this.page = 1
    this.loadBy({
      notable_id: this.notableId,
      notable_type: this.notableType,
      page: this.page,
      per_page: this.perPage,
      order: "created_at",
      order_direction: "DESC"
    }).then(data => {
      this.pages = data?.data?.meta?.pagination?.pages
        ? data.data.meta.pagination.pages
        : 2222
    })
  }

  mounted() {
    this.loadBy({
      notable_id: this.notableId,
      notable_type: this.notableType,
      page: this.page,
      per_page: this.perPage,
      order: "created_at",
      order_direction: "DESC"
    }).then(data => {
      this.pages = data?.data?.meta?.pagination?.pages
        ? data.data.meta.pagination.pages
        : 2222
    })
  }

  get notes() {
    const notes = this.getBy({
      notable_id: this.notableId,
      notable_type: this.notableType
    })
    notes.sort(
      (a, b) =>
        b.attributes.created_at.valueOf() - a.attributes.created_at.valueOf()
    )

    return notes
  }

  get newNote() {
    const note = new Note()
    note.attributes.notable_id = this.notableId
    note.attributes.notable_type = this.notableType

    return note
  }
}
