import Model from "./Model"

export class Adjustment extends Model<Adjustment> {
  id: string
  attributes!: {
    adjustable_id: number
    adjustable_type: string
    amount: string
    current_balance: string
    created_at: string
    memo: string
    product_type: string
    eip: boolean
    posted_at: string
  }
  static get modelName() {
    return "Adjustment"
  }
}
