
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Bill } from "@/types/Bill"
import { BillMember } from "@/types/BillMember"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import MemberCoverageGraphic from "@/components/MemberCoverageGraphic.vue"
import DateHelper from "@/components/utils/DateHelper"
import { number } from "card-validator"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    MemberCoverageGraphic,
    PagedDataTable
  }
})
export default class MemberLedger extends Vue {
  @Prop() private memberId!: string
  @Prop() private productType!: string
  private billMembers: BillMember[] = []
  private loading = false
  private total = 0
  private startingPerPage = 5
  private coverageData = {
    ltd: [{ year: number, months: [number] }],
    ltc: [{ year: number, months: [number] }]
  }

  created() {
    this.loadBillMembers(1, this.startingPerPage)
  }

  @Watch("memberId")
  onMemberChange() {
    this.loadBillMembers(1, this.startingPerPage)
  }

  async fetchCoverageData() {
    this.$store.dispatch("members/coverage", this.memberId).then(data => {
      this.coverageData = data.data
    })
  }

  async loadBillMembers(page: number, perPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("billMembers/loadBy", {
      member_id: this.memberId,
      "bill.product_type": this.productType,
      page: page,
      per_page: perPage,
      order: "bill.created_at",
      order_direction: "DESC",
      include: ["bill"]
    })

    this.billMembers = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  get bills() {
    return this.$store.getters["bills/getByIdsAndProductType"](
      this.billMembers.map((bm: BillMember) =>
        bm.attributes.bill_id.toString()
      ),
      this.productType
    )
  }

  bill(billMember: BillMember) {
    return (
      this.bills.find(
        (el: Bill) => parseInt(el.id) == billMember.attributes.bill_id
      ) || new Bill()
    )
  }

  getBillMembers(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadBillMembers(details.page, details.itemsPerPage)
    }
  }

  getCoverage(record: BillMember) {
    let s = ""

    if (this.bill(record).attributes.coverage_start_date) {
      const d1 = new Date(this.bill(record).attributes.coverage_start_date)
      const d2 = new Date(this.bill(record).attributes.coverage_end_date)
      s = DateHelper.dateRange(d1, d2)
    }

    return s
  }

  get headers() {
    return [
      {
        text: "POSTED",
        value: "attributes.created_at",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "COVERAGE",
        value: "attributes.coverage",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "AMOUNT",
        value: "attributes.amount",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "MEMO",
        value: "attributes.memo",
        sortable: false,
        class: "text-left caption"
      }
    ]
  }
}
