
import { Component, Prop, Vue } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { ClaimForm } from "@/types/ClaimForm"

import { cloneDeep } from "lodash"

@Component({
  components: {
    DateField,
    DialogTemplate
  }
})
export default class ClaimFormEditDialog extends Vue {
  @Prop() private claimFormId: string

  private editClaimForm: ClaimForm = new ClaimForm(cloneDeep(this.claimForm))

  get claimForm() {
    const claimForm = this.$store.getters["claimForms/getById"](
      this.claimFormId
    )
    this.editClaimForm = new ClaimForm(cloneDeep(claimForm))
    return claimForm
  }

  get delivery_methods() {
    return ["US Mail", "E-Mail", "Fax"]
  }

  async save() {
    await this.$store.dispatch("claimForms/update", this.editClaimForm)
    this.$emit("reload", this.editClaimForm)
  }

  reset() {
    this.editClaimForm = new ClaimForm(cloneDeep(this.claimForm))
  }
}
