import Model from "./Model"

export class BoardMeetingAdmin extends Model<BoardMeetingAdmin> {
  constructor(response?: BoardMeetingAdmin) {
    super(response as BoardMeetingAdmin)
  }
  id: string
  attributes!: {
    board_meeting_id: number
    name: string
    company: string
    title: string
    attended: boolean
  }
  static get modelName() {
    return "BoardMeetingAdmin"
  }
}
