
import { Component, Vue, Prop } from "vue-property-decorator"
import { BoardMeeting } from "@/types/BoardMeeting"
import CardTemplate from "./CardTemplate.vue"
import { BoardMeetingAttendee } from "@/types/BoardMeetingAttendee"

@Component({
  components: { CardTemplate }
})
export default class BoardMeetingSummary extends Vue {
  @Prop() private boardMeetingId!: BoardMeeting

  get boardMeeting(): BoardMeeting {
    return this.$store.getters["boardMeetings/getById"](this.boardMeetingId)
  }

  get attendees() {
    return this.boardMeeting
      ? this.$store.getters["boardMeetingAttendees/getBy"]({
          board_meeting_id: this.boardMeetingId
        })
      : []
  }

  get admins() {
    return this.boardMeeting
      ? this.$store.getters["boardMeetingAdmins/getBy"]({
          board_meeting_id: this.boardMeetingId
        })
      : []
  }

  get attended() {
    return this.attendees.filter(
      (attendee: BoardMeetingAttendee) => attendee.attributes.attended
    )
  }

  get voting() {
    return this.attendees.filter(
      (attendee: BoardMeetingAttendee) => (attendee.attributes.role & 1) > 0
    )
  }
  get subs() {
    return this.attendees.filter(
      (attendee: BoardMeetingAttendee) => (attendee.attributes.role & 8) > 0
    )
  }

  get plannedGuests() {
    return this.attendees.filter(
      (attendee: BoardMeetingAttendee) => (attendee.attributes.role & 2) > 0
    )
  }

  get unplannedGuests() {
    return this.attendees.filter(
      (attendee: BoardMeetingAttendee) => (attendee.attributes.role & 4) > 0
    )
  }

  get lunchOnly() {
    return this.attendees.filter(
      (attendee: BoardMeetingAttendee) => (attendee.attributes.role & 16) > 0
    )
  }

  async getPdf() {
    const response = await this.$store.dispatch(
      "boardMeetings/fetchSummaryPdf",
      this.boardMeetingId
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "board-meeting-summary.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }
}
