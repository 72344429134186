
import { Component, Vue } from "vue-property-decorator"

@Component({})
export default class ForgotPassword extends Vue {
  email = ""
  errorMessage = ""
  message = ""

  async submit() {
    this.errorMessage = ""
    if (!this.validEmail(this.email)) {
      this.errorMessage = "Please enter a valid email"
      return
    }
    const response = await this.$store.dispatch("users/requestReset", {
      email: this.email,
      type: "reset"
    })

    if (!response || response.status != 200) {
      this.message = "Something went wrong. Please try again later"
    } else {
      this.message = response.data
    }
  }

  validEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  get submitted() {
    return !!this.message && this.message.includes("has been sent")
  }
}
