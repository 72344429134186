
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import { cloneDeep } from "lodash"
import DateField from "@/components/DateField.vue"
import { Member } from "@/types/Member"
import { Membership } from "@/types/Membership"
import RecordSearch from "@/components/RecordSearch.vue"
import { Search as SearchItem } from "@/types/Search"
import moment from "moment"

@Component({
  components: {
    CardTemplate,
    DateField,
    RecordSearch
  }
})
export default class MemberEditList extends Vue {
  @Prop() private departmentId!: string

  private members: Member[] = []
  private editMemberships: Membership[] = []
  private memberships: Membership[] = []
  private massCancelMode = false
  private findAllCurrentProgress = false
  private massCancelDate = moment()
    .endOf("month")
    .format("YYYY-MM-DD")

  isLoading = false
  isUpdating = false
  updateProgress = 0
  search: string = null
  searchModel: string = null
  filter = `department_id:${this.departmentId}`

  async findAllCurrent() {
    this.massCancelMode = true
    this.findAllCurrentProgress = true

    const ms = await this.$store.dispatch("memberships/loadBy", {
      department_id: parseInt(this.departmentId),
      end_date: null
    })

    console.log(ms.data.data)

    for (const membership of ms.data.data) {
      const data = await this.$store.dispatch("members/load", {
        id: membership.attributes.member_id
      })
      const member = data?.data?.data
      const existing = this.members?.some(m => m.id == member.id)

      if (!existing) {
        this.members.push(member)
        const dup = cloneDeep(membership)
        dup.attributes.end_date = this.massCancelDate
        this.editMemberships.push(dup)

        this.memberships.push(membership)
      }
    }

    this.findAllCurrentProgress = false
  }

  @Watch("massCancelDate")
  onMassCancelDateChange() {
    for (const membership of this.editMemberships) {
      membership.attributes.end_date = new Date(this.massCancelDate)
    }
  }

  editMembership(memberId: string) {
    return this.editMemberships.find(
      element => element.attributes.member_id == parseInt(memberId)
    )
  }

  membership(memberId: string) {
    return this.memberships.find(
      element => element.attributes.member_id == parseInt(memberId)
    )
  }

  removeEditMember(member: Member) {
    const index = this.members.findIndex(item => {
      return item.id == member.id
    })

    if (index > -1) {
      this.members.splice(index, 1)

      const i = this.memberships.findIndex(item => {
        return item.attributes.member_id == parseInt(member.id)
      })

      if (i > -1) {
        this.memberships.splice(i, 1)
      }

      const i2 = this.editMemberships.findIndex(item => {
        return item.attributes.member_id == parseInt(member.id)
      })

      if (i2 > -1) {
        this.editMemberships.splice(i2, 1)
      }
    }
  }

  async addMember(event: SearchItem) {
    if (event) {
      const data = await this.$store.dispatch("members/load", {
        id: event.target_id.toString(),
        include: ["memberships", "department"]
      })
      const member = data?.data?.data
      const existing = this.members?.some(m => m.id == member.id)
      if (!existing) {
        this.members.push(member)

        const m = this.$store.getters["memberships/getBy"]({
          member_id: parseInt(member.id),
          department_id: parseInt(this.departmentId)
        })
        const s = m.sort((a: Membership, b: Membership) => {
          if (!b.attributes.end_date) return 1
          if (!a.attributes.end_date) return -1
          return a.attributes.end_date < b.attributes.end_date ? 1 : -1
        })

        if (s.length > 0) {
          this.editMemberships.push(cloneDeep(s[0]))
          this.memberships.push(s[0])
        }
      }
      this.searchModel = null
    }
  }

  async update() {
    this.isUpdating = true

    let i = 1
    for (const ms of this.editMemberships) {
      if (ms.attributes.end_date) {
        await this.$store.dispatch("members/endMembershipPolicy", {
          member_id: ms.attributes.member_id,
          end_date: ms.attributes.end_date,
          membership_id: ms.id,
          include: ["memberships", "department"]
        })
      }

      await new Promise(r => setTimeout(r, 1000))
      this.updateProgress = (i / this.editMemberships.length) * 100

      i += 1
    }

    this.isUpdating = false
    this.updateProgress = 0

    this.$emit("update-list")
  }

  maskSSN(ssn: string) {
    return ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4)
  }
}
