import setDirty from "@/store/modules/dirtyFlag"
import State from "@/types/State"
import types from "@/store/utils/loadTypes"
import Vue from "vue"
import Model from "@/types/Model"

export default {
  setDirty,
  set(
    state: State<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    payload: Array<{ id: string | number }> | { id: string | number }
  ) {
    if (payload) {
      const Clazz =
        types && state.type && types[state.type] ? types[state.type] : Model
      let newRecords: Array<{ id: string | number }> = []
      newRecords = newRecords.concat(payload)

      newRecords.forEach(record => {
        const foundItem = state.all.find(el => el.id == record.id)
        if (foundItem) {
          Object.assign(foundItem, new Clazz(record))
        } else {
          state.all.push(new Clazz(record))
        }
      })
    }
  },
  setMeta(
    state: State<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
    payload: {}
  ) {
    if (payload) {
      Vue.set(state, "meta", payload)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  remove(state: State<any>, deletedId: number | string) {
    if (deletedId) {
      const foundIndex = state.all.findIndex(el => el.id == deletedId)
      if (foundIndex >= 0) {
        Vue.delete(state.all, foundIndex)
      }
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearAll(state: State<any>) {
    state.all.splice(0, state.all.length)
  }
}
