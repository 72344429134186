
import { Component, Vue } from "vue-property-decorator"
import IifDisbursementsList from "@/components/IifDisbursementsList.vue"
import PageTemplate from "@/components/PageTemplate.vue"

import { IifableDisbursement } from "@/types/IifableDisbursement"
import axios from "axios"

@Component({
  components: {
    IifDisbursementsList,
    PageTemplate
  }
})
export default class Iif extends Vue {
  private disbursements: IifableDisbursement[] = []
  private loading = false
  private title = ""

  mounted() {
    this.load()
  }

  get id() {
    return this.$route.params.id
  }

  async load() {
    this.loading = true
    const queryParams = {
      iif_id: this.id
    }
    const data = await axios.get(
      "/disbursements/iifable",
      Object.assign(
        { params: queryParams },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    this.disbursements.splice(
      0,
      this.disbursements.length,
      ...data.data.map((el: any) => new IifableDisbursement(el)) // eslint-disable-line @typescript-eslint/no-explicit-any
    )
    this.loading = false
  }
}
