
import { Address } from "@/types/Address"
import { Department } from "@/types/Department"
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"

@Component({
  components: {
    CardTemplate
  }
})
export default class DepartmentSummary extends Vue {
  @Prop() private department!: Department
  @Prop() private address!: Address

  get lineOne() {
    let l = ""
    if (this.department) {
      l = this.department.attributes.name
    }
    return l
  }

  get lineTwo() {
    let l = ""
    if (this.address) {
      l = `${this.address.attributes.city}, ${this.address.attributes.state}`
    }
    return l
  }
}
