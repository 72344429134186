
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate
  }
})
export default class UpdatePolicyDate extends Vue {
  @Prop() private id!: number
  @Prop() private title!: string
  @Prop() private date!: string
  @Prop() private attributeName!: string
  @Prop() private dateSelector!: string
  @Prop() private optionalCopyText!: string
  @Prop() private optionalIconColor!: string

  private changedDate = ""

  mounted() {
    this.changedDate = this.date
  }

  @Watch("date")
  onDateChange(value: string) {
    if (value) {
      this.changedDate = value
    }
  }

  async update() {
    await axios.put(
      `/policies/${this.id}`,
      { [this.attributeName]: this.changedDate },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
