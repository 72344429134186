
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { Claim } from "@/types/Claim"
import { Disbursement } from "@/types/Disbursement"
import { DisbursementType } from "@/types/DisbursementType"
import { RecoverableAdjustment } from "@/types/RecoverableAdjustment"
import { Recovery } from "@/types/Recovery"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Beneficiary } from "@/types/Beneficiary"

@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate
  }
})
export default class ClaimOptions extends Vue {
  @Prop() private claim!: Claim
  @Prop() private beneficiaries!: Beneficiary[]

  private recoveryData = new Recovery()
  private valid = false
  private errorText = ""
  private loading = false
  private adjustment = new RecoverableAdjustment()
  private decrease = true
  private amount: number = null

  private newDisbursement: Disbursement = new Disbursement()

  private adjustmentReasons = [
    "Bankruptcy",
    "Claim Reclassed to NIOD",
    "Collections Failure",
    "Collections/Legal proceedings",
    "NIOD - Lost case",
    "NIOD - Dismissed case",
    "NIOD - Failed to pursue",
    "No Duplicated Benefits",
    "Offset/recover fm future benefits",
    "Waived"
  ]

  beneficiaryDisplayName(item: Beneficiary) {
    return `${item.attributes.first_name} ${item.attributes.last_name}`
  }
  created() {
    this.recoveryData.attributes.date = new Date()
    this.resetDisbursement()
    this.adjustment.attributes.adjustment_type = "write off"
  }

  disbursementTypes(type: string) {
    const disbursementTypes = this.$store.getters["disbursementTypes/getBy"]({
      product_type: type
    })

    return disbursementTypes.map((el: DisbursementType) => {
      return { value: parseInt(`${el.id}`), text: el.attributes.name }
    })
  }

  isNumber(evt: KeyboardEvent) {
    if (isNaN(parseInt(evt.key))) {
      evt.preventDefault()
    } else {
      return true
    }
  }

  resetDisbursement() {
    this.newDisbursement = new Disbursement()
    this.newDisbursement.attributes.date = new Date()
    this.newDisbursement.attributes.status = "Pending"
    this.newDisbursement.attributes.claim_id = this.claim
      ? parseInt(this.claim.id)
      : null
  }

  @Watch("recoveryData.attributes.is_overpayment")
  onIsOverpaymentChange(value: boolean) {
    if (value) {
      this.recoveryData.attributes.is_final = null
    } else {
      this.recoveryData.attributes.is_final = false
    }
  }

  async saveAdjustment() {
    this.adjustment.attributes.claim_id = parseInt(this.claim.id)
    this.adjustment.attributes.date = new Date()
    const amount = this.decrease
      ? Math.abs(this.amount)
      : Math.abs(this.amount) * -1
    this.adjustment.attributes.amount = amount

    await this.$store.dispatch("recoverableAdjustments/create", this.adjustment)

    this.adjustment = new RecoverableAdjustment()
    this.decrease = true
    this.amount = null

    this.errorText = ""
    this.loading = false

    this.$emit("new-recovery")
  }

  async saveDisbursement() {
    if (this.newDisbursement.attributes.disbursement_type_id == undefined) {
      this.newDisbursement.attributes.disbursement_type_id = null
    }
    this.newDisbursement.attributes.claim_id = parseInt(this.claim.id)

    await this.$store.dispatch("disbursements/create", this.newDisbursement)

    this.resetDisbursement()

    this.loading = false

    this.$emit("new-recovery")
  }

  async saveRecovery() {
    this.loading = true
    const data =
      this.recoveryData.attributes.date && this.recoveryData.attributes.amount

    if (!data) {
      this.errorText = "Please enter a date and amount"
      return
    }

    this.recoveryData.attributes.claim_id = parseInt(this.claim.id)

    this.recoveryData.attributes.status = "Unconfirmed"
    await this.$store.dispatch("recoveries/create", this.recoveryData)

    this.recoveryData = new Recovery()
    this.recoveryData.attributes.date = new Date()

    this.errorText = ""
    this.loading = false
    this.$emit("new-recovery")
  }
}
