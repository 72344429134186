var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PagedDataTable',{staticClass:"elevation-0 pb-4",attrs:{"id":_vm.table_id,"name":"claims-data-table","headers":_vm.headers,"items":_vm.formattedClaims,"item-key":"id","loading":_vm.loading,"itemsLength":_vm.total,"showEmpty":true,"startingItemsPerPage":_vm.startingPerPage,"must-sort":_vm.options.mustSort,"sort-by":_vm.options.sortBy,"sort-desc":_vm.options.sortDesc,"itemsPerPage":[25, 50, 100, 200]},on:{"firstPageLoad":_vm.firstPageLoad,"loadPage":_vm.getClaims,"update:sortBy":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.options, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.options, "sortDesc", $event)}},scopedSlots:_vm._u([{key:`item.dirty`,fn:function({ item }){return [(_vm.dirtyClaims.includes(item.id))?_c('v-icon',{staticClass:"js-dirty",attrs:{"color":"secondary"},on:{"click":function($event){_vm.loadClaims(_vm.currentPage, _vm.perPage)
        _vm.$emit('update-totals')}}},[_vm._v("mdi-sync-alert")]):_vm._e()]}},{key:`item.attributes.short_number`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'Claim', params: { id: item.id } },"id":"js-claim"}},[_vm._v(_vm._s(item.attributes.short_number))])]}},{key:`item.attributes.review_start_date`,fn:function({ item }){return [_c('span',{staticClass:"js-review"},[_c('DateField',{attrs:{"display":true,"value":item.attributes.review_start_date}})],1)]}},{key:`item.attributes.admin`,fn:function({ item }){return [_c('span',{staticClass:"js-admin-initials"},[_c('user-initials',{staticClass:"mx-4",attrs:{"user":_vm.claimAdmin(item),"size":"32"}})],1)]}},{key:`item.attributes.product_type`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.attributes.product_type.toUpperCase())+" ")]}},{key:`item.ach`,fn:function({ item }){return [(item.attributes.payment_method_id)?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:`item.member`,fn:function({ item }){return [(item.member.id)?_c('router-link',{attrs:{"id":"js-member","to":{
        name: 'Member',
        params: {
          id: item.member.id
        }
      }}},[_vm._v(_vm._s(item.member.attributes.last_name)+", "+_vm._s(item.member.attributes.first_name)+" ")]):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }