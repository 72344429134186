
import { Component, Vue, Prop } from "vue-property-decorator"
import moment from "moment"

@Component
export default class MonthField extends Vue {
  @Prop() private display!: boolean
  @Prop() private label!: string
  @Prop() private value!: Date
  @Prop() private monthEnd!: boolean

  private focus = true
  private date = this.value ? moment(this.value).format("MM/YYYY") : ""

  validDate(v: string) {
    if (v.length == 7 || !this.focus) {
      return /([0][0-9]|[1][012])\/\d\d\d\d/.test(v)
    }
    return true
  }

  updateValue() {
    let value = moment(this.date, "MM/YYYY")

    if (this.monthEnd) {
      value = value.endOf("month").startOf("day")
    }

    this.$emit("input", value)
  }
}
