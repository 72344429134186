
import { Component, Vue } from "vue-property-decorator"
import { Bar } from "vue-chartjs/legacy"
import DialogTemplate from "@/components/DialogTemplate.vue"
import CardTemplate from "@/components/CardTemplate.vue"

// import moment from "moment"

interface StatsData {
  m1: string
  m2: string
  data: [
    {
      month: string
      cancellations: number
      additions: number
      applications: number
    }
  ]
}

@Component({
  components: { Bar, CardTemplate, DialogTemplate }
})
export default class CancellationCount extends Vue {
  private stats: StatsData = null
  private downloadMonth = { text: "", value: new Date() }

  async created() {
    const r = await this.$store.dispatch("policies/getCancellationCounts")

    if (r?.data) {
      this.stats = r.data
    }
  }

  getCancellations() {
    const month = this.downloadMonth.value

    this.$store.dispatch("policies/getCancellations", month).then(data => {
      const fileURL = window.URL.createObjectURL(new Blob([data.data]))
      const fURL = document.createElement("a")
      const d = new Date(month)
      d.setUTCHours(12)

      const downloadFileName = `Cancellations-${
        this.monthNames[d.getMonth()]
      }-${d.getUTCFullYear()}.csv`

      fURL.href = fileURL

      fURL.setAttribute("download", downloadFileName)
      document.body.appendChild(fURL)

      fURL.click()
    })
  }

  get possibleMonths() {
    const dToday = new Date()
    dToday.setUTCHours(12)

    const months = []

    months.push({
      text: `${this.monthNames[dToday.getMonth()]} ${dToday.getUTCFullYear()}`,
      value: dToday
    })

    for (let i = 1; i < 13; i++) {
      const d = new Date()
      d.setMonth(dToday.getMonth() - i)
      months.push({
        text: `${this.monthNames[d.getMonth()]} ${d.getUTCFullYear()}`,
        value: d
      })
    }

    return months
  }

  private monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  get chartData() {
    const data = {
      labels: this.labels,
      datasets: [
        {
          label: "Applications",
          backgroundColor: "#FFE0B2",
          data: this.valueApp
        },
        {
          label: "Starts",
          backgroundColor: "#f9aa33",
          data: this.valueAdd
        },
        {
          label: "Cancellations",
          backgroundColor: "#E65100",
          data: this.valueCancel
        }
      ]
    }
    return data
  }

  get labels() {
    if (this.stats) {
      return this.stats.data.map(
        (el: { month: string; cancellations: number }) => {
          const d = el.month.split("-")
          const m = this.monthNames[Number(d[1]) - 1].slice(0, 3)
          const y = d[0].slice(-2)
          return `${m} '${y}`
        }
      )
    } else {
      return []
    }
  }
  get valueCancel() {
    if (this.stats) {
      const data = this.stats.data.map(
        (el: { month: string; cancellations: number }) => el.cancellations
      )
      return data
    } else return []
  }

  get valueAdd() {
    if (this.stats) {
      const data = this.stats.data.map(
        (el: { month: string; additions: number }) => el.additions
      )
      return data
    } else return []
  }

  get valueApp() {
    if (this.stats) {
      const data = this.stats.data.map(
        (el: { month: string; applications: number }) => el.applications
      )
      return data
    } else return []
  }

  get chartOptions() {
    return {
      animation: true,
      plugins: { legend: { display: false } },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: { display: false },
          ticks: {
            precision: 0
          }
        }
      }
    }
  }
}
