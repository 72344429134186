import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class CreditCardNotification extends Model<CreditCardNotification> {
  constructor(response?: CreditCardNotification) {
    super(response as CreditCardNotification)
    if (response) {
      this.attributes.date = DateHelper.stringToDate(this.attributes.date)
    }
  }
  id: string
  attributes!: {
    name: string
    date: Date
    view_doc_url: string
    download_doc_url: string
    classification: string
    use_case: string
  }
  static get modelName() {
    return "CreditCardNotification"
  }
}
