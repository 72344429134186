import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class BoardMeeting extends Model<BoardMeeting> {
  constructor(response?: BoardMeeting) {
    super(response as BoardMeeting)
    if (response) {
      this.attributes.date = DateHelper.stringToDate(this.attributes.date)
    }
  }
  id: string
  attributes!: {
    date: Date
    classification: string
    location: string
  }
  static get modelName() {
    return "BoardMeeting"
  }
}
