import pluralize from "pluralize"
import { camelCase } from "change-case"

/*
 * Converts the name of an included model in the api response, to it's Vuex mutator name.
 * It assumes that the mutator name follows the pattern `model/set`
 */
export default function(api: string) {
  const plural = pluralize(camelCase(api))
  return `${plural}/set`
}
