var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageTemplate',{attrs:{"type":"Member","name":"Member Merge","icon":"mdi-cog"}},[(_vm.$can('merge', 'Member'))?_c('div',[_c('v-row',[_c('v-col',{staticClass:"mb-2",attrs:{"offset":"2"}},[_c('p',{staticClass:"title"},[_vm._v("Important information regarding merging members:")]),_c('ul',[_c('li',[_vm._v(" Merging of members does not change any of the destination member personal information data like name, social or date of birth. ")]),_c('li',[_vm._v("Merging of members CANNOT be undone")]),_c('li',[_vm._v("The source member will be deleted")]),_c('li',[_vm._v(" The source member may show up in the search results for a time following the merge as the search robots get updated ")]),_c('li',[_vm._v(" All policies, claims, payment methods, payments, etc. from the source member will be transfered to the destination member ")])])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('CardTemplate',{attrs:{"title":"Permanent / Correct / Destination Member","icon":"mdi-account","iconColor":"secondary"}},[_c('v-menu',{attrs:{"bottom":"","open-on-click":false,"close-on-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"id":"js-dest-search","clearable":"","hide-details":false,"label":"Member"},on:{"keyup":_vm.doSearchDest,"focus":function($event){_vm.menuDestination = true},"blur":_vm.blurDest,"click:clear":function($event){_vm.menuDestination = true
                  _vm.destMemberName = ''
                  _vm.destSearchResults = []
                  _vm.destMemberId = null}},model:{value:(_vm.destMemberName),callback:function ($$v) {_vm.destMemberName=$$v},expression:"destMemberName"}},on))]}}],null,false,1236995274),model:{value:(_vm.menuDestination),callback:function ($$v) {_vm.menuDestination=$$v},expression:"menuDestination"}},[(_vm.destSearchResults.length)?_c('v-list',_vm._l((_vm.destSearchResults),function(result){return _c('v-list-item',{key:result.target_id,staticClass:"js-member-result",attrs:{"two-line":""},on:{"click":function($event){return _vm.selectDestMember(result)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(result.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(result.subtitle))])],1)],1)}),1):_vm._e()],1),(_vm.destMember && _vm.destMember.id)?_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('th',[_vm._v("SSN")]),_c('td',[_vm._v(_vm._s(_vm.formatSSN(_vm.destMember.attributes.ssn)))])]),_c('tr',[_c('th',[_vm._v("Last")]),_c('td',[_vm._v(_vm._s(_vm.destMember.attributes.last_name))])]),_c('tr',[_c('th',[_vm._v("First")]),_c('td',[_vm._v(_vm._s(_vm.destMember.attributes.first_name))])]),_c('tr',[_c('th',[_vm._v("Middle")]),_c('td',[_vm._v(_vm._s(_vm.destMember.attributes.middle_name))])]),_c('tr',[_c('th',[_vm._v("DOB")]),_c('td',[_c('DateField',{attrs:{"value":_vm.destMember.attributes.dob,"display":"true"}})],1)])])]):_vm._e()],1)],1),_c('v-col',[_c('CardTemplate',{attrs:{"title":"Wrong / Deleting / Source  Member","icon":"mdi-account-off","iconColor":"secondary"}},[_c('v-menu',{attrs:{"bottom":"","open-on-click":false,"close-on-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"id":"js-source-search","clearable":"","hide-details":false,"label":"Member"},on:{"keyup":_vm.doSearchSource,"focus":function($event){_vm.menuSource = true},"blur":_vm.blurSource,"click:clear":function($event){_vm.menuSource = true
                  _vm.sourceMemberName = ''
                  _vm.sourceSearchResults = []
                  _vm.sourceMemberId = null}},model:{value:(_vm.sourceMemberName),callback:function ($$v) {_vm.sourceMemberName=$$v},expression:"sourceMemberName"}},on))]}}],null,false,3655128785),model:{value:(_vm.menuSource),callback:function ($$v) {_vm.menuSource=$$v},expression:"menuSource"}},[(_vm.sourceSearchResults.length)?_c('v-list',_vm._l((_vm.sourceSearchResults),function(result){return _c('v-list-item',{key:result.target_id,staticClass:"js-member-result",attrs:{"two-line":""},on:{"click":function($event){return _vm.selectSourceMember(result)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(result.title))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(result.subtitle))])],1)],1)}),1):_vm._e()],1),(_vm.sourceMember && _vm.sourceMember.id)?_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('th',[_vm._v("SSN")]),_c('td',[_vm._v(_vm._s(_vm.formatSSN(_vm.sourceMember.attributes.ssn)))])]),_c('tr',[_c('th',[_vm._v("Last")]),_c('td',[_vm._v(_vm._s(_vm.sourceMember.attributes.last_name))])]),_c('tr',[_c('th',[_vm._v("First")]),_c('td',[_vm._v(_vm._s(_vm.sourceMember.attributes.first_name))])]),_c('tr',[_c('th',[_vm._v("Middle")]),_c('td',[_vm._v(_vm._s(_vm.sourceMember.attributes.middle_name))])]),_c('tr',[_c('th',[_vm._v("DOB")]),_c('td',[_c('DateField',{attrs:{"value":_vm.sourceMember.attributes.dob,"display":"true"}})],1)])])]):_vm._e(),_c('v-btn',{attrs:{"id":"js-merge","disabled":!_vm.destMemberId || !_vm.sourceMemberId,"small":"","color":"secondary"},on:{"click":_vm.doMerge}},[_vm._v("merge this member into the other and delete this record")])],1)],1)],1)],1):_c('div',{attrs:{"id":"js-forbidden"}},[_vm._v(" Ask a member with the system admin role to help with member merging. ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }