
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Attorney } from "@/types/Attorney"
import { Claim } from "@/types/Claim"
import { cloneDeep } from "lodash"

@Component({
  components: {
    CardTemplate,
    DialogTemplate,
    PhoneNumber
  }
})
export default class ClaimAttorney extends Vue {
  @Prop() private attorney!: Attorney
  @Prop() private claim!: Claim
  @Prop() private isDefense!: boolean

  private selectedAttorney = this.attorney ? this.attorney.id : null

  @Watch("attorney")
  onAttorneyChange() {
    this.selectedAttorney = this.attorney ? this.attorney.id : null
  }

  get attorneys() {
    return this.$store.getters["attorneys/getAll"]
  }

  fetchAttorneys() {
    this.$store.dispatch("attorneys/loadAll")
  }

  updateAttorney() {
    const claim = cloneDeep(this.claim)
    if (this.isDefense) {
      claim.attributes.defense_attorney_id = this.selectedAttorney
        ? parseInt(this.selectedAttorney)
        : null
    } else {
      claim.attributes.attorney_id = this.selectedAttorney
        ? parseInt(this.selectedAttorney)
        : null
    }

    this.$store.dispatch("claims/update", claim)
    this.$emit("update", claim)
  }
}
