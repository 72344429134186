
import { Component, Vue } from "vue-property-decorator"
import { User } from "@/types/User"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import UserRolesChecklist from "@/components/UserRolesChecklist.vue"

@Component({
  components: {
    CardTemplate,
    UserRolesChecklist,
    DialogTemplate
  }
})
export default class NewUser extends Vue {
  user: User = new User()
  roles = 0

  get userRoles() {
    const roles = this.$store.getters["config/getUserRoles"]
    return roles ? roles : []
  }

  cancel() {
    this.user = new User()
    this.roles = 0
  }

  saveUser() {
    this.user.attributes.roles = this.roles
    this.$store.dispatch("users/create", this.user)

    this.user = new User()
    this.roles = 0
  }

  validEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }
}
