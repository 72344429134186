import Model from "./Model"

export class ClaimFormDefinition extends Model<ClaimFormDefinition> {
  id: string
  attributes!: {
    name: string
    product_id: number
    product_type: string
    default_delivery_method: string
    updated_at: string
  }
  static get modelName() {
    return "ClaimFormDefinition"
  }
}
