
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { BoardMeetingAdmin } from "@/types/BoardMeetingAdmin"

@Component({
  components: {
    DialogTemplate
  }
})
export default class BoardMeetingAttendeeDialog extends Vue {
  @Prop() private admin!: BoardMeetingAdmin
  @Prop() private btnLabel!: string
  @Prop() private title!: string
  @Prop() private boardMeetingId!: number

  private editAdmin = new BoardMeetingAdmin()

  private name = ""
  private company = ""
  private adminTitle = ""

  get hideOpener() {
    return !this.btnLabel
  }

  get boardMeeting() {
    return this.$store.getters["boardMeetings/getById"](this.boardMeetingId)
  }

  created() {
    if (this.admin) {
      this.editAdmin = cloneDeep(this.admin)
    }
  }

  @Watch("boardMeetingId")
  onboardMeetingIdChanged(id: number) {
    if (id) {
      if (this.admin) {
        this.editAdmin = cloneDeep(this.admin)
      }
    }
  }

  open() {
    this.editAdmin = cloneDeep(this.admin)

    this.name = this.admin.attributes.name
    this.company = this.admin.attributes.company
    this.adminTitle = this.admin.attributes.title
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  cancel() {
    this.editAdmin = cloneDeep(this.admin)
  }

  async save() {
    this.editAdmin.attributes.name = this.name
    this.editAdmin.attributes.company = this.company
    this.editAdmin.attributes.title = this.adminTitle

    if (this.admin.id) {
      await this.$store.dispatch("boardMeetingAdmins/update", this.editAdmin)
      this.$emit("save")
      this.editAdmin = cloneDeep(this.admin)
    } else {
      await this.$store.dispatch("boardMeetingAdmins/create", this.editAdmin)
      this.$emit("save")
      this.editAdmin = cloneDeep(this.admin)
    }

    this.name = ""
    this.company = ""
    this.adminTitle = ""
  }
}
