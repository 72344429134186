
import { Address as AddressType } from "@/types/Address"
import { Department } from "@/types/Department"
import { Employer } from "@/types/Employer"
import DepartmentBilling from "@/components/DepartmentBilling.vue"
import DepartmentDirectorInfo from "@/components/DepartmentDirectorInfo.vue"
import DepartmentPresident from "@/components/DepartmentPresident.vue"
import DepartmentVicePresident from "@/components/DepartmentVicePresident.vue"
import DepartmentSection from "@/components/DepartmentSection.vue"
import DepartmentSecretary from "@/components/DepartmentSecretary.vue"
import DepartmentTreasurer from "@/components/DepartmentTreasurer.vue"
import { cloneDeep } from "lodash"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    DepartmentBilling,
    DepartmentDirectorInfo,
    DepartmentPresident,
    DepartmentVicePresident,
    DepartmentSection,
    DepartmentSecretary,
    DepartmentTreasurer
  }
})
export default class DepartmentInfoLtd extends Vue {
  @Prop() private department!: Department
  @Prop() private employer!: Employer

  private editPresident = false
  private editVP = false
  private editTreasurer = false
  private editSecretary = false
  private valid = false

  private editDepartment = cloneDeep(this.department)

  @Watch("department", { deep: true })
  onDepartmentChange(value: Department) {
    if (value) {
      this.resetDepartment()
    }
  }

  get departmentAddress(): AddressType {
    if (this.department && this.department.attributes.address_id) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.address_id
      )
    }
    return new AddressType()
  }

  get presidentAddress(): AddressType {
    if (this.department && this.department.attributes.president_address_id) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.president_address_id
      )
    }
    return new AddressType()
  }

  get vpAddress(): AddressType {
    if (this.department && this.department.attributes.vp_address_id) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.vp_address_id
      )
    }
    return new AddressType()
  }

  get treasurerAddress(): AddressType {
    if (this.department && this.department.attributes.treasurer_address_id) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.treasurer_address_id
      )
    }
    return new AddressType()
  }

  get secretaryAddress(): AddressType {
    if (this.department && this.department.attributes.secretary_address_id) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.secretary_address_id
      )
    }
    return new AddressType()
  }

  get billingAddress(): AddressType {
    if (
      this.department &&
      this.department.attributes.billing_contact_address_id
    ) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.billing_contact_address_id
      )
    }
    return new AddressType()
  }

  async save(payload: {
    address: AddressType
    parentAddressAttributeName: string
  }) {
    if (payload.address) {
      const addressHasValues = Object.values(payload.address.attributes).some(
        el => {
          return el
        }
      )

      if (addressHasValues) {
        let action = ""
        if (payload.address.id) {
          action = "update"
        } else {
          action = "create"
        }

        const data = await this.$store.dispatch(
          "addresses/" + action,
          payload.address
        )

        if (data.status == 200 && action == "create") {
          const s = `this.editDepartment.attributes.${payload.parentAddressAttributeName} = ${data.data.data.id}`
          eval(s)
        }
      }
    }

    if (this.editDepartment.attributes.status == undefined)
      this.editDepartment.attributes.status = ""
    await this.$store.dispatch("departments/update", this.editDepartment)
    this.resetDepartment()
  }

  cancel() {
    this.resetDepartment()
  }

  resetDepartment() {
    this.editDepartment = cloneDeep(this.department)
  }

  clone(addr: AddressType | null) {
    if (addr) {
      return cloneDeep(addr)
    } else {
      return new AddressType()
    }
  }
}
