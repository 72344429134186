
import { Component, Prop, Vue } from "vue-property-decorator"

import { IifableDisbursement } from "@/types/IifableDisbursement"

import AccountChip from "@/components/AccountChip.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"

@Component({
  components: {
    AccountChip,
    CardTemplate,
    CurrencyField,
    DateField,
    PagedDataTable
  }
})
export default class IifDisbursementsList extends Vue {
  @Prop() private disbursements: IifableDisbursement[]
  @Prop() private canCreate: boolean
  @Prop() private loading: boolean
  @Prop() private title: string

  private date: Date = new Date()

  get total() {
    if (this.disbursements.length == 0) {
      return "0"
    }
    return this.disbursements
      .map((el: IifableDisbursement) => el.amount)
      .reduce((prev: string, cur: string) => {
        return (parseFloat(prev) + parseFloat(cur)).toString()
      }, "0")
  }

  get headers() {
    return [
      { text: "CLAIM", value: "claim_number", class: "text-left caption" },
      { text: "DATE", value: "date", class: "text-left caption" },
      { text: "MEMBER", value: "member_name", class: "text-left caption" },
      { text: "COMMENTS", value: "comments", class: "text-left caption" },
      { text: "PAYMENTS", value: "payee", class: "text-left caption" },
      { text: "AMOUNT", value: "amount", class: "text-left caption" },
      { text: "TYPE", value: "payment_type", class: "text-left caption" },
      { text: "PLAN", value: "classification", class: "text-left caption" }
    ]
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sort(items: IifableDisbursement[], sortBy: any, sortDesc: any) {
    switch (sortBy[0]) {
      case "claim_number":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = a.claim_number
          let second = b.claim_number

          if (sortDesc[0]) {
            first = b.claim_number
            second = a.claim_number
          }
          if (!first) {
            return 1
          }
          return first.localeCompare(second)
        })
        break

      case "date":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = a.date.getTime()
          let second = b.date.getTime()

          if (sortDesc[0]) {
            first = b.date.getTime()
            second = a.date.getTime()
          }
          const result = first - second
          return result
        })
        break

      case "member_name":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = a.member_name
          let second = b.member_name

          if (sortDesc[0]) {
            first = b.member_name
            second = a.member_name
          }
          if (!first) {
            return 1
          }
          return first.localeCompare(second)
        })
        break

      case "comment":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = a.comments
          let second = b.comments

          if (sortDesc[0]) {
            first = b.comments
            second = a.comments
          }
          if (!first) {
            return 1
          }
          return first.localeCompare(second)
        })
        break

      case "payee":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = a.payee
          let second = b.payee

          if (sortDesc[0]) {
            first = b.payee
            second = a.payee
          }
          if (!first) {
            return 1
          }
          return first.localeCompare(second)
        })
        break

      case "amount":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = parseFloat(a.amount)
          let second = parseFloat(b.amount)

          if (sortDesc[0]) {
            first = parseFloat(b.amount)
            second = parseFloat(a.amount)
          }

          return first - second
        })
        break

      case "payment_type":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = a.payment_type
          let second = b.payment_type
          if (sortDesc[0]) {
            first = b.payment_type
            second = a.payment_type
          }
          if (!first) {
            return 1
          }
          return first.localeCompare(second)
        })
        break

      case "classification":
        items.sort((a: IifableDisbursement, b: IifableDisbursement) => {
          let first = a.classification
          let second = b.classification
          if (sortDesc[0]) {
            first = b.classification
            second = a.classification
          }
          if (!first) {
            return 1
          }
          return first.localeCompare(second)
        })
        break
    }

    return items
  }
}
