
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import pluralize from "pluralize"
import { camelCase } from "change-case"
import { Following } from "@/types/Following"

@Component({
  components: {
    CardTemplate
  }
})
export default class FollowingsList extends Vue {
  @Prop() private followings!: Array<Following>

  goToFollowTarget(following: Following) {
    this.$router.push({
      name: following.attributes.followable_type,
      params: { id: following.attributes.followable_id.toString() }
    })
  }

  followingName(following: Following) {
    let name = null
    const targetModel = pluralize(
      camelCase(following.attributes.followable_type)
    )
    const target = this.$store.getters[`${targetModel}/getById`](
      following.attributes.followable_id
    )

    if (target && following.attributes.followable_type == "Department") {
      name = target.attributes.name
    } else if (target && following.attributes.followable_type == "Member") {
      name = `${target.attributes.first_name} ${target.attributes.last_name}`
    } else if (target && following.attributes.followable_type == "Claim") {
      name = target.attributes.number
    } else if (target && following.attributes.followable_type == "Policy") {
      name = target.attributes.number
        ? target.attributes.number
        : target.attributes.name
    }

    return name
  }
}
