import { MutationTree, ActionTree } from "vuex"
import { Employer } from "@/types/Employer"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Employer[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Employer>, RootState> = {}
const mutations: MutationTree<State<Employer>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
