import Model from "./Model"

export class DisbursementType extends Model<DisbursementType> {
  id: string
  attributes!: {
    name: string
    product_type: string
  }
  static get modelName() {
    return "DisbursementType"
  }
}
