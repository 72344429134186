var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_c('v-card',{staticClass:"px-4 pb-4",attrs:{"flat":"","id":"js-unposted"}},[_c('p',{staticClass:"overline"},[_vm._v("Scheduled & Cancelled Payments")]),(_vm.payments.length > 0)?_c('v-simple-table',{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left overline"},[_vm._v("amount")]),_c('th',{staticClass:"text-left overline"},[_vm._v("entered")]),_c('th',{staticClass:"text-left overline"},[_vm._v("method")]),_c('th',{staticClass:"text-left overline"},[_vm._v("memo")]),_c('th',{staticClass:"text-left overline"},[_vm._v("status")]),_c('th',{staticClass:"text-left overline"},[_vm._v("in file")]),_c('th')])]),_c('tbody',_vm._l((_vm.payments),function(payment){return _c('tr',{key:payment.id,class:`js-payment payment-row ${
              payment.attributes.status == 'Scheduled'
                ? 'scheduled-row'
                : 'cancelled-row'
            }`},[_c('td',{staticClass:"js-amount"},[_c('CurrencyField',{attrs:{"value":payment.attributes.amount,"display":true}})],1),_c('td',{staticClass:"js-date"},[_c('DateField',{attrs:{"value":payment.attributes.created_at,"display":true}})],1),_c('td',{staticClass:"js-payment-method"},[_vm._v(" "+_vm._s(payment.attributes.payment_method)+" ")]),_c('td',{staticClass:"js-memo"},[_vm._v(" "+_vm._s(payment.attributes.memo)+" ")]),_c('td',{staticClass:"js-status"},[_vm._v(" "+_vm._s(payment.attributes.status)+" ")]),_c('td',{staticClass:"js-file-check"},[(
                payment.attributes.nacha_id ||
                  payment.attributes.credit_card_batch_id
              )?_c('v-icon',{attrs:{"medium":"","color":"secondary"}},[_vm._v("mdi-check-bold")]):_vm._e()],1),_c('td',{staticClass:"js-actions"},[(payment.attributes.status == 'Scheduled')?_c('v-btn',{staticClass:"js-cancel",attrs:{"x-small":"","color":"error","disabled":Number(payment.attributes.nacha_id) > 0 ||
                  Number(payment.attributes.credit_card_batch_id) > 0},on:{"click":function($event){return _vm.initiateCancel(payment)}}},[_vm._v("cancel")]):_vm._e()],1)])}),0)]):_c('p',[_vm._v("There are no Scheduled Payments!")])],1),_c('DialogTemplate',{ref:"cancelDialog",attrs:{"id":"js-cancel-dialog","title":"Cancel Payment","hideOpener":true},on:{"save":function($event){return _vm.cancelPayment()}}},[(_vm.cancelPaymentItem)?_c('span',[_c('p',{staticClass:"display-1 my-4",attrs:{"align":"center"}},[_vm._v(" Cancel the scheduled payment? ")]),_c('p',{attrs:{"align":"center"}},[_c('span',{staticClass:"display-2 blue--text"},[_c('CurrencyField',{attrs:{"value":_vm.cancelPaymentItem.attributes.amount,"display":true}})],1)]),_c('v-alert',{attrs:{"dense":"","type":"error","align":"center"}},[_vm._v(" This will not add another payment for re-submit!")])],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }