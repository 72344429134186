
import pluralize from "pluralize"
import { camelCase } from "change-case"
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import AccountChip from "@/components/AccountChip.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import DisbursementDetails from "@/components/DisbursementDetails.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import RecoveryDetails from "@/components/RecoveryDetails.vue"
import RecoverableAdjustmentDetails from "@/components/RecoverableAdjustmentDetails.vue"
import { Claim } from "@/types/Claim"
import { Disbursement } from "@/types/Disbursement"
import { DisbursementType } from "@/types/DisbursementType"
import { Member } from "@/types/Member"
import { Recovery } from "@/types/Recovery"
import { RecoverableAdjustment } from "@/types/RecoverableAdjustment"
import UserInitials from "@/components/UserInitials.vue"
import { cloneDeep } from "lodash"
import DateHelper from "./utils/DateHelper"
import { every } from "lodash"

@Component({
  components: {
    AccountChip,
    ConfirmationTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    DisbursementDetails,
    PagedDataTable,
    RecoveryDetails,
    RecoverableAdjustmentDetails,
    UserInitials
  }
})
export default class DisbursementsTable extends Vue {
  @Prop() private claim!: Claim
  @Prop() private table_id!: string
  @Prop() private startingPerPage!: number
  @Prop() private productType!: string
  @Prop() private status!: string
  @Prop() private classification!: string
  @Prop() private userAdminOnly!: boolean
  @Prop() private truncated!: { type: boolean; default: false }
  @Prop() private adminId!: number

  private createError = ""
  private editKey: string = null
  private editItem: Disbursement = new Disbursement()
  private editAdjust: RecoverableAdjustment = new RecoverableAdjustment()
  private expanded: (Disbursement | RecoverableAdjustment | Recovery)[] = []
  private editError = ""

  private disbursements: Disbursement[] = []
  private loading = false
  private total = 5
  private perPage = this.startingPerPage
  private currentPage = 1
  private decrease = true
  private reason = ""
  private amountAdjust = ""
  private checkNumberEdit: number = null
  private selected: Array<Disbursement> = []
  private selectedTotal = "0"
  private showRejectConfirm = false
  private showVoidConfirm = false
  private rejectedItem: Disbursement | Recovery = null
  private voidItem: Disbursement = null
  private voidDate = new Date()
  private voidAdd = true
  private options = {
    sortBy: "attributes.date",
    sortDesc: true,
    mustSort: true
  }

  private adjustmentReasons = [
    "Bankruptcy",
    "Claim Reclassed to NIOD",
    "Collections Failure",
    "Collections/Legal proceedings",
    "NIOD - Lost case",
    "NIOD - Dismissed case",
    "NIOD - Failed to pursue",
    "No Duplicated Benefits",
    "Offset/recover fm future benefits",
    "Waived"
  ]

  created() {
    this.loadDisbursements(1, this.startingPerPage)

    if (this.claim) {
      this.$store.dispatch("disbursementTypes/loadBy", {
        product_type: this.claim.attributes.product_type
      })
    } else {
      this.$store.dispatch("disbursementTypes/loadAll")
    }
  }

  async loadDisbursements(page: number, perPage: number) {
    this.loading = true

    let sort = this.options.sortBy
    switch (this.options.sortBy) {
      case "member":
        sort = "last_name"
        break
      case "claim":
        sort = "number"
        break
      case "attributes.status":
        sort = !this.claim ? "disbursements.status" : "status"
        break
      case "attributes.disbursement_type":
        sort = "disbursement_types.name"
        break
      default:
        sort = this.options.sortBy.replace("attributes.", "")
    }

    const filter: {
      page: number
      per_page: number
      order: string
      order_direction: string
      include: string
      "product.product_type"?: string
      status?: string | string[]
      claim_id?: string
      "policy.classification"?: string
      "claim.admin_id"?: number
    } = {
      page: page,
      per_page: perPage,
      order: sort,
      order_direction: this.options.sortDesc ? "DESC" : "ASC",
      include: "claim.policy.member"
    }

    if (this.claim) {
      filter.claim_id = this.claim.id
    } else {
      if (this.productType && this.productType != "all") {
        filter["product.product_type"] = this.productType
      }

      if (!this.status || this.status == "all") {
        filter.status = ["Pending", "Approved", "Paid"]
      } else if (this.status) {
        filter.status = this.status
      }
    }

    if (this.classification !== null) {
      filter["policy.classification"] = this.classification
    }

    if (this.userAdminOnly) {
      const currentUser = this.$store.getters["authentication/getUser"]
      filter["claim.admin_id"] = currentUser.id
    } else if (this.adminId > 0) {
      filter["claim.admin_id"] = this.adminId
    }

    const action = this.claim ? "loadCombined" : "loadBy"
    const data = await this.$store.dispatch(`disbursements/${action}`, filter)

    const claimIds = data?.data?.data
      .map(
        (d: { attributes: { claim_id: null | number } }) =>
          d.attributes.claim_id
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((n: any) => n)

    const claims = this.$store.getters["users/getBy"]({
      id: claimIds
    })

    const adminIds = claims
      .map(
        (c: { attributes: { admin_id: null | number } }) =>
          c.attributes.admin_id
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((n: any) => n)

    this.$store.dispatch("users/loadBy", { id: adminIds })

    this.disbursements = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  getDisbursements(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadDisbursements(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  goToDisbursement(item: Disbursement | Recovery | RecoverableAdjustment) {
    if (item.type == "disbursement") {
      this.$router.push({
        name: "Disbursement",
        params: { id: item.id.toString() }
      })
    }
  }

  claimAdmin(claimId: number) {
    const claim = this.$store.getters["claims/getById"](claimId)

    if (claim && claim.attributes.admin_id) {
      const user = this.$store.getters["users/getById"](
        claim.attributes.admin_id
      )
      return user
    } else {
      return null
    }
  }

  @Watch("claim")
  claimChanged() {
    this.loadDisbursements(1, this.perPage)
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadDisbursements(1, this.perPage)
  }

  @Watch("productType")
  productTypeChanged() {
    this.loadDisbursements(1, this.perPage)
  }

  @Watch("status")
  statusChanged() {
    this.loadDisbursements(1, this.perPage)
  }

  @Watch("classification")
  classificationChanged() {
    this.loadDisbursements(1, this.perPage)
  }

  @Watch("userAdminOnly")
  adminOnlyChanged() {
    this.loadDisbursements(1, this.perPage)
  }

  @Watch("adminId")
  adminIdChanged() {
    this.loadDisbursements(1, this.perPage)
  }

  @Watch("selected")
  selectedChanged() {
    if (this.selected.length == 0) {
      this.selectedTotal = "0"
    }

    this.selectedTotal = this.selected
      .map((el: Disbursement) => el.attributes.amount)
      .reduce((prev: string, cur: string) => {
        return (parseFloat(prev) + parseFloat(cur)).toString()
      }, "0")
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getTypedObject(dr: any) {
    return dr.type == "disbursement" ? new Disbursement(dr) : new Recovery(dr)
  }

  get formattedDisbursements() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatted: any[] = this.disbursements ? this.disbursements : []
    formatted.forEach(el => {
      el.member = this.whichMember(
        this.whichClaim(el.attributes.claim_id).attributes.policy_id
      )
      el.claim = this.whichClaim(el.attributes.claim_id)
      el.key = `${el.type}-${el.id}`
    })

    return formatted
  }

  getClassification(claimId: number) {
    const claim = this.$store.getters["claims/getById"](claimId)

    const policy = this.$store.getters["policies/getById"](
      claim?.attributes?.policy_id
    )

    return policy?.attributes?.classification
  }

  alternateClass(item: { type: string; attributes: { status: string } }) {
    if (item.type == "recovery") return "recovery-amount"
    if (item.type == "recoverable_adjustment") return "adjustment-amount"
    if (item.type == "disbursement" && item.attributes.status == "Rejected")
      return "rejected-amount"
    return ""
  }

  isNumber(evt: KeyboardEvent) {
    if (isNaN(parseInt(evt.key))) {
      evt.preventDefault()
    } else {
      return true
    }
  }

  calculateNonRecoverable(item: Disbursement) {
    const a = Number(item.attributes?.amount)
    const b = Number(item.attributes?.recoverable)
    const s = (a - b).toString()

    return s
  }

  get headers() {
    const listColumns = [
      {
        text: "MEMBER",
        value: "member",
        sortable: true,
        class: "text-left caption js-header-member"
      },
      {
        text: "CLAIM",
        value: "claim",
        sortable: true,
        class: "text-left caption js-header-claim"
      }
    ]

    const allColumns: (
      | {
          text: string
          value: string
          sortable: boolean
          class: string
          sort?: undefined
        }
      | {
          text: string
          value: string
          sortable: boolean
          class: string
          sort:
            | ((a: Claim, b: Claim) => 1 | -1)
            | ((a: Date, b: Date) => 1 | -1)
            | ((a: Member, b: Member) => 1 | -1)
        }
    )[] = [
      {
        text: "",
        value: "dirty",
        sortable: false,
        class: "text-left caption js-header-dirty"
      },
      {
        text: "DATE",
        value: "attributes.date",
        sortable: true,
        class: "text-left caption js-header-date",
        sort: DateHelper.sortByDate
      },
      {
        text: "TOTAL",
        value: "attributes.amount",
        sortable: true,
        class: "text-left caption js-header-total"
      },
      {
        text: "RA",
        value: "attributes.recoverable",
        sortable: true,
        class: "text-left caption js-header-recoverable"
      },
      {
        text: "NRA",
        value: "nonRecoverable",
        sortable: false,
        class: "text-left caption js-header-non-recoverable"
      },
      {
        text: "ADJ",
        value: "attributes.adjust_amount",
        sortable: true,
        class: "text-left caption js-header-adjusted"
      },
      {
        text: "OVR",
        value: "attributes.overpayment",
        sortable: true,
        class: "text-left caption js-header-overpayment"
      },
      {
        text: "ADMIN",
        value: "admin",
        sortable: true,
        class: "text-left caption js-header-claim-admin"
      },
      {
        text: "STATUS",
        value: "attributes.status",
        sortable: true,
        class: "text-left caption js-header-status"
      },
      {
        text: "TYPE",
        value: "attributes.disbursement_type",
        sortable: !this.claim,
        class: "text-left caption js-header-type"
      },
      {
        text: "CHECK",
        value: "attributes.check_number",
        sortable: true,
        class: "text-left caption js-header-check-number"
      },
      {
        text: "COMMENTS",
        value: "attributes.comments",
        sortable: true,
        class: "text-left caption js-header-comments"
      },
      {
        text: "PLAN",
        value: "attributes.classification",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "BEN",
        value: "attributes.beneficiary_id",
        sortable: true,
        class: "text-left caption js-header-beneficiary"
      },
      {
        text: "ACH",
        value: "nacha",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        class: "text-left caption js-header-actions"
      }
    ]

    if (this.truncated) {
      // splice(0, 2) => Starting at index position 0, remove two elements
      allColumns.splice(7, 1)
      allColumns.splice(11, 1)
    }

    if (!this.claim) {
      allColumns.splice(1, 0, ...listColumns)
    }

    return allColumns
  }

  get approvableDisbursements() {
    if (this.selected.length == 0) {
      return false
    }
    return every(
      this.selected,
      (disbursement: Disbursement) =>
        disbursement.attributes.status == "Pending"
    )
  }

  disbursementTypes(type: string) {
    const disbursementTypes = this.$store.getters["disbursementTypes/getBy"]({
      product_type: type
    })

    return disbursementTypes.map((el: DisbursementType) => {
      return { value: parseInt(`${el.id}`), text: el.attributes.name }
    })
  }

  whichClaim(claimId: string) {
    const claim = this.$store.getters["claims/getById"](claimId)
    return claim ? claim : new Claim()
  }

  disbursementTypeById(id: number) {
    const disbursementType = this.$store.getters["disbursementTypes/getById"](
      id
    )
    return disbursementType && disbursementType.attributes
      ? disbursementType.attributes.name
      : null
  }

  whichMember(policyId: string) {
    const policy = this.$store.getters["policies/getById"](policyId)
    return policy
      ? this.$store.getters["members/getById"](policy.attributes.member_id)
      : new Member()
  }

  currencyToDisplay(cur: string) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    })

    return formatter.format(parseFloat(cur))
  }

  isDirty(item: Disbursement | Recovery | RecoverableAdjustment) {
    let type = "recoveries"
    if (item.type == "disbursement") {
      type = "disbursements"
    }

    return this.$store.getters[`${type}/getById`](item.id)?.dirty
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      NewDisbursement: {
        connected() {
          console.log("Disbursements-NewObjectChannel connected")
        },
        rejected() {
          console.log("NewObjectChannel rejected")
        },
        received(data: {
          model_type: string
          id: string
          related_id: string
          related_type: string
          whodunnit: string
        }) {
          //the delay is necessary to avoid the TotalsRecalculatedChannel
          //from reloading a claim immediately after it's set to dirty
          setTimeout(() => {
            that.handleNew(data)
          }, 2500)
        }
      }
    }
  }

  handleNew(data: {
    model_type: string
    id: string
    related_id: string
    related_type: string
    whodunnit: string
  }) {
    if (data.model_type == "disbursements" || data.model_type == "recoveries") {
      if (!this.claim) {
        this.loadDisbursements(this.currentPage, this.perPage)
        this.$emit("update-totals")
      } else if (
        data.related_id &&
        data.related_type &&
        this.user &&
        data.whodunnit != "n/a" &&
        data.whodunnit != `${this.user.first_name} ${this.user.last_name}`
      ) {
        this.$store.commit(`${data.related_type}/setDirty`, {
          id: data.related_id,
          doIt: true
        })
      }
    }
  }

  mounted() {
    this.$cable.subscribe(
      {
        channel: "NewObjectChannel",
        room: "public"
      },
      "NewDisbursement"
    )
  }

  get user() {
    return this.$store.getters["authentication/getUser"]
  }

  openEdit(item: Disbursement | Recovery | RecoverableAdjustment, key: string) {
    this.editError = ""
    this.expanded = [item]

    if (item.type == "recoverable_adjustment") {
      this.editAdjust = JSON.parse(JSON.stringify(item))
      this.decrease = this.editAdjust.attributes.amount > 0
      this.editAdjust.attributes.amount = parseFloat(
        this.currencyToDisplay(this.editAdjust.attributes.amount.toString())
          .replace("$", "")
          .replace(",", "")
      )
      this.editAdjust.attributes.amount = Math.abs(
        this.editAdjust.attributes.amount
      )
      this.amountAdjust = this.editAdjust.attributes.amount.toString()
      this.reason = this.editAdjust.attributes.reason
    } else {
      this.editItem = JSON.parse(JSON.stringify(item))
      this.editItem.attributes.amount = this.currencyToDisplay(
        this.editItem.attributes.amount
      )
        .replace("$", "")
        .replace(",", "")
    }

    this.editKey = key
  }

  async saveEdit() {
    this.editError = this.checkIfValid(this.editItem)
    if (this.editError == "") {
      if (this.editItem.type === "recovery") {
        await this.$store.dispatch("recoveries/update", this.editItem)
      } else {
        if (this.editItem.attributes.disbursement_type_id == undefined) {
          this.editItem.attributes.disbursement_type_id = null
        }
        await this.$store.dispatch("disbursements/update", this.editItem)
      }

      this.editItem = new Disbursement()
      this.editKey = null
      this.expanded = []
      this.loadDisbursements(this.currentPage, this.perPage)
    }
  }

  async saveAdjust() {
    this.editError = this.checkIfValidAdjust(this.editAdjust)
    if (this.editError == "") {
      this.editAdjust.attributes.reason = this.reason

      const amount = this.decrease
        ? Math.abs(parseFloat(this.amountAdjust))
        : Math.abs(parseFloat(this.amountAdjust)) * -1

      this.editAdjust.attributes.amount = amount

      await this.$store.dispatch(
        "recoverableAdjustments/update",
        this.editAdjust
      )

      this.editAdjust = new RecoverableAdjustment()
      this.editKey = null
      this.expanded = []
      this.loadDisbursements(this.currentPage, this.perPage)
    }
  }

  async toggleOverpayment(recovery: Recovery) {
    const shadowRecovery = JSON.parse(JSON.stringify(recovery))
    shadowRecovery.attributes.is_overpayment = !recovery.attributes
      .is_overpayment

    await this.$store.dispatch("recoveries/update", shadowRecovery)
    this.loadDisbursements(this.currentPage, this.perPage)
  }

  async toggleFinal(recovery: Recovery) {
    const shadowRecovery = JSON.parse(JSON.stringify(recovery))
    shadowRecovery.attributes.is_final = !recovery.attributes.is_final

    await this.$store.dispatch("recoveries/update", shadowRecovery)
    this.loadDisbursements(this.currentPage, this.perPage)
  }

  checkIfValidAdjust(adjustment: RecoverableAdjustment) {
    if (!adjustment.attributes.date) {
      return "Date is required"
    }
    if (!adjustment.attributes.amount) {
      return "Total amount is required"
    }

    return ""
  }

  checkIfValid(disbursement: Disbursement) {
    if (!disbursement.attributes.date) {
      return "Date is required"
    }
    if (!disbursement.attributes.amount) {
      return "Total amount is required"
    }
    let newAmount = disbursement.attributes.amount.replace(/,/g, "")

    newAmount = parseFloat(newAmount).toFixed(2)

    if (newAmount == "NaN") {
      return "Please enter a valid total amount"
    }
    if (+newAmount >= 1000000) {
      return "Please enter a total amount less than $1,000,000"
    }
    if (+newAmount <= 0 && disbursement.type == "disbursement") {
      return "Please enter a total amount greater than $0"
    }

    let recoverableAmount = disbursement.attributes.recoverable

    if (recoverableAmount && recoverableAmount.length > 0) {
      recoverableAmount = recoverableAmount.replace(/,/g, "")
    }

    recoverableAmount = parseFloat(recoverableAmount).toFixed(2)
    if (+recoverableAmount > +newAmount) {
      return "Recoverable amount cannot be more than total amount"
    }

    disbursement.attributes.amount = newAmount
    disbursement.attributes.recoverable =
      recoverableAmount == "NaN" ? null : recoverableAmount

    return ""
  }

  async approve(disbursement: Disbursement, update = true) {
    const disbursementCopy = cloneDeep(disbursement)
    disbursementCopy.attributes.status = "Approved"
    await this.$store.dispatch("disbursements/update", disbursementCopy)

    this.selected.splice(this.selected.indexOf(disbursement), 1)

    if (update) {
      this.loadDisbursements(this.currentPage, this.perPage)
      this.$emit("update-totals")
    }
  }

  async unapprove(disbursement: Disbursement) {
    const disbursementCopy = cloneDeep(disbursement)
    disbursementCopy.attributes.status = "Pending"
    await this.$store.dispatch("disbursements/update", disbursementCopy)

    this.loadDisbursements(this.currentPage, this.perPage)
    this.$emit("update-totals")
  }

  async unconfirm(recovery: Recovery) {
    const recoveryCopy = cloneDeep(recovery)
    recoveryCopy.attributes.status = "Unconfirmed"
    await this.$store.dispatch("recoveries/update", recoveryCopy)

    this.loadDisbursements(this.currentPage, this.perPage)
    this.$emit("update-totals")
  }

  async approveSelected(disbursements: Array<Disbursement>) {
    for (let i = disbursements.length - 1; i >= 0; i--) {
      await this.approve(disbursements[i], false)
    }

    this.loadDisbursements(this.currentPage, this.perPage)
    this.$emit("update-totals")
  }

  async getCheckNumber(disbursement: Disbursement) {
    this.checkNumberEdit = disbursement.attributes.check_number
    this.editItem = cloneDeep(disbursement)
    ;(this.$refs.checkNumberDialog as DialogTemplate).open(true)

    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(this.$refs.checkNumberTextBox as any).focus()
    }, 50)
  }

  async pay() {
    this.editItem.attributes.status = "Paid"
    this.editItem.attributes.check_number = this.checkNumberEdit
    await this.$store.dispatch("disbursements/update", this.editItem)
    this.loadDisbursements(this.currentPage, this.perPage)
    this.$emit("update-totals")
  }

  async receive(recovery: Recovery) {
    const recoveryCopy = cloneDeep(recovery)
    recoveryCopy.attributes.status = "Confirmed"
    await this.$store.dispatch("recoveries/update", recoveryCopy)
    this.loadDisbursements(this.currentPage, this.perPage)
  }

  async reject(item: Disbursement | Recovery) {
    const modelString = pluralize(camelCase(item.type))
    await this.$store.dispatch(`${modelString}/reject`, { id: item.id })
    this.loadDisbursements(this.currentPage, this.perPage)
  }

  async voidDisbursement(item: Disbursement) {
    await this.$store.dispatch("disbursements/void", {
      id: item.id,
      date: this.voidDate,
      add: this.voidAdd
    })
    this.loadDisbursements(this.currentPage, this.perPage)
  }

  async destroy(disbursement: Disbursement) {
    await this.$store.dispatch("disbursements/delete", disbursement.id)
    this.loadDisbursements(this.currentPage, this.perPage)
    this.$emit("update-totals")
  }
}
