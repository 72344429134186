import DateHelper from "@/components/utils/DateHelper"
import Model from "./Model"

export class AdminBill extends Model<AdminBill> {
  constructor(response?: AdminBill) {
    super(response as AdminBill)
    if (response) {
      this.attributes.start_date = DateHelper.stringToDate(
        this.attributes.start_date
      )
      this.attributes.end_date = DateHelper.stringToDate(
        this.attributes.end_date
      )
    }
  }
  id: string
  attributes!: {
    start_date: Date
    end_date: Date
    classification: string
    name: string
    amount: string
    eip: boolean
  }
  static get modelName() {
    return "AdminBill"
  }
}
