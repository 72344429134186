
import { Claim } from "@/types/Claim"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimPaymentMethodDialog from "@/components/ClaimPaymentMethodDialog.vue"

@Component({
  components: {
    CardTemplate,
    ClaimPaymentMethodDialog
  }
})
export default class ClaimPaymentMethod extends Vue {
  @Prop() private claim!: Claim

  created() {
    this.$store.dispatch("aches/loadBy", {
      owner_id: this.claim.attributes.member_id,
      owner_type: "Member"
    })
  }

  @Watch("claim")
  onClaimChange() {
    this.$store.dispatch("aches/loadBy", {
      owner_id: this.claim.attributes.member_id,
      owner_type: "Member"
    })
  }

  get aches() {
    if (this.claim.id != null) {
      return this.$store.getters["aches/getBy"]({
        owner_id: this.claim.attributes.member_id,
        owner_type: "Member"
      })
    } else {
      return []
    }
  }

  get selectedAch() {
    return this.$store.getters["aches/getById"](
      this.claim.attributes.payment_method_id
    )
  }

  savePaymentMethod(value: number | null) {
    const payload = {
      payment_method_id: value,
      payment_method_type: value == null ? null : "Ach"
    }

    this.$store.dispatch("claims/update", {
      id: this.claim.id,
      attributes: payload
    })
  }
}
