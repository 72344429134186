
import CardTemplate from "@/components/CardTemplate.vue"
import { Claim } from "@/types/Claim"
import CurrencyField from "@/components/CurrencyField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import { IncomeWorksheet } from "@/types/IncomeWorksheet"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DialogTemplate
  }
})
export default class MonthlyIncome extends Vue {
  @Prop() private claim!: Claim

  private baseSalary: null | string = null
  private shiftDifferential: null | string = null
  private uniformAllowance: null | string = null
  private holiday: null | string = null
  private payPeriods: null | number = null
  private holidayNotes: null | string = null
  private baseNotes: null | string = null

  async addWorksheet() {
    const worksheet = new IncomeWorksheet()

    worksheet.attributes.claim_id = Number(this.claim.id)
    worksheet.attributes.base_salary = this.baseSalary
    worksheet.attributes.shift_differential = this.shiftDifferential
    worksheet.attributes.uniform_allowance = this.uniformAllowance
    worksheet.attributes.holiday = this.holiday
    worksheet.attributes.pay_periods = this.payPeriods
    worksheet.attributes.base_notes = this.baseNotes
    worksheet.attributes.holiday_notes = this.holidayNotes

    await this.$store.dispatch("incomeWorksheets/create", worksheet)

    this.$emit("add-income-worksheet")
  }
}
