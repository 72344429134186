
import { Version } from "@/types/Version"
import { Component, Prop, Vue } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"

@Component({
  components: { DateField }
})
export default class VersionsList extends Vue {
  @Prop() private versions!: [Version]

  changeSetFieldNames(names: []) {
    const n = names.filter(el => {
      return !(el == "updated_at" || el == "created_at")
    })

    return n
  }

  dataDisplay(k: string, v: string) {
    let formatted = v

    if (k == "ssn" && v) {
      formatted = `XXX-XX-${v.slice(-4)}`
    }
    return formatted
  }
}
