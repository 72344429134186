import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class PolicyProduct extends Model<PolicyProduct> {
  constructor(policyProduct?: PolicyProduct) {
    super(policyProduct as PolicyProduct)
    if (policyProduct) {
      this.attributes.start_date = DateHelper.stringToDate(
        this.attributes.start_date
      )
    }
  }
  id: string
  attributes!: {
    policy_id: number
    product_id: number
    start_date: Date
  }
  static get modelName() {
    return "PolicyProduct"
  }
}
