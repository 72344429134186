import DateHelper from "@/components/utils/DateHelper"
import Model from "./Model"

export class AdminFee extends Model<AdminFee> {
  constructor(response?: AdminFee) {
    super(response as AdminFee)
    if (response) {
      this.attributes.start_date = DateHelper.stringToDate(
        this.attributes.start_date
      )
    }
  }
  id: string
  attributes!: {
    amount: string
    start_date: Date
    classification: string
    eip: boolean
  }
  static get modelName() {
    return "AdminFee"
  }
}
