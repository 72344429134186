import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { CreditCardBatch } from "@/types/CreditCardBatch"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: CreditCardBatch[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<CreditCardBatch>, RootState> = {
  async makeFile(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<CreditCardBatch>, RootState>,
    { id }
  ) {
    try {
      const data = await axios.put(
        `/credit_card_batches/${id}/make_file`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<CreditCardBatch>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
