
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component
export default class PageTemplateList extends Vue {
  @Prop() private title!: string
  @Prop() private width!: number

  @Watch("title")
  updateTitle() {
    document.title = this.title ? this.title : "CPSA"
  }

  created() {
    document.title = this.title ? this.title : "CPSA"
  }
}
