
import { Component, Vue } from "vue-property-decorator"
import ApplicationCountByEnroller from "@/components/ApplicationCountByEnroller.vue"
import CancellationCount from "@/components/CancellationCount.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Enroller } from "@/types/Enroller"

@Component({
  components: {
    ApplicationCountByEnroller,
    CancellationCount,
    CardTemplate,
    DialogTemplate,
    PagedDataTable,
    PageTemplate
  }
})
export default class Enrollers extends Vue {
  private loading = false
  private total = 0
  private startingPerPage = 25
  private perPage = 5
  private currentPage = 1

  private enrollers: Enroller[] = []
  private newEnroller = new Enroller()

  private options = {
    sortBy: "attributes.last_name",
    sortDesc: false,
    mustSort: true
  }

  created() {
    this.loadEnrollers(1, this.startingPerPage)
  }

  getEnrollers(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadEnrollers(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  async loadEnrollers(page: number, perPage: number) {
    this.loading = true
    const data = await this.$store.dispatch("enrollers/loadBy", {
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.enrollers = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  goToEnroller(enroller: Enroller) {
    this.$router.push({
      name: "Enroller",
      params: { id: enroller.id.toString() }
    })
  }

  async save() {
    await this.$store.dispatch("enrollers/create", this.newEnroller)
    this.newEnroller = new Enroller()

    this.loadEnrollers(1, this.startingPerPage)
  }

  get headers() {
    return [
      {
        text: "Last",
        value: "attributes.last_name"
      },
      {
        text: "First",
        value: "attributes.first_name"
      }
    ]
  }
}
