
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { Enroller } from "@/types/Enroller"
import { Policy } from "@/types/Policy"
import { Premium } from "@/types/Premium"
import { Product } from "@/types/Product"
import { DepartmentProduct } from "@/types/DepartmentProduct"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import moment from "moment"
import axios from "axios"
import RecordSearch from "@/components/RecordSearch.vue"
import { Search as SearchItem } from "@/types/Search"
import { AgePremium } from "@/types/AgePremium"

@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate,
    RecordSearch
  }
})
export default class NewPolicy extends Vue {
  @Prop() private allPolicies!: [Policy]
  @Prop() private startingDepartmentId!: string
  @Prop() private memberId!: string
  private age: number
  private premium: number | null = null
  private selectedProduct: Product = null
  private selectedEnrollerId: number | null = null
  private policy = new Policy()
  private newPolicyDepartmentId: string
  private rateType = "age"
  private isCompositeRate = false
  private isModRate = false
  private compositeRateBilled: string | null = null
  private compositeRatePlanned: string | null = null
  productIds: number[] = []
  private termIsDisabled: { text: string; value: boolean }[] = [
    { text: "25", value: false },
    { text: "30", value: false },
    { text: "35", value: false },
    { text: "40", value: false },
    { text: "45", value: false },
    { text: "lifetime", value: false }
  ]

  private departmentNameNew: string = null

  created() {
    this.newPolicyDepartmentId = this.startingDepartmentId
    this.getDepartmentProducts()
    this.$store.dispatch("enrollers/loadAll")
    this.resetPolicy()
    if (this.startingDepartmentId) {
      this.$store.dispatch("departments/load", this.startingDepartmentId)
    }
    this.$store.dispatch("members/load", this.memberId)
  }

  resetPolicy() {
    this.policy = new Policy()
    this.policy.attributes.rating = null
    this.policy.attributes.start_date = new Date()
    this.policy.attributes.app_date = new Date()
    this.policy.attributes.billing_frequency = "Monthly"
    this.newPolicyDepartmentId = this.startingDepartmentId
    this.policy.attributes.is_composit_rate = false
    this.policy.attributes.increase_modified_premium = false
  }

  setPolicyDepartment(event: SearchItem) {
    this.newPolicyDepartmentId = event.target_id
    this.$store.dispatch("departments/load", this.newPolicyDepartmentId)
    this.getDepartmentProducts()
  }

  @Watch("startingDepartmentId")
  startingDepartmentChange(value: string) {
    this.getDepartmentProducts()
    this.newPolicyDepartmentId = value
    if (value) {
      this.$store.dispatch("departments/load", value)
      this.getDepartmentProducts()
    }
  }

  selectedProductRequiresEnroller() {
    return (
      this.selectedProduct &&
      (this.selectedProduct.attributes.product_type == "ltc" ||
        this.selectedProduct.attributes.eip)
    )
  }

  @Watch("selectedProduct")
  async selectedProductChange() {
    this.premium = null
    if (this.department?.attributes?.group_rate_ltc) {
      this.policy.attributes.term = "25"

      const response = await axios.get(
        "/premiums",
        Object.assign(
          {
            params: {
              premiumable_id: this.department.attributes.premiumable_id,
              premiumable_type: "Department"
            }
          },
          this.$store.getters["config/getAPIConfig"]
        )
      )
      this.premium = response.data.data[0]["attributes"]["amount_billed"]
    } else {
      try {
        this.premium = await this.agePremium({
          age: this.age,
          dayRate: this.selectedProduct.attributes.day_rate,
          term: this.policy.attributes.term
        })
        this.compositeRatePlanned = this.premium.toString()
      } catch (e) {
        this.premium = null
        this.compositeRatePlanned = "0"
      }
    }
  }

  async getDepartmentProducts() {
    this.productIds = []
    if (this.newPolicyDepartmentId) {
      const dp = await this.$store.dispatch("departmentProducts/loadBy", {
        department_id: this.newPolicyDepartmentId
      })
      this.productIds = dp.data.data
        ? dp.data.data.map((d: DepartmentProduct) =>
            d.attributes.product_id.toString()
          )
        : []

      this.$store.dispatch("products/loadBy", { id: this.productIds })
    }
  }

  @Watch("policy.attributes.term")
  async updatePremium() {
    try {
      this.premium = await this.agePremium({
        age: this.age,
        dayRate: this.selectedProduct.attributes.day_rate,
        term: this.policy.attributes.term
      })
      this.compositeRatePlanned = this.premium.toString()
    } catch (e) {
      this.premium = null
      this.compositeRatePlanned = "0"
    }
  }

  @Watch("rateType")
  updateAltRate(value: string) {
    this.isModRate = value == "mod"
    this.isCompositeRate = value == "comp"
  }

  get memberAge() {
    if (!this.age) {
      if (this.member.attributes.dob) {
        this.age = moment(this.policy.attributes.start_date).diff(
          moment(new Date(this.member.attributes.dob)),
          "years"
        )
      }
    }
    return this.age
  }

  set memberAge(newAge: number) {
    this.age = newAge
  }

  get memberTooOld() {
    console.log("too old", this.selectedProduct.attributes.eip, this.age)
    if (this.selectedProduct.attributes.eip) {
      if (this.member.attributes.sworn_safety) {
        return this.memberAge > 60
      } else {
        return this.memberAge > 55
      }
    } else {
      return false
    }
  }

  get products() {
    return this.$store.getters["products/getByIds"](this.productIds)
  }

  get member() {
    return this.$store.getters["members/getById"](this.memberId)
  }

  get selectableEnrollers() {
    const enrollers = this.$store.getters["enrollers/getAll"]

    const selectable = enrollers
      .sort((a: Enroller, b: Enroller) => {
        if (a.attributes.last_name < b.attributes.last_name) {
          return -1
        }
        if (a.attributes.last_name > b.attributes.last_name) {
          return 1
        }
        return 0
      })
      .map((el: Enroller) => {
        return {
          id: el.id,
          name: `${el.attributes.last_name}, ${el.attributes.first_name}`
        }
      })

    return selectable
  }

  get department() {
    const department = this.$store.getters["departments/getById"](
      this.newPolicyDepartmentId
    )
    return department
  }
  get availableProducts() {
    return this.products.filter((el: Product) => {
      return !el.attributes.retired_on
    })
  }

  get memberPaidProduct() {
    return this.selectedProduct && this.selectedProduct.attributes.member_paid
  }

  get availableProductItems() {
    return this.availableProducts.map((el: Product) => {
      return { text: el.attributes.name, value: el }
    })
  }

  async agePremium({
    dayRate,
    age,
    term
  }: {
    dayRate: string
    age: number
    term: string
  }) {
    if (!term || !age || !dayRate) {
      return
    }

    const response = await axios.get(
      "/age_premiums",
      Object.assign(
        {
          params: {
            age: age,
            day_rate: dayRate
          }
        },
        this.$store.getters["config/getAPIConfig"]
      )
    )

    if (response.data.data.length > 0) {
      response.data.data.sort((a: AgePremium, b: AgePremium) => {
        return b.attributes["start_date"] < a.attributes["start_date"] ? -1 : 1
      })

      this.termIsDisabled = [
        {
          text: "25",
          value: !(
            Number(response.data.data[0]["attributes"]["premium_25_year"]) > 0
          )
        },
        {
          text: "30",
          value: !(
            Number(response.data.data[0]["attributes"]["premium_30_year"]) > 0
          )
        },
        {
          text: "35",
          value: !(
            Number(response.data.data[0]["attributes"]["premium_35_year"]) > 0
          )
        },
        {
          text: "40",
          value: !(
            Number(response.data.data[0]["attributes"]["premium_40_year"]) > 0
          )
        },
        {
          text: "45",
          value: !(
            Number(response.data.data[0]["attributes"]["premium_45_year"]) > 0
          )
        },
        {
          text: "lifetime",
          value: !(
            Number(response.data.data[0]["attributes"]["premium_lifetime"]) > 0
          )
        }
      ]

      const premiumColumn = isNaN(Number(term))
        ? "premium_lifetime"
        : `premium_${term}_year`

      return response.data.data[0]["attributes"][premiumColumn]
    } else {
      this.termIsDisabled = [
        {
          text: "25",
          value: true
        },
        {
          text: "30",
          value: true
        },
        {
          text: "35",
          value: true
        },
        {
          text: "40",
          value: true
        },
        {
          text: "45",
          value: true
        },
        {
          text: "lifetime",
          value: true
        }
      ]

      return null
    }
  }

  itemsDisabled(item: { text: string; value: string }) {
    return this.termIsDisabled.find(
      (el: { text: string; value: boolean }) => el.text === item.value
    ).value
  }

  ratingsDisabled(rating: { text: string; value: string }) {
    if (
      this.selectedProduct.attributes.single_rating &&
      this.selectedProduct.attributes.single_rating.toString().length > 0
    ) {
      return rating.value != this.selectedProduct.attributes.single_rating
    } else {
      return false
    }
  }

  async save() {
    const newPolicy = new Policy()
    const increaseModifiedPremium =
      this.department && this.department.attributes.whole_date != null
    const applyInterest = this.isModRate
    const status =
      this.selectedProduct.attributes.product_type == "ltc"
        ? "UNDR-Underwriting Committee"
        : this.selectedProduct.attributes.eip
        ? "EFF1 - IN-FORCE / EFFECTIVE"
        : "active"
    newPolicy.attributes = Object.assign(this.policy.attributes, {
      name: this.selectedProduct.attributes.name,
      member_id: this.memberId,
      product_id: this.selectedProduct.id.toString(),
      status: status,
      next_bill_on: this.policy.attributes.start_date,
      department_id: this.newPolicyDepartmentId,
      is_composite_rate: this.isCompositeRate,
      is_mod_rate: this.isModRate,
      rate_lock_override: false,
      rate_increases: true,
      apply_interest: applyInterest,
      increase_modified_premium: increaseModifiedPremium
    })

    newPolicy.attributes.department_id = this.newPolicyDepartmentId

    if (this.selectedProduct.attributes.product_type == "ltd") {
      newPolicy.attributes.start_date = this.policy.attributes.start_date
    }

    if (this.selectedProductRequiresEnroller()) {
      newPolicy.attributes.enroller_id = this.selectedEnrollerId
    }

    if (this.selectedProduct.attributes.product_type == "ltc") {
      newPolicy.attributes.next_bill_on = null
      newPolicy.attributes.app_date = newPolicy.attributes.start_date
      newPolicy.attributes.start_date = null
    } else {
      newPolicy.attributes.app_date = null
    }

    const policyResponse = await this.$store.dispatch("policies/create", {
      data: newPolicy,
      include: ["policy_group"]
    })

    if (this.selectedProduct.attributes.product_type == "ltc") {
      let agePremium = 0
      if (this.isCompositeRate || this.isModRate) {
        agePremium = Number(this.compositeRatePlanned)
        this.premium = Number(this.compositeRateBilled)
      } else {
        agePremium = await this.agePremium({
          dayRate: this.selectedProduct.attributes.day_rate,
          age: this.age,
          term: this.policy.attributes.term
        })
      }

      const newPremium = new Premium()
      newPremium.attributes = Object.assign(this.policy.attributes, {
        premiumable_id: parseInt(policyResponse.data.data.id),
        premiumable_type: "Policy",
        start_date: policyResponse.data.data.attributes.app_date,
        amount_billed: this.premium,
        amount_planned: agePremium
      })

      await this.$store.dispatch("premiums/create", {
        data: newPremium
      })
    } else if (
      this.selectedProduct.attributes.product_type == "ltd" &&
      !this.selectedProduct.attributes.eip
    ) {
      const d = {
        attributes: {
          member_id: this.memberId,
          department_id: this.newPolicyDepartmentId,
          start_date: policyResponse.data.data.attributes.start_date
        }
      }
      await this.$store.dispatch("memberships/create", d)
    }

    this.selectedProduct = null
    this.selectedEnrollerId = null
    this.resetPolicy()
  }

  saveDisabled() {
    if (!this.selectedProduct) {
      return true
    } else if (this.selectedProduct.attributes.product_type == "ltc") {
      if (this.isCompositeRate || this.isModRate) {
        return !(
          Number(this.compositeRateBilled) > 0 &&
          Number(this.compositeRatePlanned) > 0
        )
      } else {
        return !(this.premium && this.selectedEnrollerId)
      }
    } else if (this.selectedProductRequiresEnroller()) {
      return this.selectedEnrollerId == null
    }
    return false
  }
}
