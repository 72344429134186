var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$can('create', 'Policy'))?_c('DialogTemplate',{attrs:{"title":"New Policy","subtitle":"Select new policies","saveDisabled":_vm.saveDisabled()},on:{"save":_vm.save,"cancel":function($event){_vm.selectedProduct = null
    _vm.resetPolicy()}},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialog }){return [_c('v-tooltip',{attrs:{"top":"","disabled":!!_vm.availableProducts.length},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),{ ...tooltip }),[_c('v-btn',_vm._g({staticClass:"js-new-policy",attrs:{"x-large":"","icon":"","color":"secondary","disabled":!_vm.availableProducts.length}},{ ...dialog }),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("No available products")])])]}}],null,false,1961102683)},[_c('div',[_c('RecordSearch',{attrs:{"id":"js-alternate-department-search","recordType":"department","label":"Alternate Department"},on:{"selectRecord":_vm.setPolicyDepartment}})],1),_c('div',[_c('v-select',{staticClass:"js-new-policy-type",attrs:{"label":"Policy Type","items":_vm.availableProductItems},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}}),(_vm.selectedProduct)?_c('div',[(_vm.memberTooOld)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" This member may be too old for this product! ")]):_vm._e(),(_vm.selectedProductRequiresEnroller())?_c('div',[_c('v-autocomplete',{attrs:{"id":"js-enroller","items":_vm.selectableEnrollers,"item-text":"name","item-value":"id","label":"Enroller","clearable":""},model:{value:(_vm.selectedEnrollerId),callback:function ($$v) {_vm.selectedEnrollerId=$$v},expression:"selectedEnrollerId"}})],1):_vm._e(),(
          _vm.selectedProduct && _vm.selectedProduct.attributes.product_type == 'ltc'
        )?_c('div',[_c('v-select',{staticClass:"js-new-policy-rating",attrs:{"label":"Rating","items":[
            { text: 'None', value: null },
            { text: 'Standard 60', value: 'Standard 60' },
            { text: 'Standard 90', value: 'Standard 90' },
            { text: 'Preferred', value: 'Preferred' }
          ],"item-disabled":_vm.ratingsDisabled},model:{value:(_vm.policy.attributes.rating),callback:function ($$v) {_vm.$set(_vm.policy.attributes, "rating", $$v)},expression:"policy.attributes.rating"}}),_c('v-text-field',{staticClass:"js-new-policy-age",attrs:{"label":"Age"},on:{"keyup":function($event){if (!_vm.department.attributes.group_rate_ltc) {
              _vm.updatePremium()
            }}},model:{value:(_vm.memberAge),callback:function ($$v) {_vm.memberAge=$$v},expression:"memberAge"}}),_c('v-select',{staticClass:"js-new-policy-term",attrs:{"label":"Term","items":[
            { text: '25 years', value: '25' },
            { text: '30 years', value: '30' },
            { text: '35 years', value: '35' },
            { text: '40 years', value: '40' },
            { text: '45 years', value: '45' },
            { text: 'Lifetime', value: 'lifetime' }
          ],"item-disabled":_vm.itemsDisabled,"disabled":_vm.department && _vm.department.attributes.group_rate_ltc},model:{value:(_vm.policy.attributes.term),callback:function ($$v) {_vm.$set(_vm.policy.attributes, "term", $$v)},expression:"policy.attributes.term"}}),_c('v-row',[_c('v-col',[_c('div',{staticClass:"overline"},[_vm._v("Premium")]),_c('v-radio-group',{staticClass:"mx-auto",attrs:{"row":""},model:{value:(_vm.rateType),callback:function ($$v) {_vm.rateType=$$v},expression:"rateType"}},[_c('v-radio',{attrs:{"id":"js-age-premium","label":"Age Based","value":"age"}}),_c('v-radio',{attrs:{"id":"js-mod-premium","label":"Modified","value":"mod"}}),_c('v-radio',{attrs:{"id":"js-comp-premium","label":"Composite","value":"comp"}})],1)],1),_c('v-col',[_c('CurrencyField',{attrs:{"id":"js-composite-rate-billed","disabled":!(_vm.isCompositeRate || _vm.isModRate),"label":"Billed"},model:{value:(_vm.compositeRateBilled),callback:function ($$v) {_vm.compositeRateBilled=$$v},expression:"compositeRateBilled"}}),(_vm.isCompositeRate && _vm.department.attributes.composite_rate)?_c('p',{staticClass:"caption mt-3"},[_vm._v(" Composite Rate"),_c('CurrencyField',{attrs:{"display":true,"value":_vm.department.attributes.composite_rate}})],1):_vm._e()],1),_c('v-col',[_c('CurrencyField',{attrs:{"id":"js-composite-rate-planned","disabled":!(_vm.isCompositeRate || _vm.isModRate),"label":"Plan"},model:{value:(_vm.compositeRatePlanned),callback:function ($$v) {_vm.compositeRatePlanned=$$v},expression:"compositeRatePlanned"}})],1)],1)],1):_vm._e(),(
          _vm.selectedProduct && _vm.selectedProduct.attributes.product_type == 'ltd'
        )?_c('DateField',{attrs:{"id":"js-new-policy-date","label":"Start Date"},model:{value:(_vm.policy.attributes.start_date),callback:function ($$v) {_vm.$set(_vm.policy.attributes, "start_date", $$v)},expression:"policy.attributes.start_date"}}):_vm._e(),(_vm.memberPaidProduct)?_c('v-select',{staticClass:"js-new-billing-frequency",attrs:{"label":"Member Billing Frequency","clearable":"","items":['Monthly', 'Quarterly', 'Semi-Annually', 'Annually']},model:{value:(_vm.policy.attributes.billing_frequency),callback:function ($$v) {_vm.$set(_vm.policy.attributes, "billing_frequency", $$v)},expression:"policy.attributes.billing_frequency"}}):_vm._e(),(
          _vm.selectedProduct && _vm.selectedProduct.attributes.product_type == 'ltc'
        )?_c('div',{staticClass:"mt-6"},[(_vm.premium)?_c('v-alert',{staticClass:"js-new-policy-premium",attrs:{"type":"success"}},[_c('b',[_vm._v("Premium $"+_vm._s(Number.parseFloat(_vm.premium).toFixed(2)))]),(_vm.department && _vm.department.attributes.group_rate_ltc)?_c('div',{staticClass:"body-2 js-group-rate"},[_vm._v(" * Group rate ")]):_vm._e()]):_c('v-alert',{staticClass:"js-new-policy-premium my-6",attrs:{"dense":"","type":"error"}},[_c('b',[_vm._v("Premium unavailable")])])],1):_vm._e(),(
          _vm.selectedProduct && _vm.selectedProduct.attributes.product_type == 'ltc'
        )?_c('DateField',{attrs:{"id":"js-new-policy-date","label":"Application Date"},model:{value:(_vm.policy.attributes.app_date),callback:function ($$v) {_vm.$set(_vm.policy.attributes, "app_date", $$v)},expression:"policy.attributes.app_date"}}):_vm._e()],1):_vm._e(),(_vm.department)?_c('v-alert',{staticClass:"js-new-policy-department-warning my-4",attrs:{"dense":"","type":_vm.newPolicyDepartmentId == _vm.startingDepartmentId ? 'info' : 'error'}},[_vm._v("New policies will be associated with "),_c('strong',[_vm._v(_vm._s(_vm.department.attributes.name))])]):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }