
import AddressDepartment from "@/components/AddressDepartment.vue"
import DepartmentSection from "@/components/DepartmentSection.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import DepartmentSectionMixin from "@/mixins/DepartmentSectionMixin"
import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {
    AddressDepartment,
    DepartmentSection,
    DialogTemplate,
    PhoneNumber
  },
  mixins: [DepartmentSectionMixin]
})
export default class DepartmentVicePresident extends Vue {}
