
import { Component, Vue } from "vue-property-decorator"
import { Attorney as AttorneyType } from "@/types/Attorney"
import AddressDepartment from "@/components/AddressDepartment.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import HelpBubble from "@/components/HelpBubble.vue"
import NewAttorney from "@/components/NewAttorney.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { cloneDeep } from "lodash"

@Component({
  components: {
    AddressDepartment,
    CardTemplate,
    ConfirmationTemplate,
    HelpBubble,
    NewAttorney,
    PageTemplate,
    PhoneNumber
  }
})
export default class Attorney extends Vue {
  private editAttorney: AttorneyType = new AttorneyType()
  private showConfirmation = false

  get id() {
    return this.$route.params.id
  }

  get attorney() {
    return this.$store.getters["attorneys/getById"](this.id)
  }

  get address() {
    return this.attorney && this.attorney.attributes.address_id
      ? this.$store.getters["addresses/getById"](
          this.attorney.attributes.address_id
        )
      : null
  }

  get claims() {
    const attorneys = this.$store.getters["claims/getBy"]({
      attorney_id: this.id
    })
    const defenseAttorneys = this.$store.getters["claims/getBy"]({
      defense_attorney_id: this.id
    })

    return attorneys.concat(defenseAttorneys)
  }

  get employers() {
    return this.$store.getters["employers/getBy"]({
      attorney_id: this.id
    })
  }

  async loadData() {
    await this.$store.dispatch("attorneys/load", {
      id: this.id,
      include: ["claims", "address", "employers"]
    })
    await this.$store.dispatch("claims/loadBy", {
      defense_attorney_id: this.id
    })
  }

  async created() {
    await this.loadData()
    this.editAttorney = cloneDeep(this.attorney)
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    await this.loadData()
    this.editAttorney = cloneDeep(this.attorney)

    next()
  }

  deleteAttorney() {
    this.$store.dispatch("attorneys/delete", this.id)

    this.$router.push({
      name: "Attorneys"
    })
  }
}
