
import { Component, Prop, Vue } from "vue-property-decorator"
@Component
export default class ConfirmationTemplate extends Vue {
  @Prop() private title!: string
  @Prop({ default: "red" }) private titleColor!: string
  @Prop() private subtitle!: string
  @Prop() private btnLabelConfirm!: "Add"
  @Prop() private btnId!: string
  @Prop() private wide!: boolean
  @Prop() private width!: string
  @Prop() private icon!: string
  @Prop() private iconSize!: string
  @Prop() private show!: false
  private btnLabelReject = "Cancel"
  confirmOk() {
    this.$emit("confirm-ok")
  }
  reject() {
    this.$emit("confirm-reject")
  }
}
