
import { Component, Prop, Vue } from "vue-property-decorator"
import { BoardMeetingAdmin } from "@/types/BoardMeetingAdmin"
import BoardMeetingAdminDialog from "@/components/BoardMeetingAdminDialog.vue"

@Component({
  components: {
    BoardMeetingAdmin,
    BoardMeetingAdminDialog
  }
})
export default class BoardMeetingAdminList extends Vue {
  @Prop() private boardMeetingId!: number

  private loading = false

  get admins() {
    return this.boardMeetingId
      ? this.$store.getters["boardMeetingAdmins/getBy"]({
          board_meeting_id: this.boardMeetingId
        })
      : []
  }

  editAdmin(admin: BoardMeetingAdmin) {
    const dialog = (this.$refs[
      `edit-admin-${admin.id}`
    ] as Vue[])[0] as BoardMeetingAdminDialog
    dialog.open()
  }

  toggleAttended(admin: BoardMeetingAdmin) {
    admin.attributes.attended = !admin.attributes.attended
    this.$store.dispatch("boardMeetingAdmins/update", admin)
  }

  // async deleteAttendee(id: number) {
  //   await this.$store.dispatch("boardMeetingAdmins/delete", id)
  // }
}
