
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DialogTemplate
  }
})
export default class UpdatePolicyCurrency extends Vue {
  @Prop() private id!: number
  @Prop() private title!: string
  @Prop() private amount!: string
  @Prop() private attributeName!: string
  @Prop() private valueSelector!: string

  private changedValue = ""

  mounted() {
    this.changedValue = this.amount
  }

  @Watch("amount")
  onValueChange(value: string) {
    if (value) {
      this.changedValue = value
    }
  }

  async update() {
    await axios.put(
      `/policies/${this.id}`,
      { [this.attributeName]: this.changedValue },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
