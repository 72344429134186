
import { Component, Vue, Watch } from "vue-property-decorator"
import { Address } from "@/types/Address"
import { Member } from "@/types/Member"
import { Policy as PolicyType } from "@/types/Policy"
import { Product } from "@/types/Product"
import BeneficiaryList from "@/components/BeneficiaryList.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimsList from "@/components/ClaimsList.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import { cloneDeep } from "lodash"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import EndPolicy from "@/components/EndPolicy.vue"
import { Enroller } from "@/types/Enroller"
import ReinstatePolicy from "@/components/ReinstatePolicy.vue"
import HelpBubble from "@/components/HelpBubble.vue"
import LaserficheList from "@/components/LaserficheList.vue"
import MakeWhole from "@/components/MakeWhole.vue"
import MonthField from "@/components/MonthField.vue"
import NotesList from "@/components/NotesList.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PaymentMethodACH from "@/components/PaymentMethodACH.vue"
import PaymentMethodAssign from "@/components/PaymentMethodAssign.vue"
import PaymentMethodCreditCard from "@/components/PaymentMethodCreditCard.vue"
import PaidInFull from "@/components/PaidInFull.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import PolicyAddPayroll from "@/components/PolicyAddPayroll.vue"
import PolicyChangePayroll from "@/components/PolicyChangePayroll.vue"
import PolicyLeavePayroll from "@/components/PolicyLeavePayroll.vue"
import PolicyLetter from "@/components/PolicyLetter.vue"
import PolicyProducts from "@/components/PolicyProducts.vue"
import PolicyRateIncrease from "@/components/PolicyRateIncrease.vue"
import PolicyRateConvertPreview from "@/components/PolicyRateConvertPreview.vue"
import PolicyTotals from "@/components/PolicyTotals.vue"
import PremiumHistory from "@/components/PremiumHistory.vue"
import UpdateBillingException from "@/components/UpdateBillingException.vue"
import UpdateBillingFrequency from "@/components/UpdateBillingFrequency.vue"
import UpdatePolicyDate from "@/components/UpdatePolicyDate.vue"
import UpdatePolicyDepartment from "@/components/UpdatePolicyDepartment.vue"
import UpdatePolicyCurrency from "@/components/UpdatePolicyCurrency.vue"
import UpdatePolicyRatingStatus from "@/components/UpdatePolicyRatingStatus.vue"
import UpdatePolicyRiderStatus from "@/components/UpdatePolicyRiderStatus.vue"
import UpdatePolicyAttributeToggle from "@/components/UpdatePolicyAttributeToggle.vue"
import UpdateRateLockOverride from "@/components/UpdateRateLockOverride.vue"
import YesNoChip from "@/components/YesNoChip.vue"
import { SortOrder } from "@/store/utils/genericGetters"
import moment from "moment"
import { Beneficiary } from "@/types/Beneficiary"
import Ledger from "@/components/Ledger.vue"
import valid from "card-validator"

@Component({
  components: {
    BeneficiaryList,
    CardTemplate,
    ClaimsList,
    CurrencyField,
    DateField,
    DialogTemplate,
    EndPolicy,
    HelpBubble,
    LaserficheList,
    Ledger,
    MakeWhole,
    MonthField,
    NotesList,
    PageTemplate,
    PaidInFull,
    PaymentMethodACH,
    PaymentMethodAssign,
    PaymentMethodCreditCard,
    PhoneNumber,
    PolicyAddPayroll,
    PolicyChangePayroll,
    UpdatePolicyDate,
    UpdatePolicyDepartment,
    PolicyLeavePayroll,
    PolicyLetter,
    PolicyProducts,
    PolicyRateIncrease,
    PolicyRateConvertPreview,
    PolicyTotals,
    PremiumHistory,
    ReinstatePolicy,
    UpdateBillingException,
    UpdateBillingFrequency,
    UpdatePolicyCurrency,
    UpdatePolicyRatingStatus,
    UpdatePolicyAttributeToggle,
    UpdatePolicyRiderStatus,
    UpdateRateLockOverride,
    YesNoChip
  }
})
export default class Policy extends Vue {
  private paymentMethodType = ""
  private paymentMethodId = 0
  private loading = true
  private loadingUnderwritingReport = false
  private loadingScheduleOfBenefits = false
  private loadingReceipt = false
  private iss_date = new Date()
  private eff_date = new Date(
    this.iss_date.getFullYear(),
    this.iss_date.getUTCMonth() + 1,
    1
  )
  private rejectCode = "CLOO - POLICY NOT ISSUED - OTHER"
  private selectedEnrollerId: number | null = null
  private authDesigneeName = ""
  private authDesigneePhone = ""
  private authDesigneeAddress = new Address()

  private receiptStartDate = new Date()
  private receiptEndDate = new Date()
  private receiptAmount = ""

  private daysDelinquent = 0

  @Watch("policy", { deep: true })
  onPolicyChange() {
    this.loadPremiums()
    this.loadPolicyChangeEvents()
    this.fetchDaysDelinquent()
  }

  get id() {
    return this.$route.params.id
  }

  get beneficiaries() {
    return this.$store.getters["beneficiaries/getBy"]({
      policy_id: this.id
    })
  }

  get claims() {
    return this.$store.getters["claims/getBy"]({
      policy_id: this.id
    })
  }

  get pageTitle() {
    const polnum =
      this.policy && this.policy.attributes.number
        ? this.policy.attributes.number
        : this.policy.attributes.name

    return polnum
  }

  get editCancelDateMessage() {
    if (this.policy && this.policy.attributes.end_date == null) {
      return "Use proper policy cancellation process to end policy."
    }

    return "Only Accountant 2 users can edit existing cancel dates."
  }

  get editStartDateMessage() {
    if (this.policy && this.policy.attributes.start_date == null) {
      return "Use proper policy start process to set initial start date of policy."
    }

    return "Edit only allowed for Accountant 2 users, LTC or EIP policies and policies not yet cancelled."
  }

  get contingentBeneficiaries() {
    const a = this.beneficiaries.filter(
      (el: { attributes: { contingent: boolean } }) => el.attributes.contingent
    )

    const b = a.filter(
      (el: { attributes: { archived_on: Date } }) =>
        el.attributes.archived_on != null
    )
    const c = a.filter(
      (el: { attributes: { archived_on: Date } }) =>
        el.attributes.archived_on == null
    )
    return c.concat(b)
  }

  get primaryBeneficiaries() {
    const a = this.beneficiaries.filter(
      (el: { attributes: { contingent: boolean } }) => !el.attributes.contingent
    )

    const b = a.filter(
      (el: { attributes: { archived_on: Date } }) =>
        el.attributes.archived_on != null
    )
    const c = a.filter(
      (el: { attributes: { archived_on: Date } }) =>
        el.attributes.archived_on == null
    )
    return c.concat(b)
  }

  get AuthorizedDesigneeAddress() {
    return this.policy && this.policy.attributes.auth_designee_address_id
      ? this.$store.getters["addresses/getById"](
          this.policy.attributes.auth_designee_address_id
        )
      : null
  }

  get selectableEnrollers() {
    const enrollers = this.$store.getters["enrollers/getAll"]

    const selectable = enrollers
      .sort((a: Enroller, b: Enroller) => {
        if (a.attributes.last_name < b.attributes.last_name) {
          return -1
        }
        if (a.attributes.last_name > b.attributes.last_name) {
          return 1
        }
        return 0
      })
      .map((el: Enroller) => {
        return {
          id: el.id,
          name: `${el.attributes.last_name}, ${el.attributes.first_name}`
        }
      })

    return selectable
  }

  get policyProducts() {
    return this.$store.getters["policyProducts/getBy"]({
      policy_id: this.policy.id
    })
  }

  loadAuthorizedDesignee() {
    if (this.AuthorizedDesigneeAddress) {
      this.authDesigneeAddress = cloneDeep(this.AuthorizedDesigneeAddress)
    }
    this.authDesigneeName = this.policy.attributes.auth_designee_name
    this.authDesigneePhone = this.policy.attributes.auth_designee_phone
  }

  sendToUnderwriting() {
    const editPolicy = cloneDeep(this.policy)
    editPolicy.attributes.status = "UNDR-Underwriting Committee"
    editPolicy.attributes.end_date = null

    this.$store.dispatch("policies/update", editPolicy)
  }

  get ageAtIssue() {
    if (!this.policy.attributes.iss_date) {
      return "not issued"
    }
    if (!this.member.attributes.dob) {
      return "no DOB"
    }
    return moment(this.policy.attributes.iss_date).diff(
      moment(new Date(this.member.attributes.dob)),
      "years"
    )
  }

  get ageToday() {
    if (!this.member.attributes.dob) {
      return "no DOB"
    }
    return moment(new Date()).diff(
      moment(new Date(this.member.attributes.dob)),
      "years"
    )
  }

  async saveAuthorizedDesignee() {
    const editPolicy = cloneDeep(this.policy)

    if (this.authDesigneeAddress.id) {
      this.$store.dispatch("addresses/update", this.authDesigneeAddress)
    } else if (this.authDesigneeAddress.attributes.street) {
      const a = await this.$store.dispatch(
        "addresses/create",
        this.authDesigneeAddress
      )
      editPolicy.attributes.auth_designee_address_id = a.data.data.id
    }

    editPolicy.attributes.auth_designee_name = this.authDesigneeName
    editPolicy.attributes.auth_designee_phone = this.authDesigneePhone

    this.$store.dispatch("policies/update", editPolicy)
  }

  saveEnroller() {
    const editPolicy = cloneDeep(this.policy)
    editPolicy.attributes.enroller_id = this.selectedEnrollerId

    this.$store.dispatch("policies/update", editPolicy)
  }

  loadEnrollers() {
    this.selectedEnrollerId = this.enroller ? this.enroller.id : null
    this.$store.dispatch("enrollers/loadAll")
  }

  showEditCC() {
    ;(this.$refs[
      `edit-cc-${this.creditCard.id}`
    ] as PaymentMethodCreditCard).open()
  }

  showEditAch() {
    ;(this.$refs[`edit-ach-${this.ach.id}`] as PaymentMethodACH).open()
  }

  showPaymentMethodAssign() {
    ;(this.$refs["payment-method-assign"] as PaymentMethodAssign).open()
  }

  cardType() {
    const validator = valid.number(this.creditCard.attributes.number)
    return validator.card ? validator.card.type : "generic"
  }

  beneficiaryAddress(beneficiary: Beneficiary) {
    const addressId = beneficiary?.attributes?.address_id
    const address = addressId
      ? this.$store.getters["addresses/getById"](addressId)
      : null

    return address
  }

  beneficiaryAch(beneficiary: Beneficiary) {
    const achId = beneficiary?.attributes?.ach_id
    const ach = achId ? this.$store.getters["aches/getById"](achId) : null

    return ach
  }

  isPaidInFull(policy: PolicyType) {
    const paidInFull = new Date(policy.attributes.paid_in_full_date)
    const today = new Date()

    return paidInFull <= today
  }

  isLumpSum(policy: PolicyType) {
    return policy.attributes.billing_frequency == "Lump Sum"
  }

  get member() {
    const member: Member = this.policy.id
      ? this.$store.getters["members/getById"](this.policy.attributes.member_id)
      : null
    return member
  }

  get policy() {
    const o = this.$store.getters["policies/getById"](this.id)
    return o ? o : new PolicyType()
  }

  get premiums() {
    return this.$store.getters["premiums/getBy"](
      {
        premiumable_id: this.id,
        premiumable_type: "Policy"
      },
      "start_date",
      SortOrder.desc
    )
  }

  get changeEvents() {
    return this.$store.getters["policyChangeEvents/getBy"](
      {
        policy_id: this.id
      },
      "created_at",
      SortOrder.desc
    )
  }

  get department() {
    if (this.policy && this.policy.attributes.department_id) {
      return this.$store.getters["departments/getById"](
        this.policy.attributes.department_id
      )
    } else {
      return null
    }
  }

  get enroller() {
    if (this.policy && this.policy.attributes.enroller_id) {
      return this.$store.getters["enrollers/getById"](
        this.policy.attributes.enroller_id
      )
    } else {
      return null
    }
  }

  get policyGroup() {
    return this.$store.getters["policyGroups/getById"](
      this.policy.attributes.policy_group_id
    )
  }

  get product() {
    const product: Product = this.policy.id
      ? this.$store.getters["products/getById"](
          this.policy.attributes.product_id
        )
      : null
    return product
  }

  get payrollDepartment() {
    const policyGroupId = this.policyGroup?.attributes.department_id
    const department = this.$store.getters["departments/getById"](policyGroupId)
    return department ? department : null
  }

  get statusColor() {
    switch (this.policy.attributes.status) {
      case "UNDR-Underwriting Committee":
        return ["info", "white"]
      case "CLOO - POLICY NOT ISSUED - OTHER":
      case "CLOM - POLICY NOT ISSUED - MEDICAL":
      case "CLOP - POLICY CANCELLED BY PLAN/CASI":
      case "CLOC - POLICY CANCELLED BY MEMBER":
      case "CLOD - POLICY CANCELLED DUE TO DEATH":
      case "CLOW - POLICY CANCELLED 10 YR WITHDRAWL":
        return ["red", "white"]
      default:
        return ["success", "white"]
    }
  }

  created() {
    this.loadPolicy(this.id)
    this.loadPremiums()
    this.loadPolicyChangeEvents()
    this.fetchDaysDelinquent()
  }

  beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    this.loadPolicy(String(to.params.id))
    next()
  }

  async notice(noticeType: string) {
    const response = await this.$store.dispatch(
      "policies/fetchPolicyDelinquentNotice",
      {
        id: this.id,
        noticeType: noticeType
      }
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `${this.policy.attributes.number}-delinquent-${noticeType}.pdf`
    )
    document.body.appendChild(fURL)

    fURL.click()
  }

  async getUnderwritingReport() {
    this.loadingUnderwritingReport = true

    const response = await this.$store.dispatch(
      "policies/fetchUnderwritingReport",
      this.id
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `underwriting-${this.policy.attributes.number}.pdf`
    )
    document.body.appendChild(fURL)

    fURL.click()
    this.loadingUnderwritingReport = false
  }

  async getReceipt() {
    this.loadingReceipt = true

    const response = await this.$store.dispatch("policies/fetchReceipt", {
      id: this.id,
      startDate: this.receiptStartDate,
      endDate: this.receiptEndDate,
      amount: this.receiptAmount
    })

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `receipt-${this.policy.attributes.number}.pdf`
    )
    document.body.appendChild(fURL)

    fURL.click()
    this.loadingReceipt = false
  }

  async getScheduleOfBenefits() {
    this.loadingScheduleOfBenefits = true

    const response = await this.$store.dispatch(
      "policies/fetchScheduleOfBenefits",
      this.id
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", `sob-${this.policy.attributes.number}.pdf`)
    document.body.appendChild(fURL)

    fURL.click()
    this.loadingScheduleOfBenefits = false
  }

  initiatePolicyEnd() {
    const form = this.$refs["endPolicyForm"] as EndPolicy
    form.open()
  }

  initiatePolicyReinstate() {
    const form = this.$refs["reinstatePolicyForm"] as ReinstatePolicy
    form.open()
  }

  initiateApprovePolicy() {
    const form = this.$refs["approvePolicyDialog"] as DialogTemplate
    form.open(true)
  }

  approvePolicy() {
    const editPolicy = cloneDeep(this.policy)
    editPolicy.attributes.iss_date = this.iss_date
    editPolicy.attributes.start_date = this.eff_date
    editPolicy.attributes.next_bill_on = this.eff_date
    editPolicy.attributes.status = "EFF1 - IN-FORCE / EFFECTIVE"
    this.$store.dispatch("policies/update", editPolicy)
  }

  initiateRejectPolicy() {
    const form = this.$refs["rejectPolicyDialog"] as DialogTemplate
    form.open(true)
  }

  rejectPolicy() {
    const editPolicy = cloneDeep(this.policy)
    editPolicy.attributes.status = this.rejectCode
    editPolicy.attributes.end_date = moment(new Date()).format("YYYY-MM-D")

    this.$store.dispatch("policies/update", editPolicy)
  }

  formatDate(d: string) {
    return moment(new Date(d)).format("M/D/YYYY")
  }

  async policyDateChanged(id: string) {
    await this.loadPolicy(id)
  }

  async loadPolicy(id: string) {
    this.loading = true
    const p = await this.$store.dispatch("policies/load", {
      id: id,
      include: [
        "beneficiaries.ach",
        "beneficiaries.address",
        "laserfiches",
        "member",
        "product",
        "policy_group",
        "policy_group.department",
        "policy_group.department.department_products",
        "policy_group.department.department_products.product",
        "claims",
        "department",
        "department.department_products",
        "department.department_product.product",
        "enroller",
        "member.aches",
        "member.credit_cards",
        "policy_products"
      ]
    })

    if (p.status == 200) {
      this.paymentMethodType = p.data.data.attributes.payment_method_type
      this.paymentMethodId = p.data.data.attributes.payment_method_id
      if (p.data.data.attributes.payment_method_type == "CreditCard") {
        await this.$store.dispatch("creditCards/load", {
          id: p.data.data.attributes.payment_method_id
        })
      } else if (p.data.data.attributes.payment_method_type == "Ach") {
        await this.$store.dispatch("aches/load", {
          id: p.data.data.attributes.payment_method_id
        })
      }
      this.$store.dispatch("payments/loadBy", {
        payable_id: id,
        payable_type: "Policy",
        posted_at: null
      })
      if (p.data.data.attributes.auth_designee_address_id) {
        await this.$store.dispatch("addresses/load", {
          id: p.data.data.attributes.auth_designee_address_id
        })
      }
    }
    this.loading = false
  }

  get ach() {
    const o =
      this.paymentMethodType == "Ach"
        ? this.$store.getters["aches/getById"](this.paymentMethodId)
        : null

    return o
  }

  get creditCard() {
    const o =
      this.paymentMethodType == "CreditCard"
        ? this.$store.getters["creditCards/getById"](this.paymentMethodId)
        : null
    return o
  }

  get aches() {
    if (this.member) {
      return this.$store.getters["aches/getBy"]({
        owner_id: this.member.id,
        owner_type: "Member"
      })
    } else {
      return []
    }
  }

  get creditCards() {
    if (this.member) {
      return this.$store.getters["creditCards/getBy"]({
        owner_id: this.member.id,
        owner_type: "Member"
      })
    } else {
      return []
    }
  }

  loadPremiums() {
    this.$store.dispatch("premiums/loadBy", {
      premiumable_id: this.id,
      premiumable_type: "Policy"
    })
  }

  loadPolicyChangeEvents() {
    this.$store.dispatch("policyChangeEvents/loadBy", {
      policy_id: this.id
    })
  }

  fetchDaysDelinquent() {
    this.$store
      .dispatch("policies/fetchDaysDelinquent", this.id)
      .then(result => {
        this.daysDelinquent = result.data.days_delinquent
      })
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      BalanceRecalculatedChannel: {
        connected() {
          console.log("BalanceRecalculatedChannel connected")
        },
        rejected() {
          console.log("BalanceRecalculatedChannel rejected")
        },
        async received(data: {
          model_type: string
          id: string
          ltc_balance: string
          ltd_balance: string
          eip_balance: string
        }) {
          if (
            data.model_type == "Policy" &&
            data.id == that.id &&
            (data.ltc_balance != that.policy.ltc_balance ||
              data.ltd_balance != that.policy.ltd_balance ||
              data.eip_balance != that.policy.eip_balance)
          ) {
            await new Promise(r => setTimeout(r, 2500))
            that.loadPolicy(data.id)
          }
        }
      }
    }
  }

  mounted() {
    this.$cable.subscribe({
      channel: "BalanceRecalculatedChannel",
      room: "public"
    })
  }
}
