import Model from "./Model"

export class Notification extends Model<Notification> {
  id: string
  attributes!: {
    user_id: number
    title: string
    body: string
    read: boolean
    date_read: string
    created_at: string
    updated_at: string
  }
  static get modelName() {
    return "Notification"
  }
}
