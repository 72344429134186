
import axios from "axios"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"

interface EventData {
  month: number
  day: number
  count: number
}

@Component({
  components: {
    CardTemplate
  }
})
export default class UserEvents extends Vue {
  @Prop() private userId!: string

  private events: EventData[] = []

  month_labels = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"]
  dayWidth = 12
  daySpace = 3
  monthHeight = 15
  monthSpace = 4

  @Watch("userId")
  onIdChange(value: string) {
    if (value) {
      this.fetchEvents(value)
    }
  }

  mounted() {
    if (this.userId) {
      this.fetchEvents(this.userId)
    }
  }

  get days() {
    return Array.from({ length: 31 }, (_, i) => i + 1)
  }

  async fetchEvents(id: string) {
    const data = await axios.get(
      `/users/${id}/heatmap_year`,
      this.$store.getters["config/getAPIConfig"]
    )
    this.events = data.data
  }

  maxCount() {
    return Math.max(...this.events.map(e => e.count))
  }

  dayColor(month: number, day: number) {
    const event = this.events.find(e => e.day == day && e.month == month)
    if (event != undefined) {
      const color = 255 - Math.round((event.count / this.maxCount()) * 255)
      return `rgb(255, ${color.toString()},  ${color.toString()})`
    } else {
      return "#ffffff"
    }
  }

  dayBorderColor(month: number, day: number) {
    const d = this.dayColor(month, day)
    return d
  }
}
