import Model from "./Model"

export class GrandAlert extends Model<GrandAlert> {
  id: string
  attributes!: {
    alertable_type: string
    alertable_id: number | string
    body: string
  }
  static get modelName() {
    return "GrandAlert"
  }
}
