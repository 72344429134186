import { render, staticRenderFns } from "./DisbursementStats.vue?vue&type=template&id=00507b30&scoped=true&"
import script from "./DisbursementStats.vue?vue&type=script&lang=ts&"
export * from "./DisbursementStats.vue?vue&type=script&lang=ts&"
import style0 from "./DisbursementStats.vue?vue&type=style&index=0&id=00507b30&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00507b30",
  null
  
)

export default component.exports