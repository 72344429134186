import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Disbursement extends Model<Disbursement> {
  constructor(response?: Disbursement) {
    super(response as Disbursement)
    if (response) {
      this.attributes.date = DateHelper.stringToDate(this.attributes.date)
      this.attributes.rejected_date = DateHelper.stringToDate(
        this.attributes.rejected_date
      )
    }
  }
  id: string
  attributes!: {
    claim_id: number
    date: Date
    amount: string
    recoverable: string
    overpayment: string
    status: string
    updated_at: string
    disbursement_type_id: number
    comments: string
    check_number: number
    nacha_id: number
    beneficiary_id: number
    rejected_date: Date
  }
  static get modelName() {
    return "Disbursement"
  }
}
