
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Ach } from "@/types/Ach"
import { cloneDeep } from "lodash"
import DialogTemplate from "@/components/DialogTemplate.vue"
import HelpBubble from "@/components/HelpBubble.vue"

@Component({
  components: {
    DialogTemplate,
    HelpBubble
  }
})
export default class MemberInfo extends Vue {
  @Prop() private ach!: Ach

  private editAch = new Ach()
  private showPaste = false
  private pasteValue = ""

  @Watch("ach", { deep: true })
  onAchChange(value: Ach) {
    if (value) {
      this.editAch = cloneDeep(value)
    }
  }

  created() {
    if (this.ach) {
      this.editAch = cloneDeep(this.ach)
    }
  }

  open() {
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  cancel() {
    this.editAch = cloneDeep(this.ach)
  }

  async save() {
    if (this.ach.id) {
      await this.$store.dispatch("aches/update", this.editAch)
      this.editAch = cloneDeep(this.ach)
    } else {
      await this.$store.dispatch("aches/create", this.editAch)
      this.editAch = cloneDeep(this.ach)
    }
  }

  paste(value: string) {
    this.showPaste = false

    const values = value.split("\n")

    this.editAch.attributes.bank_name = values.length > 0 ? values[0] : ""
    this.editAch.attributes.account_number = values.length > 1 ? values[1] : ""
    this.editAch.attributes.routing_number = values.length > 2 ? values[2] : ""
    this.editAch.attributes.account_type = values.length > 3 ? values[3] : ""

    this.pasteValue = ""
  }
}
