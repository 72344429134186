
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class PagedDataTable extends Vue {
  @Prop() private name!: { type: string | undefined; default: undefined }
  @Prop() private itemsLength!: { type: number; default: 5 }
  @Prop() private startingItemsPerPage!: { type: number; default: 5 }
  @Prop() private showEmpty!: { type: boolean; default: false }
  @Prop() private itemsPerPage: {
    type: number[]
    default: [5, 10, 15, 25, -1]
  }
  private start = 1
  private stop = 5
  private previous: number = null

  created() {
    this.$emit("firstPageLoad", {
      itemsPerPage: this.currentItemsPerPage
    })
  }

  pageChanged(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (this.previous && this.previous != 0) {
      this.$emit("loadPage", details)
    }
    this.start = details.pageStart + 1
    this.stop =
      details.pageStop == 0
        ? details.page * details.itemsPerPage
        : details.pageStop

    this.previous = details.itemsLength
  }

  get footerProps() {
    return {
      "items-per-page-options": this.itemsPerPage || [5, 10, 15, 25, -1],
      pageText: `${this.start}-${this.stop} of ${this.itemsLength}`
    }
  }
  updateCurrentItemsPerPage(newValue: number) {
    this.currentItemsPerPage = newValue
  }

  set currentItemsPerPage(newValue: number) {
    if (this.name) {
      localStorage.setItem(`${this.name}-items-per-page`, newValue.toString())
    }
  }

  get currentItemsPerPage() {
    if (this.name) {
      const itemsPerPage = localStorage.getItem(`${this.name}-items-per-page`)
      return itemsPerPage
        ? parseInt(itemsPerPage)
        : parseInt(`${this.startingItemsPerPage}`)
    }
    return parseInt(`${this.startingItemsPerPage}`)
  }
}
