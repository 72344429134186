
import { Component, Vue, Watch } from "vue-property-decorator"
import { Nacha } from "@/types/Nacha"
import AccountChip from "@/components/AccountChip.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import DateField from "@/components/DateField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Disbursement } from "@/types/Disbursement"
import moment from "moment"

@Component({
  components: {
    AccountChip,
    CardTemplate,
    CurrencyField,
    DialogTemplate,
    DateField,
    PagedDataTable,
    PageTemplate
  }
})
export default class Nachas extends Vue {
  private account = "law"
  private selected: Disbursement[] = []
  private selectedTotal = "0"
  private unlockedNachas: Nacha[] = []
  private addingNacha: Nacha = new Nacha()

  private options = {
    sortBy: "attributes.send_date",
    sortDesc: true,
    mustSort: true
  }

  private loading = false
  private total = 0
  private startingPerPage = 5
  private perPage = 5
  private currentPage = 1

  private nachas: Nacha[] = []

  created() {
    this.loadNachas(1, this.startingPerPage)
    this.$store
      .dispatch("disbursements/loadBy", {
        nacha_id: null,
        status: "Approved",
        include: ["claim.policy.member.department", "claim.policy.product"]
      })
      .then(d => {
        const notSameDepartment = d.data.included.filter(
          (el: {
            type: string
            attributes: { last_department_id: number; department_id: number }
          }) => {
            if (el.type == "member") {
              return (
                el.attributes.last_department_id != el.attributes.department_id
              )
            } else {
              return false
            }
          }
        )

        const ids = notSameDepartment.map(
          (el: { attributes: { last_department_id: number } }) => {
            return el.attributes.last_department_id
          }
        )

        if (ids.length > 0) {
          this.$store.dispatch("departments/loadBy", {
            id: ids
          })
        }
      })
  }

  @Watch("account")
  onAccountChange() {
    this.currentPage = 1
    this.loadNachas(1, this.startingPerPage)
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadNachas(1, this.startingPerPage)
  }

  @Watch("selected")
  onSelectedDisbursementsChange(value: Disbursement[]) {
    const amounts = value.map((disbursement: Disbursement) =>
      parseFloat(disbursement.attributes.amount)
    )
    if (amounts.length == 0) {
      this.selectedTotal = "0"
    } else if (amounts.length == 1) {
      this.selectedTotal = amounts[0].toString()
    } else {
      this.selectedTotal = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  get existingSelected() {
    // eslint-disable-next-line no-prototype-builtins
    return this.addingNacha.hasOwnProperty("id")
  }

  get sortableDisbursements() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatted: any[] = this.disbursements ? this.disbursements : []
    formatted.forEach(el => {
      el.member = this.disbursementMember(el)
      el.claim = this.disbursementClaim(el).attributes.number
      el.comments = this.disbursementComment(el)
      el.payee = this.disbursementPayee(el)
      el.amount = el.attributes.amount
      el.date = el.attributes.date
      el.key = el.id
    })

    return formatted
  }

  get disbursements() {
    const d = this.$store.getters["disbursements/getBy"]({
      nacha_id: null,
      status: "Approved"
    })

    const filtered = d.filter((el: Disbursement) => {
      const claim = this.$store.getters["claims/getById"](
        el.attributes.claim_id
      )
      const policy = this.$store.getters["policies/getById"](
        claim?.attributes.policy_id
      )

      if (el.attributes.beneficiary_id) {
        let beneficiary = this.$store.getters["beneficiaries/getById"](
          el.attributes.beneficiary_id
        )

        if (!beneficiary) {
          beneficiary = this.$store.dispatch("beneficiaries/loadBy", {
            id: el.attributes.beneficiary_id
          })
        }

        if (!beneficiary?.attributes?.ach_id) {
          return false
        }
      } else {
        if (!claim?.attributes.payment_method_id) {
          return false
        }
      }

      const product = this.$store.getters["products/getById"](
        policy?.attributes.product_id
      )

      if (this.account == "ltc" && product?.attributes.product_type == "ltc") {
        return true
      } else {
        const member = this.$store.getters["members/getById"](
          policy?.attributes.member_id
        )
        const department = this.$store.getters["departments/getById"](
          member?.attributes.last_department_id
        )

        return (
          this.account == department?.attributes.classification &&
          product?.attributes.product_type != "ltc"
        )
      }
    })

    return filtered.filter((el: Disbursement) => {
      return (
        el.attributes.date <
        moment(new Date())
          .endOf("month")
          .toDate()
      )
    })
  }

  disbursementClaim(disbursement: Disbursement) {
    return this.$store.getters["claims/getById"](
      disbursement.attributes.claim_id
    )
  }

  disbursementMember(disbursement: Disbursement) {
    const claim = this.disbursementClaim(disbursement)
    const policy = this.$store.getters["policies/getById"](
      claim.attributes.policy_id
    )
    const member = this.$store.getters["members/getById"](
      policy.attributes.member_id
    )

    return `${member.attributes.last_name}, ${member.attributes.first_name}`
  }

  disbursementComment(disbursement: Disbursement) {
    const c =
      disbursement.attributes.comments &&
      disbursement.attributes.comments?.length > 50
        ? `${disbursement.attributes.comments?.substring(0, 45)}...`
        : disbursement.attributes.comments

    return c ? c : ""
  }

  disbursementPayee(disbursement: Disbursement) {
    let name = ""

    if (disbursement.attributes.beneficiary_id) {
      const beneficiary = this.$store.getters["beneficiaries/getById"](
        disbursement.attributes.beneficiary_id
      )
      name = `${beneficiary?.attributes?.first_name} ${beneficiary?.attributes?.last_name}`
    } else {
      const claim = this.$store.getters["claims/getById"](
        disbursement.attributes.claim_id
      )
      const policy = this.$store.getters["policies/getById"](
        claim?.attributes.policy_id
      )
      const member = this.$store.getters["members/getById"](
        policy?.attributes.member_id
      )
      name = `${member.attributes.first_name} ${member.attributes.last_name}`
    }

    return name
  }

  async addToNacha() {
    const ids = this.selected.map((el: Disbursement) => {
      return el.id
    })

    for (const id of ids) {
      await this.$store.dispatch("disbursements/update", {
        id: id,
        attributes: { nacha_id: this.addingNacha.id }
      })
    }

    this.loadNachas(1, this.startingPerPage)
    this.$store.dispatch("disbursements/loadBy", {
      nacha_id: null,
      status: "Approved",
      include: ["claim.policy.member.department", "claim.policy.product"]
    })

    this.$store.dispatch("nachas/makeFile", { id: this.addingNacha.id })
  }

  accountToBoard(account: string) {
    let board = "CAPF"
    switch (account) {
      case "fire":
        board = "CAPF"
        break
      case "law":
        board = "CLEA"
        break
      case "ltc":
        board = "NPFBA"
        break
    }
    return board
  }

  async submitNewNacha() {
    const ids = this.selected.map((el: Disbursement) => {
      return el.id
    })

    const new_nacha = new Nacha()
    const today = new Date()

    new_nacha.attributes.name =
      this.accountToBoard(this.account) +
      "_" +
      String(today.getMonth() + 1) +
      "/" +
      String(today.getDate()) +
      "/" +
      today.getFullYear()
    new_nacha.attributes.account = this.account
    new_nacha.attributes.use_case = "disbursements"

    const res = await this.$store.dispatch("nachas/create", new_nacha)

    if (res.status == 200) {
      for (const id of ids) {
        await this.$store.dispatch("disbursements/update", {
          id: id,
          attributes: { nacha_id: res.data.data.id }
        })
      }
    }

    this.loadNachas(1, this.startingPerPage)
    this.$store.dispatch("disbursements/loadBy", {
      nacha_id: null,
      status: "Approved",
      include: ["claim.policy.member.department", "claim.policy.product"]
    })
  }

  getNachas(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadNachas(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  async loadNachas(page: number, perPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("nachas/loadBy", {
      account: this.account,
      use_case: "disbursements",
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.nachas = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false

    this.unlockedNachas = this.nachas.filter((nacha: Nacha) => {
      return nacha.attributes.send_date == null
    })
  }

  goToNacha(nacha: Nacha) {
    this.$router.push({
      name: "Nacha",
      params: { id: nacha.id.toString() }
    })
  }

  get headers() {
    return [
      {
        text: "",
        value: "lock",
        width: 50
      },
      {
        text: "Name",
        value: "attributes.name",
        sortable: true,
        class: "text-left caption",
        width: 350
      },
      {
        text: "account",
        value: "attributes.account",
        sortable: true,
        class: "text-left caption",
        width: 140
      },
      {
        text: "file",
        value: "file",
        sortable: false,
        class: "text-left caption",
        width: 140
      },
      {
        text: "sent",
        value: "attributes.send_date",
        sortable: true,
        class: "text-left caption",
        width: 140
      },
      {
        text: "result",
        value: "attributes.result",
        sortable: true,
        class: "text-left caption"
      }
    ]
  }

  get headers2() {
    return [
      {
        text: "claim",
        value: "claim",
        sortable: true
      },
      { text: "date", value: "date", sortable: true },
      { text: "member", value: "member", sortable: true },
      { text: "comments", value: "comments", sortable: true },
      { text: "payee", value: "payee", sortable: true },
      { text: "amount", value: "amount", sortable: true }
    ]
  }
}
