
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import DateField from "@/components/DateField.vue"
import HelpBubble from "@/components/HelpBubble.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"

interface TempMemberType {
  id: number
  last_name: string
  first_name: string
  ssn: string
  email: string
  phone: string
}

@Component({
  components: {
    CardTemplate,
    ConfirmationTemplate,
    DateField,
    HelpBubble,
    PageTemplate,
    PhoneNumber
  }
})
export default class PolicyGroup extends Vue {
  private members: TempMemberType[] = []

  get id() {
    return this.$route.params.id
  }

  get policyGroup() {
    return this.$store.getters["policyGroups/getById"](this.id)
  }

  get department() {
    return this.policyGroup && this.policyGroup.attributes.department_id
      ? this.$store.getters["departments/getById"](
          this.policyGroup.attributes.department_id
        )
      : null
  }

  async created() {
    await this.loadData(Number(this.id))
  }

  async loadData(id: number) {
    await this.$store.dispatch("policyGroups/load", {
      id: id,
      include: ["department"]
    })

    let page = 1

    this.members = []

    do {
      const data = await this.$store.dispatch("policyGroups/fetchMembers", {
        policyGroupId: id,
        page: page
      })

      this.members.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)
  }

  goToMember(member: TempMemberType) {
    this.$router.push({
      name: "Member",
      params: { id: member.id.toString() }
    })
  }
  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    await this.loadData(to.params.id)

    next()
  }

  get headers() {
    return [
      {
        text: "last",
        value: "last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "first",
        value: "first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "email",
        value: "email",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "ssn",
        value: "ssn",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "phone",
        value: "phone",
        class: "text-left caption"
      },
      {
        text: "waive",
        value: "waive",
        sortable: true,
        class: "text-left caption"
      }
    ]
  }
}
