import Model from "./Model"

export class Iif extends Model<Iif> {
  constructor(response?: Iif) {
    super(response as Iif)
  }
  id: string
  attributes!: {
    name: string
    account: string
    download_doc_url: string
    view_doc_url: string
    created_at: string
    updated_at: string
  }
  static get modelName() {
    return "Iif"
  }
}
