
import { Component, Vue, Watch } from "vue-property-decorator"
import { Address as AddressType } from "@/types/Address"
import { Member } from "@/types/Member"
import { Policy } from "@/types/Policy"
import { Product } from "@/types/Product"
import { GrandAlert as GrandAlertType } from "@/types/GrandAlert"
import ClaimDetails from "@/components/ClaimDetails.vue"
import ClaimsList from "@/components/ClaimsList.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import GrandAlert from "@/components/GrandAlert.vue"
import LaserficheList from "@/components/LaserficheList.vue"
import Ledger from "@/components/Ledger.vue"
import MemberInfo from "@/components/MemberInfo.vue"
import MemberLedger from "@/components/MemberLedger.vue"
import MembershipsList from "@/components/MembershipsList.vue"
import NotesList from "@/components/NotesList.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PaymentMethodsList from "@/components/PaymentMethodsList.vue"
import PoliciesList from "@/components/PoliciesList.vue"
import { cloneDeep } from "lodash"

@Component({
  components: {
    CardTemplate,
    ClaimDetails,
    ClaimsList,
    GrandAlert,
    LaserficheList,
    Ledger,
    MemberInfo,
    MemberLedger,
    MembershipsList,
    NotesList,
    PageTemplate,
    PaymentMethodsList,
    PoliciesList
  }
})
export default class MemberView extends Vue {
  private dataLoaded = false
  private grandAlertEdit = new GrandAlertType()

  get id() {
    return this.$route.params.id
  }

  get name() {
    const member = this.member
    if (member) {
      return member && member.attributes
        ? `${member.attributes.first_name} ${member.attributes.last_name}`
        : ""
    }
    return ""
  }

  get member() {
    return this.$store.getters["members/getById"](this.id)
  }

  get address(): AddressType {
    if (this.member && this.member.attributes.address_id) {
      return this.$store.getters["addresses/getById"](
        this.member.attributes.address_id
      )
    }
    return new AddressType()
  }

  get department() {
    const member = this.member
    let dep = null
    if (member && member.attributes.department_id) {
      dep = this.$store.getters["departments/getById"](
        member.attributes.department_id
      )
    } else if (member && member.attributes.last_department_id) {
      dep = this.$store.getters["departments/getById"](
        member.attributes.last_department_id
      )
    } else if (member && member.attributes.spouse_id) {
      const spouse = this.$store.getters["members/getById"](
        member.attributes.spouse_id
      )

      if (spouse && spouse.attributes.department_id) {
        dep = this.$store.getters["departments/getById"](
          spouse.attributes.department_id
        )
      } else if (spouse && spouse.attributes.last_department_id) {
        dep = this.$store.getters["departments/getById"](
          spouse.attributes.last_department_id
        )
      }
    }
    return dep
  }

  get memberships() {
    if (this.member != null) {
      return this.$store.getters["memberships/getBy"]({
        member_id: this.member.id
      })
    } else {
      return null
    }
  }

  get claims() {
    if (this.member != null) {
      return this.$store.getters["claims/getBy"]({ member_id: this.member.id })
    } else {
      return []
    }
  }

  get activePolicies() {
    return this.$store.getters["policies/getBy"]({
      member_id: this.id,
      end_date: null
    })
  }

  get rating() {
    const policiesWithRating = this.allPolicies.filter((el: Policy) => {
      return el.attributes.rating
    })
    if (policiesWithRating && policiesWithRating.length > 0) {
      return policiesWithRating[0].attributes.rating
    } else {
      return ""
    }
  }

  get allPolicies() {
    return this.$store.getters["policies/getBy"](
      { member_id: this.id },
      "status"
    ).sort((a: Policy, b: Policy) => {
      return a.attributes.status == b.attributes.status &&
        a.attributes.end_date != b.attributes.end_date &&
        a.attributes.end_date != null &&
        b.attributes.end_date != null
        ? a.attributes.end_date < b.attributes.end_date
          ? 1
          : -1
        : 0
    })
  }

  get laserficheDocuments() {
    return this.$store.getters["laserfiches/getBy"]({
      laserficheable_id: this.id,
      laserficheable_type: "Member"
    })
  }

  get products() {
    const productIds = this.allPolicies.map((el: Policy) => {
      return el.attributes.product_id.toString()
    })
    return this.$store.getters["products/getByIds"](productIds).filter(
      (el: Product) => el.attributes.member_paid
    )
  }

  get allProducts() {
    const productIds = this.allPolicies.map((el: Policy) => {
      return el.attributes.product_id.toString()
    })
    return this.$store.getters["products/getByIds"](productIds)
  }

  get creditCards() {
    if (this.member != null) {
      return this.$store.getters["creditCards/getBy"]({
        owner_id: this.member.id,
        owner_type: "Member"
      })
    } else {
      return []
    }
  }
  get aches() {
    if (this.member != null) {
      return this.$store.getters["aches/getBy"]({
        owner_id: this.member.id,
        owner_type: "Member"
      })
    } else {
      return []
    }
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      BalanceRecalculatedChannel: {
        connected() {
          console.log("BalanceRecalculatedChannel connected")
        },
        rejected() {
          console.log("BalanceRecalculatedChannel rejected")
        },
        received(data: {
          model_type: string
          id: string
          ltc_balance: string
          ltd_balance: string
        }) {
          if (
            data.model_type == "Member" &&
            data.id == that.id &&
            (data.ltc_balance != that.ltc_balance ||
              data.ltd_balance != that.ltd_balance)
          ) {
            that.$store.dispatch("members/load", data.id)
          }
        }
      }
    }
  }

  get ltd_balance() {
    return this.member && !!this.member.attributes.ltd_balance
      ? this.member.attributes.ltd_balance
      : "0"
  }

  get ltc_balance() {
    return this.member && !!this.member.attributes.ltc_balance
      ? this.member.attributes.ltc_balance
      : "0"
  }

  @Watch("member", { deep: true })
  onMemberChange(value: Member) {
    if (value) {
      const memberAddId = value.attributes.address_id
      const memberDepId = value.attributes.department_id
      const memberLastDepId = value.attributes.last_department_id
      const spouseId = value.attributes.spouse_id
      if (memberAddId) {
        this.$store.dispatch("addresses/load", memberAddId)
      }
      if (memberDepId) {
        const d = this.$store.getters["departments/getById"](memberDepId)
        if (!d) {
          this.$store.dispatch("departments/load", memberDepId)
        }
      }
      if (memberLastDepId) {
        const d = this.$store.getters["departments/getById"](memberLastDepId)
        if (!d) {
          this.$store.dispatch("departments/load", memberLastDepId)
        }
      }
      if (spouseId) {
        this.$store.dispatch("members/load", spouseId).then(spouse => {
          if (spouse.status == 200) {
            const departmentId = spouse.data.data.attributes.last_department_id
            if (departmentId) {
              this.$store.dispatch("departments/load", departmentId)
            }
          }
        })
      }

      this.grandAlertEdit = cloneDeep(this.grandAlert)
    }
  }

  deleteAlert() {
    this.$store.dispatch("grandAlerts/delete", this.grandAlertEdit.id)
    this.$store.commit("grandAlerts/remove", {
      id: this.grandAlertEdit.id
    })

    this.grandAlertEdit = new GrandAlertType()
  }

  async saveAlert() {
    if (this.grandAlertEdit.id) {
      this.$store.dispatch("grandAlerts/update", this.grandAlertEdit)
    } else {
      this.grandAlertEdit.attributes.alertable_id = this.id
      this.grandAlertEdit.attributes.alertable_type = "Member"
      this.$store.dispatch("grandAlerts/create", this.grandAlertEdit)
    }

    this.dataLoaded = false
    await this.reloadMember(this.id)
    this.dataLoaded = true

    this.grandAlertEdit = cloneDeep(this.grandAlert)
  }

  undoAlertEdit() {
    this.grandAlertEdit = cloneDeep(this.grandAlert)
  }

  set tab(value) {
    localStorage.setItem("lastMemberTab", value.toString())
    const query = { ...this.$route.query }
    query.tab = value.toString()
    this.$router.replace({ query: query })
  }
  get tab() {
    const tab = Array.isArray(this.$route.query.tab)
      ? this.$route.query.tab[0]
      : this.$route.query.tab
    return parseInt(tab) || 0
  }

  async reloadMember(memberId: string | number) {
    await this.$store.dispatch("members/load", {
      id: memberId,
      include: [
        "address",
        "memberships",
        "policies.claims",
        "policies.policy_group",
        "memberships.department",
        "laserfiches",
        "bills",
        "credit_cards",
        "aches"
      ]
    })

    const productIds = this.allPolicies.map((p: Policy) => {
      return p.attributes.product_id
    })
    this.$store.dispatch("products/loadBy", { id: productIds })

    this.$store.dispatch("bills/loadBy", {
      billable_id: memberId,
      billable_type: "Member"
    })

    this.$store.dispatch("payments/loadBy", {
      payable_id: memberId,
      payable_type: "Member"
    })

    this.$store.dispatch("grandAlerts/loadBy", {
      alertable_id: memberId,
      alertable_type: "Member"
    })
  }

  get grandAlert() {
    const ga = this.$store.getters["grandAlerts/getBy"]({
      alertable_id: this.id,
      alertable_type: "Member"
    })

    return ga && ga.length > 0 ? ga[0] : new GrandAlertType()
  }

  @Watch("$route.query.tab", { immediate: true })
  routeChange() {
    if (
      !this.$route.query ||
      this.$route.query.tab == null ||
      this.$route.query.tab == undefined
    ) {
      const query = { ...this.$route.query }
      query.tab = localStorage.getItem("lastMemberTab") || "0"
      this.$router.replace({ query: query })
    }
  }

  async created() {
    await this.reloadMember(this.id)
    this.dataLoaded = true
  }

  mounted() {
    this.$cable.subscribe({
      channel: "BalanceRecalculatedChannel",
      room: "public"
    })
  }

  async beforeRouteUpdate(
    to: { params: { id: number }; query: { tab: string }; path: string },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    if (to.params.id != from.params.id) {
      this.dataLoaded = false
      await this.reloadMember(to.params.id)
      this.dataLoaded = true
    }
    next()
  }
}
