
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DialogTemplate
  }
})
export default class UpdateBillingFrequency extends Vue {
  @Prop() private id!: number
  @Prop() private billing_frequency!: ""

  private changedBillingFrequency = ""

  @Watch("billing_frequency")
  onFreqChange(value: string) {
    if (value) {
      this.changedBillingFrequency = value
    }
  }

  private billingFrequencies = [
    "Monthly",
    "Quarterly",
    "Semi-Annually",
    "Annually"
  ]

  async update() {
    console.log("update to ", this.changedBillingFrequency)
    await axios.put(
      `/policies/${this.id}`,
      { billing_frequency: this.changedBillingFrequency },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
