import { MutationTree, ActionTree } from "vuex"
import { Benefit } from "@/types/Benefit"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Benefit[] } = {
  all: []
}

const getters = {}
const actions: ActionTree<State<Benefit>, RootState> = {}
const mutations: MutationTree<State<Benefit>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
