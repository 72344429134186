
import { Component, Prop, Vue } from "vue-property-decorator"
import { Parser } from "json2csv"

@Component({})
export default class AdvancedSearchResults extends Vue {
  @Prop() private items: any[] //eslint-disable-line @typescript-eslint/no-explicit-any
  @Prop() private headers: any[] //eslint-disable-line @typescript-eslint/no-explicit-any
  @Prop() private value: boolean

  get shouldShow() {
    return this.value
  }

  set shouldShow(value: boolean) {
    this.$emit("input", value)
  }

  createCSV() {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fields = this.headers.map((el: any) => el.value)

    const parser = new Parser({ fields })
    const csv = parser.parse(this.items)
    const csvBlob = new Blob([csv], { type: "text/csv" })

    const link = document.createElement("a")
    link.href = URL.createObjectURL(csvBlob)
    link.download = "advanced_search_results.csv"
    link.click()
    URL.revokeObjectURL(link.href)
  }
}
