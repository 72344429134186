
import { Component, Vue } from "vue-property-decorator"
import BoardMeetingList from "@/components/BoardMeetingList.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import PageTemplate from "@/components/PageTemplate.vue"

@Component({
  components: {
    BoardMeetingList,
    CardTemplate,
    PageTemplate
  }
})
export default class BoardMeetings extends Vue {}
