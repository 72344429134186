import Model from "./Model"

export class Product extends Model<Product> {
  id: string
  attributes!: {
    name: string
    description: string
    product_type: string
    premium: string
    member_paid: boolean
    eip: boolean
    retired_on: Date
    updated_at: string
    day_rate: string
    day_amount: number
    has_death_benefit: boolean
    has_max_benefit: boolean
    single_rating: string
  }
  static get modelName() {
    return "Product"
  }
}
