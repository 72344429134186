import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Disbursement } from "@/types/Disbursement"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import modelToMutation from "@/store/utils/modelToMutation"
import { isEmpty } from "lodash"
import errorHandler from "@/store/utils/errorHandler"

const state: { all: Disbursement[] } = {
  all: []
}

const getters = {
  getActiveByStatusAndProductType: (
    state: State<Disbursement>,
    getters: {},
    rootState: RootState,
    rootGetters: any // eslint-disable-line @typescript-eslint/no-explicit-any
  ) => ({ status, product }: { status: string; product: string }) => {
    const activeStates = rootGetters["config/getActiveStates"]
      ? rootGetters["config/getActiveStates"].map((el: string) =>
          el.includes("_") ? el.split("_")[1] : el
        )
      : []

    const thirty_days = new Date()
    thirty_days.setDate(thirty_days.getDate() - 30)
    thirty_days.setHours(0, 0, 0, 0)

    const disbursements = state.all.filter(el => {
      const claim = rootGetters["claims/getById"](el.attributes.claim_id)
      let match = true

      if (!claim || !activeStates.includes(claim.attributes.state)) {
        match = false
      }
      if (product && product != "all") {
        match = match && claim.attributes.product_type == product
      }
      if (status && status != "all") {
        match = match && el.attributes.status == status
      }
      if (el.attributes.status == "Paid") {
        match =
          match &&
          el.attributes.date != null &&
          el.attributes.date >= thirty_days
      }

      return match
    })

    return disbursements
  }
}

const actions: ActionTree<State<Disbursement>, RootState> = {
  async void(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<Disbursement>, RootState>,
    params: { id: number; date: string; add: boolean }
  ) {
    try {
      const data = await axios.put(
        `/disbursements/${params.id}/void`,
        { date: params.date, add: params.add },
        rootGetters["config/getAPIConfig"]
      )
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async reject(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<Disbursement>, RootState>,
    { id }
  ) {
    try {
      const data = await axios.put(
        `/disbursements/${id}/reject`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getStats({
    dispatch,
    rootGetters
  }: ActionContext<State<Disbursement>, RootState>) {
    try {
      const data = await axios.get(
        "/disbursements/stats",
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getStatsLtc({
    dispatch,
    rootGetters
  }: ActionContext<State<Disbursement>, RootState>) {
    try {
      const data = await axios.get(
        "/disbursements/stats_ltc",
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getRawStats(
    { dispatch, rootGetters }: ActionContext<State<Disbursement>, RootState>,
    params: { month: string; classification: string }
  ) {
    try {
      const data = await axios.get(
        `/disbursements/raw_stats?month=${params.month}&classification=${params.classification}`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async loadCombined(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<Disbursement>, RootState>,
    params: { include?: string[] | string; [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    try {
      if (Array.isArray(params?.include)) {
        params.include = params.include.join(",")
      }

      const queryParams = !isEmpty(params)
        ? {
            params: params
          }
        : {}
      const data = await axios.get(
        "/disbursements/combine_recoveries",
        Object.assign(queryParams, rootGetters["config/getAPIConfig"])
      )

      data.data.data.forEach((el: { type: string }) => {
        const mutation = modelToMutation(el.type)
        commit(mutation, el, { root: true })
      })

      if (data.data.included) {
        data.data.included.forEach((el: { type: string }) => {
          const mutation = modelToMutation(el.type)
          commit(mutation, el, { root: true })
        })
      }

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<Disbursement>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
