
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import { ClaimFormDefinition } from "@/types/ClaimFormDefinition"
import { Product } from "@/types/Product"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    CardTemplate,
    DialogTemplate
  }
})
export default class ClaimFormDefinitionsList extends Vue {
  @Prop() private definitions!: [ClaimFormDefinition]
  @Prop() private products!: [Product]

  private newDefinition = new ClaimFormDefinition()

  private clonedDefinitions: ClaimFormDefinition[] = this.definitions
    ? cloneDeep(this.definitions)
    : []

  @Watch("definitions", { deep: true })
  onChangeDefinitions() {
    this.clonedDefinitions = this.definitions ? cloneDeep(this.definitions) : []
  }

  get productTypes() {
    return ["ltc", "ltd"]
  }

  get mappedProducts() {
    return this.products
      ? this.products.map(el => {
          return { id: Number(el.id), name: el.attributes.name }
        })
      : []
  }

  get deliveryTypes() {
    return ["US Mail", "E-Mail", "Fax"]
  }

  handleSave(definition: ClaimFormDefinition) {
    if (definition) {
      if (definition.attributes.default_delivery_method == undefined) {
        definition.attributes.default_delivery_method = null
      }
      if (definition.attributes.product_type == undefined) {
        definition.attributes.product_type = null
      }
      if (definition.attributes.product_id == undefined) {
        definition.attributes.product_id = null
      }
      this.$store.dispatch("claimFormDefinitions/update", {
        data: definition,
        successMessage: "Claim Form updated"
      })
    } else {
      this.$store.dispatch("claimFormDefinitions/create", {
        data: this.newDefinition,
        successMessage: "New claim form definition added"
      })
      this.reset()
    }
  }

  productName(productId: number) {
    const product = this.products
      ? this.products.find((el: Product) => parseInt(el.id) == productId)
      : null
    return product ? product.attributes.name : ""
  }

  reset() {
    this.newDefinition = new ClaimFormDefinition()
  }

  get someDirty() {
    return this.definitions?.some(def => def.dirty)
  }

  reloadDefinition(def: ClaimFormDefinition) {
    this.$store.dispatch("claimFormDefinitions/load", def.id)
  }
}
