
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import { EstimatedPayable } from "@/types/EstimatedPayable"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField
  }
})
export default class ClaimEstimatedPayable extends Vue {
  @Prop() private estimatedPayable!: EstimatedPayable
}
