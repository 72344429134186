
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import { Policy } from "@/types/Policy"
import moment from "moment"

@Component({
  components: {
    CardTemplate
  }
})
export default class PolicyTotals extends Vue {
  @Prop() private policy!: Policy

  dateToDisplay(dateString: string) {
    const s = moment(dateString, "YYYY-MM-DD")

    if (!s.isValid()) {
      return undefined
    }

    return s.format("l")
  }
}
