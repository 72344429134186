
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component
export default class UserRolesChecklist extends Vue {
  @Prop() private roles!: { [key: string]: number }
  @Prop() private value!: number
  @Prop() private readonly!: boolean
  private selectedRoles: number[] = []

  get userRoles() {
    const returnRoles = []
    if (this.roles) {
      for (const [name, num] of Object.entries(this.roles)) {
        returnRoles.push({ text: this.titleCase(name), value: num })
      }
    }
    return returnRoles.sort((a: { text: string }, b: { text: string }) => {
      return a.text > b.text ? 1 : -1
    })
  }

  created() {
    this.calculateSelected()
  }

  @Watch("value")
  onValueChange() {
    this.calculateSelected()
  }

  @Watch("roles")
  onRolesChange() {
    this.calculateSelected()
  }

  calculateSelected() {
    if (this.value && this.roles) {
      this.selectedRoles = []
      for (const key of Object.keys(this.roles)) {
        if (
          this.hasRole(key) &&
          !this.selectedRoles.includes(this.roles[key])
        ) {
          this.selectedRoles.push(this.roles[key])
        }
      }
    }
  }

  updateValue() {
    this.$emit(
      "input",
      this.selectedRoles.reduce((total: number, current: number) => {
        return (total += current)
      }, 0)
    )
  }

  titleCase(value: string) {
    return value
      ? value
          .toLowerCase()
          .replace(/_/g, " ")
          .split(" ")
          .map(function(word) {
            return word.charAt(0).toUpperCase() + word.slice(1)
          })
          .join(" ")
      : ""
  }

  hasRole(roleId: string) {
    const tableVal = this.roles[roleId] ? this.roles[roleId] : 0

    let masked = 0
    if (tableVal) {
      masked = tableVal & this.value
    }

    return masked
  }
}
