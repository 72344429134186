
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"

@Component({
  components: { CardTemplate }
})
export default class DepartmentSection extends Vue {
  @Prop() private title!: string
}
