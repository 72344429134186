import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class User extends Model<User> {
  constructor(response?: User) {
    super(response as User)
    if (response) {
      if (this.attributes.disabled_at) {
        this.attributes.disabled_at = DateHelper.stringToDate(
          this.attributes.disabled_at
        )
      }
    }
  }
  id: string
  subjectName = "User"
  attributes!: {
    first_name: string
    last_name: string
    email: string
    roles: number
    roles_list: []
    password_is_set: boolean
    updated_at: string
    disabled_at: Date
    avatar_color: null | string
  }
  static get modelName() {
    return "User"
  }

  get name(): string {
    let s = ""

    if (this.attributes.first_name) {
      s += this.attributes.first_name
    }

    if (this.attributes.last_name) {
      s += " " + this.attributes.last_name
    }

    s = s.trim()

    if (s.length == 0) {
      s = this.attributes.email
    }
    return s
  }
}
