import axios from "axios"
import { MutationTree, ActionTree } from "vuex"
import { Search } from "@/types/Search"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import setDirty from "@/store/modules/dirtyFlag"
import errorHandler from "@/store/utils/errorHandler"

const state: { all: Search[] } = {
  all: []
}

const getters = {
  getAll: (state: State<Search>) => {
    return state.all
  }
}

const actions: ActionTree<State<Search>, RootState> = {
  async performSearch(
    { dispatch, rootGetters },
    input: { q: string; field: string; filter: string } | string
  ) {
    let params = `q=${typeof input == "string" ? input : input.q}`
    if (!(typeof input == "string") && input.field)
      params += `&field=${input.field}`
    if (!(typeof input == "string") && input.filter)
      params += `&filter=${input.filter}`
    try {
      const data = await axios.get(
        `/search?${params}`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<Search>> = {
  setDirty,
  addSearchResult(state: State<Search>, payload: Search) {
    Object.assign(state, { all: payload })
  },
  clearSearchResults(state: State<Search>) {
    state.all.splice(0, state.all.length)
  }
}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
