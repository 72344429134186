
import { Component, Vue } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import AccountChip from "@/components/AccountChip.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PaymentsList from "@/components/PaymentsList.vue"
import PaymentsAsDeposit from "@/components/PaymentsAsDeposit.vue"
import { Deposit as DepositType } from "@/types/Deposit"

@Component({
  components: {
    AccountChip,
    CardTemplate,
    DateField,
    DialogTemplate,
    PageTemplate,
    PaymentsAsDeposit,
    PaymentsList
  }
})
export default class Deposit extends Vue {
  private stats = {}
  private statsRateLocked = {}
  private editDeposit = new DepositType()
  private regeneratingPdf = false

  get id() {
    return this.$route.params.id
  }

  get deposit() {
    return this.$store.getters["deposits/getById"](this.id)
  }

  get displayName() {
    if (this.deposit && this.deposit.attributes) {
      return this.deposit.attributes.name
        ? this.deposit.attributes.name
        : `Deposit ${new Date(
            this.deposit.attributes.deposit_date
          ).toLocaleString([], {
            day: "numeric",
            month: "numeric",
            year: "numeric"
          })}`
    } else {
      return "Deposit"
    }
  }

  save() {
    this.$store.dispatch("deposits/update", this.editDeposit)
  }

  getReportUrl(deposit: DepositType, download: boolean) {
    if (deposit) {
      const url = download
        ? deposit.attributes.download_report_url
        : deposit.attributes.view_report_url
      return url ? this.baseUrl + url : ""
    } else {
      return ""
    }
  }

  regeneratePdf() {
    this.regeneratingPdf = true
    this.$store.dispatch("deposits/regeneratePDF", this.id)
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  async created() {
    await this.$store.dispatch("deposits/load", {
      id: this.id
    })

    this.editDeposit = cloneDeep(this.deposit)

    this.$store.dispatch("deposits/stats", this.id).then(data => {
      this.statsRateLocked = data.data.rate_locked
      delete data.data.rate_locked
      this.stats = data.data
    })
  }

  mounted() {
    this.$cable.subscribe({
      channel: "DepositPdfRegenChannel",
      room: "public"
    })
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      DepositPdfRegenChannel: {
        connected() {
          console.log("DepositPdfRegenChannel connected")
        },
        rejected() {
          console.log("DepositPdfRegenChannel rejected")
        },
        received(data: { id: string }) {
          if (data.id == that.id) {
            that.$store.dispatch("deposits/load", {
              id: that.id
            })
            that.regeneratingPdf = false
          }
        }
      }
    }
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    await this.$store.dispatch("deposits/load", {
      id: to.params.id
    })
    this.editDeposit = cloneDeep(this.deposit)

    this.$store.dispatch("deposits/stats", to.params.id).then(data => {
      this.stats = data.data
    })

    next()
  }
}
