
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"

@Component({
  components: {
    CardTemplate
  }
})
export default class DisbursementTotals extends Vue {
  @Prop() private disbursement!: number
  @Prop() private recoverable!: number
  @Prop() private recovered!: number
  @Prop() private write_off!: number
  @Prop() private reclassified!: number
  @Prop() private overpayment!: number
  @Prop() private overpaymentRecovered!: number
  @Prop() private death!: number
  @Prop() private benefits!: number

  get outstandingOverpayment() {
    return this.overpayment - this.overpaymentRecovered
  }

  get outstanding() {
    return (
      this.recoverable - this.recovered - this.write_off - this.reclassified
    )
  }
}
