
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Address as AddressType } from "@/types/Address"
import { Department } from "@/types/Department"
import { Member } from "@/types/Member"
import { Policy } from "@/types/Policy"
import { Product } from "@/types/Product"
import { Search } from "@/types/Search"
import { cloneDeep } from "lodash"
import Address from "@/components/Address.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DepartmentSummary from "@/components/DepartmentSummary.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import MemberCoverageGraphic from "@/components/MemberCoverageGraphic.vue"
import SupplementalBillEntry from "@/components/SupplementalBillEntry.vue"
import NewMember from "@/components/NewMember.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { Membership } from "@/types/Membership"

@Component({
  components: {
    Address,
    CardTemplate,
    DateField,
    DepartmentSummary,
    DialogTemplate,
    MemberCoverageGraphic,
    NewMember,
    PhoneNumber,
    SupplementalBillEntry
  }
})
export default class MemberInfo extends Vue {
  @Prop() private member!: Member
  @Prop() private products!: [Product]
  @Prop() private policies!: [Policy]

  private editMember: Member = new Member()
  private valid = false
  private editSpouseName = ""
  private menu = false
  private searchResults: Search[] = []
  private showExisting = false
  private spouseMember: Member = new Member()
  private spouseSameAddress = false
  private selectedProduct: Product = null
  private retroWaiveLtdDate = new Date()
  private coverageData = {}
  private spouseCheckVerified = false

  created() {
    this.editMember = cloneDeep(this.member)
    const departmentAddId = this.department?.attributes.address_id
    if (departmentAddId) {
      this.$store.dispatch("addresses/load", departmentAddId)
    }
    this.fetchCoverageData()
  }

  fetchCoverageData() {
    if (this.member && this.member.id) {
      this.$store.dispatch("members/coverage", this.member.id).then(data => {
        this.coverageData = data.data
      })
    }
  }

  @Watch("member", { deep: true })
  onMemberChange(value: Member) {
    if (value) {
      this.editMember = cloneDeep(value)
      this.fetchCoverageData()
      this.spouseMember.attributes.is_spouse = !this.editMember.attributes
        .is_spouse
    }
  }

  @Watch("department")
  onDepartment(value: Department) {
    if (value) {
      const departmentAddId = value.attributes.address_id
      if (departmentAddId) {
        this.$store.dispatch("addresses/load", departmentAddId)
      }
    }
  }

  genderClass(gender: string) {
    return gender == "unspecified" ? "red--text" : ""
  }

  marriedStatusClass(status: string) {
    return status == "unknown" ? "red--text" : ""
  }

  async doSearch(event: KeyboardEvent) {
    if ([13, 37, 38, 39, 40].includes(event.keyCode)) {
      return
    }
    this.editMember.attributes.spouse_id = null

    if (this.editSpouseName?.trim().length >= 2) {
      const data = await this.$store.dispatch(
        "search/performSearch",
        "m:" + this.editSpouseName
      )
      this.searchResults = data.data
    }
  }

  //due to an existing issue with vuetify, the menu display needs to be handled manually
  //https://github.com/vuetifyjs/vuetify/issues/9129#issuecomment-671810286
  blur() {
    setTimeout(() => {
      this.menu = false
    }, 500)
  }

  get memberPaidPolicies() {
    return this.policies
      ? this.policies.filter(
          el =>
            this.policyProduct(el) &&
            this.policyProduct(el).attributes.member_paid
        )
      : []
  }

  get currentSpouse() {
    const spouse = this.$store.getters["members/getById"](
      this.member?.attributes?.spouse_id
    )
    if (!spouse && this.member?.attributes?.spouse_id) {
      this.$store.dispatch("members/load", this.member?.attributes?.spouse_id)
    }
    return spouse
  }

  get editSpouse() {
    const spouse = this.$store.getters["members/getById"](
      this.editMember?.attributes?.spouse_id
    )
    if (!spouse && this.editMember?.attributes?.spouse_id) {
      this.$store.dispatch(
        "members/load",
        this.editMember?.attributes?.spouse_id
      )
    }
    return spouse
  }

  get spouseName() {
    const spouse = this.currentSpouse
    if (spouse && spouse.attributes) {
      return `${spouse.attributes.first_name} ${spouse.attributes.last_name}`
    }
    return null
  }

  get validSpouse() {
    const spouse = this.editSpouse
    return (
      !spouse ||
      (this.editMember.attributes.spouse_id &&
        this.editMember.attributes.spouse_id != parseInt(this.member.id) &&
        (!spouse.attributes.spouse_id ||
          spouse.attributes.spouse_id == parseInt(this.member.id)))
    )
  }

  cancel() {
    this.resetMember()
  }

  resetMember() {
    this.editMember = cloneDeep(this.member)
  }

  policyProduct(policy: Policy) {
    const product = this.products.find((p: Product) => {
      return Number(p.id) == Number(policy.attributes.product_id)
    })
    return product
  }

  get address(): AddressType {
    if (this.member && this.member.attributes.address_id) {
      return this.$store.getters["addresses/getById"](
        this.member.attributes.address_id
      )
    }
    return new AddressType()
  }

  get memberships() {
    return this.$store.getters["memberships/getBy"]({
      member_id: this.member.id
    })
  }

  get currentMemberships() {
    return this.memberships.filter((el: Membership) => {
      return el.attributes.end_date == null
    })
  }

  get department() {
    const member = this.member
    let dep = null
    if (member && member.attributes.department_id) {
      dep = this.$store.getters["departments/getById"](
        member.attributes.department_id
      )
    }
    return dep
  }

  get departmentAddress() {
    const dep = this.department
    let add = null

    if (dep && dep.attributes.address_id) {
      add = this.$store.getters["addresses/getById"](dep.attributes.address_id)
    }
    return add
  }

  async addSpouseMember() {
    let ssnExists =
      this.spouseMember &&
      this.spouseMember.attributes &&
      this.spouseMember.attributes.ssn &&
      this.spouseMember.attributes.ssn.length > 0

    if (ssnExists) {
      ssnExists = await this.checkExistingSsn()
    }
    let spouseAddressId = null

    if (!ssnExists) {
      if (this.spouseSameAddress) {
        const memberAddId = this.member.attributes.address_id
        const memberAddress = this.$store.getters["addresses/getById"](
          memberAddId
        )

        let spouseAddress = cloneDeep(memberAddress)
        spouseAddress.id = null
        spouseAddress = await this.$store.dispatch(
          "addresses/create",
          spouseAddress
        )

        spouseAddressId = spouseAddress.data.data.id
      }

      this.spouseMember.attributes.address_id = spouseAddressId

      this.spouseMember.attributes.last_department_id = this.member.attributes.last_department_id

      const result = await this.$store.dispatch(
        "members/create",
        this.spouseMember
      )

      if (result) {
        this.editMember.attributes.spouse_id = result.data.data.id
        await this.save()
      }
    }
  }

  async checkExistingSsn() {
    const result = await this.$store.dispatch("members/doesSSNExist", {
      ssn: this.spouseMember.attributes.ssn.replace(/[-]/g, "")
    })

    this.showExisting = result

    return result
  }

  cancelFromSSN() {
    ;(this.$refs.spouseMemberDialog as DialogTemplate).cancel()
    this.showExisting = false
  }

  async save() {
    await this.$store.dispatch("members/update", this.editMember)
    this.resetMember()
  }

  async saveWaive() {
    if (
      this.editMember.attributes.waive_ltd != this.member.attributes.waive_ltd
    ) {
      const waive_date =
        typeof this.retroWaiveLtdDate == "string"
          ? this.retroWaiveLtdDate
          : this.retroWaiveLtdDate.toISOString().replace(/T.*/, "")

      if (this.currentMemberships.length > 0) {
        await this.$store.dispatch("members/retroWaive", {
          memberId: this.member.id,
          waiveDate: waive_date,
          waiveState: this.editMember.attributes.waive_ltd,
          waiveReason: this.editMember.attributes.waive_reason_ltd,
          productType: "ltd"
        })
      } else {
        await this.save()
      }
    }

    if (
      this.editMember.attributes.waive_ltc !=
        this.member.attributes.waive_ltc ||
      this.editMember.attributes.waive_reason_ltc !=
        this.member.attributes.waive_reason_ltc
    ) {
      await this.save()
    }
  }

  maskSSN(ssn: string) {
    return ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4)
  }
}
