import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class BillingGroup extends Model<BillingGroup> {
  constructor(response?: BillingGroup) {
    super(response as BillingGroup)
    if (response) {
      this.attributes.date = DateHelper.stringToDate(this.attributes.date)
    }
  }
  id: string
  attributes!: {
    classification: string
    name: string
    date: Date
    status: string
    created_at: string
  }
  static get modelName() {
    return "BillingGroup"
  }
}
