
import { Component, Vue } from "vue-property-decorator"
import PageTemplate from "@/components/PageTemplate.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import { Search } from "@/types/Search"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CardTemplate,
    DateField,
    PageTemplate
  }
})
export default class MemberMerge extends Vue {
  private menuDestination = false
  private menuSource = false
  private destMemberName = ""
  private sourceMemberName = ""
  private destMemberId: string | null = null
  private sourceMemberId: string | null = null
  private destSearchResults: Search[] = []
  private sourceSearchResults: Search[] = []

  async doMerge() {
    await this.$store.dispatch("members/merge", {
      sourceMemberId: this.sourceMemberId,
      destinationMemberId: this.destMemberId
    })

    this.sourceMemberId = null
    this.sourceMemberName = ""

    await this.$store.dispatch("alerts/addSuccess", "Merge complete!")
  }

  async doSearchDest(event: KeyboardEvent) {
    if ([13, 37, 38, 39, 40].includes(event.keyCode)) {
      return
    }
    if (this.destMemberName?.trim().length >= 2) {
      // this.destSearchResults = []
      const data = await this.$store.dispatch(
        "search/performSearch",
        "m:" + this.destMemberName
      )
      this.destSearchResults = data.data
    }
  }

  async doSearchSource(event: KeyboardEvent) {
    if ([13, 37, 38, 39, 40].includes(event.keyCode)) {
      return
    }
    if (this.sourceMemberName?.trim().length >= 2) {
      // this.sourceSearchResults = []
      const data = await this.$store.dispatch(
        "search/performSearch",
        "m:" + this.sourceMemberName
      )
      this.sourceSearchResults = data.data
    }
  }

  async selectDestMember(result: Search) {
    this.destMemberName = result.title
    this.destSearchResults = []

    await this.$store.dispatch("members/load", {
      id: result.target_id
    })

    this.destMemberId = result.target_id
  }

  async selectSourceMember(result: Search) {
    this.sourceMemberName = result.title
    this.sourceSearchResults = []

    await this.$store.dispatch("members/load", {
      id: result.target_id
    })

    this.sourceMemberId = result.target_id
  }

  get destMember() {
    return this.destMemberId
      ? this.$store.getters["members/getById"](this.destMemberId)
      : null
  }

  get sourceMember() {
    return this.sourceMemberId
      ? this.$store.getters["members/getById"](this.sourceMemberId)
      : null
  }

  formatSSN(ssn: string) {
    return ssn
      ? ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4)
      : ""
  }
  //due to an existing issue with vuetify, the menu display needs to be handled manually
  //https://github.com/vuetifyjs/vuetify/issues/9129#issuecomment-671810286
  blurDest() {
    setTimeout(() => {
      this.menuDestination = false
    }, 500)
  }
  blurSource() {
    setTimeout(() => {
      this.menuSource = false
    }, 500)
  }
}
