
import { Component, Vue, Prop } from "vue-property-decorator"
import { BoardMeetingAttendee } from "@/types/BoardMeetingAttendee"
import PhoneNumber from "./PhoneNumber.vue"

@Component({
  components: { PhoneNumber }
})
export default class AttendeeDetails extends Vue {
  @Prop() private attendee!: BoardMeetingAttendee
  @Prop() private departmentName!: string

  get addressLineOne() {
    let s = this.attendee.attributes.street
      ? this.attendee.attributes.street
      : ""
    if (
      this.attendee.attributes.apartment &&
      this.attendee.attributes.apartment.length > 0
    ) {
      s += this.attendee.attributes.apartment
        ? s.length
          ? ", " + this.attendee.attributes.apartment
          : this.attendee.attributes.apartment
        : ""
    }
    return s
  }
  get addressLineTwo() {
    let s = this.attendee.attributes.city ? this.attendee.attributes.city : ""
    s += this.attendee.attributes.state
      ? s.length
        ? ", " + this.attendee.attributes.state
        : this.attendee.attributes.state
      : ""
    s += this.attendee.attributes.zip
      ? s.length
        ? " " + this.attendee.attributes.zip
        : this.attendee.attributes.zip
      : ""
    return s
  }
}
