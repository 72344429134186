
import { Component, Vue, Watch } from "vue-property-decorator"
import { mapGetters } from "vuex"
import jwtDecode from "jwt-decode"
import { User as UserType } from "@/types/User"
import { Note } from "@/types/Note"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import UserEvents from "@/components/UserEvents.vue"
import UserEventsHeatmap from "@/components/UserEventsHeatmap.vue"
import UserEventsHeatmapYear from "@/components/UserEventsHeatmapYear.vue"
import UserRolesChecklist from "@/components/UserRolesChecklist.vue"
import UserInitials from "@/components/UserInitials.vue"

import { cloneDeep } from "lodash"

@Component({
  computed: { ...mapGetters("users", ["getById"]) },
  components: {
    CardTemplate,
    DialogTemplate,
    PageTemplate,
    UserEvents,
    UserEventsHeatmap,
    UserEventsHeatmapYear,
    UserRolesChecklist,
    UserInitials
  }
})
export default class User extends Vue {
  newUserRoles = 0
  editing = false
  private editUser = new UserType()

  @Watch("user", { deep: true })
  onUserChange() {
    this.reset()
  }

  get id() {
    return this.$route.params.id
  }

  get avatarColor() {
    return this.editUser.attributes.avatar_color
      ? this.editUser.attributes.avatar_color
      : "indigo"
  }

  get ownNote() {
    const note = new Note()
    note.attributes.user_id = parseInt(this.id)
    return note
  }

  get otherNote() {
    const note = new Note()
    note.attributes.user_id = parseInt(this.id) + 1
    return note
  }

  getById!: (id: string) => UserType

  get user() {
    const u = this.getById(this.id)
    this.newUserRoles = u && u.attributes ? u.attributes.roles : 0
    return u
  }

  get email() {
    const user = this.user
    return user && user.attributes ? user.attributes.email : "n/a"
  }

  get name() {
    const user = this.user
    let sName = ""

    if (user?.attributes?.first_name) {
      sName += user.attributes.first_name
    }

    if (user?.attributes?.last_name) {
      sName += " " + user.attributes.last_name
    }

    sName = sName.trim()

    if (sName.length == 0) {
      sName = user?.attributes?.email
    }
    return sName
  }

  get roles() {
    const user = this.user

    return user && user.attributes ? user.attributes.roles : 0
  }

  get loggedInUserId() {
    let id = 0
    const token: string = this.$store.getters["config/getToken"]
    if (token) {
      const tokenObject: { roles: []; id: number } = jwtDecode(token)
      id = tokenObject.id
    }
    return id
  }

  get rules(): [] {
    return this.user ? this.user.attributes.roles_list : []
  }

  get userRoles() {
    return this.$store.getters["config/getUserRoles"]
  }

  saveRoles() {
    const user = JSON.parse(JSON.stringify(this.user))
    user.attributes.roles = this.newUserRoles
    this.$store.dispatch("users/update", user)
    this.editing = false
  }

  disableUser() {
    const user = JSON.parse(JSON.stringify(this.user))
    user.attributes.disabled_at = new Date().toISOString()
    this.$store.dispatch("users/update", user)
  }

  enableUser() {
    const user = JSON.parse(JSON.stringify(this.user))
    user.attributes.disabled_at = null
    this.$store.dispatch("users/update", user)
  }

  created() {
    if (this.$store.getters["users/getById"](this.id) == null) {
      this.$store.dispatch("users/load", this.id)
    }
    this.reset()
  }

  beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    if (this.$store.getters["users/getById"](to.params.id) == null) {
      this.$store.dispatch("users/load", to.params.id)
      this.reset()
    }
    next()
  }

  reloadUser() {
    this.$store.dispatch("users/load", this.id)
    this.reset()
  }

  reset() {
    this.editUser = this.user ? cloneDeep(this.user) : new UserType()
  }

  async save() {
    await this.$store.dispatch("users/update", this.editUser)
    this.reset()
  }
}
