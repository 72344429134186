import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Claim extends Model<Claim> {
  constructor(response?: Claim) {
    super(response as Claim)
    if (response) {
      this.attributes.diagnosis_date = DateHelper.stringToDate(
        this.attributes.diagnosis_date
      )
      this.attributes.disability_began = DateHelper.stringToDate(
        this.attributes.disability_began
      )
      this.attributes.last_worked = DateHelper.stringToDate(
        this.attributes.last_worked
      )
      this.attributes.review_start_date = DateHelper.stringToDate(
        this.attributes.review_start_date
      )
      this.attributes.first_check_recoverable = DateHelper.stringToDate(
        this.attributes.first_check_recoverable
      )
      this.attributes.last_check_recoverable = DateHelper.stringToDate(
        this.attributes.last_check_recoverable
      )
      this.attributes.first_check_non_recoverable = DateHelper.stringToDate(
        this.attributes.first_check_non_recoverable
      )
      this.attributes.last_check_non_recoverable = DateHelper.stringToDate(
        this.attributes.last_check_non_recoverable
      )
      this.attributes.first_check_death = DateHelper.stringToDate(
        this.attributes.first_check_death
      )
      this.attributes.last_check_death = DateHelper.stringToDate(
        this.attributes.last_check_death
      )
      this.attributes.claim_start = DateHelper.stringToDate(
        this.attributes.claim_start
      )
      this.attributes.claim_end = DateHelper.stringToDate(
        this.attributes.claim_end
      )
      this.attributes.elimination_start = DateHelper.stringToDate(
        this.attributes.elimination_start
      )
      this.attributes.elimination_end = DateHelper.stringToDate(
        this.attributes.elimination_end
      )
      this.attributes.death_date = DateHelper.stringToDate(
        this.attributes.death_date
      )
    }
  }
  id: string
  attributes!: {
    number: string
    short_number: string
    member_id: number
    product_type: string
    state: string
    states: Array<string>
    status: string
    disability_type_id: number
    category: string
    injury_type: string
    how_occured: string
    disability_began: Date
    last_worked: Date
    diagnosis_date: Date
    policy_id: number
    updated_at: string
    disbursements_total: string
    recoverable_total: string
    recoveries_total: string
    overpayment_total: string
    overpayment_recovered_total: string
    write_off_total: string
    reclassified_total: string
    death_total: string
    admin_id: number
    payment_method_id: number
    payment_method_type: string
    attorney_id: number
    defense_attorney_id: number
    balance_forward_recoverable: string
    balance_forward_non_recoverable: string
    review_start_date: Date
    first_check_non_recoverable: Date
    last_check_non_recoverable: Date
    first_check_recoverable: Date
    last_check_recoverable: Date
    first_check_death: Date
    last_check_death: Date
    sworn_safety: boolean
    traumatic: boolean
    job_related: boolean
    classification: string
    long_term: boolean
    claim_start: Date
    claim_end: Date
    elimination_start: Date
    elimination_end: Date
    death_date: Date
    daily_amount: string
    income_worksheet_id: number
    monthly_income: string
    benefits_total: string
  }
  static get modelName() {
    return "Claim"
  }
}
