import Model from "./Model"

export class Ach extends Model<Ach> {
  constructor(ach?: Ach) {
    super(ach as Ach)
  }
  id: string
  attributes!: {
    account_number: string
    routing_number: string
    account_type: string
    owner_id: number
    owner_type: string
    bank_name: string
  }
  static get modelName() {
    return "Ach"
  }
}
