
import { Component, Vue, Watch } from "vue-property-decorator"
import { Bar } from "vue-chartjs/legacy"
import CardTemplate from "@/components/CardTemplate.vue"

interface EnrollerCountData {
  name: string
  count: number
}

@Component({
  components: { Bar, CardTemplate }
})
export default class ApplicationCountByEnroller extends Vue {
  private enrollers: EnrollerCountData[] = []
  private days = "30"

  async created() {
    const r = await this.$store.dispatch(
      "enrollers/getApplicationCounts",
      this.days
    )
    this.enrollers = r.data
  }

  @Watch("days")
  async daysChanged(value: string) {
    const r = await this.$store.dispatch(
      "enrollers/getApplicationCounts",
      value
    )
    this.enrollers = r.data
  }

  get chartData() {
    const data = {
      labels: this.labels,
      datasets: [
        {
          label: "Applicatiions",
          backgroundColor: "#f9aa33",
          data: this.values
        }
      ]
    }
    return data
  }

  get labels() {
    if (this.enrollers) {
      return this.enrollers.map((el: { name: string }) => {
        return el.name
      })
    } else {
      return []
    }
  }

  get values() {
    if (this.enrollers) {
      const data = this.enrollers.map((el: { count: number }) => el.count)
      return data
    } else return []
  }

  get chartOptions() {
    return {
      animation: true,
      indexAxis: "y",
      plugins: { legend: { display: false } },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: { display: false },
          ticks: {
            precision: 0
          }
        }
      }
    }
  }
}
