
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import HelpBubble from "@/components/HelpBubble.vue"
import { Premium } from "@/types/Premium"
import { cloneDeep } from "lodash"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    HelpBubble
  }
})
export default class PremiumHistory extends Vue {
  @Prop() private premiumableId!: number
  @Prop() private premiumableType!: string
  @Prop() private premiums!: Premium[]
  @Prop() private title!: string

  private newPremium = new Premium()
  private editPremium = new Premium()

  save() {
    this.newPremium.attributes.premiumable_id = this.premiumableId
    this.newPremium.attributes.premiumable_type = this.premiumableType
    this.$store.dispatch("premiums/create", this.newPremium)
    this.newPremium = new Premium()
  }

  open(premium: Premium) {
    this.editPremium = cloneDeep(premium)
  }

  update() {
    this.$store.dispatch("premiums/update", this.editPremium)
  }

  asQuarterly(value: string) {
    return value
  }

  get current() {
    const today = new Date()
    const premium = this.premiums.find(premium => {
      return premium.attributes.start_date <= today
    })

    return premium?.id
  }
}
