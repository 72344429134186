import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class IifableDisbursement extends Model<IifableDisbursement> {
  constructor(response?: IifableDisbursement) {
    super(response as IifableDisbursement)
    if (response) {
      this.date = DateHelper.stringToDate(this.date)
    }
  }

  disbursement_id: string
  claim_number: string
  date: Date
  member_name: string
  comments: string
  payee: string
  amount: string
  payment_type: string
  classification: string

  static get modelName() {
    return "IifableDisbursement"
  }
}
