
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import { Laserfiche } from "@/types/Laserfiche"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DateField,
    PagedDataTable
  }
})
export default class LaserficheList extends Vue {
  @Prop() private type!: string
  @Prop() private id!: string
  private documents: Laserfiche[] = []
  private loading = false
  private total = 0
  private startingPerPage = 5
  private perPage = 5
  private options = {
    sortBy: "attributes.created_date",
    sortDesc: true,
    mustSort: true
  }

  @Watch("id")
  onIdChange() {
    this.loadDocuments(1, this.startingPerPage)
  }

  created() {
    this.loadDocuments(1, this.startingPerPage)
  }

  async loadDocuments(page: number, perPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("laserfiches/loadBy", {
      laserficheable_id: this.id,
      laserficheable_type: this.type,
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.documents = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  getDocuments(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadDocuments(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
    }
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadDocuments(1, this.perPage)
  }

  get headers() {
    return [
      { text: "Description", value: "attributes.name" },
      { text: "Date", value: "attributes.created_date" },
      { text: "View", value: "attributes.document_id", sortable: false }
    ]
  }

  get laserficheUrl() {
    return process.env.VUE_APP_LASERFICHE_WEB_URL
  }

  getLink(entry: Laserfiche) {
    return `${this.laserficheUrl}?db=laserfiche#id=${entry.attributes.document_id};view=pages`
  }

  async getCoverSheet() {
    const response = await axios.get(
      `/members/${this.id}/separator_page`,
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    console.log(fileURL)
    const fURL = document.createElement("a")

    fURL.href = fileURL
    console.log(fURL)
    fURL.setAttribute("download", "separator_page.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }
}
