
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Member } from "@/types/Member"
import { Product } from "@/types/Product"
import DateField from "@/components/DateField.vue"
import NewMember from "@/components/NewMember.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"

@Component({
  components: {
    DateField,
    NewMember,
    PagedDataTable,
    PhoneNumber
  }
})
export default class MembersList extends Vue {
  @Prop() private departmentId!: string
  @Prop() private products!: Product[]
  @Prop() private showInactive: boolean

  private options = {
    sortBy: "attributes.last_name",
    sortDesc: false,
    mustSort: true
  }
  private loading = false
  private total = 0
  private startingPerPage = 50
  private perPage = 50
  private currentPage = 1

  private members: Member[] = []
  created() {
    this.loadMembers(1, this.startingPerPage)
  }

  @Watch("departmentId")
  updateDepartmentId() {
    this.loadMembers(1, this.startingPerPage)
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadMembers(1, this.perPage)
  }

  goToMember(member: Member) {
    this.$router.push({
      name: "Member",
      params: { id: member.id.toString() }
    })
  }

  getMembers(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadMembers(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  async loadMembers(page: number, perPage: number) {
    this.loading = true
    const show = this.showInactive ? { show: "all" } : {}

    const data = await this.$store.dispatch(
      "members/loadBy",
      Object.assign(show, {
        department_id: this.departmentId,
        page: page,
        per_page: perPage,
        order: this.options.sortBy.replace("attributes.", ""),
        order_direction: this.options.sortDesc ? "DESC" : "ASC",
        include: ["address", "memberships"]
      })
    )

    this.members = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  constructMemberType(m: Member) {
    return new Member(m)
  }

  get headers() {
    return [
      {
        text: "LAST NAME",
        value: "attributes.last_name",
        sortable: true,
        class: "text-left caption js-header-last"
      },
      {
        text: "FIRST NAME",
        value: "attributes.first_name",
        sortable: true,
        class: "text-left caption js-header-first"
      },
      {
        text: "SSN",
        value: "attributes.ssn",
        sortable: true,
        class: "text-left caption js-header-ssn"
      },
      {
        text: "DOB",
        value: "attributes.dob",
        sortable: true,
        class: "text-left caption js-header-dob"
      },
      {
        text: "EMAIL",
        value: "attributes.email",
        sortable: true,
        class: "text-left caption js-header-email"
      },
      {
        text: "PHONE",
        value: "attributes.phone",
        sortable: true,
        class: "text-left caption js-header-phone"
      },
      {
        text: "CARD",
        value: "attributes.enrollment_card",
        sortable: true,
        class: "text-left caption js-header-card"
      },
      {
        text: "CURRENT",
        value: "attributes.department_id",
        sortable: true,
        class: "text-left caption js-header-current"
      },
      {
        text: "WAIVE",
        value: "attributes.waive_ltd",
        sortable: true,
        class: "text-left caption js-header-current"
      },
      {
        text: "EDIT",
        value: "edit",
        sortable: false,
        class: "text-left caption"
      }
    ]
  }
}
