import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Payment extends Model<Payment> {
  constructor(response?: Payment) {
    super(response as Payment)
    if (response) {
      this.attributes.date_received = DateHelper.stringToDate(
        this.attributes.date_received
      )
      this.attributes.check_date = DateHelper.stringToDate(
        this.attributes.check_date
      )
      this.attributes.period_covered = DateHelper.stringToDate(
        this.attributes.period_covered
      )
    }
  }
  id: string
  attributes!: {
    payable_id: number
    payable_type: string
    amount: string
    premium_amount: string | null
    current_balance: string
    date_received: Date
    period_covered: Date
    payment_method: string
    check_number: string
    check_date: Date
    created_at: string
    memo: string
    product_type: string
    status: string
    nacha_id: number
    credit_card_batch_id: number
    eip: boolean
    posted_at: string
    product_id: number
  }
  static get modelName() {
    return "Payment"
  }
}
