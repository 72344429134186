
import { Component, Vue, Watch } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DateHelper from "@/components/utils/DateHelper"
import PageTemplate from "@/components/PageTemplate.vue"
import { CreditCardNotification } from "@/types/CreditCardNotification"
import feeHelper from "@/services/feeHelper"

class BillablePolicy {
  policy_id: number
  amount: string
  last: string
  next: string
  member_first_name: string
  member_last_name: string
  policy_number: string
  billing_frequency: string
  balance: string
  billing_exception: boolean
  payment_expired: boolean
  paid_in_full_date: string
}

@Component({
  components: {
    CurrencyField,
    DateField,
    PageTemplate
  }
})
export default class CreditCardNotify extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selected: any[] = []
  private policies: BillablePolicy[] = []
  private loading = false
  private working = false
  private workingCompleteMessage = ""
  private notifications: CreditCardNotification[] = []
  private account = ""
  private selectedTotal = "0"
  private selectedTotalWithBalance = "0"

  async fetchCC() {
    this.loading = true

    this.policies = []
    let page = 1

    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    const classification = accountParts.length > 1 ? accountParts[1] : null

    do {
      const data = await this.$store.dispatch(
        "policies/fetchPoliciesToNotifyCreditCard",
        { page: page, productType: prodType, classification: classification }
      )
      this.policies.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)
    this.loading = false
  }

  @Watch("account")
  onAccountChange() {
    this.selected = []
    this.fetchCC()
    this.fetchNotifications()
  }

  @Watch("selected")
  onSelectedChange(value: BillablePolicy[]) {
    const amounts = value.map((item: BillablePolicy) =>
      this.calculateTotal(item)
    )
    const amountsWithBalance = value.map((item: BillablePolicy) =>
      (
        parseFloat(this.calculateTotal(item)) + parseFloat(item.balance)
      ).toString()
    )

    if (amounts.length == 0) {
      this.selectedTotal = "0"
    } else if (amounts.length == 1) {
      this.selectedTotal = amounts[0].toString()
    } else {
      this.selectedTotal = amounts
        .reduce((prev: string, cur: string) => {
          return (parseFloat(prev) + parseFloat(cur)).toString()
        })
        .toString()
    }

    if (amountsWithBalance.length == 0) {
      this.selectedTotalWithBalance = "0"
    } else if (amountsWithBalance.length == 1) {
      this.selectedTotalWithBalance = amountsWithBalance[0].toString()
    } else {
      this.selectedTotalWithBalance = amountsWithBalance
        .reduce((prev: string, cur: string) => {
          return (parseFloat(prev) + parseFloat(cur)).toString()
        })
        .toString()
    }
  }

  async fetchNotifications() {
    const accountParts = this.account.split("_")
    const classification = accountParts.length > 1 ? accountParts[1] : 2

    const filter = {
      use_case: "billing",
      classification: classification,
      page: 1,
      per_page: 5,
      order: "date",
      order_direction: "DESC"
    }
    const data = await this.$store.dispatch(
      "creditCardNotifications/loadBy",
      filter
    )

    this.notifications = data.data.data
  }

  async makeNotification() {
    this.working = true

    const policyIds = this.selected.map(el => el.policy_id)
    const accountParts = this.account.split("_")
    const classification =
      accountParts.length > 1 ? ["fire", "law"][Number(accountParts[1])] : "ltc"

    const payload = {
      name: `CC Notification ${classification.toUpperCase()} (${DateHelper.shortFormat(
        new Date()
      )})`,
      use_case: "billing",
      classification: classification,
      policyIds: policyIds.join(","),
      date: new Date()
    }

    await this.$store.dispatch("creditCardNotifications/initiate", payload)

    await new Promise(r => setTimeout(r, 2000))
    this.working = false
    this.workingCompleteMessage =
      "Notification package has been created. Do not expect a change to this list of policies."
    this.fetchNotifications()
  }

  chipDate(date: Date) {
    return DateHelper.shortFormat(date)
  }

  calculateTotal(policy: BillablePolicy) {
    const a = Number(policy.amount)
    const m = Math.min(
      this.frequencyAsMonths(policy.billing_frequency),
      this.monthsUntilPif(policy)
    )
    const f = Number(this.creditCardFee(policy.billing_frequency))
    const b = Number(policy.balance)
    const val = m * a + f + b

    return val.toString()
  }

  creditCardFee(billingFrequency: string) {
    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    return feeHelper.fee(billingFrequency, prodType == "ltd")
  }

  frequencyAsMonths(billingFrequency: string) {
    return feeHelper.frequencyAsMonths(billingFrequency)
  }

  monthsUntilPif(policy: BillablePolicy) {
    if (!policy.paid_in_full_date || !policy.next) {
      return 12 // some number at least as long as the longest billing frequency
    }

    const pif = DateHelper.stringToDate(policy.paid_in_full_date)
    const nextBill = DateHelper.stringToDate(policy.next)

    let months = (pif.getFullYear() - nextBill.getFullYear()) * 12
    months += pif.getMonth()
    months -= nextBill.getMonth()
    months += 1 // because paid in full date is end of month and bill coverage is beginning of month

    return months <= 0 ? 0 : months
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  getCSV() {
    let csv = ""

    csv = this.selected
      .map(el => {
        const n = el.next
          ? DateHelper.stringToDate(el.next).toLocaleDateString()
          : ""
        const l = el.last
          ? DateHelper.stringToDate(el.last).toLocaleDateString()
          : ""

        return [
          el.member_last_name,
          el.member_first_name,
          el.policy_number,
          el.member_email,
          l,
          n,
          this.calculateTotal(el),
          el.amount,
          el.billing_frequency,
          this.creditCardFee(el.billing_frequency)
        ].join(",")
      })
      .join("\n")

    csv = [
      "last",
      "first",
      "policy",
      "email",
      "last",
      "next",
      "amount",
      "premium",
      "freq",
      "fee"
    ]
      .join(",")
      .concat("\n")
      .concat(csv)

    let nameForDownload = ""
    switch (this.account) {
      case "ltc":
        nameForDownload = "LTC-cc-renewal-pre-billing.csv"
        break
      case "ltd_0":
        nameForDownload = "EIP-fire-cc-reneal-pre-billing.csv"
        break
      case "ltd_1":
        nameForDownload = "EIP-law-cc-renewal-pre-billing.csv"
        break
    }

    const anchor = document.createElement("a")
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    anchor.target = "_blank"
    anchor.download = nameForDownload
    anchor.click()
  }

  get headers() {
    return [
      {
        text: "",
        value: "billing_exception",
        sortable: true,
        class: "text-left caption",
        width: "80px"
      },
      {
        text: "last",
        value: "member_last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "first",
        value: "member_first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "email",
        value: "member_email",
        class: "text-left caption"
      },
      {
        text: "polnum",
        value: "policy_number",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "premium",
        value: "amount",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "freq",
        value: "billing_frequency",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "fee",
        value: "fee",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "total",
        value: "total",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "last bill",
        value: "last",
        class: "text-right",
        sortable: true
      },
      {
        text: "next bill",
        value: "next",
        class: "text-right",
        sortable: true
      },
      {
        text: "balance",
        value: "balance",
        class: "text-right",
        sortable: true
      }
    ]
  }
}
