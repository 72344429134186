
import { Component, Vue } from "vue-property-decorator"
import { BoardMeetingAttendee } from "@/types/BoardMeetingAttendee"
import BoardMeetingAdminDialog from "@/components/BoardMeetingAdminDialog.vue"
import BoardMeetingAdminList from "@/components/BoardMeetingAdminList.vue"
import BoardMeetingAttendeeDialog from "@/components/BoardMeetingAttendeeDialog.vue"
import BoardMeetingAttendeeList from "@/components/BoardMeetingAttendeeList.vue"
import BoardMeetingDialog from "@/components/BoardMeetingDialog.vue"
import BoardMeetingSummary from "@/components/BoardMeetingSummary.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import axios from "axios"
import moment from "moment"
import { BoardMeetingAdmin } from "@/types/BoardMeetingAdmin"

@Component({
  components: {
    BoardMeetingAdminDialog,
    BoardMeetingAdminList,
    BoardMeetingAttendeeDialog,
    BoardMeetingAttendeeList,
    BoardMeetingDialog,
    BoardMeetingSummary,
    CardTemplate,
    DateField,
    PageTemplate
  }
})
export default class BoardMeeting extends Vue {
  private meetingActions = false

  async created() {
    this.fetchBoardMeeting()
    this.$store.dispatch("departments/loadAll")
  }

  fetchBoardMeeting() {
    this.$store.dispatch("boardMeetings/load", {
      id: this.boardMeetingId,
      include: ["board_meeting_attendees", "board_meeting_admins"]
    })
  }

  beforeRouteUpdate(
    to: { params: { id: string; modelName: string } },
    from: { params: { id: string; modelName: string } },
    next: CallableFunction
  ) {
    this.$store.dispatch("boardMeetings/load", {
      id: to.params.id,
      include: ["board_meeting_attendees", "board_meeting_admins"]
    })
    next()
  }

  get boardMeeting() {
    return this.$store.getters["boardMeetings/getById"](this.boardMeetingId)
  }

  get attendees() {
    return this.boardMeeting
      ? this.$store.getters["boardMeetingAttendees/getBy"]({
          board_meeting_id: this.boardMeetingId
        })
      : []
  }

  get newAttendee() {
    const attendee = new BoardMeetingAttendee()
    attendee.attributes.board_meeting_id = this.boardMeeting.id
    return attendee
  }

  get newAdmin() {
    const admin = new BoardMeetingAdmin()
    admin.attributes.board_meeting_id = this.boardMeeting.id
    return admin
  }

  get boardName() {
    let name = ""
    if (this.boardMeeting) {
      name =
        this.boardMeeting.attributes.classification == "fire" ? "CAPF" : "CLEA"
    }
    return name
  }

  get boardMeetingId() {
    return parseInt(this.$route.params.id)
  }

  async getInviteable() {
    const response = await axios.get(
      `/board_meetings/${this.boardMeetingId}/inviteable`,
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    const fileDate = moment(
      this.boardMeeting.attributes.date,
      "YYYY-MM-DD"
    ).format("l")

    fURL.href = fileURL
    fURL.setAttribute("download", `board_meeting_${fileDate}_invite.csv`)
    document.body.appendChild(fURL)

    fURL.click()
  }

  async getMailingLabels(voting: boolean) {
    const response = await axios.get(
      `/board_meetings/${
        this.boardMeetingId
      }/labels?voting=${voting.toString()}`,
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `board_meeting_${voting ? "voting" : "non_voting"}_attendee_labels.pdf`
    )
    document.body.appendChild(fURL)

    fURL.click()
  }

  async getContactList() {
    const response = await axios.get(
      `/board_meetings/${this.boardMeetingId}/contact_list`,
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "board_meeting_contacts.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }

  async getReimbursementForms() {
    const response = await axios.get(
      `/board_meetings/${this.boardMeetingId}/reimbursement_forms`,
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "board_meeting_reimbursement_forms.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }

  async getSignInSheet() {
    const response = await axios.get(
      `/board_meetings/${this.boardMeetingId}/sign_in_sheet`,
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "board_meeting_sign_in_sheet.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }

  async getAttending() {
    const response = await axios.get(
      `/board_meetings/${this.boardMeetingId}/attending`,
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "board_meeting_attending.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }
}
