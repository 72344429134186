import { MutationTree, ActionTree } from "vuex"
import { Following } from "@/types/Following"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Following[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Following>, RootState> = {}

const mutations: MutationTree<State<Following>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
