
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import UserInitials from "@/components/UserInitials.vue"
import { Policy } from "@/types/Policy"

@Component({
  components: {
    CardTemplate,
    UserInitials
  }
})
export default class PoliciesInUnderwriting extends Vue {
  private loading = false

  async created() {
    this.loading = true

    await this.$store.dispatch("policies/loadBy", {
      status: "UNDR-Underwriting Committee",
      include: ["member"]
    })

    this.loading = false
  }

  get policies() {
    return this.$store.getters["policies/getBy"]({
      status: "UNDR-Underwriting Committee"
    }).sort((a: Policy, b: Policy) => {
      return a.attributes.number.localeCompare(b.attributes.number)
    })
  }

  memberName(policy: Policy) {
    const member = this.$store.getters["members/getById"](
      policy.attributes.member_id
    )
    return member
      ? `${member.attributes.first_name} ${member.attributes.last_name}`
      : "?"
  }
}
