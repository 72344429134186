import Model from "./Model"

export class IncomeWorksheet extends Model<IncomeWorksheet> {
  constructor(incomeWorksheet?: IncomeWorksheet) {
    super(incomeWorksheet as IncomeWorksheet)
  }
  id: string
  attributes!: {
    claim_id: number
    base_salary: string
    shift_differential: string
    uniform_allowance: string
    holiday: string
    pay_periods: number
    base_notes: string
    holiday_notes: string
  }
  static get modelName() {
    return "IncomeWorksheet"
  }
}
