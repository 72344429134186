
import { Claim } from "@/types/Claim"
import { Component, Prop, Vue } from "vue-property-decorator"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    ConfirmationTemplate,
    DialogTemplate
  }
})
export default class ClaimMover extends Vue {
  @Prop() private claim!: Claim
  private showConfirm = false
  private confirmationText = ""
  private claimEvent = ""

  async claimStateChange(event: string) {
    let newStateName = event.toUpperCase()
    if (event == "receive paperwork") {
      newStateName = "REVIEW"
    } else if (event == "deactivate") {
      newStateName = "INACTIVE REVIEW"
    } else if (event == "close inactive") {
      newStateName = "CLOSE"
    }
    this.confirmationText = `Select 'OK' to proceed to ${newStateName}.`
    this.showConfirm = true
    this.claimEvent = event
  }

  async confirmOk() {
    this.showConfirm = false

    await this.$store.dispatch("claims/stateChange", {
      id: this.claim.id,
      event: this.claimEvent
    })
    this.$emit("claimStateChange", this.claim.id)

    if (this.claimEvent == "close") {
      ;(this.$refs.dialog as DialogTemplate).open(true)
    }
  }

  confirmReject() {
    this.showConfirm = false
  }

  get memberUrl() {
    return "/#/members/" + this.claim.attributes.member_id + "?tab=0"
  }
}
