
import { Component, Vue, Prop } from "vue-property-decorator"
import { RecoverableAdjustment } from "@/types/RecoverableAdjustment"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CurrencyField,
    DateField
  }
})
export default class RecoverableAdjustmentDetails extends Vue {
  @Prop() private adjustment!: RecoverableAdjustment
}
