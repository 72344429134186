import axios from "axios"
import { MutationTree, ActionContext, ActionTree } from "vuex"
import { AdvancedSearch } from "@/types/AdvancedSearch"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: AdvancedSearch[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<AdvancedSearch>, RootState> = {
  async addTags(
    { dispatch, rootGetters }: ActionContext<State<AdvancedSearch>, RootState>,
    { id, tags }
  ) {
    try {
      const data = await axios.put(
        `advanced_searches/${id}/add_tags`,
        { tags },
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },

  async removeTags(
    { dispatch, rootGetters }: ActionContext<State<AdvancedSearch>, RootState>,
    { id, tags }
  ) {
    try {
      const data = await axios.put(
        `advanced_searches/${id}/remove_tags`,
        { tags },
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },

  async syncTags(
    { dispatch, rootGetters }: ActionContext<State<AdvancedSearch>, RootState>,
    { id, tags }
  ) {
    try {
      const data = await axios.put(
        `advanced_searches/${id}/sync_tags`,
        { tags },
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<AdvancedSearch>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
