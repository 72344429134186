import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Bill extends Model<Bill> {
  constructor(response?: Bill) {
    super(response as Bill)
    if (response) {
      this.attributes.coverage_start_date = DateHelper.stringToDate(
        this.attributes.coverage_start_date
      )
      this.attributes.coverage_end_date = DateHelper.stringToDate(
        this.attributes.coverage_end_date
      )
      this.attributes.sent_on = DateHelper.stringToDate(this.attributes.sent_on)
    }
  }
  id: string
  attributes!: {
    billable_id: number
    billable_type: string
    amount: string
    current_balance: string
    number: string
    coverage_start_date: Date
    coverage_end_date: Date
    sent_on: Date
    view_doc_url: string
    download_doc_url: string
    created_at: string
    memo: string
    product_type: string
    eip: boolean
    reversed: boolean
    posted_at: string
  }
  static get modelName() {
    return "Bill"
  }
}
