
import axios from "axios"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Bar } from "vue-chartjs/legacy"
import CardTemplate from "@/components/CardTemplate.vue"

interface EventData {
  route: string
  count: number
}

@Component({
  components: {
    Bar,
    CardTemplate
  }
})
export default class UserEvents extends Vue {
  @Prop() private userId!: string

  private events: EventData[] = []

  @Watch("userId")
  onIdChange(value: string) {
    if (value) {
      this.fetchEvents(value)
    }
  }

  mounted() {
    if (this.userId) {
      this.fetchEvents(this.userId)
    }
  }

  async fetchEvents(id: string) {
    const data = await axios.get(
      `/users/${id}/events`,
      this.$store.getters["config/getAPIConfig"]
    )
    this.events = data.data
  }

  get chartData() {
    const data = {
      labels: this.events.map((e: EventData) => e.route),
      datasets: [
        {
          label: "Routes",
          backgroundColor: "#f9aa33",
          data: this.events.map((e: EventData) => e.count)
        }
      ]
    }
    return data
  }

  get chartOptions() {
    return {
      animation: true,
      indexAxis: "y",
      plugins: { legend: { display: false } },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: { display: false },
          ticks: {
            precision: 0
          }
        }
      }
    }
  }
}
