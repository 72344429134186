
import { Component, Vue } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import RecordSearch from "@/components/RecordSearch.vue"
import { DepartmentProduct } from "@/types/DepartmentProduct"
import { Product } from "@/types/Product"
import { Search as SearchItem } from "@/types/Search"

@Component({
  components: {
    DateField,
    DialogTemplate,
    RecordSearch
  }
})
export default class MergeDepartments extends Vue {
  private loading = false
  private departmentIdOld: string = null
  private departmentIdNew: string = null

  private departmentNameOld: string = null
  private departmentNameNew: string = null

  private mergeDate = new Date()
  private productIdsOld: string[] = []
  private productIdsNew: string[] = []
  private page = "department_selection"
  private productMap: Map<string, string> = new Map()

  private mergeStatus: string = null

  next() {
    this.page = "product_mapping"
    this.loadProducts()
  }

  async loadProducts() {
    await this.$store.dispatch("departmentProducts/loadBy", {
      department_id: [this.departmentIdOld, this.departmentIdNew]
    })
    const departmentProductsOld = this.$store.getters[
      "departmentProducts/getBy"
    ]({
      department_id: this.departmentIdOld
    })

    const departmentProductsNew = this.$store.getters[
      "departmentProducts/getBy"
    ]({
      department_id: this.departmentIdNew
    })

    this.productIdsOld = departmentProductsOld.map((el: DepartmentProduct) => {
      return el.attributes.product_id.toString()
    })

    this.productIdsNew = departmentProductsNew.map((el: DepartmentProduct) => {
      return el.attributes.product_id.toString()
    })
  }

  get productsOld() {
    return this.$store.getters["products/getByIds"](this.productIdsOld)
  }

  get productsNew() {
    const products = this.$store.getters["products/getByIds"](
      this.productIdsNew
    ).filter((el: Product) => el.attributes.retired_on == null)

    products.unshift({ attributes: { name: "none" } })

    return products
  }

  getProductName(id: string) {
    return this.$store.getters["products/getById"](id).attributes.name
  }

  reset() {
    this.departmentIdOld = ""
    this.departmentIdNew = ""
    this.departmentNameOld = ""
    this.departmentNameNew = ""
    this.mergeDate = new Date()
    this.productIdsOld = []
    this.productIdsNew = []
    this.page = "department_selection"
    this.productMap = new Map()
  }

  async save() {
    this.loading = true

    const result = await this.$store.dispatch("departments/merge", {
      old_department_id: this.departmentIdOld,
      new_department_id: this.departmentIdNew,
      merge_date: this.mergeDate,
      product_map: this.productMap
    })

    if (result.status == "200") {
      this.mergeStatus = result.data
    } else {
      this.$store.dispatch("alerts/addError", "Error in merging departments")
    }

    this.loading = false
    this.reset()
  }

  setDepartmentNew(event: SearchItem) {
    this.departmentIdNew = event.target_id
    this.departmentNameNew = event.title
  }

  setDepartmentOld(event: SearchItem) {
    this.departmentIdOld = event.target_id
    this.departmentNameOld = event.title
  }

  updateMapping(newProduct: string, oldProduct: string) {
    const found = this.productMap.has(oldProduct)

    if (found && newProduct == "none") this.productMap.delete(oldProduct)
    else if (newProduct != "none") this.productMap.set(oldProduct, newProduct)
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      DepartmentMergeChannel: {
        connected() {
          console.log("DepartmentMergeChannel connected")
        },
        rejected() {
          console.log("DepartmentMergeChannel rejected")
        },
        received(data: { successes: number; failures: number; email: string }) {
          that.mergeStatus = `Merge complete. ${
            data.successes
          } out of ${data.successes +
            data.failures} members moved. See a full report at ${data.email}.`
        }
      }
    }
  }

  mounted() {
    this.$cable.subscribe({
      channel: "DepartmentMergeChannel",
      room: "public"
    })
  }
}
