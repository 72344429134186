
import { Component, Vue } from "vue-property-decorator"
import AdvancedSearchList from "@/components/AdvancedSearchList.vue"
import AdvancedSearchQueryDialog from "@/components/AdvancedSearchQueryDialog.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Tag } from "@/types/Tag"
import { AdvancedSearch as AdvancedSearchType } from "@/types/AdvancedSearch"

@Component({
  components: {
    AdvancedSearchList,
    AdvancedSearchQueryDialog,
    PageTemplate
  }
})
export default class AdvancedSearch extends Vue {
  private advancedSearches: AdvancedSearchType[] = []
  private advancedSearchesLength = 0
  private tagFilters: Array<string> = []

  private page: number
  private perPage: number
  private options = {
    sortBy: "attributes.created_at",
    sortDesc: true,
    mustSort: true
  }

  private startingPerPage = 5

  get tags() {
    return this.$store.getters["tags/getAll"].map(
      (el: Tag) => el.attributes.name
    )
  }

  get tagFiltersModel() {
    return this.tagFilters
  }

  set tagFiltersModel(newValue) {
    this.tagFilters.splice(0, this.tagFilters.length)
    this.tagFilters = this.tagFilters.concat(newValue)
    this.loadAdvancedSearches(this.page, this.perPage, this.tagFilters)
  }

  created() {
    this.loadAdvancedSearches(1, this.startingPerPage, null)
    this.loadTags()
  }

  reload() {
    this.loadAdvancedSearches(this.page, this.perPage, this.tagFilters)
    this.loadTags()
  }

  async loadTags() {
    this.$store.dispatch("tags/loadAll")
  }

  async loadAdvancedSearches(
    page: number,
    perPage: number,
    tags: Array<string>
  ) {
    this.page = page
    this.perPage = perPage
    const data = await this.$store.dispatch("advancedSearches/loadBy", {
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC",
      tags: tags
    })
    this.advancedSearchesLength = data?.data?.meta?.pagination?.count
    const advancedSearches = data?.data?.data
    this.advancedSearches.splice(
      0,
      this.advancedSearches.length,
      ...advancedSearches.map(
        (el: AdvancedSearchType) => new AdvancedSearchType(el)
      )
    )
  }

  async saveQuery({
    search,
    tags
  }: {
    search: AdvancedSearch
    tags: string[]
  }) {
    const response = await this.$store.dispatch(
      "advancedSearches/create",
      search
    )

    await this.addTags(response.data.data.id, tags)

    await this.loadAdvancedSearches(this.page, this.perPage, this.tagFilters)

    await this.loadTags()
  }

  async addTags(id: string, tags: string[]) {
    await this.$store.dispatch("advancedSearches/addTags", {
      id: id,
      tags: tags
    })
  }

  async removeTags(id: string, tags: string[]) {
    await this.$store.dispatch("advancedSearches/removeTags", {
      id: id,
      tags: tags
    })
  }
}
