import { render, staticRenderFns } from "./MyClaims.vue?vue&type=template&id=32a1ed10&scoped=true&"
import script from "./MyClaims.vue?vue&type=script&lang=ts&"
export * from "./MyClaims.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32a1ed10",
  null
  
)

export default component.exports