
import { Component, Vue, Prop } from "vue-property-decorator"
import { Membership } from "@/types/Membership"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: { DateField, DialogTemplate }
})
export default class ModifyMembership extends Vue {
  @Prop() private membership!: Membership

  async reverse() {
    if (this.membership) {
      await this.$store.dispatch("memberships/reverse", this.membership.id)
    }
  }
}
