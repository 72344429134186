
import { Component, Vue, Watch } from "vue-property-decorator"
import AccountChip from "@/components/AccountChip.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Payment } from "@/types/Payment"
import { Policy } from "@/types/Policy"
import { CreditCardBatch as Batch } from "@/types/CreditCardBatch"

@Component({
  components: {
    AccountChip,
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    PagedDataTable,
    PageTemplate
  }
})
export default class CreditCardPayments extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selected: any[] = []
  private loading = false

  private total = 0
  private startingPerPage = 5
  private perPage = 5
  private currentPage = 1
  private options = {
    sortBy: "attributes.send_date",
    sortDesc: true,
    mustSort: true
  }

  private account = "ltc"
  private addingBatch: Batch = new Batch()
  private batches: Batch[] = []
  private unlockedBatches: Batch[] = []
  private selectedTotal = "0"

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sortablePayments: any[] = []

  async fetchScheduled() {
    this.loading = true
    const data = await this.$store.dispatch("payments/loadBy", {
      payment_method: "CreditCard",
      status: "Scheduled",
      payable_type: "Policy",
      credit_card_batch_id: null
    })

    if (data.data.data) {
      let policyIds = data.data.data.map((el: Payment) => {
        return el.attributes.payable_id
      })
      policyIds = [...new Set(policyIds)]

      if (policyIds.length > 0) {
        // if we try and fetch policies like this but ther policyIds is empty, it attempts to get them all
        // resulting in a server that poops out trying to hard to return all the policies.
        const tempPolicies = await this.$store.dispatch("policies/loadBy", {
          id: policyIds,
          include: "member"
        })

        if (tempPolicies.data.data) {
          let creditCardIds = tempPolicies.data.data.map((el: Policy) => {
            return el.attributes.payment_method_id
          })
          creditCardIds = [...new Set(creditCardIds)]

          if (creditCardIds.length > 0) {
            await this.$store.dispatch("creditCards/loadBy", {
              id: creditCardIds
            })
          }
        }
      }
    }
    await this.sortPayments()
    this.loading = false
  }

  async mounted() {
    await this.loadBatches(1, this.startingPerPage)
    await this.fetchScheduled()
    this.loading = false
  }

  async getBatches(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      await this.loadBatches(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }
  async loadBatches(page: number, perPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("creditCardBatches/loadBy", {
      account: this.account,
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.batches = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false

    this.unlockedBatches = this.batches.filter((batch: Batch) => {
      return batch.attributes.send_date == null
    })
  }

  paymentPolicy(payment: Payment) {
    const d = payment.attributes.payable_id
      ? this.$store.getters["policies/getById"](payment.attributes.payable_id)
      : null

    return d
  }

  policyMember(policy: Policy) {
    const d = policy.attributes.member_id
      ? this.$store.getters["members/getById"](policy.attributes.member_id)
      : null

    return d
  }

  policyCreditCard(policy: Policy) {
    const d = policy.attributes.payment_method_id
      ? this.$store.getters["creditCards/getById"](
          policy.attributes.payment_method_id
        )
      : null

    return d
  }

  async sortPayments() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unsortedPayments = await this.payments()
    this.sortablePayments = unsortedPayments ? unsortedPayments : []
    this.sortablePayments.forEach(payment => {
      const policy = this.paymentPolicy(payment)
      const member = policy ? this.policyMember(policy) : null
      const card = policy ? this.policyCreditCard(policy) : null

      payment.member_last_name = member ? member.attributes.last_name : ""
      payment.member_first_name = member ? member.attributes.first_name : ""
      payment.policy_number = policy ? policy.attributes.number : ""
      payment.status = payment.attributes.status
      payment.amount = payment.attributes.amount
      payment.account = policy ? policy.attributes.classification : 0
      payment.billing_exception = policy
        ? policy.attributes.billing_exception
        : false

      payment.cc_last_four = card ? card.attributes.number.slice(-4) : ""
      payment.exp_mo = card ? card.attributes.expiration_month : ""
      payment.exp_yr = card ? card.attributes.expiration_year : ""
    })
  }

  async payments() {
    const p = this.$store.getters["payments/getBy"]({
      payment_method: "CreditCard",
      status: "Scheduled",
      credit_card_batch_id: null
    })

    const filtered = p.filter((el: Payment) => {
      const policy = this.$store.getters["policies/getById"](
        el.attributes.payable_id
      )

      return (
        policy &&
        this.account == policy.attributes.classification &&
        el.attributes.credit_card_batch_id == null
      )
    })

    return filtered
  }

  get existingSelected() {
    // eslint-disable-next-line no-prototype-builtins
    return this.addingBatch.hasOwnProperty("id")
  }

  async addToBatch() {
    const ids = this.selected.map(el => {
      return el.id
    })

    for (const id of ids) {
      await this.$store.dispatch("payments/update", {
        id: id,
        attributes: { credit_card_batch_id: this.addingBatch.id }
      })
    }

    await this.loadBatches(1, this.startingPerPage)

    this.$store.dispatch("creditCardBatches/makeFile", {
      id: this.addingBatch.id
    })
  }

  async submitNewBatch() {
    this.loading = true

    const ids = this.selected.map((el: Payment) => {
      return el.id
    })

    const new_batch = new Batch()
    const today = new Date()

    new_batch.attributes.name =
      this.accountToBoard(this.account) +
      "_" +
      String(today.getMonth() + 1) +
      "/" +
      String(today.getDate()) +
      "/" +
      today.getFullYear()
    new_batch.attributes.account = this.account

    const res = await this.$store.dispatch(
      "creditCardBatches/create",
      new_batch
    )

    if (res.status == 200) {
      const updatesToWaitFor = ids.map(id => {
        return this.$store.dispatch("payments/update", {
          id: id,
          attributes: { credit_card_batch_id: res.data.data.id }
        })
      })

      await Promise.all(updatesToWaitFor)
    }

    this.loading = false

    this.loadBatches(1, this.startingPerPage)
    this.fetchScheduled()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentExpired(item: any) {
    const lastDayOfExpMonth =
      item.exp_mo == 12
        ? new Date(item.exp_yr + 1, 0, 0)
        : new Date(item.exp_yr, item.exp_mo, 0)

    const currentDate = new Date()
    return currentDate > lastDayOfExpMonth
  }

  accountToBoard(account: string) {
    let board = "CAPF"
    switch (account) {
      case "fire":
        board = "CAPF"
        break
      case "law":
        board = "CLEA"
        break
      case "ltc":
        board = "NPFBA"
        break
    }
    return board
  }

  @Watch("selected")
  onSelectedPaymentsChange(value: Payment[]) {
    const amounts = value.map((payment: Payment) =>
      parseFloat(payment.attributes.amount)
    )
    if (amounts.length == 0) {
      this.selectedTotal = "0"
    } else if (amounts.length == 1) {
      this.selectedTotal = amounts[0].toString()
    } else {
      this.selectedTotal = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  @Watch("account")
  onAccountChange() {
    this.currentPage = 1
    this.loadBatches(1, this.startingPerPage)
    this.fetchScheduled()
  }

  goToBatch(batch: Batch) {
    this.$router.push({
      name: "CreditCardBatch",
      params: { id: batch.id.toString() }
    })
  }

  get headers() {
    return [
      {
        text: "",
        value: "billing_exception",
        sortable: true,
        class: "text-left",
        width: "85px"
      },
      {
        text: "last",
        value: "member_last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "first",
        value: "member_first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "polnum",
        value: "policy_number",
        class: "text-left caption",
        sortable: true,
        width: "275px"
      },
      {
        text: "account",
        value: "account",
        sortable: false,
        class: "text-left caption",
        width: 140
      },
      {
        text: "amount",
        value: "amount",
        class: "text-left caption",
        sortable: true,
        width: "150px"
      },
      {
        text: "card",
        value: "cc_last_four",
        class: "text-left caption",
        sortable: true,
        width: "100px"
      },
      {
        text: "expire",
        value: "expire",
        class: "text-left caption",
        sortable: true,
        width: "100px"
      },
      {
        text: "memo",
        value: "attributes.memo",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "status",
        value: "status",
        class: "text-left caption",
        sortable: true
      }
    ]
  }

  get headersBatches() {
    return [
      {
        text: "",
        value: "lock",
        width: 50
      },
      {
        text: "Name",
        value: "attributes.name",
        sortable: true,
        class: "text-left caption",
        width: 350
      },
      {
        text: "account",
        value: "attributes.account",
        sortable: true,
        class: "text-left caption",
        width: 140
      },
      {
        text: "file",
        value: "file",
        sortable: false,
        class: "text-left caption",
        width: 140
      },
      {
        text: "sent",
        value: "attributes.send_date",
        sortable: true,
        class: "text-left caption",
        width: 140
      },
      {
        text: "result",
        value: "attributes.result",
        sortable: true,
        class: "text-left caption"
      }
    ]
  }
}
