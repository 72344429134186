
import { Component, Vue } from "vue-property-decorator"
import IifFilesList from "@/components/IifFilesList.vue"
import IifDisbursementsFilter from "@/components/IifDisbursementsFilter.vue"
import IifDisbursementsList from "@/components/IifDisbursementsList.vue"
import PageTemplate from "@/components/PageTemplate.vue"

import { Iif } from "@/types/Iif"
import { IifableDisbursement } from "@/types/IifableDisbursement"

import axios from "axios"
import moment from "moment"

class Options {
  sortBy: string
  sortDesc: boolean
  mustSort: boolean
}

@Component({
  components: {
    IifFilesList,
    IifDisbursementsFilter,
    IifDisbursementsList,
    PageTemplate
  }
})
export default class Iifs extends Vue {
  private disbursements: IifableDisbursement[] = []
  private selectedDisbursements: IifableDisbursement[] = []
  private iifs: Iif[] = []
  private iifsLength = 0

  private startDate: Date
  private endDate: Date
  private classification: string
  private paymentType: string

  private page: number
  private perPage: number

  private loading = false

  private options: Options = {
    sortBy: "attributes.created_at",
    sortDesc: true,
    mustSort: true
  }

  private startingPerPage = 5

  created() {
    this.loadIifs(1, this.startingPerPage)
  }

  reloadIif() {
    this.loadIifs(this.page, this.perPage)
  }

  async loadIifs(page: number, perPage: number) {
    this.page = page
    this.perPage = perPage

    const data = await this.$store.dispatch("iifs/loadBy", {
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.iifsLength = data?.data?.meta?.pagination?.count

    const iifs = data?.data?.data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.iifs.splice(0, this.iifs.length, ...iifs.map((el: any) => new Iif(el)))
  }

  async loadDisbursements({
    startDate,
    endDate,
    classification,
    paymentType
  }: {
    startDate: Date
    endDate: Date
    classification: string
    paymentType: string
  }) {
    this.loading = true
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const queryParams = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      payment_type: paymentType,
      classification: classification
    }
    this.startDate = startDate
    this.endDate = endDate
    this.classification = classification
    this.paymentType = paymentType

    const data = await axios.get(
      "/disbursements/iifable",
      Object.assign(
        { params: queryParams },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    this.disbursements.splice(
      0,
      this.disbursements.length,
      ...data.data.map((el: any) => new IifableDisbursement(el)) // eslint-disable-line @typescript-eslint/no-explicit-any
    )
    this.loading = false
  }

  async createIIF(date: string) {
    const ids = this.disbursements.map((el: IifableDisbursement) => {
      return el.disbursement_id
    })

    const new_iif = new Iif()

    let disbursementDate = null
    if (date) {
      disbursementDate = moment(date).format("YYYY-MM-DD")
    }
    const startDate = moment(this.startDate).format("YYYY-MM-DD")
    const endDate = moment(this.endDate).format("YYYY-MM-DD")
    const classification = this.classification
    const paymentType = this.paymentType
    let name = `Disbursements ${startDate}-${endDate}`
    if (classification) {
      name += `-${classification}`
    }
    if (paymentType) {
      name += `-${paymentType}`
    }
    new_iif.attributes.name = name

    const res = await this.$store.dispatch("iifs/create", new_iif)

    if (res.status == 200) {
      for (const id of ids) {
        await this.$store.dispatch("disbursements/update", {
          id: id,
          attributes: { iif_id: res.data.data.id }
        })
      }
    }

    await this.$store.dispatch("iifs/makeFile", {
      id: res.data.data.id,
      date: disbursementDate
    })

    this.loadDisbursements({
      startDate: this.startDate,
      endDate: this.endDate,
      classification: this.classification,
      paymentType: this.paymentType
    })
    this.loadIifs(1, this.startingPerPage)
  }

  updateOptions(options: Options) {
    this.options = options
    this.loadIifs(this.page, this.perPage)
  }
}
