
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
@Component({
  components: {
    ConfirmationTemplate
  }
})
export default class DialogTemplate extends Vue {
  @Prop() private title!: string
  @Prop() private subtitle!: string
  @Prop() private btnLabel!: string
  @Prop() private btnId!: string
  @Prop() private fullscreen!: false
  @Prop() private isLoader!: boolean
  @Prop() private loading!: boolean
  @Prop() private hideOpener!: boolean
  @Prop() private wide!: boolean
  @Prop() private width!: string
  @Prop() private iconColor!: string
  @Prop() private icon!: string
  @Prop() private iconSize!: string
  @Prop() private totalPages!: number
  @Prop() private page!: number
  @Prop() private openerDisabled!: boolean
  @Prop() private nextDisabled!: boolean
  @Prop() private saveDisabled!: boolean
  @Prop() private showOKbutton!: boolean
  @Prop() private requireConfirm!: false
  @Prop() private confirmTitle!: string
  @Prop() private confirmSubtitle!: string
  @Prop() private confirmBtnLabel!: string
  @Prop() private confirmBody!: string
  @Prop() private dialogId!: string
  private edit = false
  private valid = false
  private confirmed = false
  private showConfirm = false
  confirmOk() {
    this.confirmed = true
    this.showConfirm = false
    // setTimeout(() => {
    this.save()
    // }, 500)
  }
  confirmReject() {
    this.confirmed = false
    this.showConfirm = false
  }
  save() {
    const reallySave =
      !this.requireConfirm || (this.requireConfirm && this.confirmed)
    if (reallySave) {
      this.edit = false
      this.confirmed = false
      this.$emit("save")
    } else if (this.requireConfirm) {
      this.showConfirm = true
    }
  }
  doNotSave() {
    this.confirmed = false
    this.showConfirm = false
  }
  cancel() {
    this.edit = false
    this.$emit("cancel")
  }
  open(open: boolean) {
    this.edit = open
  }
  @Watch("loading")
  onLoadingChange(value: boolean) {
    if (!value && this.isLoader) {
      this.edit = false
    }
  }
  validateForm() {
    ;(this.$refs.form as Vue & { validate: () => boolean }).validate()
  }
}
