
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {}
})
export default class AccountChip extends Vue {
  @Prop() private account!: string

  get planColor() {
    let color = "yellow"
    switch (this.account) {
      case "fire":
        color = "red"
        break
      case "law":
        color = "blue"
        break
      case "ltc":
        color = "purple"
        break
    }
    return color
  }

  get accountToBoard() {
    let board = "??"
    switch (this.account) {
      case "fire":
        board = "CAPF"
        break
      case "law":
        board = "CLEA"
        break
      case "ltc":
        board = "NPFBA"
        break
    }
    return board
  }
}
