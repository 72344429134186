
import { Component, Vue } from "vue-property-decorator"
import PageTemplate from "@/components/PageTemplate.vue"
import { Department } from "@/types/Department"
import AccountChip from "@/components/AccountChip.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import HelpBubble from "@/components/HelpBubble.vue"

@Component({
  components: {
    AccountChip,
    CurrencyField,
    DateField,
    HelpBubble,
    PageTemplate
  }
})
export default class Departments extends Vue {
  private loading = false

  get departments() {
    return this.$store.getters["departments/getAll"]
  }

  get departmentsForTable() {
    return this.departments.map((el: Department) => {
      return {
        id: el.id,
        name: el.attributes.name,
        live: !!el.attributes.live,
        ltd_billable: !!el.attributes.ltd_billable,
        ltc_billable: !!el.attributes.ltc_billable,
        eip_billable: !!el.attributes.eip_billable,
        ltd_balance: el.attributes.ltd_balance,
        ltc_balance: el.attributes.ltc_balance,
        eip_balance: el.attributes.eip_balance,
        effective_date: el.attributes.effective_date,
        ltc_effective_date: el.attributes.ltc_effective_date,
        classification: el.attributes.classification,
        billing_name: el.attributes.billing_name,
        ltc_billing_name: el.attributes.ltc_billing_name,
        eip_billing_name: el.attributes.eip_billing_name,
        is_child: !!el.attributes.is_child
      }
    })
  }

  created() {
    this.loadDepartments()
  }

  async loadDepartments() {
    this.loading = true
    await this.$store.dispatch("departments/loadAll")
    this.loading = false
  }

  goTo(item: Department) {
    this.$router.push({
      name: "Department",
      params: { id: item.id }
    })
  }

  get headers() {
    return [
      {
        text: "name",
        value: "name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "live",
        value: "live",
        sortable: true,
        class: "text-left caption",
        width: "85px"
      },
      {
        text: "ltd",
        value: "ltd_billable",
        sortable: true,
        class: "text-left caption",
        width: "75px"
      },
      {
        text: "ltc",
        value: "ltc_billable",
        sortable: true,
        class: "text-left caption",
        width: "75px"
      },
      {
        text: "eip",
        value: "eip_billable",
        sortable: true,
        class: "text-left caption",
        width: "75px"
      },
      {
        text: "ltd balance",
        value: "ltd_balance",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "ltc balance",
        value: "ltc_balance",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "eip balance",
        value: "eip_balance",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "ltd",
        value: "effective_date",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "ltc",
        value: "ltc_effective_date",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "child",
        value: "is_child",
        sortable: true,
        class: "text-left caption",
        width: "90px"
      },
      {
        text: "board",
        value: "classification",
        sortable: true,
        class: "text-left caption"
      }
    ]
  }
}
