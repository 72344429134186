
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import valid from "card-validator"
import { CreditCard } from "@/types/CreditCard"
import { cloneDeep } from "lodash"
import DialogTemplate from "@/components/DialogTemplate.vue"
import HelpBubble from "@/components/HelpBubble.vue"

@Component({
  components: {
    DialogTemplate,
    HelpBubble
  }
})
export default class PaymentMethodCreditCard extends Vue {
  @Prop() private creditCard!: CreditCard

  private editCard = new CreditCard()
  private showPaste = false
  private pasteValue = ""

  @Watch("creditCard", { deep: true })
  onCardChange(value: CreditCard) {
    if (value) {
      this.editCard = cloneDeep(value)
    }
  }

  created() {
    if (this.creditCard) {
      this.editCard = cloneDeep(this.creditCard)
    }
  }

  cardType() {
    const validator = valid.number(this.editCard.attributes.number)
    return validator.card ? validator.card.type : "generic"
  }

  validateNumber(number: string) {
    return valid.number(number).isValid || "Invalid card number"
  }

  open() {
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  cancel() {
    this.editCard = cloneDeep(this.creditCard)
  }

  async save() {
    if (this.creditCard.id) {
      await this.$store.dispatch("creditCards/update", this.editCard)
      this.editCard = cloneDeep(this.creditCard)
    } else {
      await this.$store.dispatch("creditCards/create", this.editCard)
      this.editCard = cloneDeep(this.creditCard)
    }
  }

  paste(value: string) {
    this.showPaste = false
    const values = value.split("\n")

    this.editCard.attributes.number = values.length > 0 ? values[0] : ""
    this.editCard.attributes.full_name = values.length > 1 ? values[1] : ""
    this.editCard.attributes.expiration_month =
      values.length > 2 ? parseInt(values[2]) : null
    this.editCard.attributes.expiration_year =
      values.length > 3 ? parseInt(values[3]) : null

    this.pasteValue = ""
  }
}
