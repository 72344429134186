import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import modelToMutation from "../utils/modelToMutation"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import { Ach } from "@/types/Ach"
import { Address } from "@/types/Address"
import { Beneficiary } from "@/types/Beneficiary"

const state: { all: Beneficiary[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Beneficiary>, RootState> = {
  async build(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<Beneficiary>, RootState>,
    data: {
      ach: Ach["attributes"]
      address: Address["attributes"]
      beneficiary: Beneficiary["attributes"]
    }
  ) {
    const combinedData = {
      ...data.beneficiary,
      ach: { ...data.ach },
      address: { ...data.address }
    }
    return axios
      .post(
        "/beneficiaries/build_beneficiary?include=ach,address",
        JSON.parse(JSON.stringify(combinedData)),
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {}; included: [] } }) => {
        commit("set", data && data.data.data)
        if (data.data.included) {
          data.data.included.forEach((el: { type: string }) => {
            const mutation = modelToMutation(el.type)
            commit(mutation, el, { root: true })
          })
        }
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  }
}

const mutations: MutationTree<State<Beneficiary>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
