import { MutationTree, ActionTree } from "vuex"
import { Tag } from "@/types/Tag"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Tag[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Tag>, RootState> = {}
const mutations: MutationTree<State<Tag>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
