
import AddressDepartment from "@/components/AddressDepartment.vue"
import DateField from "@/components/DateField.vue"
import DepartmentSection from "@/components/DepartmentSection.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import DepartmentSectionMixin from "@/mixins/DepartmentSectionMixin"
import CurrencyField from "@/components/CurrencyField.vue"
import YesNoChip from "./YesNoChip.vue"
import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {
    AddressDepartment,
    DateField,
    DepartmentSection,
    CurrencyField,
    PhoneNumber,
    DialogTemplate,
    YesNoChip
  },
  mixins: [DepartmentSectionMixin]
})
export default class DepartmentBilling extends Vue {}
