
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Note } from "@/types/Note"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({ components: { DialogTemplate } })
export default class NoteDetails extends Vue {
  @Prop() private note!: Note

  private updatedNote = JSON.parse(JSON.stringify(this.note))

  @Watch("note")
  onNoteChange() {
    this.updatedNote = JSON.parse(JSON.stringify(this.note))
  }

  saveNote() {
    this.updatedNote.attributes.text = this.updatedNote.attributes.text.trim()
    if (this.note.id) {
      this.$store.dispatch("notes/update", {
        data: this.updatedNote,
        successMessage: "Note updated"
      })
    } else {
      this.$store.dispatch("notes/create", {
        data: this.updatedNote,
        successMessage: "New note created"
      })
    }

    this.updatedNote = JSON.parse(JSON.stringify(this.note))
  }
}
