import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class PolicyGroup extends Model<PolicyGroup> {
  constructor(response?: PolicyGroup) {
    super(response as PolicyGroup)
    if (response) {
      this.attributes.last_billed_on = DateHelper.stringToDate(
        this.attributes.last_billed_on
      )
      this.attributes.next_bill_on = DateHelper.stringToDate(
        this.attributes.next_bill_on
      )
    }
  }
  id: string
  attributes!: {
    department_id: number
    last_billed_on: Date
    next_bill_on: Date
    billing_frequency: string
    payment_method_id: number
    payment_method_type: string
    product_type: string
    eip: boolean
  }
  static get modelName() {
    return "PolicyGroup"
  }
}
