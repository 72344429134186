
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import DateField from "@/components/DateField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import ReportForm from "@/components/ReportForm.vue"
import { Report } from "@/types/Report"

@Component({
  components: {
    CardTemplate,
    ConfirmationTemplate,
    DateField,
    PagedDataTable,
    ReportForm
  }
})
export default class ReportsList extends Vue {
  private reports: Report[] = []
  private loading = false
  private total = 0
  selectedId: number | null = null
  showConfirm = false
  private currentPage = 1
  private startingPerPage = 10
  private perPage = 10
  private options = {
    sortBy: "attributes.created_at",
    sortDesc: true,
    mustSort: true
  }
  private reportType = "all"

  created() {
    this.loadReports(1, this.startingPerPage)
  }

  async loadReports(page: number, perPage: number) {
    this.loading = true
    this.currentPage = page

    const params = {
      page: page,
      per_page: perPage,
      report_type: this.reportType,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    }

    if (this.reportType == "all") {
      delete params.report_type
    }

    const data = await this.$store.dispatch("reports/loadBy", params)

    this.reports = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  getReports(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadReports(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
    }
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadReports(1, this.perPage)
  }

  @Watch("reportType")
  reportTypeChanged() {
    this.loadReports(1, this.perPage)
  }

  get headers() {
    return [
      { text: "NAME", value: "attributes.name", class: "text-left caption" },
      {
        text: "TYPE",
        value: "attributes.report_type",
        class: "text-left caption"
      },
      {
        text: "DATE",
        value: "attributes.created_at",
        class: "text-left caption"
      },
      {
        text: "ACTIONS",
        value: "attributes",
        sortable: false,
        class: "text-left caption"
      }
    ]
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  get reportTypes() {
    return [
      "all",
      "accounts receivable",
      "actuary",
      "age_out",
      "administrators_admin",
      "claims",
      "deferred_income",
      "department_info",
      "financial_overview",
      "membership",
      "mod_21",
      "plan_participation",
      "recoverable_allowance",
      "transaction",
      "trustee_admin"
    ]
  }

  reportTypeLabel(s: string) {
    const a = s.split(/[\s_]+/)
    return a
      .map((s: string) => {
        return s[0].toUpperCase() + s.substring(1)
      })
      .join(" ")
  }

  mounted() {
    this.$cable.subscribe({
      channel: "ReportGeneratedChannel",
      room: "public"
    })
  }
  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      ReportGeneratedChannel: {
        connected() {
          console.log("ReportGeneratedChannel connected")
        },
        rejected() {
          console.log("ReportGeneratedChannel rejected")
        },
        received(data: { report: string }) {
          console.log("received report generated message with this data", data)
          setTimeout(() => {
            that.loadReports(1, that.startingPerPage)
          }, 5000)
        }
      }
    }
  }
  async deleteReport(id: number) {
    await this.$store.dispatch("reports/delete", id)
    this.loadReports(this.currentPage, this.perPage)
  }
}
