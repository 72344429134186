
import { Component, Vue } from "vue-property-decorator"
import AltRateAgeOut from "@/components/AltRateAgeOut.vue"
import CancellationCount from "@/components/CancellationCount.vue"
import DisbursementStats from "@/components/DisbursementStats.vue"
import DisbursementStatsLtc from "@/components/DisbursementStatsLtc.vue"
import RecoveryStats from "@/components/RecoveryStats.vue"
import FollowingsList from "@/components/FollowingsList.vue"
import MyClaims from "@/components/MyClaims.vue"
import NotificationsWidget from "@/components/NotificationsWidget.vue"
import OutstandingEstimatedPayables from "@/components/OutstandingEstimatedPayables.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PoliciesInUnderwriting from "@/components/PoliciesInUnderwriting.vue"

@Component({
  components: {
    AltRateAgeOut,
    CancellationCount,
    DisbursementStats,
    DisbursementStatsLtc,
    RecoveryStats,
    FollowingsList,
    MyClaims,
    NotificationsWidget,
    OutstandingEstimatedPayables,
    PoliciesInUnderwriting,
    PageTemplate
  }
})
export default class Home extends Vue {
  private disbursementStats: unknown = {}
  private disbursementStatsLtc: unknown = {}
  private recoveryStats: unknown = {}

  get followings() {
    return this.$store.getters["followings/getBy"]({ user_id: this.user.id })
  }

  get notifications() {
    return this.$store.getters["notifications/getAll"]
  }

  get user() {
    return this.$store.getters["authentication/getUser"]
  }

  get claims() {
    if (this.user) {
      return this.$store.getters["claims/getBy"]({ admin_id: this.user.id })
    } else {
      return []
    }
  }

  created() {
    this.$store.dispatch("followings/loadBy", {
      user_id: this.user.id,
      include: "followable"
    })

    const activeStates = this.$store.getters["config/getActiveStates"]
    this.$store.dispatch("claims/loadBy", {
      admin_id: this.user.id,
      aasm_state: activeStates,
      include: ["disbursements"]
    })
    this.$store.dispatch("disbursements/getStats").then(data => {
      this.disbursementStats = data?.data ? data.data : {}
    })
    this.$store.dispatch("disbursements/getStatsLtc").then(data => {
      this.disbursementStatsLtc = data?.data ? data.data : {}
    })
    this.$store.dispatch("recoveries/getStats").then(data => {
      this.recoveryStats = data?.data ? data.data : {}
    })
  }
}
