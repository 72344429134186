
import pluralize from "pluralize"
import { camelCase } from "change-case"
import { Component, Vue, Watch } from "vue-property-decorator"
import AccountChip from "@/components/AccountChip.vue"
import DateField from "@/components/DateField.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Claim } from "@/types/Claim"
import { cloneDeep } from "lodash"
import CurrencyField from "@/components/CurrencyField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Disbursement } from "@/types/Disbursement"
import { Payment } from "@/types/Payment"
import { Nacha as NachaType } from "@/types/Nacha"
import moment from "moment"

class LocalPaymentType {
  id: number
  date: string
  first_name: string
  last_name: string
  amount: string
  status: string
}

@Component({
  components: {
    AccountChip,
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    PagedDataTable,
    PageTemplate
  }
})
export default class Nacha extends Vue {
  private editNacha: NachaType = new NachaType()
  private selected: Disbursement[] = []
  private loading = false
  private disablePayments = false
  private totalNachaAmount = "0"
  private fileGenerating = false
  private payments: LocalPaymentType[] = []

  get id() {
    return this.$route.params.id
  }

  get nacha() {
    return this.$store.getters["nachas/getById"](this.id)
  }

  get disbursements() {
    return this.$store.getters["disbursements/getBy"]({
      nacha_id: this.id
    })
  }

  async fetchPayments() {
    this.loading = true
    let page = 1
    this.payments = []

    do {
      const data = await this.$store.dispatch("nachas/fetchPayments", {
        page: page,
        id: this.id
      })
      if (data?.data) {
        this.payments.push(...data.data.data)
        page = data.data.meta.next_page
      } else {
        page = null
      }
    } while (page)

    this.loading = false
  }

  async fetchDisbursements() {
    await this.$store.dispatch("nachas/load", {
      id: this.id,
      include: ["disbursements", "disbursements.claim.policy.member"]
    })
  }

  get disbursementsAllPaid() {
    return (
      this.disbursements.length ==
      this.disbursements.filter((el: Disbursement) => {
        return el.attributes.status == "Paid"
      }).length
    )
  }

  get title() {
    if (this.nacha) {
      return this.nacha.attributes.name
    } else {
      return "Unnamed NACHA file"
    }
  }

  statusClass(item: Disbursement | Payment) {
    switch (item.attributes.status) {
      case "Rejected":
        return "red"
      case "Scheduled":
        return "blue"
      case "Paid":
        return "green"
      default:
        return ""
    }
  }

  @Watch("nacha")
  onNachaChange(value: NachaType) {
    if (value) {
      this.editNacha = cloneDeep(value)
    } else {
      this.editNacha = new NachaType()
    }
  }

  @Watch("nacha.dirty")
  onNachaDirty(value: boolean) {
    if (value) {
      this.$store.commit("disbursements/clearAll")
      this.$store.dispatch("nachas/load", {
        id: this.id,
        include: [
          "disbursements",
          "disbursements.claim.policy.member",
          "payments"
        ]
      })

      this.editNacha = cloneDeep(this.nacha)
    }
  }

  calculateNachaAmount() {
    const amounts =
      this.nacha.attributes.use_case == "payments"
        ? this.payments.map((payment: LocalPaymentType) =>
            parseFloat(payment.amount)
          )
        : this.disbursements.map((disbursement: Disbursement) =>
            parseFloat(disbursement.attributes.amount)
          )

    if (amounts.length == 0) {
      this.totalNachaAmount = "0"
    } else if (amounts.length == 1) {
      this.totalNachaAmount = amounts[0].toString()
    } else {
      this.totalNachaAmount = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  whichClaim(claimId: string) {
    const claim = this.$store.getters["claims/getById"](claimId)
    return claim ? claim : new Claim()
  }

  disbursementMember(disbursent: Disbursement) {
    const claimId = disbursent.attributes.claim_id
    const claim = this.$store.getters["claims/getById"](claimId)
    const policy = this.$store.getters["policies/getById"](
      claim.attributes.policy_id
    )
    const member = this.$store.getters["members/getById"](
      policy.attributes.member_id
    )
    return member
  }

  paymentMember(payment: Payment) {
    const payableType = payment.attributes.payable_type

    if (payableType != "Policy") {
      return null
    }

    const payableId = payment.attributes.payable_id

    const policy = this.$store.getters["policies/getById"](payableId)
    const member = policy
      ? this.$store.getters["members/getById"](policy.attributes.member_id)
      : null

    return member
  }

  async created() {
    await this.fetchDisbursements()
    await this.fetchPayments()
    this.calculateNachaAmount()

    this.editNacha = cloneDeep(this.nacha)
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    await this.fetchDisbursements()
    await this.fetchPayments()
    this.calculateNachaAmount()

    next()
  }

  async payAllPayments() {
    this.disablePayments = true
    await this.$store.dispatch("nachas/payAllPayments", { id: this.id })
  }

  async paySelectedDisbursements() {
    this.loading = true
    for (let i = 0; i < this.selected.length; i++) {
      //eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      if (this.$can("update", this.selected[i])) {
        const tempDisbursement = cloneDeep(this.selected[i])
        tempDisbursement.attributes.status = "Paid"
        await this.$store.dispatch("disbursements/update", tempDisbursement)
      }
    }
    this.loading = false
  }

  async removeFromNacha(item: Disbursement | Payment) {
    item.attributes.nacha_id = null
    const modelString = pluralize(camelCase(item.type))
    await this.$store.dispatch(`${modelString}/update`, item)
    if (this.nacha.attributes.download_doc_url) {
      this.makeFile()
    }
    this.calculateNachaAmount()
  }
  async removePaymentFromNacha(id: number | string) {
    const o: { id: number | string; attributes: { nacha_id: string } } = {
      id: id,
      attributes: { nacha_id: null }
    }

    await this.$store.dispatch("payments/update", o)
    await this.fetchPayments()
    if (this.nacha.attributes.download_doc_url) {
      this.makeFile()
    }
    this.calculateNachaAmount()
  }

  makeFile() {
    this.$store.dispatch("nachas/makeFile", { id: this.id }).then(res => {
      if (res) {
        this.fileGenerating = true
      }
    })
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  save() {
    this.$store.dispatch("nachas/update", this.editNacha)

    const n = {
      ...this.editNacha,
      ...{
        attributes: { include: "disbursements", ...this.editNacha.attributes }
      }
    }
    this.$store.dispatch("nachas/update", n)
  }

  sendOnToday() {
    this.editNacha.attributes.send_date = new Date()
    this.save()
  }

  getCSV() {
    let csv = ""

    if (this.nacha.attributes.use_case == "payments") {
      csv = this.payments
        .map(el => {
          return [
            `${el.first_name} ${el.last_name}`,
            el.amount,
            moment(el.date, "YYYY-MM-DD").format("l"),
            el.status
          ].join(",")
        })
        .join("\n")
    } else {
      csv = this.disbursements
        .map((el: Disbursement) => {
          return [
            `${this.disbursementMember(el).attributes.first_name} ${
              this.disbursementMember(el).attributes.last_name
            }`,
            el.attributes.amount,
            moment(el.attributes.date, "YYYY-MM-DD").format("l"),
            el.attributes.status
          ].join(",")
        })
        .join("\n")
    }

    csv = ["name", "amount", "date", "status"]
      .join(",")
      .concat("\n")
      .concat(csv)

    const anchor = document.createElement("a")
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    anchor.target = "_blank"
    anchor.download = `${this.nacha.attributes.name}-${this.nacha.attributes.use_case}.csv`
    anchor.click()
  }

  get headers() {
    return [
      {
        text: "amount",
        value: "amount",
        class: "text-left caption"
      },
      {
        text: "date",
        value: "date",
        class: "text-left caption"
      },
      {
        text: "member",
        value: "member",
        class: "text-left caption",
        sortable: false
      },
      {
        text: "status",
        value: "status",
        class: "text-left caption"
      },
      {
        text: "",
        value: "actions",
        class: "text-right",
        sortable: false
      }
    ]
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      NachaPayAllCompleteChannel: {
        connected() {
          console.log("NachaPayAllCompleteChannel connected")
        },
        rejected() {
          console.log("NachaPayAllCompleteChannel rejected")
        },
        received(data: { nacha_id: number }) {
          if (data.nacha_id.toString() == that.id) {
            that.disablePayments = false
            that.fetchPayments()
            that.calculateNachaAmount()
          }
        }
      },
      NachaChannel: {
        connected() {
          console.log("NachaChannel connected")
        },
        rejected() {
          console.log("NachaChannel rejected")
        },
        async received(data: { nacha_id: number }) {
          if (data.nacha_id.toString() == that.id) {
            that.fileGenerating = false
            that.$store.dispatch("nachas/load", { id: that.id })
          }
        }
      }
    }
  }

  mounted() {
    this.$cable.subscribe({
      channel: "NachaPayAllCompleteChannel",
      room: "public"
    })
    this.$cable.subscribe({
      channel: "NachaChannel",
      room: "public"
    })
  }
}
