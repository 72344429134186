
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class CardTemplate extends Vue {
  @Prop() private icon!: string
  @Prop() private iconColor!: string
  @Prop() private title!: string
  @Prop() private width!: string
  @Prop() private klass!: string
  @Prop() private backgroundColor!: string
}
