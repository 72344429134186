import Model from "./Model"

export class BillMember extends Model<BillMember> {
  id: string
  attributes!: {
    bill_id: number
    member_id: number
    amount: string
  }
  static get modelName() {
    return "BillMember"
  }
}
