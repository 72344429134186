
import { Component, Prop, Vue } from "vue-property-decorator"
import { Ach } from "@/types/Ach"
import { CreditCard } from "@/types/CreditCard"
import { Policy } from "@/types/Policy"
import { PolicyGroup } from "@/types/PolicyGroup"
import { cloneDeep } from "lodash"
import valid from "card-validator"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: { DialogTemplate }
})
export default class PaymentMethodAssign extends Vue {
  @Prop() private aches!: [Ach]
  @Prop() private creditCards!: [CreditCard]
  @Prop() private policyOrGroup!: Policy | PolicyGroup

  private editPolicy: Policy | PolicyGroup = new Policy()

  created() {
    if (this.policyOrGroup) {
      this.editPolicy = cloneDeep(this.policyOrGroup)
    }
  }

  clearMethod() {
    this.editPolicy.attributes.payment_method_type = ""
    this.editPolicy.attributes.payment_method_id = 0
    this.save()
    ;(this.$refs.dialog as DialogTemplate).open(false)
  }

  get selectedPaymentMethod(): Ach | CreditCard {
    if (this.editPolicy.attributes.payment_method_type == "CreditCard") {
      const id = this.editPolicy.attributes.payment_method_id
      return this.$store.getters["creditCards/getById"](id)
    } else if (this.editPolicy.attributes.payment_method_type == "Ach") {
      const id = this.editPolicy.attributes.payment_method_id
      return this.$store.getters["aches/getById"](id)
    }
    return undefined
  }
  set selectedPaymentMethod(value: Ach | CreditCard) {
    if (value && value.type == "ach") {
      this.editPolicy.attributes.payment_method_type = "Ach"
      this.editPolicy.attributes.payment_method_id = parseInt(value.id)
    } else if (value && value.type == "credit_card") {
      this.editPolicy.attributes.payment_method_type = "CreditCard"
      this.editPolicy.attributes.payment_method_id = parseInt(value.id)
    }
  }

  open() {
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  async save() {
    const which =
      this.policyOrGroup instanceof Policy ? "policies" : "policyGroups"
    await this.$store.dispatch(`${which}/update`, this.editPolicy)
    this.editPolicy = cloneDeep(this.policyOrGroup)
    this.$emit("save")
  }

  cardType(creditCard: CreditCard) {
    const validator = valid.number(creditCard.attributes.number)
    return validator.card ? validator.card.type : "generic"
  }
}
