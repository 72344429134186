
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DialogTemplate
  }
})
export default class RecoveryStats extends Vue {
  @Prop() rawData!: { data: [] }
  private theTab = "0"
  private downloadMonth: { text: string; value: Date }

  displayMonth(date: string) {
    const d = new Date(date)
    d.setUTCHours(12)

    return `${this.monthNames[d.getMonth()]}, ${d.getFullYear()}`
  }

  created() {
    this.downloadMonth = {
      text: this.monthNames[new Date().getMonth()],
      value: new Date()
    }
  }

  getRawStats() {
    const classification = this.theTab
    const month = this.downloadMonth.value
    this.$store
      .dispatch("recoveries/getRawStats", {
        month: month,
        classification: classification
      })
      .then(data => {
        const fileURL = window.URL.createObjectURL(new Blob([data.data]))
        const fURL = document.createElement("a")
        const d = new Date(month)
        d.setUTCHours(12)
        const downloadFileName = `${this.monthNames[d.getMonth()]}-${
          classification == "0" ? "fire" : "law"
        }.csv`
        fURL.href = fileURL
        fURL.setAttribute("download", downloadFileName)
        document.body.appendChild(fURL)
        fURL.click()
      })
  }

  get possibleMonths() {
    const dToday = new Date()
    dToday.setUTCHours(12)

    const months = []

    months.push({ text: this.monthNames[dToday.getMonth()], value: dToday })

    for (let i = 1; i < 7; i++) {
      const d = new Date()
      d.setMonth(dToday.getMonth() - i)
      months.push({ text: this.monthNames[d.getMonth()], value: d })
    }

    return months
  }

  private monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
}
