
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import HelpBubble from "@/components/HelpBubble.vue"
import RecordSearch from "./RecordSearch.vue"
import { Search as SearchItem } from "@/types/Search"

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate,
    HelpBubble,
    RecordSearch
  }
})
export default class PolicyChangePayroll extends Vue {
  @Prop() private policyId!: number
  private effectiveDate = new Date()
  private changeToDepartment: string | null = null

  initiateChange() {
    ;(this.$refs.policyChangeDialog as DialogTemplate).open(true)
  }

  setDepartment(event: SearchItem) {
    this.changeToDepartment = event.target_id
  }

  async save() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      policyId: this.policy.id,
      newDepartmentId: this.changeToDepartment,
      effectiveDate: this.effectiveDate
    }

    console.log(params)
    await this.$store.dispatch("policies/changePayroll", params)

    this.$emit("save")
  }

  get policy() {
    return this.$store.getters["policies/getById"](this.policyId)
  }

  get policyProduct() {
    if (this.policy) {
      return this.$store.getters["products/getById"](
        this.policy.attributes.product_id
      )
    } else {
      return null
    }
  }
}
