export default abstract class Model<T> {
  dirty = false
  attributes = {}
  type?: string = ""

  constructor()
  constructor(response: T)
  constructor(response?: T) {
    if (response) {
      Object.assign(this, response)
    }
  }
}
