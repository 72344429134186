
import { Component, Vue, Watch } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Report } from "@/types/Report"

@Component({
  components: { DateField, DialogTemplate }
})
export default class ReportForm extends Vue {
  private report: Report = new Report()
  private reportType = ""
  private productType = ""

  async save() {
    await this.$store.dispatch("reports/create", this.report)
    this.$emit("reload")
  }

  created() {
    this.report.attributes.kwargs.eip = false
  }

  @Watch("report.attributes.report_type")
  onReportTypeChange(value: string) {
    if (value && (value == "actuary" || value == "recoverable_allowance")) {
      if (this.report.attributes.kwargs.classification == "npfba") {
        this.report.attributes.kwargs.start_date = new Date(2002, 4, 1)
      } else {
        this.report.attributes.kwargs.start_date = new Date(2021, 11, 1)
      }
    }
  }

  @Watch("report.attributes.kwargs.classification")
  onReportClassificationChange(value: string) {
    if (value && this.report.attributes.report_type == "actuary") {
      if (value == "npfba") {
        this.report.attributes.kwargs.start_date = new Date(2002, 4, 1)
      } else {
        this.report.attributes.kwargs.start_date = new Date(2021, 11, 1)
      }
    }
  }

  get reportTypes() {
    return [
      { text: "Actuary", value: "actuary" },
      { text: "Age Out", value: "age_out" },
      { text: "FSM 20 Year & New Policies", value: "administrators_admin" },
      { text: "Claims", value: "claims" },
      { text: "Deferred Income", value: "deferred_income" },
      { text: "Department Info", value: "department_info" },
      { text: "LTC Financial Overview", value: "financial_overview" },
      { text: "LTC Plan Participation", value: "plan_participation" },
      { text: "Membership", value: "membership" },
      { text: "Mod 21", value: "mod_21" },
      { text: "Recoverable Allowance", value: "recoverable_allowance" },
      { text: "Transaction", value: "transaction" },
      { text: "NPFBA Trustee Admin", value: "trustee_admin" }
    ]
  }

  get classifications() {
    return [
      { text: "Fire", value: "fire" },
      { text: "Law", value: "law" }
    ]
  }

  get actuaryClassifications() {
    return [
      { text: "CAPF", value: "fire" },
      { text: "CLEA", value: "law" },
      { text: "NPFBA", value: "npfba" }
    ]
  }

  get productTypes() {
    return [
      { text: "LTD", value: "ltd" },
      { text: "LTC", value: "ltc" }
    ]
  }
}
