
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DialogTemplate
  }
})
export default class UpdatePolicyRiderStatus extends Vue {
  @Prop() private id!: number
  @Prop() private rider!: number | null

  private changedRider = ""

  @Watch("rider")
  onRiderChange(value: string) {
    if (value) {
      this.changedRider = value
    }
  }

  private riderOptions = ["Yes", "No", "Unknown"]

  async update() {
    const val =
      this.changedRider == "Unknown" ? null : this.changedRider == "Yes"
    console.log("update to ", this.changedRider, val)
    await axios.put(
      `/policies/${this.id}`,
      { rider: val },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
