
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

import { Iif } from "@/types/Iif"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import IifEditDialog from "@/components/IifEditDialog.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"

import DateHelper from "./utils/DateHelper"

@Component({
  components: {
    CardTemplate,
    DateField,
    IifEditDialog,
    PagedDataTable
  }
})
export default class Iifs extends Vue {
  private options = {
    sortBy: "attributes.created_at",
    sortDesc: true,
    mustSort: true
  }

  private loading = false
  private startingPerPage = 5
  private perPage = 5
  private currentPage = 1

  @Prop() private iifs: Iif[]
  @Prop() private length: number

  @Watch("options", { deep: true })
  optionsChanged() {
    this.$emit("update-options", this.options)
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  get headers() {
    return [
      {
        text: "NAME",
        value: "attributes.name",
        sortable: true,
        class: "text-left caption",
        width: 350
      },
      {
        text: "DATE",
        value: "attributes.created_at",
        sortable: true,
        class: "text-left caption",
        width: 350,
        sort: DateHelper.sortByDate
      },
      {
        text: "FILE",
        value: "file",
        sortable: false,
        class: "text-left caption",
        width: 140
      }
    ]
  }

  loadPage(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    this.$emit("load-page", details)
  }

  goToIif(iif: Iif) {
    this.$router.push({
      name: "Iif",
      params: { id: `${iif.id}` }
    })
  }
}
