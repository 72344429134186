
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "./CurrencyField.vue"
import { Benefit } from "@/types/Benefit"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate
  }
})
export default class BenefitsSnapshot extends Vue {
  @Prop() private benefits!: Benefit[]
}
