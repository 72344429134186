
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { AdminFee } from "@/types/AdminFee"

@Component({
  components: { CardTemplate, CurrencyField, DateField, DialogTemplate }
})
export default class AdminFees extends Vue {
  @Prop() private adminFees!: AdminFee[]
  @Prop() private classification!: string
  @Prop() private eip!: boolean

  private newFee = new AdminFee()

  save() {
    this.newFee.attributes.classification = this.classification
    this.newFee.attributes.eip = this.eip
    this.$store.dispatch("adminFees/create", this.newFee)
    this.newFee = new AdminFee()
  }

  get current() {
    const today = new Date()
    const fee = this.adminFees.find(fee => {
      return fee.attributes.start_date <= today
    })
    return fee?.id
  }

  get title() {
    let s = `${this.classification} Admin Rates`
    if (this.eip) {
      s += " (EIP)"
    }
    return s
  }
}
