import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { PolicyGroup } from "@/types/PolicyGroup"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: PolicyGroup[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<PolicyGroup>, RootState> = {
  async checkMembership(
    { dispatch, rootGetters }: ActionContext<State<PolicyGroup>, RootState>,
    id: string
  ) {
    try {
      const data = await axios.get(
        `policy_groups/${id}/check_membership`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchMembers(
    { dispatch, rootGetters }: ActionContext<State<PolicyGroup>, RootState>,
    params: { policyGroupId: number; page: number }
  ) {
    try {
      const data = await axios.get(
        `policy_groups/${params.policyGroupId}/members?page=${params.page}`,
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchMemberPdf(
    { dispatch, rootGetters }: ActionContext<State<PolicyGroup>, RootState>,
    policyGroupId: number
  ) {
    try {
      const data = await axios.get(
        `policy_groups/${policyGroupId}/member_pdf`,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<PolicyGroup>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
