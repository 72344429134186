import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Policy } from "@/types/Policy"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Policy[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Policy>, RootState> = {
  async fetchDelinquent(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
      classification: null | string
    }
  ) {
    try {
      let url = `policies/delinquent?page=${params.page}&product_type=${params.productType}`
      if (params.classification != null) {
        url = url + `&classification=${params.classification}`
      }

      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPaidInFull({
    dispatch,
    rootGetters
  }: ActionContext<State<Policy>, RootState>) {
    try {
      const data = await axios.get(
        "policies/paid_in_full",
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchCancelled(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
      classification: null | string
    }
  ) {
    try {
      let url = `policies/cancelled?page=${params.page}&product_type=${params.productType}`
      if (params.classification != null) {
        url = url + `&classification=${params.classification}`
      }

      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchOrphaned(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      productType: string
    }
  ) {
    try {
      const url = `policies/orphaned?product_type=${params.productType}`

      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesToBillAch(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
      classification: null | string
    }
  ) {
    try {
      let url = `policies/billable?type=Ach&page=${params.page}&product_type=${params.productType}`
      if (params.classification != null) {
        url = url + `&classification=${params.classification}`
      }
      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesToBillCheck(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
      classification: null | string
    }
  ) {
    try {
      let url = `policies/billable?type=Check&page=${params.page}&product_type=${params.productType}`
      if (params.classification != null) {
        url = url + `&classification=${params.classification}`
      }

      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesToPayCheck(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
    }
  ) {
    try {
      const data = await axios.get(
        `policies/payable?type=Check&page=${params.page}&productType=${params.productType}`,
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesToNotifyCreditCard(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
      classification: null | string
    }
  ) {
    try {
      let url = `policies/billable?type=CreditCard&notify=true&page=${params.page}&product_type=${params.productType}`
      if (params.classification != null) {
        url = url + `&classification=${params.classification}`
      }

      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesExpiringCreditCard(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
      classification: null | string
    }
  ) {
    try {
      let url = `policies/billable?type=CreditCard&expiring=true&page=${params.page}&product_type=${params.productType}`
      if (params.classification != null) {
        url = url + `&classification=${params.classification}`
      }
      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesToBillCreditCard(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      page: number | string
      productType: string
      classification: null | string
    }
  ) {
    try {
      let url = `policies/billable?type=CreditCard&page=${params.page}&product_type=${params.productType}`
      if (params.classification != null) {
        url = url + `&classification=${params.classification}`
      }

      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesPayrollAdditions(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,

    date: string | null
  ) {
    try {
      let url = "policies/new_payroll"
      if (date != null) {
        url = url + `?date=${date}`
      }

      console.log("url", url)
      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPoliciesPayrollAdditionsReport(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      productType: string
      departmentId: number
      date: string
    }
  ) {
    return axios
      .post(
        "/policies/new_payroll_report",
        {
          product_type: params.productType,
          department_id: params.departmentId,
          date: params.date
        },
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },

  async delinquentNotices(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      notice_type: string
      policyIds: string
    }
  ) {
    return axios
      .post(
        "/policies/delinquent_notices",
        {
          notice_type: params.notice_type,
          policy_ids: params.policyIds
        },
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async removeFromPolicyGroup(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      policyId: number
      newProductId: number
      effectiveDate: string
      paymentMethodType: string
      paymentMethodId: number
      billingFrequency: string
      newPremium: string
    }
  ) {
    try {
      const data = await axios.post(
        `policies/${params.policyId}/remove_from_group`,
        {
          policy_id: params.policyId,
          new_product_id: params.newProductId,
          effective_date: params.effectiveDate,
          payment_method_type: params.paymentMethodType,
          payment_method_id: params.paymentMethodId,
          billing_frequency: params.billingFrequency,
          new_premium: params.newPremium
        },
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async changePayroll(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      policyId: number
      newDepartmentId: number
      effectiveDate: string
    }
  ) {
    try {
      const data = await axios.post(
        `policies/${params.policyId}/change_payroll`,
        {
          policy_id: params.policyId,
          new_department_id: params.newDepartmentId,
          effective_date: params.effectiveDate
        },
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async addToPayroll(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: {
      policyId: number
      effectiveDate: string
    }
  ) {
    try {
      const data = await axios.post(
        `policies/${params.policyId}/add_to_payroll`,
        {
          policy_id: params.policyId,
          effective_date: params.effectiveDate
        },
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchUnderwritingReport(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    id: number
  ) {
    try {
      const data = await axios.get(
        `/policies/${id}/underwriting`,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPolicyDelinquentNotice(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: { id: number; noticeType: string }
  ) {
    try {
      const data = await axios.post(
        `/policies/${params.id}/notice`,
        { notice_type: params.noticeType },
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchReceipt(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    params: { id: number; startDate: Date; endDate: Date; amount: string }
  ) {
    try {
      const data = await axios.post(
        `/policies/${params.id}/receipt`,
        {
          start_date: params.startDate,
          end_date: params.endDate,
          amount: params.amount
        },
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchScheduleOfBenefits(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    id: number
  ) {
    try {
      const data = await axios.get(
        `/policies/${id}/schedule_of_benefits`,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async reinstatePolicy(
    { dispatch, commit, rootGetters }: ActionContext<State<Policy>, RootState>,
    id: number
  ) {
    try {
      const data = await axios.post(
        `/policies/${id}/reinstate`,
        {},
        rootGetters["config/getAPIConfig"]
      )

      if (data?.data?.data) {
        commit("set", data.data.data)
      }

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getCancellationCounts({
    dispatch,
    rootGetters
  }: ActionContext<State<Policy>, RootState>) {
    try {
      const data = await axios.get(
        "/policies/cancellation_counts",
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getApplicationCounts({
    dispatch,
    rootGetters
  }: ActionContext<State<Policy>, RootState>) {
    try {
      const data = await axios.get(
        "/policies/application_counts",
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getCancellations(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    date: string
  ) {
    try {
      const data = await axios.get(
        `/policies/cancellations?date=${date}`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchDaysDelinquent(
    { dispatch, rootGetters }: ActionContext<State<Policy>, RootState>,
    id: number
  ) {
    try {
      const data = await axios.get(
        `/policies/${id}/days_delinquent`,
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<Policy>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
