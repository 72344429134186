
import CardTemplate from "@/components/CardTemplate.vue"
import { Benefit } from "@/types/Benefit"
import { Beneficiary } from "@/types/Beneficiary"
import ConfirmationTemplate from "./ConfirmationTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Disbursement } from "@/types/Disbursement"
import { DisbursementType } from "@/types/DisbursementType"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import PagedDataTable from "./PagedDataTable.vue"
import { Claim } from "@/types/Claim"
import { cloneDeep } from "lodash"

@Component({
  components: {
    CardTemplate,
    ConfirmationTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    PagedDataTable
  }
})
export default class Benefits extends Vue {
  @Prop() private claim!: Claim
  @Prop() private beneficiaries!: Beneficiary[]

  private benefits: Benefit[] = []
  private selected: Benefit[] = []
  private selectedTotal = "0"
  private loading = false
  private total = 0
  private startingPerPage = 5
  private perPage = 5
  private currentPage = 1
  private options = {
    sortBy: "start_date",
    sortDesc: true,
    mustSort: true
  }
  private newBenefit = new Benefit()
  private editBenefit: null | Benefit = null
  private deleteBenefit: null | Benefit = null
  private newDisbursement = new Disbursement()
  private showConfirm = false

  resetDisbursement() {
    this.deleteBenefit = null
    this.newDisbursement = new Disbursement()
    this.newDisbursement.attributes.date = new Date()
    this.newDisbursement.attributes.status = "Pending"
    this.newDisbursement.attributes.claim_id = this.claim
      ? parseInt(this.claim.id)
      : null
  }

  async loadBenefits(page: number, perPage: number) {
    this.loading = true

    const sort = this.options.sortBy.replace("attributes.", "")

    const filter: {
      claim_id: string
      page: number
      per_page: number
      order: string
      order_direction: string
    } = {
      claim_id: this.claim.id,
      page: page,
      per_page: perPage,
      order: sort,
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    }

    const data = await this.$store.dispatch("benefits/loadBy", filter)

    this.benefits = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  firstPageLoad(details: { itemsPerPage: number }) {
    this.loadBenefits(1, details.itemsPerPage)
  }

  getBenefits(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadBenefits(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadBenefits(1, this.perPage)
  }

  @Watch("selected")
  selectedChanged() {
    if (this.selected.length == 0) {
      this.selectedTotal = "0"
    }

    this.selectedTotal = this.selected
      .map((el: Benefit) => el.attributes.total)
      .reduce((prev: number, cur: number) => {
        return prev + cur
      }, 0)
      .toString()

    const selectedComments = this.selected
      .map((el: Benefit) => el.attributes.benefit_type)
      .join(" + ")

    this.newDisbursement.attributes.amount = this.selectedTotal
    this.newDisbursement.attributes.comments = selectedComments
  }

  beneficiaryDisplayName(item: Beneficiary) {
    return `${item.attributes.first_name} ${item.attributes.last_name}`
  }

  @Watch("claim", { deep: true })
  claimChanged() {
    this.loadBenefits(1, this.perPage)
    this.resetDisbursement()
  }

  startEdit(benefit: Benefit) {
    this.editBenefit = cloneDeep(benefit)
  }

  startDelete(benefit: Benefit) {
    this.deleteBenefit = benefit
  }

  async doDeleteBenefit() {
    await this.$store.dispatch("benefits/delete", this.deleteBenefit.id)
    this.deleteBenefit = null
    await this.loadBenefits(1, this.perPage)
  }

  async saveEdit() {
    await this.$store.dispatch("benefits/update", this.editBenefit)
    this.editBenefit = null
    await this.loadBenefits(1, this.perPage)
  }

  async addBenefit() {
    this.newBenefit.attributes.claim_id = Number(this.claim.id)

    console.log("addBenefit", this.newBenefit)
    await this.$store.dispatch("benefits/create", this.newBenefit)

    this.newBenefit = new Benefit()
    this.loadBenefits(1, this.perPage)
  }

  async createDisbursement() {
    if (this.newDisbursement.attributes.disbursement_type_id == undefined) {
      this.newDisbursement.attributes.disbursement_type_id = null
    }
    this.newDisbursement.attributes.claim_id = parseInt(this.claim.id)
    this.newDisbursement.attributes.status = "Pending"

    await this.$store.dispatch("disbursements/create", this.newDisbursement)

    this.resetDisbursement()

    this.loading = false

    this.$emit("new-disbursement")
  }

  disbursementTypes(type: string) {
    const disbursementTypes = this.$store.getters["disbursementTypes/getBy"]({
      product_type: type
    })

    return disbursementTypes.map((el: DisbursementType) => {
      return { value: parseInt(`${el.id}`), text: el.attributes.name }
    })
  }

  get benefitTypes() {
    switch (this.claim.attributes.classification) {
      case "fire":
        return [
          "Minimum Benefit $500 Plan A/B",
          "Minimum Benefit $750 Plan A",
          "Minimum Benefit $700 Plan B",
          "Minimum Benefit $1,000",
          "75% Plan A/B",
          "70% Plan A/B",
          "67%",
          "50/50",
          "80%",
          "100% Catastrophic",
          "Maximum Benefit $11,000",
          "Maximum Benefit $6,500"
        ]
      case "law":
        return [
          "85% Plan A",
          "70% Plan A/B",
          "100% Catastrophic",
          "67% Plan B",
          "50/50",
          "90%",
          "50%",
          "Minimum Benefit $1,000 Plan A",
          "Minimum Benefit $250 Plan B",
          "Minimum Benefit $100 Plan A/B",
          "Minimum Benefit $500",
          "Maximum Benefit Plan A $10,000",
          "Maximum Benefit CDCR $6,000"
        ]
      default:
        return [
          "Minimum Benefit $100 Plan A/B",
          "Minimum Benefit $500 Plan A/B",
          "Minimum Benefit $750 Plan A",
          "Minimum Benefit $1,000 Plan A",
          "Minimum Benefit $250 Plan B",
          "Minimum Benefit $700 Plan B",
          "Minimum Benefit $500",
          "Minimum Benefit $1,000",
          "Maximum Benefit Plan A $10,000",
          "Maximum Benefit CDCR $6,000",
          "Minimum Benefit $1,000",
          "75% Plan A/B",
          "70% Plan A/B",
          "85% Plan A",
          "67% Plan B",
          "50/50",
          "90%",
          "80%",
          "50%",
          "100% Catastrophic"
        ]
    }
  }

  get headers() {
    return [
      {
        text: "START",
        value: "attributes.start_date",
        sortable: true,
        class: "caption",
        width: "110px"
      },
      {
        text: "END",
        value: "attributes.end_date",
        sortable: true,
        class: "caption",
        width: "110px"
      },
      {
        text: "DAYS",
        value: "attributes.days",
        sortable: true,
        class: "caption",
        width: "100px"
      },
      {
        text: "AMOUNT",
        value: "attributes.amount",
        sortable: true,
        class: "caption",
        width: "150px"
      },
      {
        text: "TYPE",
        value: "attributes.benefit_type",
        sortable: true,
        class: "caption"
      },
      {
        text: "COLA",
        value: "attributes.cola_amount",
        sortable: true,
        class: "caption",
        width: "110px"
      },
      {
        text: "",
        value: "attributes.cola_description",
        sortable: true,
        class: "caption"
      },
      {
        text: "OFFSET",
        value: "attributes.offset_amount",
        sortable: true,
        class: "caption",
        width: "110px"
      },
      {
        text: "",
        value: "attributes.offset_description",
        sortable: true,
        class: "caption"
      },
      {
        text: "EFFECTIVE",
        value: "attributes.total",
        sortable: true,
        class: "caption"
      },
      {
        text: "",
        value: "edit",
        sortable: false,
        class: "caption",
        width: "90px"
      }
    ]
  }
}
