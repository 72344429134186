
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DateHelper from "@/components/utils/DateHelper"
import PageTemplate from "@/components/PageTemplate.vue"
import feeHelper from "@/services/feeHelper"

class BillablePolicy {
  policy_id: number
  amount: string
  last: string
  next: string
  member_first_name: string
  member_last_name: string
  member_email: string
  policy_number: string
  billing_frequency: string
  balance: string
  billable_exception: boolean
  payment_expired: boolean
  paid_in_full_date: string
}

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    PageTemplate
  }
})
export default class CreditCardBill extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selected: any[] = []
  private billCoverageDate = this.getFirstDayOfThisMonth()
  private workingCompleteMessage = ""
  private selectedTotal = "0"

  private policies: BillablePolicy[] = []

  private loading = false
  private working = false
  private workProgress = 0
  private account = ""

  async fetchCreditCard() {
    this.loading = true
    let page = 1

    this.policies = []

    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    const classification = accountParts.length > 1 ? accountParts[1] : null

    do {
      const data = await this.$store.dispatch(
        "policies/fetchPoliciesToBillCreditCard",
        { page: page, productType: prodType, classification: classification }
      )
      this.policies.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)

    this.loading = false
  }

  @Watch("account")
  onAccountChange() {
    this.selectedTotal = "0"
    this.selected = []
    this.fetchCreditCard()
  }

  @Watch("selected")
  onSelectedDisbursementsChange(value: BillablePolicy[]) {
    const amounts = value.map((item: BillablePolicy) =>
      parseFloat(this.calculateAmountBilled(item))
    )
    if (amounts.length == 0) {
      this.selectedTotal = "0"
    } else if (amounts.length == 1) {
      this.selectedTotal = amounts[0].toString()
    } else {
      this.selectedTotal = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  async makeBills() {
    if (!this.working) {
      const policyIds = this.selected.map(el => el.policy_id)
      this.working = true
      for (let i = 0; i < policyIds.length; i++) {
        this.workProgress = 100 * (i / 3)
        await this.$store.dispatch("bills/generateSingle", {
          policy_id: policyIds[i],
          coverage_start_date: this.billCoverageDate,
          add_payment_method: "CreditCard"
        })
      }
      this.workProgress = 100
      await new Promise(r => setTimeout(r, 2000))
      this.selected = []
      this.policies = []
      this.working = false
      this.workingCompleteMessage =
        "Billing should be complete. But in some cases the robots working behind the scenes may take longer to clean up. Try waiting a few minutes and refreshing this page if this list contains policies that you expected to be billed."
    }
  }

  getFirstDayOfThisMonth() {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  calculateAmountBilled(policy: BillablePolicy) {
    const a = Number(policy.amount)
    const m = Math.min(
      this.frequencyAsMonths(policy.billing_frequency),
      this.monthsUntilPif(policy)
    )
    const f = Number(this.creditCardFee(policy.billing_frequency))
    const val = m * a + f

    return val.toString()
  }

  creditCardFee(billingFrequency: string) {
    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    return feeHelper.fee(billingFrequency, prodType == "ltd")
  }

  frequencyAsMonths(billingFrequency: string) {
    return feeHelper.frequencyAsMonths(billingFrequency)
  }

  monthsUntilPif(policy: BillablePolicy) {
    if (!policy.paid_in_full_date) {
      return 12 // some number at least as long as the longest billing frequency
    }

    const pif = DateHelper.stringToDate(policy.paid_in_full_date)
    const nextBill = this.billCoverageDate

    let months = (pif.getFullYear() - nextBill.getFullYear()) * 12
    months += pif.getMonth()
    months -= nextBill.getMonth()
    months += 1 // because paid in full date is end of month and bill coverage is beginning of month

    return months <= 0 ? 0 : months
  }

  getCSV() {
    let csv = ""

    csv = this.selected
      .map(el => {
        const n = el.next
          ? DateHelper.stringToDate(el.next).toLocaleDateString()
          : ""
        const l = el.last
          ? DateHelper.stringToDate(el.last).toLocaleDateString()
          : ""

        return [
          el.member_last_name,
          el.member_first_name,
          el.policy_number,
          el.member_email,
          l,
          n,
          this.calculateAmountBilled(el),
          el.amount,
          el.billing_frequency,
          this.creditCardFee(el.billing_frequency)
        ].join(",")
      })
      .join("\n")

    csv = [
      "last",
      "first",
      "policy",
      "email",
      "last",
      "next",
      "amount",
      "premium",
      "freq",
      "fee"
    ]
      .join(",")
      .concat("\n")
      .concat(csv)

    let nameForDownload = ""
    switch (this.account) {
      case "ltc":
        nameForDownload = "LTC-CC-pre-billing.csv"
        break
      case "ltd_0":
        nameForDownload = "EIP-fire-CC-pre-billing.csv"
        break
      case "ltd_1":
        nameForDownload = "EIP-law-CC-pre-billing.csv"
        break
    }

    const anchor = document.createElement("a")
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    anchor.target = "_blank"
    anchor.download = nameForDownload
    anchor.click()
  }

  get headers() {
    return [
      {
        text: "",
        value: "billing_exception",
        sortable: true,
        class: "text-left caption",
        width: "75px"
      },
      {
        text: "LAST",
        value: "member_last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "FIRST",
        value: "member_first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "EMAIL",
        value: "member_email",
        class: "text-left caption"
      },
      {
        text: "POLNUM",
        value: "policy_number",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "AMOUNT",
        value: "total",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "PREMIUM",
        value: "amount",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "FREQ",
        value: "billing_frequency",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "FEE",
        value: "fee",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "LAST BILL",
        value: "last",
        class: "text-right",
        sortable: true
      },
      {
        text: "NEXT BILL",
        value: "next",
        class: "text-right",
        sortable: true
      },
      {
        text: "PREV BALANCE",
        value: "balance",
        class: "text-right",
        sortable: true
      }
    ]
  }
}
