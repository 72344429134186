
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate
  }
})
export default class PolicyChangePayroll extends Vue {
  @Prop() private policyId!: number
  private effectiveDate = new Date()

  initiateChange() {
    ;(this.$refs.policyAddToPayrollDialog as DialogTemplate).open(true)
  }

  async save() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      policyId: this.policy.id,
      effectiveDate: this.effectiveDate
    }

    console.log(params)
    await this.$store.dispatch("policies/addToPayroll", params)

    this.$emit("save")
  }

  get policy() {
    return this.$store.getters["policies/getById"](this.policyId)
  }
}
