
import { Component, Prop, Vue } from "vue-property-decorator"
import { Policy } from "@/types/Policy"
import { PolicyGroup } from "@/types/PolicyGroup"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import EndPolicy from "@/components/EndPolicy.vue"
import NewPolicy from "@/components/NewPolicy.vue"
import PaymentMethodAssign from "@/components/PaymentMethodAssign.vue"
import valid from "card-validator"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    EndPolicy,
    NewPolicy,
    PaymentMethodAssign
  }
})
export default class PoliciesList extends Vue {
  @Prop() private policies!: [Policy] | [PolicyGroup]
  @Prop() private memberId!: string
  @Prop() private departmentId!: string

  private showBillDialog = false
  private billStart = new Date()
  private showProveResults = false
  private billProveData = {}
  private membershipChangeData = [""]

  get proveBillHeaders() {
    return [
      {
        text: "Last",
        align: "start",
        sortable: true,
        value: "last_name"
      },
      { text: "First", value: "first_name" },
      { text: "ssn", value: "ssn" },
      { text: "amount", value: "amount" }
    ]
  }

  sendBillGenerate(itemId: string, itemType: string) {
    this.showBillDialog = false

    if (itemType == "policy_group") {
      this.$store.dispatch("bills/generateSingle", {
        policy_group_id: itemId,
        coverage_start_date: this.billStart
      })
    } else {
      this.$store.dispatch("bills/generateSingle", {
        policy_id: itemId,
        coverage_start_date: this.billStart
      })
    }
  }

  async sendBillProve(itemId: string, itemType: string) {
    this.billProveData = {}
    ;(this.$refs.dialog as DialogTemplate).open(true)

    if (itemType == "policy_group") {
      this.billProveData = await this.$store.dispatch("bills/proveSingle", {
        policy_group_id: itemId,
        coverage_start_date: this.billStart
      })
    } else {
      this.billProveData = { error: "Not yet for single policies!" }
    }
  }

  async checkMembership(policyGroupId: string) {
    const changes = await this.$store.dispatch(
      "policyGroups/checkMembership",
      policyGroupId
    )

    this.membershipChangeData = changes.data
    ;(this.$refs.membershipDialog as DialogTemplate).open(true)
  }

  goToItem(item: Policy | PolicyGroup) {
    const name = item.type == "policy" ? "Policy" : "PolicyGroup"
    this.$router.push({
      name: name,
      params: { id: item.id }
    })
  }

  assignPayment(policyOrGroupId: string) {
    const form = this.$refs[`payment-method-${policyOrGroupId}`] as [
      PaymentMethodAssign
    ]
    form[0].open()
  }

  endPolicy(policyId: string) {
    const form = this.$refs[`end-policy-${policyId}`] as [EndPolicy]
    form[0].open()
  }

  paymentMethodNumber(paymentMethodId: number, paymentMethodType: string) {
    if (paymentMethodType == "Ach") {
      const accountNumber = this.$store.getters["aches/getById"](
        paymentMethodId
      )?.attributes.account_number
      return accountNumber
        ? accountNumber.length > 4
          ? `...${accountNumber.slice(-4)}`
          : accountNumber
        : ""
    }
    if (paymentMethodType == "CreditCard") {
      const cardNumber = this.$store.getters["creditCards/getById"](
        paymentMethodId
      )?.attributes.number
      return cardNumber
        ? cardNumber.length <= 4
          ? `...${cardNumber}`
          : `...${cardNumber.slice(-4)}`
        : ""
    }
  }

  cardTypeImagePath(creditCardId: number) {
    const cardNumber = this.paymentMethodNumber(creditCardId, "CreditCard")
    const validator = valid.number(cardNumber)
    const cardType = validator.card ? validator.card.type : "generic"
    return `/credit_cards/${cardType}.svg`
  }

  policyGroup(policyGroupId: number) {
    const pg = this.$store.getters["policyGroups/getById"](policyGroupId)
    return pg || new PolicyGroup()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  policyRowClass(policyOrGroup: any) {
    let s = "policy-row"

    if (policyOrGroup.type == "policy") {
      const policy: Policy = policyOrGroup
      if (policy.attributes.reversed) {
        s += " policy-reversed"
      }
    }

    return s
  }

  get aches() {
    if (this.memberId != null) {
      return this.$store.getters["aches/getBy"]({
        owner_id: this.memberId,
        owner_type: "Member"
      })
    } else {
      return this.$store.getters["aches/getBy"]({
        owner_id: this.departmentId,
        owner_type: "Department"
      })
    }
  }

  get creditCards() {
    if (this.memberId != null) {
      return this.$store.getters["creditCards/getBy"]({
        owner_id: this.memberId,
        owner_type: "Member"
      })
    } else {
      return this.$store.getters["creditCards/getBy"]({
        owner_id: this.departmentId,
        owner_type: "Department"
      })
    }
  }

  get headers() {
    return this.memberId
      ? [
          "name",
          "number",
          "start",
          "end",
          "billing frequency",
          "last billed",
          "next bill",
          "payment method",
          "payroll",
          ""
        ]
      : [
          "product type",
          "eip",
          "billing frequency",
          "last billed",
          "next bill",
          "payment method",
          ""
        ]
  }
}
