import Model from "./Model"

export class DepartmentProduct extends Model<DepartmentProduct> {
  id: string
  attributes!: {
    department_id: number
    product_id: number
  }
  static get modelName() {
    return "DepartmentProduct"
  }
}
