const feeHelper = {
  fee(billingFrequency: string, ltd: boolean) {
    switch (billingFrequency) {
      case "Quarterly":
        return "2"
      case "Semi-Annually":
        if (ltd) {
          return "0"
        } else {
          return "1"
        }
      case "Annually":
        return "0"
      case "Monthly":
        return "0"
      default:
        return "2"
    }
  },
  frequencyAsMonths(billingFrequency: string) {
    switch (billingFrequency) {
      case "Quarterly":
        return 3
      case "Semi-Annually":
        return 6
      case "Annually":
        return 12
      case "Monthly":
        return 1
      default:
        return 3
    }
  }
}

export default feeHelper
