import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Deposit extends Model<Deposit> {
  constructor(response?: Deposit) {
    super(response as Deposit)
    if (response) {
      this.attributes.deposit_date = DateHelper.stringToDate(
        this.attributes.deposit_date
      )
    }
  }
  id: string
  attributes!: {
    deposit_date: Date
    view_report_url: string
    download_report_url: string
    account: string
    name: string
  }
  static get modelName() {
    return "Deposit"
  }
}
