import axios from "axios"
import jwtDecode from "jwt-decode"
import { MutationTree, ActionContext } from "vuex"
import { RootState } from "@/types/RootState"
import {
  AuthenticatedUser,
  AuthenticationState as State
} from "@/types/Authentication"

const token = localStorage.getItem("token")
const jwt = token ? jwtDecode(token) : null
export const state = jwt
  ? {
      status: { loggingIn: false, loggedIn: true },
      user: jwt
    }
  : {
      status: { loggingIn: false, loggedIn: false },
      user: null
    }

export const getters = {
  getLoggedIn: (state: State): boolean => state.status.loggedIn,
  getLoggingIn: (state: State): boolean => state.status.loggingIn,
  getUser: (state: State): AuthenticatedUser => state.user,
  getRoles: (state: State): Array<object> =>
    state.user ? state.user.roles : null
}

export const actions = {
  async login(
    { commit, dispatch, rootGetters }: ActionContext<State, RootState>,
    { email, password }: { email: string; password: string }
  ) {
    commit("loginRequest", { email })

    try {
      const user = await axios.post(
        "/users/sign_in",
        {
          user: { email, password }
        },
        rootGetters["config/getAPIConfig"]
      )

      const token = user.headers.authorization.split(" ")[1]
      commit("config/setToken", token, { root: true })
      commit("loginSuccess", jwtDecode(token))
      const name = user.data.first_name ? user.data.first_name : ""
      await dispatch("alerts/addSuccess", `Welcome back ${name}`, {
        root: true
      })
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const axiosError = error as { response: { data: unknown } }
        return { error: axiosError.response.data }
      }
      commit("loginFailure")
    }
  },
  async logout({
    commit,
    dispatch,
    rootGetters
  }: ActionContext<State, RootState>) {
    try {
      await axios.delete("users/sign_out", rootGetters["config/getAPIConfig"])

      dispatch("alerts/addSuccess", "Logout successful", { root: true })
    } catch (err) {
      dispatch("alerts/addError", "Logout failed", { root: true })
    } finally {
      commit("config/setToken", undefined, { root: true })
      commit("logout")
    }
  }
}

export const mutations: MutationTree<State> = {
  loginRequest(state: State) {
    state.status = { loggedIn: false, loggingIn: true }
  },
  loginSuccess(state: State, user: AuthenticatedUser) {
    state.status = { loggedIn: true, loggingIn: false }
    state.user = user
  },
  loginFailure(state: State) {
    state.status = { loggedIn: false, loggingIn: false }
    state.user = null
  },
  logout(state: State) {
    state.status = { loggedIn: false, loggingIn: false }
    state.user = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
