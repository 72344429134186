import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class EstimatedPayable extends Model<EstimatedPayable> {
  constructor(estimatedPayable?: EstimatedPayable) {
    super(estimatedPayable as EstimatedPayable)
    if (estimatedPayable) {
      this.attributes.date = DateHelper.stringToDate(this.attributes.date)
    }
  }
  id: string
  attributes!: {
    claim_id: number
    recoverable_amount: string
    non_recoverable_amount: string
    death_amount: string
    date: Date
    memeo: string
  }
  static get modelName() {
    return "EstimatedPayable"
  }
}
