
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { Membership } from "@/types/Membership"
import { Policy } from "@/types/Policy"
import { Product } from "@/types/Product"
import { Search } from "@/types/Search"
import { DepartmentProduct } from "@/types/DepartmentProduct"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: { DateField, DialogTemplate }
})
export default class ModifyMembership extends Vue {
  @Prop() private memberId!: string
  @Prop() private membership!: Membership
  @Prop() private ending!: boolean
  dialog = false
  startNew = ""
  newMembership: Membership = new Membership()
  search = ""
  page = "membership"
  productIds: string[] = []

  endDate: Date = new Date()

  selectedProducts: Product[] = []
  departments: Search[] = []

  created() {
    this.startNew = this.ending ? "" : "Yes"
    this.resetMembership()
  }

  resetMembership() {
    this.newMembership = new Membership()
    this.newMembership.attributes.member_id = parseInt(this.memberId)
    this.newMembership.attributes.start_date = new Date()
  }

  resetAllData() {
    this.resetMembership()
    this.page = "membership"
    this.startNew = this.ending ? "" : "Yes"
    this.search = ""
    this.selectedProducts.splice(0, this.selectedProducts.length)
  }

  get departmentProducts() {
    return this.$store.getters["departmentProducts/getBy"]({
      department_id: this.newMembership.attributes.department_id
    })
  }

  get products() {
    return this.$store.getters["products/getByIds"](this.productIds)
  }

  get policies() {
    return this.$store.getters["policies/getBy"]({
      member_id: this.memberId,
      end_date: null
    })
  }

  get member() {
    return this.$store.getters["members/getById"](this.memberId)
  }

  get endDateAsDate() {
    const sEndDate =
      typeof this.endDate == "string"
        ? this.endDate
        : this.endDate.toISOString()
    const parts = sEndDate.split("-")

    return new Date(parseInt(parts[0]), parseInt(parts[1]), 0)
  }

  get nextDisabled() {
    const sEndDate =
      typeof this.endDate == "string"
        ? this.endDate
        : this.endDate.toISOString()

    const ed = this.endDateAsDate

    return (
      !this.startNew ||
      (this.startNew == "Yes" &&
        (!this.newMembership.attributes.department_id ||
          !this.newMembership.attributes.start_date)) ||
      (this.membership &&
        (this.membership.attributes.start_date > ed || sEndDate == ""))
    )
  }

  startNewChange() {
    if (this.startNew == "No") {
      this.resetMembership()
      this.selectedProducts.splice(0, this.selectedProducts.length)
    }
    this.departments = []
  }

  @Watch("search")
  async searchTermChange(value: string) {
    if (value) {
      if (value && value.trim().length >= 2) {
        const data = await this.$store.dispatch(
          "search/performSearch",
          "d:" + value.trim()
        )
        this.departments = data.data
      }
    }
  }

  @Watch("newMembership.attributes.department_id")
  async newDepartmentChange() {
    if (this.newMembership.attributes.department_id) {
      const dp = await this.$store.dispatch("departmentProducts/loadBy", {
        department_id: this.newMembership.attributes.department_id
      })

      this.productIds = dp.data.data
        ? dp.data.data.map((d: DepartmentProduct) =>
            d.attributes.product_id.toString()
          )
        : []

      this.$store.dispatch("products/loadBy", { id: this.productIds })
    }
  }

  matchesPolicy(product: Product) {
    return this.policies.find(
      (el: Policy) => el.attributes.product_id == parseInt(product.id)
    )
  }

  async save() {
    this.dialog = false

    let s =
      typeof this.newMembership.attributes.start_date == "string"
        ? this.newMembership.attributes.start_date
        : this.newMembership.attributes.start_date.toISOString()
    const startParts = s.split("-")

    this.newMembership.attributes.start_date = new Date(
      parseInt(startParts[0]),
      parseInt(startParts[1]) - 1,
      1
    )

    s =
      typeof this.endDate == "string"
        ? this.endDate
        : this.endDate.toISOString()
    const endParts = s.split("-")
    this.endDate = new Date(parseInt(endParts[0]), parseInt(endParts[1]), 0)

    if (this.membership) {
      await this.$store.dispatch("members/endMembershipPolicy", {
        member_id: this.membership.attributes.member_id,
        end_date: this.endDate,
        membership_id: this.membership.id,
        include: ["memberships", "department", "policies"]
      })
    }

    if (this.newMembership.attributes.department_id) {
      await this.$store.dispatch("memberships/create", {
        data: this.newMembership,
        include: ["member", "department"]
      })
    }

    for (let i = 0; i < this.selectedProducts.length; i++) {
      const el = this.selectedProducts[i]
      const matchingPolicy = this.matchesPolicy(el)
      const newPolicy = new Policy()
      newPolicy.attributes.name = el.attributes.name
      newPolicy.attributes.start_date = this.newMembership.attributes.start_date
      newPolicy.attributes.member_id = parseInt(this.memberId)
      newPolicy.attributes.product_id = parseInt(el.id)
      newPolicy.attributes.status = "active"
      newPolicy.attributes.billing_frequency = matchingPolicy
        ? matchingPolicy.attributes.billing_frequency
        : "Monthly"

      await this.$store.dispatch("policies/create", {
        data: newPolicy,
        include: ["member", "member.policies.policy_group"]
      })
    }

    this.resetAllData()
  }
}
