
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "./DateField.vue"

@Component({
  components: { CardTemplate, DateField }
})
export default class Signatures extends Vue {
  @Prop() private productType!: string
  @Prop() private data!: []
  @Prop() private departmentName!: string
  @Prop() private departmentId!: number

  async downloadReport() {
    const today = new Date()
    const nextMonth =
      today.getMonth() + 1 == 12
        ? new Date(today.getFullYear() + 1, 0, 1)
        : new Date(today.getFullYear(), today.getMonth() + 1, 1)

    const response = await this.$store.dispatch(
      "policies/fetchPoliciesPayrollAdditionsReport",
      {
        departmentId: this.departmentId,
        productType: this.productType,
        date: nextMonth.toISOString().split("T")[0]
      }
    )

    const fileURL = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/pdf"
      })
    )
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `payroll-${this.departmentName.replaceAll(" ", "-")}-${
        this.productType
      }.pdf`
    )
    document.body.appendChild(fURL)

    fURL.click()
  }
}
