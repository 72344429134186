
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Ach } from "@/types/Ach"
import { Address } from "@/types/Address"
import { Beneficiary } from "@/types/Beneficiary"
import { Member } from "@/types/Member"
import { Membership } from "@/types/Membership"
import { Product } from "@/types/Product"
import { Search } from "@/types/Search"
import BeneficiaryFields from "@/components/BeneficiaryFields.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import statesList from "states-us"
import { cloneDeep } from "lodash"
import moment from "moment"

@Component({
  components: {
    BeneficiaryFields,
    CardTemplate,
    DateField,
    DialogTemplate,
    PhoneNumber
  }
})
export default class NewMember extends Vue {
  @Prop() private departmentId!: string
  @Prop() private member!: Member
  @Prop() private products!: Product[]

  private loading = false
  private demographicValid = false
  private newMember: Member = this.member ? this.member : new Member()
  private page = "demographic"
  private selectedProducts: Product[] = []
  private noProduct: string[] = []
  private showExisting = false
  private showSimilarNames = false
  private nameVerified = true
  private existingMembers: Search[] = []
  private existing = ""
  private clonedMember = cloneDeep(this.member)
  private address = new Address()
  private newMemberOrder = ["demographic", "beneficiary"]
  private editMemberOrder = ["demographic"]
  private beneficiariesToAdd: Record<
    string,
    Beneficiary | Ach | Address | string
  >[] = []
  private panel = [0]
  private noMembership = false

  created() {
    this.resetRecords()
  }

  @Watch("member", { deep: true })
  onMemberChange(value: Member) {
    if (value) {
      this.resetRecords()
    }
  }

  @Watch("noMembership")
  onNoMembershipChange(value: boolean) {
    if (value) {
      this.newMemberOrder = ["demographic"]
    } else {
      this.newMemberOrder = ["demographic", "beneficiary"]
    }
  }

  get membership() {
    if (!this.member) return new Membership()
    const membership = this.$store.getters["memberships/getBy"]({
      member_id: this.member.id,
      end_date: null
    })[0]

    return membership ? membership : new Membership()
  }

  setAddress(beneficiaryCount: number, isContingent: boolean, evt: Event) {
    if (evt) {
      const beneAddType = isContingent
        ? "contingent_address"
        : "primary_address"
      this.beneficiariesToAdd[beneficiaryCount][beneAddType] = cloneDeep(
        this.address
      )
    }
  }

  membershipStartDelta(membership: Membership) {
    const d = new Date(membership.attributes.start_date)
    const today = new Date()

    let months = (d.getUTCFullYear() - today.getUTCFullYear()) * 12
    months -= today.getUTCMonth()
    months += d.getUTCMonth()

    return months
  }

  membershipStartDate(membership: Membership) {
    return moment(membership.attributes.start_date, "YYYY-MM-DD").format("l")
  }

  membershipStartWords(membership: Membership) {
    const months = this.membershipStartDelta(membership)

    switch (true) {
      case months < -1:
        return `Start date of ${Math.abs(
          months
        )} months ago will generate a Supplemental Bill for ${Math.abs(months) +
          1} months`
      case months == -1:
        return "Start date of last month will generate a Supplemental Bill"
      case months == 0:
        return "Start date of this month will generate a Supplemental Bill"
      case months == 1:
        return "Start date of next month will generate a Supplemental Bill if next month has been billed"
      case months > 1:
        return `Start date in ${months} months`
    }
  }

  resetRecords() {
    this.page = "demographic"
    this.newMember = this.member ? cloneDeep(this.member) : new Member()
    this.beneficiariesToAdd = []

    if (!this.member) {
      const today = new Date()

      this.membership.attributes.start_date =
        today.getMonth() + 1 == 12
          ? new Date(today.getFullYear() + 1, 0, 1)
          : new Date(today.getFullYear(), today.getMonth() + 1, 1)

      this.address = new Address()
    } else {
      const address = this.$store.getters["addresses/getById"](
        this.member.attributes.address_id
      )

      this.address = address ? cloneDeep(address) : new Address()
    }

    this.selectedProducts.splice(0, this.selectedProducts.length)
    this.noProduct.splice(0, this.noProduct.length)
  }

  cancelFromSSN() {
    ;(this.$refs.dialog as DialogTemplate).cancel()
    this.showExisting = false
    this.resetRecords()
  }

  cancelFromName() {
    ;(this.$refs.dialog as DialogTemplate).cancel()
    this.showSimilarNames = false
    this.resetRecords()
  }

  productChange() {
    if (this.selectedProducts && this.noProduct) {
      this.noProduct.splice(0, this.noProduct.length)
    }
    this.noMembership =
      this.noProduct.length == 1 && this.noProduct[0] == "None"
  }

  noProductChange() {
    if (this.products && this.products.length == 0) {
      this.noProduct = ["None"]
      this.noMembership =
        this.noProduct.length == 1 && this.noProduct[0] == "None"
      return
    }
    if (this.noProduct) {
      this.selectedProducts.splice(0, this.selectedProducts.length)
    }
    this.noMembership =
      this.noProduct.length == 1 && this.noProduct[0] == "None"
  }

  maskSSN(ssn: string) {
    return ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5, 4)
  }

  get department() {
    return this.$store.getters["departments/getById"](this.departmentId)
  }

  get departmentName() {
    // const department = this.$store.getters["departments/getById"](
    //   this.departmentId
    // )
    return this.department ? this.department.attributes.name : ""
  }

  get subtitle() {
    return this.page == "demographic"
      ? "Demographic Information"
      : this.page == "beneficiary"
      ? "Beneficiary Information"
      : "Select Products For This Member"
  }

  async loadNewRelationships() {
    if (!this.membership?.attributes.start_date) {
      await this.$store.dispatch("memberships/loadBy", {
        member_id: this.member.id,
        end_date: null
      })
      await this.$store.dispatch("addresses/loadBy", {
        id: this.member.attributes.address_id
      })
    }
    this.resetRecords()
  }

  get nextDisabled() {
    let d =
      this.page == "demographic"
        ? !this.demographicValid ||
          (this.newMember.attributes.ssn &&
            this.existing == this.newMember.attributes.ssn) ||
          !this.nameVerified ||
          (!this.selectedProducts.length && !this.noProduct.length)
        : false

    if (this.page == "policy" && this.noMembership) {
      d = false
    }
    return d
  }

  get saveDisabled() {
    return this.page == "demographic"
      ? !this.demographicValid ||
          (this.newMember.attributes.ssn &&
            this.existing == this.newMember.attributes.ssn) ||
          !this.nameVerified
      : false
  }

  movePage(direction: string) {
    if (this.member) {
      let i = this.editMemberOrder.indexOf(this.page)
      i += direction == "next" ? 1 : -1
      this.page = this.editMemberOrder[i]
    } else {
      let i = this.newMemberOrder.indexOf(this.page)
      i += direction == "next" ? 1 : -1
      this.page = this.newMemberOrder[i]
      if (this.page == "beneficiary") {
        for (let p = 0; p < this.selectedProducts.length; p++) {
          this.beneficiariesToAdd.push({
            product_id: this.selectedProducts[p].id,
            primary: new Beneficiary(),
            contingent: new Beneficiary(),
            primary_ach: new Ach(),
            contingent_ach: new Ach(),
            primary_address: new Address(),
            contingent_address: new Address()
          })
        }
      }
    }
  }

  async saveMember() {
    let result
    this.loading = true
    this.membership.attributes.department_id = parseInt(this.departmentId)
    this.newMember.attributes.department_id = parseInt(this.departmentId)
    if (!this.member) {
      if (this.noMembership) {
        if (this.address) {
          const data = await this.$store.dispatch(
            "addresses/create",
            this.address
          )
          this.newMember.attributes.address_id = data.data.data.id
        }
        this.newMember.attributes.department_id = null
        this.newMember.attributes.last_department_id = parseInt(
          this.departmentId
        )
        await this.$store.dispatch("members/create", this.newMember)
      } else {
        result = await this.$store.dispatch("members/build", {
          member: this.newMember.attributes,
          other: {
            address: this.address.attributes,
            membership: this.membership.attributes,
            products: this.selectedProducts,
            beneficiaries: this.beneficiariesToAdd
          }
        })
      }
    } else {
      const address = this.member.attributes.address_id
        ? await this.$store.dispatch("addresses/update", this.address)
        : await this.$store.dispatch("addresses/create", this.address)

      this.newMember.attributes.address_id = address.data.data.id

      result = await this.$store.dispatch("members/update", this.newMember)
    }
    this.newMember = new Member()
    this.noMembership = false

    this.$emit("update-members")

    if (!result) {
      this.loading = false
      return
    }
    this.$store.dispatch("bills/loadBy", {
      billable_id: this.departmentId,
      billable_type: "Department"
    })

    this.resetRecords()
    this.loading = false
    this.page = "demographic"
  }

  async checkExisting() {
    if (this.newMember.attributes.ssn) {
      const result = await this.$store.dispatch("members/doesSSNExist", {
        ssn: this.newMember.attributes.ssn.replace(/[-]/g, "")
      })
      this.showExisting = result
      if (this.showExisting) this.existing = this.newMember.attributes.ssn
    }
  }

  async checkSimilarNames() {
    if (
      this.newMember.attributes.last_name &&
      this.newMember.attributes.last_name.length > 0 &&
      this.newMember.attributes.first_name &&
      this.newMember.attributes.first_name.length > 0
    ) {
      const value =
        "m: " +
        this.newMember.attributes.last_name +
        " " +
        this.newMember.attributes.first_name
      const data = await this.$store.dispatch("search/performSearch", value)

      this.nameVerified = data && data.data.length == 0

      this.existingMembers = data.data
      this.showSimilarNames = data.data.length > 0
    }
  }

  goToItem(item: Search) {
    if (item) {
      this.$router.push(
        {
          name: item.target_model,
          params: { id: item.target_id.toString() }
        },
        () => {
          // success callback
        },
        err => {
          if (!err.message.includes("Avoided redundant navigation")) {
            throw err
          }
        }
      )
    }
  }
  get states() {
    return statesList.filter(x => !x.territory)
  }
}
