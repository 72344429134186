import Model from "./Model"

export class Following extends Model<Following> {
  id: string
  attributes!: {
    followable_type: string
    followable_id: number | string
    user_id: number | string
  }
  static get modelName() {
    return "Following"
  }
}
