import { Ability, AbilityClass } from "@casl/ability"

type Actions =
  | "create"
  | "update"
  | "delete"
  | "manage"
  | "view"
  | "approve"
  | "pay"
  | "add_disbursement"
  | "approve_disbursement"
  | "pay_disbursement"
  | "add_recovery"
  | "read"
  | "view_full_ssn"
  | "generate_number"
type Subjects =
  | "Member"
  | "Department"
  | "Disbursement"
  | "Claim"
  | "Recovery"
  | "User"
  | "all"
  | []

export type AppAbility = Ability<[Actions, Subjects]>
export const AppAbility = Ability as AbilityClass<AppAbility>
