
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimPaymentMethod from "@/components/ClaimPaymentMethod.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import BeneficiaryList from "@/components/BeneficiaryList.vue"
import HelpBubble from "@/components/HelpBubble.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Disbursement as DisbursementType } from "@/types/Disbursement"
import { Claim } from "@/types/Claim"
import { Nacha } from "@/types/Nacha"
import { DisbursementType as DisbursementTypeType } from "@/types/DisbursementType"
import { Beneficiary } from "@/types/Beneficiary"
import { cloneDeep } from "lodash"

@Component({
  components: {
    CardTemplate,
    ClaimPaymentMethod,
    CurrencyField,
    BeneficiaryList,
    DateField,
    DialogTemplate,
    HelpBubble,
    PageTemplate
  }
})
export default class Disbursement extends Vue {
  private beneficiarySelectDisabled = true
  private editRecoverable = ""
  private editOverpayment = ""
  private editType: null | DisbursementTypeType = null
  private editCheck = ""

  showRecoverableDialog() {
    ;(this.$refs.recoverableDialog as DialogTemplate).open(true)
  }

  showOverpaymentDialog() {
    ;(this.$refs.overpaymentDialog as DialogTemplate).open(true)
  }

  showTypeDialog() {
    ;(this.$refs.typeDialog as DialogTemplate).open(true)
  }

  showCheckDialog() {
    console.log("booty")
    ;(this.$refs.checkDialog as DialogTemplate).open(true)
  }

  updateRecoverable() {
    const editDisbursement = cloneDeep(this.disbursement)
    editDisbursement.attributes.recoverable = this.editRecoverable
    this.$store.dispatch("disbursements/update", editDisbursement)
  }

  updateOverpayment() {
    const editDisbursement = cloneDeep(this.disbursement)
    editDisbursement.attributes.overpayment = this.editOverpayment
    this.$store.dispatch("disbursements/update", editDisbursement)
  }

  updateCheck() {
    const editDisbursement = cloneDeep(this.disbursement)
    editDisbursement.attributes.check_number = this.editCheck
    this.$store.dispatch("disbursements/update", editDisbursement)
  }

  updateType() {
    const editDisbursement = cloneDeep(this.disbursement)
    if (this.editType.id == undefined) {
      editDisbursement.attributes.disbursement_type_id = null
    } else {
      editDisbursement.attributes.disbursement_type_id = this.editType.id
    }
    this.$store.dispatch("disbursements/update", editDisbursement)
  }

  get id() {
    return this.$route.params.id
  }

  get disbursement() {
    return this.$store.getters["disbursements/getById"](this.id)
  }

  get claim() {
    return this.disbursement?.attributes?.claim_id
      ? this.$store.getters["claims/getById"](
          this.disbursement.attributes.claim_id
        )
      : new Claim()
  }

  get claimAdmin() {
    return this.claim?.attributes?.admin_id
      ? this.$store.getters["users/getById"](this.claim.attributes.admin_id)
      : null
  }

  get claimAdminName() {
    const a = this.claimAdmin

    if (a) {
      return a.name
    } else {
      return "No Claim Administrator Set"
    }
  }

  get disbursementType() {
    return this.disbursement?.attributes?.disbursement_type_id
      ? this.$store.getters["disbursementTypes/getById"](
          this.disbursement.attributes.disbursement_type_id
        )
      : new DisbursementTypeType()
  }

  get nacha() {
    return this.disbursement?.attributes?.nacha_id
      ? this.$store.getters["nachas/getById"](
          this.disbursement.attributes.nacha_id
        )
      : new Nacha()
  }

  get beneficiary() {
    const beneficiaryId = this.disbursement?.attributes?.beneficiary_id

    return beneficiaryId
      ? this.$store.getters["beneficiaries/getById"](beneficiaryId)
      : null
  }

  get beneficiaryAddress() {
    const beneAddId = this.beneficiary?.attributes?.address_id
    return beneAddId
      ? this.$store.getters["addresses/getById"](beneAddId)
      : null
  }

  get policy() {
    const policyId = this.claim?.attributes?.policy_id
    return policyId ? this.$store.getters["policies/getById"](policyId) : null
  }

  get member() {
    const memberId = this.policy?.attributes?.member_id
    return memberId ? this.$store.getters["members/getById"](memberId) : null
  }

  get beneficiaryAch() {
    const achId = this.beneficiary?.attributes?.ach_id
    return achId ? this.$store.getters["aches/getById"](achId) : null
  }

  get hasBeneficiaryWithACH() {
    return this.beneficiary?.attributes?.ach_id
  }

  get policyBeneficiaries() {
    const policyId = this.policy?.id

    const benes = policyId
      ? this.$store.getters["beneficiaries/getBy"]({
          policy_id: policyId
        })
      : []

    const g = benes.filter(
      (el: { id: string; attributes: { archived_on: Date } }) => {
        return (
          el.attributes.archived_on == null ||
          el.id == this.disbursement.attributes.beneficiary_id?.toString()
        )
      }
    )

    return g
  }

  get disbursementTypes() {
    if (this.claim.id) {
      const dts = this.$store.getters["disbursementTypes/getBy"]({
        product_type: this.claim.attributes.product_type
      })

      const noneType = new DisbursementTypeType()
      noneType.attributes.name = "NONE"
      dts.push(noneType)

      return dts
    } else {
      return []
    }
  }

  selectBeneficiary(beneficiary: Beneficiary | null) {
    const editDisbursement = cloneDeep(this.disbursement)
    editDisbursement.attributes.beneficiary_id = beneficiary
      ? beneficiary.id
      : null
    this.$store.dispatch("disbursements/update", editDisbursement)
    this.beneficiarySelectDisabled = true
  }

  beneficiaryTitleText(beneficiary: Beneficiary) {
    if (
      beneficiary?.attributes?.first_name ||
      beneficiary?.attributes?.last_name
    ) {
      return `${beneficiary.attributes.first_name} ${beneficiary.attributes.last_name}`
    } else {
      return `${beneficiary.attributes.trust_name}`
    }
  }

  beneficiarySubTitleText(beneficiary: Beneficiary) {
    const archiveText =
      beneficiary.attributes.archived_on != null
        ? " **not available for future disbursements"
        : ""
    let s = ""
    if (beneficiary?.attributes?.trust_name) {
      s = beneficiary.attributes.trustee_names
    } else {
      s = beneficiary.attributes.relationship
        ? beneficiary.attributes.relationship
        : ""
    }

    s += ` (${
      beneficiary.attributes.percent ? beneficiary.attributes.percent : "100"
    }%)${archiveText}`
    return s
  }

  created() {
    this.$store
      .dispatch("disbursements/load", {
        id: this.id,
        include: [
          "claim",
          "claim.policy.beneficiaries",
          "claim.policy.member",
          "disbursement_type",
          "nacha",
          "beneficiary",
          "beneficiary.ach"
        ]
      })
      .then(data => {
        this.editRecoverable = data.data.data.attributes.recoverable
        this.editCheck = data.data.data.attributes.check_number
        if (data.data.data.attributes.disbursement_type_id) {
          this.editType = this.disbursementType
        }
      })
    this.$store.dispatch("disbursementTypes/loadAll")
  }

  @Watch("claim")
  onClaimChange(value: Claim) {
    if (value) {
      this.$store.dispatch("aches/loadBy", {
        owner_id: value.attributes.member_id,
        owner_type: "Member"
      })

      if (value.attributes.admin_id) {
        this.$store.dispatch("users/load", {
          id: value.attributes.admin_id
        })
      }
    }
  }

  @Watch("beneficiary")
  onBeneficiaryChange(value: Beneficiary) {
    if (value) {
      this.$store.dispatch("beneficiaries/load", {
        id: value.id,
        include: ["ach"]
      })
    }
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    this.$store
      .dispatch("disbursements/load", {
        id: to.params.id,
        include: [
          "claim",
          "claim.policy.beneficiaries",
          "claim.policy.member",
          "disbursement_type",
          "nacha",
          "beneficiary",
          "beneficiary.ach"
        ]
      })
      .then(data => {
        this.editRecoverable = data.data.data.attributes.recoverable
        if (data.data.data.attributes.disbursement_type_id) {
          this.editType = this.disbursementType
        }
      })

    next()
  }

  calculateNonRecoverable(item: DisbursementType) {
    const a = Number(item.attributes?.amount)
    const b = Number(item.attributes?.recoverable)
    const s = (a - b).toString()

    return s
  }
}
