var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CardTemplate',{attrs:{"title":"Department History","icon":"mdi-briefcase-clock-outline","iconColor":"secondary","id":"js-membership-list"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('span',[(_vm.noActiveMemberships && _vm.$can('create', 'Membership'))?_c('ModifyMembership',{attrs:{"memberId":_vm.memberId},on:{"member:reload":function($event){return _vm.$emit('member:reload')}}}):_vm._e()],1)]},proxy:true}])},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-simple-table',{attrs:{"id":"js-memberships"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left overline"},[_vm._v("Department")]),_c('th',{staticClass:"text-left overline"},[_vm._v("hire")]),_c('th',{staticClass:"text-left overline"},[_vm._v("from")]),_c('th',{staticClass:"text-left overline"},[_vm._v("to")]),_c('th')])]),_c('tbody',_vm._l((_vm.sortedMemberships),function(membership){return _c('tr',{key:membership.id,class:_vm.rowClass(membership)},[_c('td',[(membership.attributes.department_id)?_c('router-link',{attrs:{"id":"js-department-link","to":{
                  name: 'Department',
                  params: { id: membership.attributes.department_id }
                }}},[_vm._v(" "+_vm._s(_vm.getDepartmentName(membership.attributes.department_id)))]):_vm._e()],1),_c('td',{attrs:{"id":"js-hire-date"}},[(
                  membership.attributes.hire_date && membership.id != _vm.editId
                )?_c('DateField',{staticClass:"hire-date",attrs:{"display":true},model:{value:(membership.attributes.hire_date),callback:function ($$v) {_vm.$set(membership.attributes, "hire_date", $$v)},expression:"membership.attributes.hire_date"}}):_vm._e(),(membership.id == _vm.editId)?_c('DateField',{attrs:{"id":"js-edit-hire-date","dense":""},model:{value:(_vm.editMembership.attributes.hire_date),callback:function ($$v) {_vm.$set(_vm.editMembership.attributes, "hire_date", $$v)},expression:"editMembership.attributes.hire_date"}}):_vm._e()],1),_c('td',{attrs:{"id":"js-start-date"}},[(
                  membership.attributes.start_date && membership.id != _vm.editId
                )?_c('DateField',{attrs:{"display":true},model:{value:(membership.attributes.start_date),callback:function ($$v) {_vm.$set(membership.attributes, "start_date", $$v)},expression:"membership.attributes.start_date"}}):(membership.id == _vm.editId)?_c('DateField',{attrs:{"id":"js-edit-start-date","dense":""},model:{value:(_vm.editMembership.attributes.start_date),callback:function ($$v) {_vm.$set(_vm.editMembership.attributes, "start_date", $$v)},expression:"editMembership.attributes.start_date"}}):_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("N/A")])]}}],null,true)},[_c('span',[_vm._v("Initial start date not available")])])],1)],1),_c('td',{staticClass:"text-center"},[(
                  !membership.attributes.end_date &&
                    _vm.$can('update', 'Membership') &&
                    _vm.$can('create', 'Policy') &&
                    _vm.$can('update', 'Policy')
                )?_c('ModifyMembership',{attrs:{"membership":membership,"memberId":_vm.memberId,"ending":true}}):(membership.id != _vm.editId)?_c('DateField',{attrs:{"display":true},model:{value:(membership.attributes.end_date),callback:function ($$v) {_vm.$set(membership.attributes, "end_date", $$v)},expression:"membership.attributes.end_date"}}):(membership.id == _vm.editId)?_c('DateField',{attrs:{"id":"js-edit-end-date","dense":""},model:{value:(_vm.editMembership.attributes.end_date),callback:function ($$v) {_vm.$set(_vm.editMembership.attributes, "end_date", $$v)},expression:"editMembership.attributes.end_date"}}):_vm._e()],1),_c('td',[(
                  _vm.$can('update', 'Membership') &&
                    !membership.attributes.reversed
                )?_c('span',{staticClass:"js-membership-edit"},[(membership.id == _vm.editId)?_c('span',[_c('v-icon',{attrs:{"id":"js-save-hire-icon","title":"Save","small":""},on:{"click":_vm.save}},[_vm._v(" mdi-content-save ")]),_c('v-icon',{attrs:{"id":"js-cancel-edit-icon","title":"Cancel","small":""},on:{"click":function($event){_vm.editId = null}}},[_vm._v(" mdi-close ")])],1):_c('v-icon',{staticClass:"pr-2",attrs:{"id":"js-edit-hire-icon","title":"Edit","small":""},on:{"click":function($event){return _vm.openEdit(membership)}}},[_vm._v(" mdi-pencil ")]),(
                    !membership.attributes.end_date &&
                      _vm.$can('reverse', 'Membership')
                  )?_c('ReverseMembership',{attrs:{"membership":membership}}):_vm._e()],1):_vm._e()])])}),0)]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }