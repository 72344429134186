
import { Component, Prop, Vue } from "vue-property-decorator"
import { Product } from "@/types/Product"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: { DialogTemplate }
})
export default class ProductsListDialog extends Vue {
  @Prop() private products!: Array<Product>
  @Prop() private allProducts!: Array<Product>
  private localState!: Array<string>

  get items() {
    const products = this.allProducts.map(el => {
      return { text: el.attributes.description, value: el.id }
    })
    return products
  }

  get productsModel() {
    if (!this.localState || this.localState.length == 0) {
      this.localState = this.products.map((el: Product) => el.id)
    }
    return this.localState
  }

  set productsModel(products) {
    this.localState = products
  }

  save() {
    this.$emit("save-products", this.localState)
  }

  cancel() {
    this.localState = this.products.map((el: Product) => el.id)
  }
}
