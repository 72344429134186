
import { Component, Vue } from "vue-property-decorator"
import PageTemplate from "@/components/PageTemplateList.vue"
import AgePremiumHistory from "@/components/AgePremiumHistory.vue"
import AgePremiumUpdate from "@/components/AgePremiumUpdate.vue"

@Component({
  components: {
    PageTemplate,
    AgePremiumHistory,
    AgePremiumUpdate
  }
})
export default class AgePremiums extends Vue {}
