
import { Component, Prop, Vue } from "vue-property-decorator"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    DialogTemplate
  }
})
export default class ReinstatePolicy extends Vue {
  @Prop() private policyId!: string

  open() {
    const form = this.$refs["reinstateDialog"] as DialogTemplate
    form.open(true)
  }

  get policy() {
    return this.$store.getters["policies/getById"](this.policyId)
  }

  reinstate() {
    this.$store.dispatch("policies/reinstatePolicy", this.policyId)
  }
}
