
import { Component, Vue } from "vue-property-decorator"
import PageTemplateList from "@/components/PageTemplateList.vue"
import ReportsList from "@/components/ReportsList.vue"

@Component({
  components: {
    PageTemplateList,
    ReportsList
  }
})
export default class Reports extends Vue {}
