
import { Component, Prop, Vue } from "vue-property-decorator"
import { AdvancedSearch } from "@/types/AdvancedSearch"
import AdvancedSearchQueryDialog from "@/components/AdvancedSearchQueryDialog.vue"
import AdvancedSearchResults from "@/components/AdvancedSearchResults.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import DateHelper from "@/components/utils/DateHelper"

import axios from "axios"

@Component({
  components: {
    AdvancedSearchQueryDialog,
    AdvancedSearchResults,
    ConfirmationTemplate,
    PagedDataTable
  }
})
export default class AdvancedSearchList extends Vue {
  @Prop() private advancedSearches: AdvancedSearch[]
  @Prop() private length: number

  private options = {
    sortBy: "attributes.date",
    sortDesc: true,
    mustSort: true
  }

  private loading = false
  private startingPerPage = 5
  private perPage = 5
  private currentPage = 1
  private showResults = false
  private resultsData: any[] = [] //eslint-disable-line @typescript-eslint/no-explicit-any
  private resultsHeaders: any[] = [] //eslint-disable-line @typescript-eslint/no-explicit-any
  private selectedId: number | null = null
  private showConfirm = false

  get headers() {
    return [
      {
        text: "NAME",
        value: "attributes.name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "TAGS",
        value: "attributes.tags",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "DATE",
        value: "attributes.updated_at",
        sortable: true,
        class: "text-left caption",
        sort: DateHelper.sortByDate,
        width: "200px"
      },
      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
        class: "text-left caption",
        width: "188px"
      }
    ]
  }

  loadPage(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    this.$emit("load-page", details)
  }

  async saveQuery({
    search,
    tags
  }: {
    search: AdvancedSearch
    tags: string[]
  }) {
    await this.$store.dispatch("advancedSearches/update", search)
    await this.syncTags(search.id, tags)
    this.$emit("load-page", {
      page: this.currentPage,
      itemsPerPage: this.perPage
    })
  }

  async deleteQuery(id: number) {
    await this.$store.dispatch("advancedSearches/delete", id)
    this.$emit("load-page", {
      page: this.currentPage,
      itemsPerPage: this.perPage
    })
  }

  async runQuery(id: string) {
    try {
      const data = await axios.get(
        `/advanced_searches/${id}/run`,
        this.$store.getters["config/getAPIConfig"]
      )

      if (data.data.length > 0) {
        this.resultsHeaders = Object.keys(data.data[0]).map(el => {
          return { text: `${el}`, value: `${el}` }
        })
      }

      this.resultsData = data.data
      this.showResults = true
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const axiosError = error as { response: { data: { message: unknown } } }

        this.$store.dispatch(
          "alerts/addError",
          axiosError.response.data.message,
          {
            root: true
          }
        )
      }
    }
  }

  async syncTags(id: string, tags: string[]) {
    await this.$store.dispatch("advancedSearches/syncTags", {
      id: id,
      tags: tags
    })
  }

  formattedDate(date: Date) {
    return date.toLocaleString("en-US")
  }
}
