import Model from "./Model"

export class Attorney extends Model<Attorney> {
  id: string
  attributes!: {
    name: string
    firm: string
    address_id: number
    phone: string
    fax: string
    email: string
  }
  static get modelName() {
    return "Attorney"
  }
}
