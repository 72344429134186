
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Signature } from "@/types/Signature"
import { cloneDeep } from "lodash"
import axios from "axios"

@Component({
  components: { CardTemplate, DialogTemplate }
})
export default class Signatures extends Vue {
  @Prop() private signatures!: Signature[]

  private newSignature = new Signature()
  private editSignature = new Signature()
  private previewSignature = new Signature()
  private showEditDialog = false
  private showPreviewDialog = false
  private file: File = null

  async save() {
    const sig = await this.$store.dispatch(
      "signatures/create",
      this.newSignature
    )

    const formData = new FormData()
    formData.append("image", this.file)

    try {
      await axios.put(
        `signatures/${sig.data.data.id}/add_image`,
        formData,
        this.$store.getters["config/getAPIConfig"]
      )
    } catch (e) {
      console.log(e)
    }
    this.newSignature = new Signature()
    this.$emit("signature-added")
  }

  async doEdit() {
    await this.$store.dispatch("signatures/update", this.editSignature)

    const formData = new FormData()
    formData.append("image", this.file)

    this.showEditDialog = false

    await axios.put(
      `signatures/${this.editSignature.id}/add_image`,
      formData,
      this.$store.getters["config/getAPIConfig"]
    )

    this.$emit("signature-added")
  }

  initiateEdit(signature: Signature) {
    this.editSignature = cloneDeep(signature)
    this.showEditDialog = true
  }

  initiatePreview(signature: Signature) {
    this.previewSignature = signature
    this.showPreviewDialog = true
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }
}
