
import { Component, Vue } from "vue-property-decorator"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PayrollAdditionsDepartment from "@/components/PayrollAdditionsDepartment.vue"
import CardTemplate from "@/components/CardTemplate.vue"

@Component({
  components: {
    CardTemplate,
    PayrollAdditionsDepartment,
    PagedDataTable,
    PageTemplate
  }
})
export default class Attorneys extends Vue {
  private data = {}
  private departments: string[] = []
  private showLTD = false
  private showLTC = true
  private showEIP = true

  async created() {
    const today = new Date()
    const nextMonth =
      today.getMonth() + 1 == 12
        ? new Date(today.getFullYear() + 1, 0, 1)
        : new Date(today.getFullYear(), today.getMonth() + 1, 1)

    const result = await this.$store.dispatch(
      "policies/fetchPoliciesPayrollAdditions",
      nextMonth.toISOString().split("T")[0]
    )

    if (result && result.status == 200) {
      this.data = result.data
      this.departments = []
      for (const key in this.data) {
        // skip loop if the property is from prototype
        if (!Object.prototype.hasOwnProperty.call(this.data, key)) continue

        this.departments.push(key)
      }
    }

    console.log(this.data)
    console.log(this.departments)
  }

  // get headers() {
  //   return [
  //     {
  //       text: "NAME",
  //       value: "attributes.name",
  //       class: "text-left caption"
  //     },
  //     {
  //       text: "FIRM",
  //       value: "attributes.firm",
  //       class: "text-left caption"
  //     }
  //   ]
  // }
}
