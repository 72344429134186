import Model from "./Model"

export class AgePremium extends Model<AgePremium> {
  constructor(agePremium?: AgePremium) {
    super(agePremium as AgePremium)
  }
  id: string
  attributes!: {
    day_rate: number
    age: number
    start_date: Date
    premium_25_year: number
    premium_30_year: number
    premium_35_year: number
    premium_40_year: number
    premium_45_year: number
    premium_lifetime: number
  }
  static get modelName() {
    return "AgePremium"
  }
}
