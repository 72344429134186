var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$can('create', 'Bill'))?_c('DialogTemplate',{attrs:{"id":"js-add-supplemental","iconSize":"small","icon":"mdi-plus","title":"Add LTD Supplemental","requireConfirm":"true","confirmBtnLabel":"add"},on:{"save":_vm.addSupplemental},scopedSlots:_vm._u([{key:"confirmation-text",fn:function(){return [_c('div',{staticClass:"display-1 my-2"},[_vm._v("Supplement Bill Creation")]),_c('v-alert',{attrs:{"type":_vm.supplementalOptions.isAdd ? 'success' : 'error',"dense":""}},[_vm._v(" Coverage to be "+_vm._s(_vm.supplementalOptions.isAdd ? "ADDED" : "REDUCED")+" ")]),_c('p',{staticClass:"text-center title"},[_vm._v(" Dates: "),_c('DateField',{attrs:{"display":true,"value":_vm.supplementalOptions.start_date}}),_vm._v(" to "),_c('DateField',{attrs:{"display":true,"value":_vm.supplementalOptions.end_date}})],1),_c('p',{staticClass:"text-center display-3 red--text"},[_vm._v(" months: "+_vm._s(_vm.monthsDelta( _vm.supplementalOptions.start_date, _vm.supplementalOptions.end_date ))+" ")]),(
        _vm.monthsDelta(
          _vm.supplementalOptions.start_date,
          _vm.supplementalOptions.end_date
        ) > 100
      )?_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Really? "+_vm._s(_vm.monthsDelta( _vm.supplementalOptions.start_date, _vm.supplementalOptions.end_date ))+" months? That's a long time. Could one of the years be incorrect?")]):(
        _vm.monthsDelta(
          _vm.supplementalOptions.start_date,
          _vm.supplementalOptions.end_date
        ) > 4
      )?_c('v-alert',{attrs:{"type":"warn"}},[_vm._v("Are you sure you have entered the dates correctly?")]):_vm._e(),_c('p',{staticClass:"blue--text text-center subtitle-1"},[_vm._v(" memo: "+_vm._s(_vm.supplementalOptions.memo)+" ")])]},proxy:true}],null,false,3292909279)},[_c('v-select',{attrs:{"id":"js-policy-select","items":_vm.policies,"item-text":_vm.policyDisplayText,"item-value":"id","rules":[v => !!v || 'Policy is required']},model:{value:(_vm.supplementalOptions.policyId),callback:function ($$v) {_vm.$set(_vm.supplementalOptions, "policyId", $$v)},expression:"supplementalOptions.policyId"}}),_c('v-row',[_c('v-col',[_c('DateField',{attrs:{"id":"js-supplemental-start-date","label":"start date","required":true},model:{value:(_vm.supplementalOptions.start_date),callback:function ($$v) {_vm.$set(_vm.supplementalOptions, "start_date", $$v)},expression:"supplementalOptions.start_date"}})],1),_c('v-col',[_c('DateField',{attrs:{"id":"js-supplemental-end-date","label":"end date","required":true},model:{value:(_vm.supplementalOptions.end_date),callback:function ($$v) {_vm.$set(_vm.supplementalOptions, "end_date", $$v)},expression:"supplementalOptions.end_date"}})],1)],1),_c('v-radio-group',{attrs:{"mandatory":""},model:{value:(_vm.supplementalOptions.isAdd),callback:function ($$v) {_vm.$set(_vm.supplementalOptions, "isAdd", $$v)},expression:"supplementalOptions.isAdd"}},[_c('v-radio',{attrs:{"label":"Add Coverage","value":true,"id":"js-supplemental-add-true"}}),_c('v-radio',{attrs:{"label":"Remove Coverage","id":"js-supplemental-add-false","value":false}})],1),_c('v-text-field',{attrs:{"id":"js-supplemental-memo","label":"memo"},model:{value:(_vm.supplementalOptions.memo),callback:function ($$v) {_vm.$set(_vm.supplementalOptions, "memo", $$v)},expression:"supplementalOptions.memo"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }