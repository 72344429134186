
import { Component, Vue, Prop } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CurrencyField,
    DateField
  }
})
export default class PaymentsAsDeposit extends Vue {
  @Prop() private stats!: { summary: {} }

  get plans() {
    return Object.keys(this.stats).filter(el => el != "summary")
  }

  get summary() {
    return this.stats.summary
  }

  displayMonth(date: string) {
    if (date == null) {
      return ""
    } else {
      const d = new Date(date)
      d.setUTCHours(12)

      return `${this.monthNames[d.getMonth()]}, ${d.getFullYear()}`
    }
  }

  private monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  ]
}
