
import { Address as AddressType } from "@/types/Address"
import { Component, Prop, Vue } from "vue-property-decorator"
import statesList from "states-us"

@Component({
  components: {}
})
export default class AddressDepartment extends Vue {
  @Prop() private address!: AddressType
  @Prop() private edit!: false

  get lineOne() {
    let s = this.address.attributes.street ? this.address.attributes.street : ""
    if (
      this.address.attributes.apartment &&
      this.address.attributes.apartment.length > 0
    ) {
      s += this.address.attributes.apartment
        ? s.length
          ? ", " + this.address.attributes.apartment
          : this.address.attributes.apartment
        : ""
    }
    return s
  }
  get lineTwo() {
    let s = this.address.attributes.city ? this.address.attributes.city : ""
    s += this.address.attributes.state
      ? s.length
        ? ", " + this.address.attributes.state
        : this.address.attributes.state
      : ""
    s += this.address.attributes.zip
      ? s.length
        ? " " + this.address.attributes.zip
        : this.address.attributes.zip
      : ""
    return s
  }

  get states() {
    return statesList.filter(x => !x.territory)
  }
}
