
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component
export default class PhoneNumber extends Vue {
  @Prop() private display!: boolean
  @Prop() private value!: string
  @Prop() private label!: string
  @Prop() private dense!: boolean
  @Prop() private required!: boolean

  private localValue = this.value

  @Watch("value")
  onValueChange(value: string) {
    this.localValue = value
  }

  updateValue() {
    this.$emit("input", this.localValue)
  }

  format(input: string) {
    const match = input.match(/^(\d{3})\D*(\d{3})\D*(\d{4})/)
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : input
  }

  get phone() {
    if (!this.localValue) {
      return ""
    }
    const split = this.localValue.split("::")
    return this.format(split[0])
  }

  set phone(value) {
    const cleaned = value.replace(/\D/g, "")
    const extension = this.extension ? `::${this.extension}` : ""
    this.localValue = `${cleaned}${extension}`
  }

  get extension() {
    if (!this.localValue) {
      return ""
    }

    const split = this.localValue.split("::")

    return split.length > 1 ? split[1] : ""
  }

  set extension(value) {
    const split = this.localValue.split("::")

    this.localValue = value ? `${split[0]}::${value}` : split[0]
  }
}
