
import { Component, Vue } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { Enroller as EnrollerType } from "@/types/Enroller"

class EnrollerPolicy {
  policy_id: number
  member_first_name: string
  member_last_name: string
  start_date: string
  end_date: string
}

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate,
    PageTemplate,
    PhoneNumber
  }
})
export default class Enroller extends Vue {
  private editEnroller = new EnrollerType()
  private loading = false
  private policies: EnrollerPolicy[] = []
  private pdfStartDate: null | Date = null
  private pdfEndDate: null | Date = null

  get id() {
    return this.$route.params.id
  }

  get enroller() {
    return this.$store.getters["enrollers/getById"](this.id)
  }

  save() {
    this.$store.dispatch("enrollers/update", this.editEnroller)
  }

  cancel() {
    this.editEnroller = cloneDeep(this.enroller)
  }

  async created() {
    await this.$store.dispatch("enrollers/load", {
      id: this.id
    })

    this.editEnroller = cloneDeep(this.enroller)

    this.fetchPolicies(this.id)
  }

  async fetchPolicies(enrollerId: string | number) {
    this.loading = true
    let page = 1
    this.policies = []

    do {
      const data = await this.$store.dispatch("enrollers/fetchPolicies", {
        id: enrollerId,
        page: page
      })
      this.policies.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)

    this.loading = false
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    await this.$store.dispatch("enrollers/load", {
      id: to.params.id
    })
    this.editEnroller = cloneDeep(this.enroller)
    if (to.params.id) {
      this.fetchPolicies(to.params.id)
    }

    next()
  }

  initiatePdf() {
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  async getPoliciesPdf() {
    const response = await this.$store.dispatch("enrollers/fetchReport", {
      EnrollerId: this.id,
      startDate: this.pdfStartDate,
      endDate: this.pdfEndDate
    })

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `${this.enroller.attributes.first_name}_${this.enroller.attributes.last_name}.pdf`
    )
    document.body.appendChild(fURL)

    fURL.click()
  }

  get headers() {
    return [
      {
        text: "last",
        value: "last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "first",
        value: "first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "effective",
        value: "start_date",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "cancel",
        value: "end_date",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "polnum",
        value: "number",
        class: "text-left caption",
        sortable: true,
        width: 275
      },
      {
        text: "type",
        value: "product_type",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "product",
        value: "product",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "department",
        value: "department",
        class: "text-left caption",
        sortable: true
      }
    ]
  }
}
