
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import { Search as SearchItem } from "@/types/Search"

@Component({
  components: {
    CardTemplate,
    DateField
  }
})
export default class RecordSearch extends Vue {
  @Prop() private recordType!: string
  @Prop() private filter!: string
  @Prop() private icon!: string
  @Prop() private label!: string
  @Prop() private previousSearch!: string

  isLoading = false
  search: string = null
  searchModel: SearchItem = null
  searchResults: SearchItem[] = []

  async created() {
    await this.searchTermChange(this.previousSearch)
    this.searchModel = this.searchResults[0]
  }

  @Watch("search")
  async searchTermChange(value: string) {
    if (value) {
      value = `${this.recordType[0]}:` + value

      const split = value.split(":")
      if (split.length > 1 && split[1].trim().length >= 3) {
        this.isLoading = true

        const data = await this.$store.dispatch("search/performSearch", {
          q: value,
          filter: this.filter
        })

        this.searchResults = data
          ? data.data.sort((a: SearchItem, b: SearchItem) => {
              return a.title > b.title ? 1 : -1
            })
          : []
        this.isLoading = false
      }
    }
  }

  mounted() {
    document.addEventListener("keydown", this.keyListener)
  }

  beforeDestroy() {
    document.removeEventListener("keydown", this.keyListener)
  }

  keyListener(e: KeyboardEvent) {
    if (e.key === "b" && (e.ctrlKey || e.metaKey)) {
      const bar: any = this.$refs.searchBar // eslint-disable-line @typescript-eslint/no-explicit-any
      bar.$el.querySelector("input").focus()
    }
  }

  selectRecord() {
    if (this.searchModel == undefined) this.$emit("selectRecord", "")
    else this.$emit("selectRecord", this.searchModel)
  }

  clear() {
    this.$store.commit("search/clearSearchResults")
  }
}
