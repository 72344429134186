import State from "@/types/State"
export default function(
  state: State<any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  data: { id: number; updated_at: string; doIt?: boolean }
) {
  const found = state.all.find(el => el.id == data.id)
  if (found) {
    const d1 = new Date(data.updated_at)
    const d2 = new Date(found.attributes.updated_at)

    const dif = d1.getTime() - d2.getTime()
    const difSeconds = Math.abs(dif / 1000)
    if (difSeconds > 15 || data.doIt || !found.attributes.updated_at) {
      found.dirty = true
    }
  }
}
