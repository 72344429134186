import { pascalCase } from "change-case"
import { snakeCase } from "lodash"
import pluralize from "pluralize"

const directories = require.context("@/types", false)
const types: { [key: string]: any } = {} // eslint-disable-line @typescript-eslint/no-explicit-any
const ignore = [
  "Alert",
  "Authentication",
  "Config",
  "Model",
  "RootState",
  "State",
  "Search"
]
directories.keys().forEach(type => {
  if (
    type.match(/.ts$/g) ||
    ignore.some(el => type.match(el)) ||
    !directories(type)[pascalCase(type)]
  ) {
    return
  }
  types[pluralize(snakeCase(type))] = directories(type)[pascalCase(type)]
})

export default types
