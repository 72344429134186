
import { Component, Vue } from "vue-property-decorator"
import BillingLabels from "@/components/BillingLabels.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimFormDefinitionsList from "@/components/ClaimFormDefinitionsList.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import Employers from "@/components/Employers.vue"
import MembershipChanges from "@/components/MembershipChanges.vue"
import MergeDepartments from "@/components/MergeDepartments.vue"
import NewDepartment from "@/components/NewDepartment.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import ProductsList from "@/components/ProductsList.vue"
import SeparatorPages from "@/components/SeparatorPages.vue"
import Signatures from "@/components/Signatures.vue"
import { SortOrder } from "@/store/utils/genericGetters"

@Component({
  components: {
    BillingLabels,
    CardTemplate,
    ClaimFormDefinitionsList,
    DialogTemplate,
    Employers,
    MembershipChanges,
    MergeDepartments,
    NewDepartment,
    PageTemplate,
    ProductsList,
    SeparatorPages,
    Signatures
  }
})
export default class Admin extends Vue {
  get products() {
    const products = this.$store.getters["products/getAll"]
    return products ? products : []
  }

  get claimFormDefinitions() {
    return this.$store.getters["claimFormDefinitions/getAll"]
  }

  get employers() {
    return this.$store.getters["employers/getBy"]({}, "name", SortOrder.asc)
  }

  get signatures() {
    return this.$store.getters["signatures/getBy"]({}, "name", SortOrder.asc)
  }

  get attorneys() {
    return this.$store.getters["attorneys/getAll"]
  }

  signatureAdded() {
    this.$store.dispatch("signatures/loadAll")
  }

  mounted() {
    this.$store.dispatch("claimFormDefinitions/loadAll")
    this.$store.dispatch("products/loadAll")
    this.$store.dispatch("employers/loadAll", ["attorney"])
    this.$store.dispatch("signatures/loadAll")
  }
}
