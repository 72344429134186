
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import PageTemplate from "@/components/PageTemplate.vue"
// import DateHelper from "@/components/utils/DateHelper"
import moment from "moment"

class CustomPolicy {
  policy_id: number
  amount: string
  member_first_name: string
  member_last_name: string
  member_email: string
  policy_number: string
  pemium: string
  last_balanced_at: string
  days_delinquent: number
  billing_frequency: string
  balance: string
  billing_exception: boolean
  payment_method: string
}

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    PageTemplate
  }
})
export default class CheckPayments extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selected: any[] = []
  private policies: CustomPolicy[] = []
  private loading = false
  private account = ""
  private cancelled = false
  private working = false
  private workingCompleteMessage = ""

  async fetchDelinquent() {
    this.policies = []
    this.selected = []

    if (this.account == "") {
      return
    }

    this.loading = true
    let page = 1

    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    const classification = accountParts.length > 1 ? accountParts[1] : null

    do {
      const actionString = this.cancelled
        ? "policies/fetchCancelled"
        : "policies/fetchDelinquent"

      const data = await this.$store.dispatch(actionString, {
        page: page,
        productType: prodType,
        classification: classification
      })
      this.policies.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)

    this.loading = false
  }

  async makeCSV() {
    let csv = ""

    csv = this.selected
      .map(el => {
        return [
          el.member_last_name,
          el.member_first_name,
          el.policy_number,
          el.billing_exception ? "Y" : "",
          this.paymentMethod(el.payment_method),
          el.last_balanced_at
            ? moment(el.last_balanced_at).format("M/D/YYYY")
            : "",
          el.days_delinquent,
          el.billing_frequency,
          el.balance,
          el.premium
        ].join(",")
      })
      .join("\n")

    csv = [
      "last",
      "first",
      "polnum",
      "exception",
      "method",
      "paid up",
      "days",
      "freq",
      "balance",
      "premium"
    ]
      .join(",")
      .concat("\n")
      .concat(csv)

    const fileName = "delinquent.csv"

    const anchor = document.createElement("a")
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    anchor.target = "_blank"
    anchor.download = fileName
    anchor.click()
  }

  async makeNotification(notice: string) {
    this.working = true

    const policyIds = this.selected.map(el => el.policy_id)

    const payload = {
      notice_type: notice,
      policyIds: policyIds.join(",")
    }

    const response = await this.$store.dispatch(
      "policies/delinquentNotices",
      payload
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", `delinquent-${notice}.pdf`)
    document.body.appendChild(fURL)

    fURL.click()
    this.working = false
  }

  @Watch("account")
  onAccountChange() {
    this.fetchDelinquent()
  }

  @Watch("cancelled")
  onCancelledChange() {
    this.fetchDelinquent()
  }

  paymentMethod(s: string) {
    if (s == undefined || s == "") {
      return "check"
    } else if (s == "CreditCard") {
      return "CC"
    } else {
      return s.toUpperCase()
    }
  }

  billingFrequency(s: string) {
    if (s) {
      const parts = s.split("-").map((el: string) => el[0].toUpperCase())
      return parts.join("")
    } else {
      return s
    }
  }

  itemRowBackground(days: number) {
    if (days > 65) {
      return "crazy-old"
    } else if (days > 42) {
      return "really-old"
    } else {
      return "sorta-old"
    }
  }

  goToPolicy(item: CustomPolicy) {
    this.$router.push({
      name: "Policy",
      params: { id: item.policy_id.toString() }
    })
  }

  get headers() {
    return [
      {
        text: "LAST",
        value: "member_last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "FIRST",
        value: "member_first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "POLNUM",
        value: "policy_number",
        class: "text-left caption",
        sortable: true,
        width: "275px"
      },
      {
        text: "",
        value: "billing_exception",
        class: "text-left caption",
        sortable: true,
        width: "50px"
      },
      {
        text: "PAID UP",
        value: "last_balanced_at",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "DELINQUENT",
        value: "days_delinquent",
        class: "text-left caption",
        sortable: false
      },
      {
        text: "METHOD",
        value: "payment_method",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "FREQ",
        value: "billing_frequency",
        class: "text-left caption",
        sortable: true,
        width: "100px"
      },
      {
        text: "BALANCE",
        value: "balance",
        class: "text-left caption",
        sortable: true,
        width: "120px"
      },
      {
        text: "PREMIUM",
        value: "premium",
        class: "text-left caption",
        sortable: false,
        width: "150px"
      }
    ]
  }
}
