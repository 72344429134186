import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Recovery extends Model<Recovery> {
  constructor(response?: Recovery) {
    super(response as Recovery)
    if (response) {
      this.attributes.date = DateHelper.stringToDate(this.attributes.date)
      this.attributes.check_date = DateHelper.stringToDate(
        this.attributes.check_date
      )
    }
  }
  id: string
  attributes!: {
    claim_id: number
    date: Date
    check_date: Date
    amount: string
    status: string
    is_overpayment: boolean
    is_final: null | boolean
    updated_at: string
    memo: string
  }
  static get modelName() {
    return "Recovery"
  }
}
