
import { Component, Prop, Vue } from "vue-property-decorator"
import { Policy } from "@/types/Policy"
import CardTemplate from "@/components/CardTemplate.vue"
import { Letter } from "@/types/Letter"
import { Signature } from "@/types/Signature"

@Component({
  components: { CardTemplate }
})
export default class PolicyLetter extends Vue {
  @Prop() private policy!: Policy
  private letter: number = null
  private signatureId: number = null

  async download() {
    const response = await this.$store.dispatch("letters/download", {
      policyId: this.policy.id,
      letterId: this.letter,
      signatureId: this.signatureId
    })

    const fileURL = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "application/pdf"
      })
    )
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", `letter-${this.policy.attributes.number}.pdf`)
    document.body.appendChild(fURL)

    fURL.click()
  }

  get letters() {
    const ls = this.$store.getters["letters/getAll"]

    const data = ls
      ? ls
          .map((el: Letter) => {
            return {
              name: el.attributes.name,
              id: el.id
            }
          })
          .sort((a: { name: string }, b: { name: string }) => a > b)
      : []

    return data
  }

  get signatures() {
    const signatures = this.$store.getters["signatures/getAll"]

    if (!signatures) {
      return []
    }

    const items = signatures.map((el: Signature) => {
      return { text: el.attributes.name, value: el.id }
    })

    return items
  }

  created() {
    this.$store.dispatch("letters/loadAll")
    this.$store.dispatch("signatures/loadAll")
  }
}
