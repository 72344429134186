import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { CreditCardNotification } from "@/types/CreditCardNotification"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: CreditCardNotification[] } = {
  all: []
}

const getters = {}
const actions: ActionTree<State<CreditCardNotification>, RootState> = {
  async initiate(
    {
      dispatch,
      rootGetters
    }: ActionContext<State<CreditCardNotification>, RootState>,
    params: {
      classification: string
      use_case: string
      policyIds: string
      name: string
      date: Date
    }
  ) {
    return axios
      .post(
        "/credit_card_notifications/initiate",
        {
          name: params.name,
          classification: params.classification,
          use_case: params.use_case,
          policy_ids: params.policyIds,
          date: params.date
        },
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  }
}
const mutations: MutationTree<State<CreditCardNotification>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
