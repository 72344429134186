import Model from "./Model"

export class DisabilityType extends Model<DisabilityType> {
  id: string
  attributes!: {
    name: string
    category: number
  }
  static get modelName() {
    return "DisabilityType"
  }
}
