
import AddressDepartment from "@/components/AddressDepartment.vue"
import { Component, Vue } from "vue-property-decorator"
import DepartmentSection from "@/components/DepartmentSection.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import DepartmentSectionMixin from "@/mixins/DepartmentSectionMixin"
import { Department } from "@/types/Department"

@Component({
  components: {
    AddressDepartment,
    DepartmentSection,
    DialogTemplate,
    PhoneNumber
  },
  mixins: [DepartmentSectionMixin]
})
export default class DepartmentDirectorInfo extends Vue {
  // editDepartment is part of DepartmentSectionMixin. local version is needed to make
  // typescript happy  when it is referenced in function and is merged with
  // mixin version.
  private editDepartment!: Department
}
