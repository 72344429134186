
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import AgePremiumTable from "@/components/AgePremiumTable.vue"
import axios from "axios"
import moment from "moment"

@Component({
  components: { CardTemplate, AgePremiumTable }
})
export default class AgePremiums extends Vue {
  private premiums: { date: string; dayn_rate: string }[] = []

  localizeDate(date: string) {
    return moment(date, "YYYY-MM-DD")
      .toDate()
      .toLocaleDateString("en-US")
  }

  async created() {
    const response = await axios.get(
      "/age_premiums/day_rates",
      this.$store.getters["config/getAPIConfig"]
    )

    this.premiums = response.data.day_rates
  }
}
