import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Payment } from "@/types/Payment"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Payment[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Payment>, RootState> = {
  async reject(
    { commit, dispatch, rootGetters }: ActionContext<State<Payment>, RootState>,
    params: { id: string; memo: string }
  ) {
    try {
      const url =
        params.memo.length == 0
          ? `/payments/${params.id}/reject`
          : `/payments/${params.id}/reject?memo=${params.memo}`

      const data = await axios.put(url, {}, rootGetters["config/getAPIConfig"])
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchDepositable(
    { dispatch, rootGetters }: ActionContext<State<Payment>, RootState>,
    params: {
      page: number | string
      classification: string
      positiveOnly: boolean
    }
  ) {
    try {
      const data = await axios.get(
        `payments/depositable?classification=${params.classification}&page=${params.page}&positive_only=${params.positiveOnly}`,
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<Payment>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
