import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Laserfiche extends Model<Laserfiche> {
  constructor(response?: Laserfiche) {
    super(response as Laserfiche)
    if (response) {
      this.attributes.created_date = DateHelper.stringToDate(
        this.attributes.created_date
      )
      this.attributes.modified_date = DateHelper.stringToDate(
        this.attributes.modified_date
      )
    }
  }
  id: string
  attributes!: {
    laserficheable_id: number
    laserficheable_type: string
    document_id: number
    name: string
    created_date: Date
    modified_date: Date
  }
  static get modelName() {
    return "Laserfiche"
  }
}
