import { MutationTree, ActionTree } from "vuex"
import { DepartmentProduct } from "@/types/DepartmentProduct"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: DepartmentProduct[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<DepartmentProduct>, RootState> = {}
const mutations: MutationTree<State<DepartmentProduct>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
