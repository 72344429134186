
import { Address as AddressType } from "@/types/Address"
import { Department } from "@/types/Department"
import { Employer } from "@/types/Employer"
import DepartmentGeneralInfo from "@/components/DepartmentGeneralInfo.vue"
import DepartmentMemberCount from "@/components/DepartmentMemberCount.vue"
import DepartmentSection from "@/components/DepartmentSection.vue"
import { cloneDeep } from "lodash"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    DepartmentGeneralInfo,
    DepartmentMemberCount,
    DepartmentSection
  }
})
export default class DepartmentExtras extends Vue {
  @Prop() private department!: Department
  @Prop() private employer!: Employer

  private editBroker = false
  private editPresident = false
  private editVP = false
  private editTreasurer = false
  private editSecretary = false
  private valid = false

  private editDepartment = cloneDeep(this.department)

  @Watch("department", { deep: true })
  onDepartmentChange(value: Department) {
    if (value) {
      this.resetDepartment()
    }
  }

  get departmentAddress(): AddressType {
    if (this.department && this.department.attributes.address_id) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.address_id
      )
    }
    return new AddressType()
  }

  async save(payload: {
    address: AddressType
    parentAddressAttributeName: string
  }) {
    if (payload.address) {
      const addressHasValues = Object.values(payload.address.attributes).some(
        el => {
          return el
        }
      )

      if (addressHasValues) {
        let action = ""
        if (payload.address.id) {
          action = "update"
        } else {
          action = "create"
        }

        const data = await this.$store.dispatch(
          "addresses/" + action,
          payload.address
        )

        if (data.status == 200 && action == "create") {
          const s = `this.editDepartment.attributes.${payload.parentAddressAttributeName} = ${data.data.data.id}`
          eval(s)
        }
      }
    }

    if (this.editDepartment.attributes.status == undefined)
      this.editDepartment.attributes.status = ""
    await this.$store.dispatch("departments/update", this.editDepartment)
    this.resetDepartment()
  }

  cancel() {
    this.resetDepartment()
  }

  resetDepartment() {
    this.editDepartment = cloneDeep(this.department)
  }

  clone(addr: AddressType | null) {
    if (addr) {
      return cloneDeep(addr)
    } else {
      return new AddressType()
    }
  }
}
