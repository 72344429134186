import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Iif } from "@/types/Iif"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Iif[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Iif>, RootState> = {
  async makeFile(
    { commit, dispatch, rootGetters }: ActionContext<State<Iif>, RootState>,
    { id, date }
  ) {
    try {
      const data = await axios.put(
        `/iifs/${id}/make_file`,
        { date },
        rootGetters["config/getAPIConfig"]
      )
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<Iif>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
