
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import moment from "moment"

@Component
export default class DateField extends Vue {
  @Prop() private display!: boolean
  @Prop() private value!: Date | string
  @Prop() private label!: string
  @Prop() private outlined!: boolean
  @Prop() private dense!: boolean
  @Prop() private hideDetails!: boolean
  @Prop() private required!: boolean
  @Prop() private type!: string

  private date = !this.value
    ? ""
    : this.display
    ? moment(this.value, "YYYY-MM-DD").format("l")
    : this.formatedValue()
  private supported = true
  private menu = false

  created() {
    const test = document.createElement("input")
    try {
      test.type = "date"
    } catch (e) {
      console.log(e)
    }
    if (test.type === "text") {
      this.supported = false
    }
  }

  @Watch("value")
  onValueChange(value: string) {
    if (this.display) {
      this.date = moment(value, "YYYY-MM-DD").format("l")
    } else if (this.date != value) {
      this.date = this.formatedValue()
    }
  }

  @Watch("display")
  onDisplayChange() {
    if (
      this.display &&
      this.date != moment(this.value, "YYYY-MM-DD").format("l")
    ) {
      this.date = moment(this.value, "YYYY-MM-DD").format("l")
    } else if (!this.display && this.date != this.formatedValue()) {
      this.date = this.formatedValue()
    }
  }

  updateValue() {
    this.$emit("input", this.date)
    this.menu = false
  }

  dateToDisplay(dateString: string, type: string) {
    const s = moment(dateString, "YYYY-MM-DD")

    if (!s.isValid()) {
      return undefined
    }

    if (type == "month") {
      return s.format("MMMM YYYY")
    }

    return s.format("l")
  }

  formatedValue() {
    const formatted =
      this.value && typeof this.value != "string"
        ? this.value.toISOString()
        : this.value

    return formatted && typeof formatted == "string"
      ? formatted.replace(/T.*/, "")
      : ""
  }
}
