import Model from "./Model"

export class Version extends Model<Version> {
  id: string
  attributes!: {
    item_type: string
    item_id: number
    event: string
    whodunnit: string
    changeset: object
  }
  static get modelName() {
    return "Version"
  }
}
