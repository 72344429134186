
import CardTemplate from "@/components/CardTemplate.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { EstimatedPayable } from "@/types/EstimatedPayable"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {
    CardTemplate,
    ConfirmationTemplate,
    CurrencyField,
    DateField,
    DialogTemplate
  }
})
export default class ClaimEstimatedPayableList extends Vue {
  @Prop() private estimatedPayables!: EstimatedPayable[]

  private newEstimate = new EstimatedPayable()
  private showDeleteConfirm = false

  addEstimated() {
    this.$emit("add-estimated-payable", this.newEstimate)
    this.newEstimate = new EstimatedPayable()
  }

  editEstimate(estimatedPayable: EstimatedPayable) {
    this.newEstimate = estimatedPayable
    ;(this.$refs.estimatedPayableDialog as DialogTemplate).open(true)
  }

  confirmDelete() {
    this.showDeleteConfirm = false
    this.$emit("delete-estimated-payable", this.newEstimate)
  }

  deleteEstimate(estimatedPayable: EstimatedPayable) {
    this.newEstimate = estimatedPayable

    this.showDeleteConfirm = true
  }
}
