
import { Component, Prop, Vue } from "vue-property-decorator"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { BoardMeetingAttendee } from "@/types/BoardMeetingAttendee"
import BoardMeetingAttendeeDialog from "@/components/BoardMeetingAttendeeDialog.vue"
import AttendeeDetails from "@/components/AttendeeDetails.vue"

@Component({
  components: {
    AttendeeDetails,
    BoardMeetingAttendeeDialog,
    PagedDataTable,
    PhoneNumber
  }
})
export default class BoardMeetingAttendeeList extends Vue {
  @Prop() private boardMeetingId!: number

  private loading = false
  private sortBy = "department"

  attendeeRolls(attendee: BoardMeetingAttendee) {
    const roles = []
    if ((attendee.attributes.role & 2) > 0) {
      roles.push("Planned Guest")
    }
    if ((attendee.attributes.role & 4) > 0) {
      roles.push("Unplanned Guest")
    }
    if ((attendee.attributes.role & 8) > 0) {
      roles.push("Substitute")
    }
    if ((attendee.attributes.role & 16) > 0) {
      roles.push("Lunch Only")
    }

    return roles.join(", ")
  }

  get attendees() {
    const sortFunction =
      this.sortBy == "department" ? this.sortByDepartment : this.sortByName
    return this.boardMeetingId
      ? this.$store.getters["boardMeetingAttendees/getBy"]({
          board_meeting_id: this.boardMeetingId
        }).sort(sortFunction)
      : []
  }

  sortByDepartment(a: BoardMeetingAttendee, b: BoardMeetingAttendee) {
    const deptA = this.departmentName(a.attributes.department_id)
    const deptB = this.departmentName(b.attributes.department_id)
    return deptA.localeCompare(deptB)
  }

  sortByName(a: BoardMeetingAttendee, b: BoardMeetingAttendee) {
    const partsA = a.attributes.name.split(" ")
    const partsB = b.attributes.name.split(" ")
    const lastA = partsA.length > 0 ? partsA[partsA.length - 1] : ""
    const lastB = partsB.length > 0 ? partsB[partsB.length - 1] : ""
    return lastA.localeCompare(lastB)
  }

  get newAttendee() {
    const attendee = new BoardMeetingAttendee()
    attendee.attributes.board_meeting_id = this.boardMeetingId
    return attendee
  }

  departmentName(id: number) {
    if (id == null || id == 0) {
      return ""
    }

    const department = this.$store.getters["departments/getById"](id)

    if (department == null) {
      return ""
    }
    return department.attributes.name
  }

  editAttendee(attendee: BoardMeetingAttendee) {
    const dialog = (this.$refs[
      `edit-attendee-${attendee.id}`
    ] as Vue[])[0] as BoardMeetingAttendeeDialog
    dialog.open()
  }

  toggleAttended(attendee: BoardMeetingAttendee) {
    attendee.attributes.attended = !attendee.attributes.attended
    this.$store.dispatch("boardMeetingAttendees/update", attendee)
  }

  toggleReimbursedHotel(attendee: BoardMeetingAttendee) {
    attendee.attributes.reimbursed_hotel = !attendee.attributes.reimbursed_hotel
    this.$store.dispatch("boardMeetingAttendees/update", attendee)
  }
  toggleReimbursedExpenses(attendee: BoardMeetingAttendee) {
    attendee.attributes.reimbursed_expenses = !attendee.attributes
      .reimbursed_expenses
    this.$store.dispatch("boardMeetingAttendees/update", attendee)
  }

  async deleteAttendee(id: number) {
    await this.$store.dispatch("boardMeetingAttendees/delete", id)
  }
}
