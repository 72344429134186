
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import { Claim } from "@/types/Claim"
import UserInitials from "@/components/UserInitials.vue"

@Component({
  components: {
    CardTemplate,
    UserInitials
  }
})
export default class OutstandingEstimatedPayables extends Vue {
  private claims: Claim[] = []
  private previous = "0"
  private loading = false

  async created() {
    this.fetchClaims()
  }

  claimAdmin(admin_id: number | null) {
    return admin_id ? this.$store.getters["users/getById"](admin_id) : null
  }

  @Watch("previous")
  onPreviousChange() {
    this.fetchClaims()
  }

  async fetchClaims() {
    this.loading = true
    const response = await this.$store.dispatch(
      "claims/getOutstandingEstimatedPayables",
      this.previous
    )

    if (response?.data?.data) {
      this.claims = response.data.data

      const claimAdminIds = this.claims
        .map((claim: Claim) => {
          return claim.attributes.admin_id
        })
        .filter(n => n)

      if (claimAdminIds.length > 0) {
        await this.$store.dispatch("users/loadBy", { id: claimAdminIds })
      }
    }

    this.loading = false
  }
}
