import DateHelper from "@/components/utils/DateHelper"
import Model from "./Model"

export class Nacha extends Model<Nacha> {
  constructor(response?: Nacha) {
    super(response as Nacha)
    if (response) {
      this.attributes.send_date = DateHelper.stringToDate(
        this.attributes.send_date
      )
    }
  }
  id: string
  attributes!: {
    name: string
    account: string
    send_date: Date
    result: string
    download_doc_url: string
    view_doc_url: string
    use_case: string
  }
  static get modelName() {
    return "Nacha"
  }
}
