
import { Claim } from "@/types/Claim"
import { Policy } from "@/types/Policy"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimForm from "@/components/ClaimForm.vue"
import ClaimFormLtc from "@/components/ClaimFormLtc.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    CardTemplate,
    ClaimForm,
    ClaimFormLtc,
    CurrencyField,
    DialogTemplate
  }
})
export default class ClaimsList extends Vue {
  @Prop() private claims!: [Claim]
  @Prop() private memberId!: string
  @Prop() private activePolicies!: [Policy]
  @Prop() private listTitle!: string

  private policyLTC: Policy = null
  private policyLTD: Policy = null

  @Watch("activePolicies")
  onValueChange() {
    this.setPolicies()
  }

  productTypeClass(claim: Claim) {
    return claim.attributes.product_type == "ltc"
      ? "overline primary--text"
      : "overline accent--text"
  }

  created() {
    this.setPolicies()
  }

  setPolicies() {
    this.policyLTC = null
    this.policyLTD = null
    const policies = this.activePolicies.sort((a: Policy, b: Policy) => {
      return a.attributes.start_date < b.attributes.start_date ? 1 : -1
    })

    policies.forEach((policy: Policy) => {
      const product = this.$store.getters["products/getById"](
        policy.attributes.product_id
      )
      if (product) {
        if (!this.policyLTC && product.attributes.product_type == "ltc") {
          this.policyLTC = policy
        }
        if (!this.policyLTD && product.attributes.product_type == "ltd") {
          this.policyLTD = policy
        }
      }
    })
  }

  claimPolicy(claim: Claim) {
    const policy = this.$store.getters["policies/getById"](
      claim.attributes.policy_id
    )
    return policy
  }
}
