
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import axios from "axios"

@Component({
  components: { CardTemplate, ConfirmationTemplate }
})
export default class AgePremiums extends Vue {
  private show = false
  private file: File = null
  private updateText = ""

  async submitFiles() {
    const formData = new FormData()
    formData.append("source", this.file)

    try {
      await axios.post(
        "age_premiums/full_set",
        formData,
        this.$store.getters["config/getAPIConfig"]
      )
      this.updateText = "Your premium schedule file has been applied"
      this.show = true
    } catch (e) {
      this.updateText = e
      this.show = true
    }
  }

  close() {
    this.file = null
    this.show = false
  }
}
