
import { Component, Prop, Vue } from "vue-property-decorator"
import { Adjustment } from "@/types/Adjustment"
import CurrencyField from "@/components/CurrencyField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    CurrencyField,
    DialogTemplate
  }
})
export default class AdjustmentEntry extends Vue {
  @Prop() private adjustableType!: string
  @Prop() private adjustableId!: string
  @Prop() private productType!: string
  @Prop() private eip!: boolean
  private loading = false
  private validAdjustment = false
  private newAdjustment = new Adjustment()
  private askConfirm = false
  private saveConfirmed = false

  created() {
    this.reset()
  }
  reset() {
    this.newAdjustment = new Adjustment()
    this.newAdjustment.attributes.product_type = this.productType
    this.newAdjustment.attributes.eip = this.eip
  }

  async save() {
    this.loading = true
    this.newAdjustment.attributes.adjustable_type = this.adjustableType
    this.newAdjustment.attributes.adjustable_id = parseInt(this.adjustableId)

    await this.$store.dispatch("adjustments/create", {
      data: this.newAdjustment,
      successMessage: "New adjustment added"
    })

    this.$emit("load-bills-and-payments")
    this.loading = false
    this.reset()
  }

  get saveDisabled() {
    return !this.validAdjustment
  }
}
