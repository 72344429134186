import Model from "./Model"

export class Report extends Model<Report> {
  constructor(report?: Report) {
    super(report as Report)
    if (!this.attributes.kwargs) {
      this.attributes.kwargs = {
        start_date: undefined,
        end_date: undefined,
        product_type: undefined,
        year: undefined,
        classification: undefined,
        eip: undefined
      }
    }
  }
  id: string
  attributes!: {
    name: string
    report_type: string
    download_doc_url: string
    view_doc_url: string
    created_at: string
    kwargs: {
      start_date: Date
      end_date: Date
      product_type: string
      year: string
      classification: string
      eip: boolean
    }
  }
  static get modelName() {
    return "Report"
  }
}
