import { MutationTree, ActionTree } from "vuex"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import { IncomeWorksheet } from "@/types/IncomeWorksheet"

const state: { all: IncomeWorksheet[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<IncomeWorksheet>, RootState> = {}
const mutations: MutationTree<State<IncomeWorksheet>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
