import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Letter } from "@/types/Letter"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Letter[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Letter>, RootState> = {
  async download(
    { dispatch, rootGetters }: ActionContext<State<Letter>, RootState>,
    params: {
      policyId: number | string
      letterId: number | string
      signatureId: number | string
    }
  ) {
    try {
      const data = await axios.get(
        `/letters/${params.letterId}/download?policy_id=${params.policyId}&signature_id=${params.signatureId}`,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<Letter>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
