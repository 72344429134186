
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Department } from "@/types/Department"
import { Bill } from "@/types/Bill"
import { Member } from "@/types/Member"
import { Payment } from "@/types/Payment"
import { Policy } from "@/types/Policy"
import { Adjustment } from "@/types/Adjustment"
import BillDetails from "@/components/BillDetails.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import MonthField from "@/components/MonthField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PaymentDetails from "@/components/PaymentDetails.vue"
import DateHelper from "@/components/utils/DateHelper"

@Component({
  components: {
    BillDetails,
    ConfirmationTemplate,
    CurrencyField,
    DateField,
    MonthField,
    PagedDataTable,
    PaymentDetails
  }
})
export default class BillingList extends Vue {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @Prop() private billable!: Department | Member | Policy
  @Prop() private productType!: string
  @Prop() private eip!: boolean

  private bills: Bill[] = []
  private payments: Payment[] = []
  private adjustments: Adjustment[] = []

  private combined: any[] = []
  private editBillId: string = null
  private editBill: Bill = new Bill()
  private editPaymentId: string = null
  private rejectPaymentId: string = null
  private rejectText = ""
  private editPayment: Payment = new Payment()
  private editAdjustmentId: string = null
  private editAdjustment: Adjustment = new Adjustment()
  private loading = false
  private startingPage = 1
  private startingPerPage = 25
  private total = 0
  private latestPayment = new Payment()
  private latestBill = new Bill()
  private latestAdjustment = new Adjustment()
  private showConfirm = false
  private showReverseConfirm = false
  private reverseBillItem: Bill = new Bill()
  private lastPageInfo: { page: number; itemsPerPage: number }

  created() {
    this.loadAll(this.startingPage, this.startingPerPage)
  }

  @Watch("billable", { deep: true })
  onBillableChange() {
    this.loadAll(this.startingPage, this.startingPerPage)
  }

  async loadLatestPayment() {
    const filter = {
      payable_id: this.billable.id,
      payable_type: this.billableType,
      product_type: this.productType,
      eip: this.eip.toString(),
      page: 1,
      per_page: 1,
      order: "posted_at",
      order_direction: "DESC"
    }
    const payments = await this.$store.dispatch("payments/loadBy", filter)

    const ps = payments.data.data.filter(
      (el: { attributes: { posted_at: any } }) => {
        return el.attributes.posted_at != null
      }
    )

    this.latestPayment = ps.length > 0 ? ps[0] : null
  }

  async loadLatestBill() {
    const filter = {
      billable_id: this.billable.id,
      billable_type: this.billableType,
      product_type: this.productType,
      eip: this.eip.toString(),
      page: 1,
      per_page: 1,
      order: "posted_at",
      order_direction: "DESC"
    }
    const bills = await this.$store.dispatch("bills/loadBy", filter)
    this.latestBill = bills.data.data.length > 0 ? bills.data.data[0] : null
  }

  async loadLatestAdjustment() {
    const filter = {
      adjustable_id: this.billable.id,
      adjustable_type: this.billableType,
      product_type: this.productType,
      eip: this.eip.toString(),
      page: 1,
      per_page: 1,
      order: "created_at",
      order_direction: "DESC"
    }
    const adjustments = await this.$store.dispatch("adjustments/loadBy", filter)
    this.latestAdjustment =
      adjustments.data.data.length > 0 ? adjustments.data.data[0] : null
  }

  async loadAll(
    page: number = this.startingPage,
    perPage: number = this.startingPerPage
  ) {
    const combinedData = await this.$store.dispatch(
      "bills/loadBillsAndPayments",
      {
        billable_id: this.billable.id,
        payable_id: this.billable.id,
        adjustable_id: this.billable.id,
        billable_type: this.billableType,
        payable_type: this.billableType,
        adjustable_type: this.billableType,
        product_type: this.productType,
        eip: this.eip,
        page: page,
        per_page: perPage
      }
    )

    this.total = combinedData?.data?.meta?.pagination?.count

    this.combined = combinedData?.data?.data

    this.loadLatestPayment()
    this.loadLatestBill()
    this.loadLatestAdjustment()
  }

  get department() {
    return this.billable instanceof Department ? this.billable : null
  }

  get billableType() {
    switch (true) {
      case this.billable instanceof Department:
        return "Department"
      case this.billable instanceof Member:
        return "Member"
      case this.billable instanceof Policy:
        return "Policy"
      default:
        return "Department"
    }
  }

  paymentAmountEditable(payment: Payment) {
    if (payment.attributes.posted_at == null) {
      return true
    }

    const isLatestPayment = payment.id == this.latestPayment.id
    const isNewerThanBill = this.latestBill
      ? payment.attributes.posted_at > this.latestBill.attributes.posted_at
      : true
    const isNewerThanAdjustment = this.latestAdjustment
      ? payment.attributes.posted_at >
        this.latestAdjustment.attributes.posted_at
      : true

    return isLatestPayment && isNewerThanBill && isNewerThanAdjustment
  }

  adjustmentAmountEditable(adjustment: Adjustment) {
    const isLatestAdjustment = adjustment.id == this.latestAdjustment.id
    const isNewerThanBill = this.latestBill
      ? adjustment.attributes.posted_at > this.latestBill.attributes.posted_at
      : true
    const isNewerThanPayment = this.latestPayment
      ? adjustment.attributes.posted_at >
        this.latestPayment.attributes.posted_at
      : true

    return isLatestAdjustment && isNewerThanBill && isNewerThanPayment
  }

  recordKey(record: Bill | Payment) {
    return `${record.id}-${this.getTypeName(record)}`
  }

  getCoverage(record: any) {
    let s = ""

    if (record.type == "bill" && record.attributes.coverage_start_date) {
      const d1 = new Date(record.attributes.coverage_start_date)
      const d2 = new Date(record.attributes.coverage_end_date)
      s = DateHelper.dateRange(d1, d2)
    } else if (record.type == "payment") {
      const d = record.attributes.period_covered
        ? new Date(record.attributes.period_covered)
        : null
      s = DateHelper.shortFormat(d)
    }

    return s
  }

  paymentMethod(payment: Payment) {
    switch (payment.attributes.payment_method) {
      case "Check":
        return payment.attributes.check_number
      case "Credit Card":
      case "CreditCard":
        return "CC"
      case "ACH":
      case "Ach":
        return "ACH"
      default:
        return payment.attributes.check_number
    }
  }

  getTypeName(record: Bill | Payment) {
    return record.type ? "bill" : "payment"
  }

  getData(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.lastPageInfo = details
      this.loadAll(details.page, details.itemsPerPage)
    }
  }

  goToNacha(item: Payment) {
    this.$router.push({
      name: "Nacha",
      params: { id: item.attributes.nacha_id.toString() }
    })
  }

  goToNachas() {
    this.$router.push({
      name: "AchPayments"
    })
  }

  async getPdf() {
    const response = await this.$store.dispatch(
      "bills/fetchBillsAndPaymentsPdf",
      {
        billable_id: this.billable.id,
        payable_id: this.billable.id,
        adjustable_id: this.billable.id,
        billable_type: this.billableType,
        payable_type: this.billableType,
        adjustable_type: this.billableType,
        product_type: this.productType,
        eip: this.eip,
        page:
          this.lastPageInfo == undefined
            ? this.startingPage
            : this.lastPageInfo.page,
        per_page:
          this.lastPageInfo == undefined
            ? this.startingPerPage
            : this.lastPageInfo.itemsPerPage
      }
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "ledger.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }

  isBill(record: Bill | Payment | Adjustment) {
    return record.type == "bill"
  }

  isPayment(record: Bill | Payment | Adjustment) {
    return record.type == "payment"
  }

  isAdjustment(record: Bill | Payment | Adjustment) {
    return record.type == "adjustment"
  }

  klass(record: Bill | Payment | Adjustment) {
    if (this.isBill(record)) {
      return ""
    }
    if (this.isPayment(record)) {
      return "payment"
    }
    if (this.isAdjustment(record)) {
      return Number(record.attributes.amount) < 0 ? "red--text" : "green--text"
    }
  }

  getUrl(bill: any, download: boolean) {
    if (bill.type == "bill") {
      const url = download
        ? bill.attributes.download_doc_url
        : bill.attributes.view_doc_url
      return url ? this.baseUrl + url : ""
    }
    return ""
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  get emailText() {
    return this.department && this.department.attributes.billing_contact_email_1
      ? "Email Bill"
      : "Add a billing email in order to send via email"
  }

  emailBill(bill: Bill) {
    this.$store.dispatch("bills/sendEmail", bill.id)
  }

  openBillEdit(bill: Bill) {
    this.editBill = JSON.parse(JSON.stringify(bill))
    this.editBillId = bill.id
  }

  openReverseDialog(bill: Bill) {
    this.reverseBillItem = bill
    this.showReverseConfirm = true
  }

  async reverseBill() {
    await this.$store.dispatch("bills/reverse", this.reverseBillItem.id)
    this.reverseBillItem = new Bill()
    this.loadAll()
  }

  openPaymentEdit(payment: Payment) {
    this.editPayment = new Payment(payment)
    this.editPaymentId = payment.id
  }

  openAdjustmentEdit(adjustment: Adjustment) {
    this.editAdjustment = new Adjustment(adjustment)
    this.editAdjustmentId = adjustment.id
  }

  showRejectConfirm(payment: Payment) {
    this.rejectPaymentId = payment.id
    this.showConfirm = true
  }

  async removeFromNacha(item: Payment) {
    console.log(
      "we will remove payment from nacha",
      item.id,
      item.attributes.nacha_id
    )
    const nacha_id = item.attributes.nacha_id
    item.attributes.nacha_id = null

    await this.$store.dispatch("payments/update", item)
    await this.$store.dispatch("nachas/makeFile", {
      id: nacha_id
    })
    this.loadAll()
  }

  async rejectPaymentEdit() {
    await this.$store.dispatch("payments/reject", {
      id: this.rejectPaymentId,
      memo: this.rejectText
    })
    this.rejectPaymentId = null
    this.rejectText = ""
    this.loadAll()
  }

  async saveBillEdit() {
    await this.$store.dispatch("bills/update", {
      data: this.editBill,
      successMessage: "Bill updated"
    })
    this.editBill = new Bill()
    this.editBillId = null

    this.loadAll()
  }

  async savePaymentEdit() {
    await this.$store.dispatch("payments/update", {
      data: this.editPayment,
      successMessage: "Payment updated"
    })
    this.editPayment = new Payment()
    this.editPaymentId = null

    this.loadAll()
  }

  async saveAdjustmentEdit() {
    await this.$store.dispatch("adjustments/update", {
      data: this.editAdjustment,
      successMessage: "Adjustment updated"
    })
    this.editAdjustment = new Adjustment()
    this.editAdjustmentId = null

    this.loadAll()
  }

  async sentOnToday(bill: Bill) {
    let tempBill = new Bill()
    tempBill = JSON.parse(JSON.stringify(bill))
    tempBill.attributes.sent_on = new Date()

    await this.$store.dispatch("bills/update", tempBill)

    this.loadAll()
  }

  get headers() {
    return [
      {
        text: "date",
        value: "date",
        sortable: false,
        class: "text-left overline old-overline js-header-date"
      },
      {
        text: "check#",
        value: "check",
        sortable: false,
        class: "text-left overline old-overline js-header-check"
      },
      {
        text: "coverage",
        value: "coverage",
        sortable: false,
        class: "text-left overline old-overline js-header-coverage"
      },
      {
        text: "amount",
        value: "amount",
        sortable: false,
        class: "text-left overline old-overline js-header-amount"
      },
      {
        text: "premium",
        value: "premium",
        sortable: false,
        class: "text-left overline old-overline js-header-premium"
      },
      {
        text: "fee",
        value: "fee",
        sortable: false,
        class: "text-left overline old-overline js-header-fee"
      },
      {
        text: "balance",
        value: "balance",
        sortable: false,
        class: "text-left overline old-overline js-header-balance"
      },
      {
        text: "status",
        value: "status",
        sortable: false,
        class: "text-left overline old-overline js-header-status"
      },
      {
        text: "memo",
        value: "memo",
        sortable: false,
        class: "text-left overline old-overline js-header-memo"
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        class: "text-left overline old-overline js-header-actions",
        width: "100px"
      },
      {
        text: "",
        value: "edit",
        sortable: false,
        class: "text-left overline old-overline js-header-memo"
      }
    ]
  }
}
