
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Following as FollowingType } from "@/types/Following"

@Component
export default class Following extends Vue {
  @Prop() private recordId!: string
  @Prop() private type!: string
  private nudge = 0

  @Watch("recordId", { immediate: true })
  onRecordIdChange(newId: string) {
    if (newId) {
      this.loadFollowing()
    }
  }

  @Watch("type", { immediate: true })
  onTypeChange(newType: string) {
    if (newType) {
      this.loadFollowing()
    }
  }

  loadFollowing() {
    this.$store.dispatch("followings/loadBy", {
      followable_id: this.recordId,
      followable_type: this.type
    })
  }

  get user() {
    return this.$store.getters["authentication/getUser"]
  }

  get following() {
    return this.$store.getters["followings/getBy"]({
      followable_id: this.recordId,
      followable_type: this.type,
      user_id: this.user?.id
    })
  }

  get icon() {
    return this.following.length ? "mdi-eye-off" : "mdi-eye"
  }

  get helpText() {
    return this.following.length ? "Stop Following" : "Follow"
  }

  follow() {
    if (this.following.length) {
      this.nudge = 0

      this.$store.dispatch("followings/delete", this.following[0].id)
    } else {
      this.nudge = 20

      const following = new FollowingType()
      following.attributes = {
        followable_id: this.recordId,
        followable_type: this.type,
        user_id: this.user?.id
      }

      this.$store.dispatch("followings/create", following)
    }
  }
}
