
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import RecordSearch from "@/components/RecordSearch.vue"
import { Search } from "@/types/Search"

import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DialogTemplate,
    RecordSearch
  }
})
export default class UpdatePolicyDate extends Vue {
  @Prop() private id!: number
  @Prop() private departmentId!: number
  @Prop() private title!: string
  @Prop() private optionalCopyText!: string
  @Prop() private optionalIconColor!: string

  private changedDepartment = 0

  mounted() {
    this.changedDepartment = this.departmentId
  }

  @Watch("departmentId")
  onDateChange(value: number) {
    if (value) {
      this.changedDepartment = value
    }
  }

  async update() {
    await axios.put(
      `/policies/${this.id}`,
      { department_id: this.changedDepartment },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }

  handleSearchSelected(item: Search) {
    this.changedDepartment = Number(item.target_id)
  }
}
