
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import { Claim } from "@/types/Claim"
import { Member } from "@/types/Member"
import UserInitials from "@/components/UserInitials.vue"

@Component({
  components: {
    DateField,
    PagedDataTable,
    UserInitials
  }
})
export default class ClaimsTable extends Vue {
  @Prop() private table_id!: string
  @Prop() private productType!: string
  @Prop() private state!: string
  @Prop() private userAdminOnly!: boolean
  @Prop() private adminId!: number

  private claims: Claim[] = []
  private loading = false
  private total = 0
  private startingPerPage = 25
  private perPage = 25
  private currentPage = 1
  private options = {
    sortBy: "member",
    sortDesc: false,
    mustSort: true
  }

  async loadClaims(page: number, perPage: number) {
    this.loading = true

    let sort = this.options.sortBy
    switch (this.options.sortBy) {
      case "member":
        sort = "last_name"
        break
      case "attributes.short_number":
        sort = "claims.number"
        break
      default:
        sort = this.options.sortBy.replace("attributes.", "")
    }

    const filter: {
      page: number
      per_page: number
      order: string
      order_direction: string
      include: string
      "product.product_type"?: string
      admin_id?: number
      aasm_state?: string | string[]
    } = {
      page: page,
      per_page: perPage,
      order: sort,
      order_direction: this.options.sortDesc ? "DESC" : "ASC",
      include: "policy.member"
    }

    if (this.productType && this.productType != "all") {
      filter["product.product_type"] = this.productType
    }

    if (this.userAdminOnly) {
      const currentUser = this.$store.getters["authentication/getUser"]
      filter["admin_id"] = currentUser.id
    } else if (this.adminId > 0) {
      filter["admin_id"] = this.adminId
    } else {
      delete filter.admin_id
    }

    if (!this.state || this.state == "active") {
      const allStates: string[] = this.$store.getters["config/getActiveStates"]
      const states: string[] = []
      allStates?.forEach(s => {
        if (
          !this.productType ||
          this.productType == "all" ||
          s.includes(this.productType)
        ) {
          states.push(s.replace(" ", "_"))
        }
      })
      filter.aasm_state = states
    } else if (this.state) {
      if (this.productType && this.productType != "all") {
        filter.aasm_state = `${this.productType}_${this.state.replace(
          " ",
          "_"
        )}`
      } else {
        const states = [
          `ltd_${this.state.replace(" ", "_")}`,
          `ltc_${this.state.replace(" ", "_")}`
        ]
        filter.aasm_state = states
      }
    }

    const data = await this.$store.dispatch("claims/loadBy", filter)

    const adminIds = data?.data?.data
      .map(
        (c: { attributes: { admin_id: null | number } }) =>
          c.attributes.admin_id
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((n: any) => n)

    this.$store.dispatch("users/loadBy", { id: adminIds })

    this.claims = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  firstPageLoad(details: { itemsPerPage: number }) {
    this.loadClaims(1, details.itemsPerPage)
  }

  getClaims(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadClaims(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadClaims(1, this.perPage)
  }

  @Watch("userAdminOnly")
  adminOnlyChanged() {
    this.loadClaims(1, this.perPage)
  }

  @Watch("adminId")
  adminIdChanged() {
    this.loadClaims(1, this.perPage)
  }

  @Watch("productType")
  productTypeChanged() {
    this.loadClaims(1, this.perPage)
  }

  @Watch("state")
  stateChanged() {
    this.loadClaims(1, this.perPage)
  }

  get formattedClaims() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatted: any[] = this.claims ? this.claims : []
    formatted.forEach(el => {
      el.member = this.whichMember(el.attributes.member_id)
    })

    return formatted
  }

  get dirtyClaims() {
    return this.claims
      ? this.claims
          .filter(c => {
            return this.$store.getters["claims/getById"](c.id)?.dirty
          })
          .map(c => c.id)
      : []
  }

  get headers() {
    const all: {
      text: string
      value: string
      sortable: boolean
      class: string
      width?: string
      align?: string
    }[] = [
      {
        text: "NUMBER",
        value: "attributes.short_number",
        sortable: true,
        class: "text-left caption",
        width: "200px"
      },
      {
        text: "PRODUCT",
        value: "attributes.product_type",
        sortable: false,
        class: "text-left caption js-header-product",
        align: this.productType && this.productType != "all" ? " d-none" : "",
        width: "100px"
      },
      {
        text: "ADMIN",
        value: "attributes.admin",
        sortable: false,
        class: "text-left caption js-header-admin",
        width: "100px"
      },
      {
        text: "ACH",
        value: "ach",
        sortable: false,
        class: "text-left caption",
        width: "100px"
      },
      {
        text: "REVIEW",
        value: "attributes.review_start_date",
        sortable: true,
        class: "text-left caption js-header-review-start",
        width: "150px"
      },
      {
        text: "STEP",
        value: "attributes.state",
        sortable: false,
        class: "text-left caption js-header-step",
        align: this.state && this.state != "active" ? " d-none" : "",
        width: "250px"
      },

      {
        text: "MEMBER",
        value: "member",
        sortable: true,
        class: "text-left caption"
      }
    ]

    if (this.dirtyClaims.length) {
      all.splice(0, 0, {
        text: "",
        value: "dirty",
        sortable: false,
        class: "text-left caption js-header-dirty",
        width: "1%"
      })
    }

    return all
  }

  whichMember(memberId: string) {
    const member = this.$store.getters["members/getById"](memberId)
    return member ? member : new Member()
  }

  claimAdmin(claim: Claim) {
    return claim.attributes.admin_id
      ? this.$store.getters["users/getById"](claim.attributes.admin_id)
      : null
  }
}
