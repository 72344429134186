
import { Component, Vue } from "vue-property-decorator"
import { mapGetters } from "vuex"
import { Alert } from "@/types/Alert"

@Component({
  computed: {
    ...mapGetters("alerts", ["getSuccesses"])
  }
})
export default class SuccessAlerts extends Vue {
  getSuccesses!: () => Alert[]

  dismissAlert(uuid: string) {
    this.$store.commit("alerts/removeAlert", uuid)
  }
}
