import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class RecoverableAdjustment extends Model<RecoverableAdjustment> {
  constructor(response?: RecoverableAdjustment) {
    super(response as RecoverableAdjustment)
    if (response) {
      this.attributes.date = DateHelper.stringToDate(this.attributes.date)
    }
  }
  id: string
  attributes!: {
    claim_id: number
    adjustment_type: string
    amount: number
    reason: string
    date: Date
  }
  static get modelName() {
    return "RecoverableAdjustment"
  }
}
