
import { Component, Prop, Vue } from "vue-property-decorator"
import { Bill } from "@/types/Bill"
import { Department } from "@/types/Department"
import { Member } from "@/types/Member"
import { Payment } from "@/types/Payment"
import { Product } from "@/types/Product"
import BillingList from "@/components/BillingList.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import MultiPaymentEntry from "@/components/MultiPaymentEntry.vue"
import PaymentEntry from "@/components/PaymentEntry.vue"
import AdjustmentEntry from "@/components/AdjustmentEntry.vue"
import RefundEntry from "@/components/RefundEntry.vue"
import Unposted from "@/components/Unposted.vue"
import { SortOrder } from "@/store/utils/genericGetters"
import { Policy } from "@/types/Policy"

@Component({
  components: {
    AdjustmentEntry,
    BillingList,
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    MultiPaymentEntry,
    PaymentEntry,
    RefundEntry,
    Unposted
  }
})
export default class Ledger extends Vue {
  @Prop() private bills!: Bill[]
  @Prop() private payments!: Payment[]
  @Prop() private products!: Product[]
  @Prop() private billable!: Department | Member | Policy
  @Prop() private productType!: string
  @Prop() private eip!: boolean
  @Prop() private memberPaid!: { type: boolean; default: false }

  private billStart = new Date()
  get unpostedPayments() {
    const payableType =
      this.billable.type.charAt(0).toUpperCase() + this.billable.type.slice(1)
    return this.$store.getters["payments/getBy"](
      {
        payable_id: this.billable.id,
        payable_type: payableType,
        posted_at: null
      },
      "created_at",
      SortOrder.desc
    )
  }

  balanceClass(balance: number) {
    if (balance == 0) {
      return "title"
    } else if (balance > 0) {
      return "red--text title"
    } else {
      return "green--text title"
    }
  }

  get balanceFieldName() {
    return this.eip ? "eip_balance" : this.productType + "_balance"
  }

  load() {
    ;(this.$refs.list as Vue & { loadAll: () => void }).loadAll()
  }

  get titleString() {
    const s = this.eip ? "eip" : this.productType
    const billableName =
      this.billable.type == "member"
        ? "Member"
        : this.billable.type == "policy"
        ? "Policy"
        : ""
    return `${s} ${billableName} Ledger`
  }

  async billGenerate() {
    await this.$store.dispatch("bills/generateSingle", {
      policy_id: this.billable.id,
      coverage_start_date: this.billStart
    })
  }
}
