import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Beneficiary extends Model<Beneficiary> {
  constructor(response?: Beneficiary) {
    super(response as Beneficiary)
    if (response) {
      this.attributes.trust_date = DateHelper.stringToDate(
        this.attributes.trust_date
      )
    }
  }
  id: string
  attributes!: {
    address_id: number
    contingent: boolean
    first_name: string
    last_name: string
    policy_id: number
    phone: string
    relationship: string
    trust_date: Date
    trust_name: string
    trustee_names: string
    ach_id: number
    percent: number
    archived_on: Date
  }
  static get modelName() {
    return "Beneficiary"
  }
}
