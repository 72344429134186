
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class ClaimStepper extends Vue {
  @Prop() private currentState!: string
  @Prop() private states!: [string]

  isStepBefore(state: string) {
    const currentPosition = this.states.indexOf(this.currentState)
    const position = this.states.indexOf(state)

    return position < currentPosition
  }

  isCurrentStep(state: string) {
    const currentPosition = this.states.indexOf(this.currentState)
    const position = this.states.indexOf(state)

    return position == currentPosition
  }

  completeIcon(isCurrent: boolean) {
    return isCurrent ? "" : "mdi-check"
  }

  stepColor(isCurrent: boolean) {
    return isCurrent ? "secondary" : "accent"
  }
}
