
import { Component, Prop, Vue } from "vue-property-decorator"
import { Address } from "@/types/Address"
import { Attorney } from "@/types/Attorney"
import AddressDepartment from "@/components/AddressDepartment.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { cloneDeep } from "lodash"

@Component({
  components: { AddressDepartment, DialogTemplate, PhoneNumber }
})
export default class NewAttorney extends Vue {
  @Prop() private attorney!: Attorney
  @Prop() private address!: Address

  private editAttorney: Attorney = this.attorney
    ? cloneDeep(this.attorney)
    : new Attorney()
  private editAddress: Address = this.address
    ? cloneDeep(this.address)
    : new Address()

  async saveAttorney() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let savedAddress: any = null
    if (this.address) {
      savedAddress = await this.$store.dispatch("addresses/update", {
        data: this.editAddress
      })
    } else {
      savedAddress = await this.$store.dispatch("addresses/create", {
        data: this.editAddress
      })
    }

    if (savedAddress?.data?.data?.id) {
      this.editAttorney.attributes.address_id = parseInt(
        savedAddress?.data?.data?.id
      )
    }

    if (this.attorney) {
      await this.$store.dispatch("attorneys/update", {
        data: this.editAttorney
      })
    } else {
      await this.$store.dispatch("attorneys/create", {
        data: this.editAttorney
      })
    }
    this.$emit("save")
  }

  cancel() {
    this.editAttorney = this.attorney ? this.attorney : new Attorney()
    this.editAddress = this.address ? this.address : new Address()
  }
}
