import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Bill } from "@/types/Bill"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import { isEmpty } from "lodash"
import errorHandler from "../utils/errorHandler"

const state: { all: Bill[] } = {
  all: []
}

const getters = {
  getByIdsAndProductType: (state: State<Bill>) => (
    ids: string[],
    productType: string
  ) => {
    return state.all.filter(
      (el: Bill) =>
        ids.includes(el.id) && el.attributes.product_type == productType
    )
  }
}

const actions: ActionTree<State<Bill>, RootState> = {
  sendEmail(
    { commit, dispatch, rootGetters }: ActionContext<State<Bill>, RootState>,
    id: string
  ) {
    axios
      .put(`/bills/${id}/send_email`, {}, rootGetters["config/getAPIConfig"])
      .then((data: { data: string | { data: {} } }) => {
        if (typeof data.data == "string") {
          dispatch("alerts/addError", data.data, { root: true })
        } else if (data.data instanceof Object) {
          dispatch("alerts/addSuccess", "Bill Sent!", { root: true })
          commit("set", data && data.data.data)
        }
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async reverse(
    { commit, dispatch, rootGetters }: ActionContext<State<Bill>, RootState>,
    id: string
  ) {
    try {
      const url = `/bills/${id}/reverse`
      const data = await axios.put(url, {}, rootGetters["config/getAPIConfig"])
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  generateSingle(
    { dispatch, rootGetters }: ActionContext<State<Bill>, RootState>,
    data: {
      policy_group_id: string
      coverage_start_date: string
    }
  ) {
    axios
      .post("/bills/generate_single", data, rootGetters["config/getAPIConfig"])
      .then((data: { data: string | { data: {} } }) => {
        if (typeof data.data == "string") {
          dispatch("alerts/addError", data.data, { root: true })
        } else if (data.data instanceof Object) {
          dispatch("alerts/addSuccess", "Bill Generation Initiated", {
            root: true
          })
        }
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async proveSingle(
    { rootGetters }: ActionContext<State<Bill>, RootState>,
    data: {
      policy_group_id: string
      coverage_start_date: string
    }
  ) {
    try {
      const result = await axios.post(
        "/bills/prove_single",
        data,
        rootGetters["config/getAPIConfig"]
      )

      return result
    } catch (error) {
      if (typeof error === "object" && error !== null && "response" in error) {
        const axiosError = error as { response: { data: unknown } }
        return { error: axiosError.response.data }
      }
    }
  },
  async generateSupplemental(
    { dispatch, rootGetters }: ActionContext<State<Bill>, RootState>,
    data: {
      policy_id: string
      start_date: string
      end_date: string
      is_add: boolean
      memo: string
    }
  ) {
    try {
      console.log(data)
      const result = await axios.post(
        "/bills/generate_supplemental",
        data,
        rootGetters["config/getAPIConfig"]
      )

      return result
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async loadBillsAndPayments(
    { dispatch, rootGetters }: ActionContext<State<Bill>, RootState>,
    params: { include?: string[] | string; [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    try {
      if (Array.isArray(params?.include)) {
        params.include = params.include.join(",")
      }

      const queryParams = !isEmpty(params)
        ? {
            params: params
          }
        : {}
      const data = await axios.get(
        "/bills/combine_payments",
        Object.assign(queryParams, rootGetters["config/getAPIConfig"])
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchBillsAndPaymentsPdf(
    { dispatch, rootGetters }: ActionContext<State<Bill>, RootState>,
    params: {
      billable_id: number
      payable_id: number
      adjustable_id: number
      billable_type: string
      payable_type: string
      adjustable_type: string
      product_type: string
      page: number
      per_page: number
    }
  ) {
    try {
      const data = await axios.get(
        `/bills/combined_payments_pdf?billable_id=${params.billable_id}&payable_id=${params.payable_id}&adjustable_id=${params.adjustable_id}&billable_type=${params.billable_type}&payable_type=${params.payable_type}&adjustable_type=${params.adjustable_type}&product_type=${params.product_type}&page=${params.page}&per_page=${params.per_page}`,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<Bill>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
