
import { Component, Vue } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"
import CardTemplate from "@/components/CardTemplate.vue"

@Component({
  components: {
    CardTemplate,
    DateField
  }
})
export default class IifDisbursementsFilter extends Vue {
  private startDate = new Date(new Date().getFullYear(), new Date().getMonth())
  private endDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  )
  private classification: null | string = null
  private paymentType: null | string = null

  private classifications = [
    { value: null, text: "All" },
    { value: "fire", text: "Fire" },
    { value: "law", text: "Law" },
    { value: "ltc", text: "LTC" }
  ]
  private paymentTypes = [
    { value: null, text: "All" },
    { value: "Ach", text: "ACH" },
    { value: "Check", text: "Check" }
  ]

  findDisbursements() {
    this.$emit("changeFilters", {
      startDate: this.startDate,
      endDate: this.endDate,
      classification: this.classification,
      paymentType: this.paymentType
    })
  }
}
