
import { Membership } from "@/types/Membership"
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import ModifyMembership from "@/components/ModifyMembership.vue"
import ReverseMembership from "@/components/ReverseMembership.vue"

@Component({
  components: { CardTemplate, DateField, ModifyMembership, ReverseMembership }
})
export default class MembershipsList extends Vue {
  @Prop() private memberships!: [Membership]
  @Prop() private memberId!: string

  private editId: string = null
  private editMembership: Membership = new Membership()

  getDepartmentName = (departmentId: BigInteger) => {
    const department = this.$store.getters["departments/getById"](departmentId)
    return department ? department.attributes.name : "not found"
  }

  get sortedMemberships() {
    return this.memberships.sort((a: Membership, b: Membership) => {
      if (!b.attributes.end_date) return 1
      if (!a.attributes.end_date) return -1
      return a.attributes.end_date < b.attributes.end_date ? 1 : -1
    })
  }

  get noActiveMemberships() {
    return !this.memberships.some(membership => {
      return !membership.attributes.end_date
    })
  }

  openEdit(membership: Membership) {
    this.editMembership = JSON.parse(JSON.stringify(membership))
    this.editId = membership.id
  }

  rowClass(membership: Membership) {
    let s = "js-membership"

    if (membership.attributes.reversed) {
      s += " membership-reversed"
    }

    return s
  }

  async save() {
    await this.$store.dispatch("memberships/update", {
      data: this.editMembership,
      successMessage: "Membership updated"
    })
    this.editMembership = new Membership()
    this.editId = null
    this.$emit("member:reload")
  }
}
