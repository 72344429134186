import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Enroller } from "@/types/Enroller"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import axios from "axios"
import errorHandler from "../utils/errorHandler"

const state: { all: Enroller[] } = {
  all: []
}

const getters = {}
const mutations: MutationTree<State<Enroller>> = {}

const actions: ActionTree<State<Enroller>, RootState> = {
  async getApplicationCounts(
    { dispatch, rootGetters }: ActionContext<State<Enroller>, RootState>,
    days: string
  ) {
    try {
      const data = await axios.get(
        `/enrollers/application_counts?days=${days}`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPolicies(
    { dispatch, rootGetters }: ActionContext<State<Enroller>, RootState>,
    params: {
      id: number | string
      page: number | string
    }
  ) {
    try {
      const url = `enrollers/${params.id}/policies?page=${params.page}`
      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchReport(
    { dispatch, rootGetters }: ActionContext<State<Enroller>, RootState>,
    params: {
      EnrollerId: string
      startDate: null | string
      endDate: null | string
    }
  ) {
    try {
      let url = `enrollers/${params.EnrollerId}/report`
      if (params.startDate) {
        url = url + `?start_date=${params.startDate}`
      }
      if (params.endDate) {
        if (url.includes("?")) {
          url = url + "&"
        } else {
          url = url + "?"
        }

        url = url + `end_date=${params.endDate}`
      }
      const data = await axios.get(
        url,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
