
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import LienDialog from "@/components/LienDialog.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Claim } from "@/types/Claim"
import { Department } from "@/types/Department"
import { Employer } from "@/types/Employer"
import { Lien } from "@/types/Lien"

@Component({
  components: {
    CardTemplate,
    ConfirmationTemplate,
    CurrencyField,
    DateField,
    LienDialog,
    PageTemplate
  }
})
export default class LiensList extends Vue {
  @Prop() private claim: Claim
  @Prop() private department: Department
  @Prop() private employer: Employer
  @Prop() private liens: Lien[]

  private showConfirm = false
  private selectedId: number

  get headers() {
    return [
      {
        text: "TYPE",
        value: "attributes.lien_type",
        class: "text-left caption"
      },
      {
        text: "AMOUNT",
        value: "attributes.amount",
        class: "text-end caption"
      },
      {
        text: "WCAB NUMBER",
        value: "attributes.wcab_number",
        class: "text-left caption"
      },
      {
        text: "CREATED",
        value: "attributes.created_at",
        class: "text-left caption"
      },
      {
        text: "DOWNLOAD",
        value: "attributes",
        class: "text-left caption",
        sortable: false
      }
    ]
  }

  get claimId() {
    return this.$route.params.id
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  async deleteLien(id: number) {
    await this.$store.dispatch("liens/delete", id)
  }
}
