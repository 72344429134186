
import { ClaimForm } from "@/types/ClaimForm"
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimFormEditDialog from "@/components/ClaimFormEditDialog.vue"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CardTemplate,
    ClaimFormEditDialog,
    DateField
  }
})
export default class ClaimFormsList extends Vue {
  @Prop() private claimForms!: [ClaimForm]
  @Prop() private claimId!: ""
  private newClaimForm: ClaimForm = new ClaimForm()
  private valid = true

  addClaimForm() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.newClaimForm.attributes.name = this.newClaimForm.attributes.name.trim()
      this.newClaimForm.attributes.claim_id = parseInt(this.claimId)

      this.$store.dispatch("claimForms/create", {
        data: this.newClaimForm,
        successMessage: "New claim form added"
      })
      ;(this.$refs.form as Vue & {
        resetValidation: () => void
      }).resetValidation()
    }
  }

  get someDirty() {
    return this.claimForms?.some(form => form.dirty)
  }

  async sentOnToday(claimForm: ClaimForm) {
    let tempClaimForm = new ClaimForm()
    tempClaimForm = JSON.parse(JSON.stringify(claimForm))
    tempClaimForm.attributes.sent_on = new Date()
    await this.$store.dispatch("claimForms/update", {
      data: tempClaimForm,
      successMessage: "Claim Form updated"
    })

    await this.reloadClaim()
    await this.reloadForm(claimForm)
  }
  async receivedOnToday(claimForm: ClaimForm) {
    let tempClaimForm = new ClaimForm()
    tempClaimForm = JSON.parse(JSON.stringify(claimForm))
    tempClaimForm.attributes.received_on = new Date()
    await this.$store.dispatch("claimForms/update", {
      data: tempClaimForm,
      successMessage: "Claim Form updated"
    })

    await this.reloadClaim()
    await this.reloadForm(claimForm)
  }

  async reloadClaim() {
    await this.$store.dispatch("claims/load", this.claimId)
  }
  async reloadForm(form: ClaimForm) {
    await this.$store.dispatch("claimForms/load", form.id)
  }
}
