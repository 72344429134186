/* eslint-disable @typescript-eslint/no-explicit-any */
import State from "@/types/State"
import { isEmpty } from "lodash"

export default {
  getAll: (state: State<any>) => {
    return state.all
  },
  getById: (state: State<any>) => (id: string | number) => {
    const result = state.all.find((el: any) => el.id == id)
    return result || null
  },
  getBy: (state: State<any>) => (
    filters?: { [key: string]: any },
    sortBy?: string,
    sortOrder?: SortOrder
  ) => {
    return state.all
      .filter((el: any) => {
        return (
          !filters ||
          isEmpty(filters) ||
          Object.keys(filters).reduce((validSoFar: boolean, field: string) => {
            return validSoFar && el.attributes[field] == filters[field]
          }, true)
        )
      })
      .sort((a: any, b: any) => {
        sortOrder = sortOrder ? sortOrder : 1

        if (!sortBy) {
          return a.id > b.id ? sortOrder : -sortOrder
        }
        return a.attributes[sortBy] > b.attributes[sortBy]
          ? sortOrder
          : -sortOrder
      })
  },
  getMeta: (state: State<any>) => {
    return state.meta
  }
}

export enum SortOrder {
  asc = 1,
  desc = -1
}
