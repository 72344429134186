import DateHelper from "@/components/utils/DateHelper"
import Model from "./Model"

export class Premium extends Model<Premium> {
  constructor(response?: Premium) {
    super(response as Premium)
    if (response) {
      this.attributes.start_date = DateHelper.stringToDate(
        this.attributes.start_date
      )
    }
  }
  id: string
  attributes!: {
    amount_billed: number
    amount_planned: number
    premiumable_id: number
    premiumable_type: string
    start_date: Date
  }
  static get modelName() {
    return "Premium"
  }
}
