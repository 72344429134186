
import { Component, Vue, Watch } from "vue-property-decorator"
import { DepartmentProduct } from "@/types/DepartmentProduct"
import { Department as DepartmentType } from "@/types/Department"
import Address from "@/components/Address.vue"
import { Address as AddressType } from "@/types/Address"
import CardTemplate from "@/components/CardTemplate.vue"
import CompositeRateHistory from "@/components/CompositeRateHistory.vue"
import DateField from "@/components/DateField.vue"
import DepartmentExtras from "@/components/DepartmentExtras.vue"
import DepartmentInfoEip from "@/components/DepartmentInfoEip.vue"
import DepartmentInfoLtc from "@/components/DepartmentInfoLtc.vue"
import DepartmentInfoLtd from "@/components/DepartmentInfoLtd.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import Ledger from "@/components/Ledger.vue"
import { Member } from "@/types/Member"
import MembersList from "@/components/MembersList.vue"
import MemberEditList from "@/components/MemberEditList.vue"
import NewMember from "@/components/NewMember.vue"
import MembershipChanges from "@/components/MembershipChanges.vue"
import NotesList from "@/components/NotesList.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PaymentMethodsList from "@/components/PaymentMethodsList.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import PoliciesList from "@/components/PoliciesList.vue"
import { Product } from "@/types/Product"
import PremiumHistory from "@/components/PremiumHistory.vue"
import ProductsList from "@/components/ProductsList.vue"
import ProductsListDialog from "@/components/ProductsListDialog.vue"
import { SortOrder } from "@/store/utils/genericGetters"
import { PolicyGroup } from "@/types/PolicyGroup"

interface TempMemberType {
  id: number
  last_name: string
  first_name: string
  premium: string
  ssn: string
  email: string
  phone: string
  waive: boolean
  sworn_safety: boolean
  street: string
  apartment: string
  city: string
  state: string
  zip: string
}

@Component({
  components: {
    Address,
    CardTemplate,
    CompositeRateHistory,
    DateField,
    DepartmentExtras,
    DepartmentInfoEip,
    DepartmentInfoLtc,
    DepartmentInfoLtd,
    DialogTemplate,
    Ledger,
    MemberEditList,
    MembersList,
    NewMember,
    MembershipChanges,
    NotesList,
    PageTemplate,
    PaymentMethodsList,
    PhoneNumber,
    PoliciesList,
    PremiumHistory,
    ProductsList,
    ProductsListDialog
  }
})
export default class Department extends Vue {
  private edit = false
  private tab = parseInt(localStorage.getItem("lastDepartmentTab") || "0")
  private membersTab = 0
  private dataLoaded = false
  private editMembers: Member[] = []
  private prodRadio = "ltd"
  private billNote = ""
  private hint = "Will be printed on bill and cleared each month"
  private pgMembersLTD: TempMemberType[] = []
  private pgMembersLTC: TempMemberType[] = []
  private pgMembersEIP: TempMemberType[] = []
  private individualMembersLTC: TempMemberType[] = []
  private individualMembersEIP: TempMemberType[] = []
  private membersDataLoading = false
  private claimsReportType = "ltd"

  get id() {
    return this.$route.params.id
  }

  get department() {
    return this.$store.getters["departments/getById"](this.id)
  }

  get employer() {
    return this.department?.attributes?.employer_id
      ? this.$store.getters["employers/getById"](
          this.department.attributes.employer_id
        )
      : null
  }

  get employerName() {
    return this.department && this.employer
      ? `A member of the ${this.employer.attributes.name} employer group`
      : ""
  }

  get name() {
    return this.department ? this.department.attributes.name : ""
  }

  get type() {
    return this.department ? this.department.attributes.department_type : ""
  }

  get status() {
    return this.department ? this.department.attributes.status : ""
  }

  get effective() {
    return this.department ? this.department.attributes.effective_date : ""
  }

  get address(): AddressType {
    if (this.department) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.address_id
      )
    }
    return new AddressType()
  }

  get billingAddress(): AddressType {
    if (this.department) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.billing_contact_address_id
      )
    }
    return new AddressType()
  }

  get allProducts() {
    return this.$store.getters["products/getAll"]
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      BalanceRecalculatedChannel: {
        connected() {
          console.log("BalanceRecalculatedChannel connected")
        },
        rejected() {
          console.log("BalanceRecalculatedChannel rejected")
        },
        received(data: {
          model_type: string
          id: string
          ltc_balance: string
          ltd_balance: string
        }) {
          if (
            data.model_type == "Department" &&
            data.id == that.id &&
            (data.ltc_balance != that.ltc_balance ||
              data.ltd_balance != that.ltd_balance)
          ) {
            that.$store.dispatch("departments/load", data.id)
          }
        }
      }
    }
  }

  get departmentProducts() {
    return this.$store.getters["departmentProducts/getBy"]({
      department_id: this.id
    })
  }

  get ltcBills() {
    return this.$store.getters["bills/getBy"]({
      billable_id: this.id,
      billable_type: "Department",
      product_type: "ltc"
    })
  }

  get ltdBills() {
    return this.$store.getters["bills/getBy"]({
      billable_id: this.id,
      billable_type: "Department",
      product_type: "ltd",
      eip: "false"
    })
  }

  get eipBills() {
    return this.$store.getters["bills/getBy"]({
      billable_id: this.id,
      billable_type: "Department",
      product_type: "ltd",
      eip: "true"
    })
  }

  get ltcPayments() {
    return this.$store.getters["payments/getBy"]({
      payable_id: this.id,
      payable_type: "Department",
      product_type: "ltc"
    })
  }

  get ltdPayments() {
    return this.$store.getters["payments/getBy"]({
      payable_id: this.id,
      payable_type: "Department",
      product_type: "ltd",
      eip: "false"
    })
  }

  get eipPayments() {
    return this.$store.getters["payments/getBy"]({
      payable_id: this.id,
      payable_type: "Department",
      product_type: "ltd",
      eip: "true"
    })
  }

  get policyGroups() {
    return this.$store.getters["policyGroups/getBy"]({
      department_id: this.id
    })
  }

  get policyGroupLTD() {
    const pgs = this.$store.getters["policyGroups/getBy"]({
      department_id: this.id
    }).filter((pg: PolicyGroup) => {
      return pg.attributes.product_type == "ltd" && !pg.attributes.eip
    })

    return pgs && pgs.length > 0 ? pgs[0] : null
  }

  get policyGroupEIP() {
    const pgs = this.$store.getters["policyGroups/getBy"]({
      department_id: this.id
    }).filter((pg: PolicyGroup) => {
      return pg.attributes.product_type == "ltd" && pg.attributes.eip
    })

    return pgs && pgs.length > 0 ? pgs[0] : null
  }

  get policyGroupLTC() {
    const pgs = this.$store.getters["policyGroups/getBy"]({
      department_id: this.id
    }).filter((pg: PolicyGroup) => {
      return pg.attributes.product_type == "ltc"
    })

    return pgs && pgs.length > 0 ? pgs[0] : null
  }

  get products() {
    const productIds = this.departmentProducts.map((el: DepartmentProduct) => {
      if (el.attributes.department_id == parseInt(this.id)) {
        return el.attributes.product_id.toString()
      }
    })
    return this.$store.getters["products/getByIds"](productIds)
  }

  get availableProducts() {
    return this.products.filter((el: Product) => {
      return !el.attributes.retired_on
    })
  }

  get availableProductsForNewMember() {
    return this.products.filter((el: Product) => {
      return (
        !el.attributes.retired_on &&
        el.attributes.product_type == "ltd" &&
        el.attributes.eip == false
      )
    })
  }

  get balanceClassLTC() {
    if (this.department && this.department.attributes.ltc_balance) {
      return this.balanceClass(this.department.attributes.ltc_balance)
    }
    return "title"
  }

  get balanceClassLTD() {
    if (this.department && this.department.attributes.ltd_balance) {
      return this.balanceClass(this.department.attributes.ltd_balance)
    }
    return "title"
  }

  get creditCards() {
    if (this.id != null) {
      return this.$store.getters["creditCards/getBy"]({
        owner_id: this.id,
        owner_type: "Department"
      })
    } else {
      return []
    }
  }
  get aches() {
    if (this.id != null) {
      return this.$store.getters["aches/getBy"]({
        owner_id: this.id,
        owner_type: "Department"
      })
    } else {
      return []
    }
  }
  get ltd_balance() {
    return this.department && !!this.department.attributes.ltd_balance
      ? this.department.attributes.ltd_balance
      : "0"
  }

  get ltc_balance() {
    return this.department && !!this.department.attributes.ltc_balance
      ? this.department.attributes.ltc_balance
      : "0"
  }

  get premiums() {
    return this.$store.getters["premiums/getBy"](
      {
        premiumable_id: this.id,
        premiumable_type: "Department"
      },
      "start_date",
      SortOrder.desc
    )
  }

  get compositeRates() {
    const crs = this.$store.getters["compositeRates/getBy"](
      {
        department_id: this.id
      },
      "start_date",
      SortOrder.desc
    )

    return crs
  }

  goToMember(member: TempMemberType) {
    this.$router.push({
      name: "Member",
      params: { id: member.id.toString() }
    })
  }

  async fetchPayrollMembers(evt: Event) {
    if (evt.toString() == "0" || evt.toString() == "1") {
      return
    }

    let page = 1
    let pgId = null

    this.membersDataLoading = true
    const tabEvtNumber = Number(evt)

    if (tabEvtNumber == 2) {
      this.pgMembersLTD = []
      pgId = this.policyGroupLTD.id
    } else if (tabEvtNumber == 3) {
      this.pgMembersLTC = []
      pgId = this.policyGroupLTC.id
    } else if (tabEvtNumber == 4) {
      this.pgMembersEIP = []
      pgId = this.policyGroupEIP.id
    } else if (tabEvtNumber == 5) {
      this.individualMembersLTC = []
    } else if (tabEvtNumber == 6) {
      this.individualMembersEIP = []
    }

    do {
      let data = null

      if (tabEvtNumber == 2 || tabEvtNumber == 3 || tabEvtNumber == 4) {
        data = await this.$store.dispatch("policyGroups/fetchMembers", {
          policyGroupId: pgId,
          page: page
        })
      } else if (tabEvtNumber == 5 || tabEvtNumber == 6) {
        data = await this.$store.dispatch("departments/fetchMembers", {
          departmentId: this.department.id,
          productType: tabEvtNumber == 5 ? "ltc" : "ltd",
          page: page
        })
      }

      if (tabEvtNumber == 2) {
        this.pgMembersLTD.push(...data.data.data)
      } else if (tabEvtNumber == 3) {
        this.pgMembersLTC.push(...data.data.data)
      } else if (tabEvtNumber == 4) {
        this.pgMembersEIP.push(...data.data.data)
      } else if (tabEvtNumber == 5) {
        this.individualMembersLTC.push(...data.data.data)
      } else if (tabEvtNumber == 6) {
        this.individualMembersEIP.push(...data.data.data)
      }

      page = data.data.meta.next_page
    } while (page)

    this.membersDataLoading = false
  }

  async getClaimsReport() {
    const productType =
      this.claimsReportType == "eip" ? "ltd" : this.claimsReportType
    const eip = this.claimsReportType == "eip"

    const response = await this.$store.dispatch(
      "departments/fetchClaimsReport",
      { id: this.id, productType: productType, eip: eip }
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `${this.department?.attributes?.name}-claims.xlsx`
    )
    document.body.appendChild(fURL)

    fURL.click()
  }

  async getMembersPdf(productType: string) {
    const pgId =
      productType == "ltd"
        ? this.policyGroupLTD.id
        : productType == "eip"
        ? this.policyGroupEIP.id
        : this.policyGroupLTC.id
    const response = await this.$store.dispatch(
      "policyGroups/fetchMemberPdf",
      pgId
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute(
      "download",
      `${this.department?.attributes?.name}-${productType.toUpperCase()}.pdf`
    )
    document.body.appendChild(fURL)

    fURL.click()
  }

  saveProdRadio() {
    localStorage.setItem("lastProductType", this.prodRadio)
  }

  balanceClass(balance: string) {
    if (parseFloat(balance) == 0) {
      return "title"
    } else if (parseFloat(balance) > 0) {
      return "red--text title"
    } else {
      return "green--text title"
    }
  }

  @Watch("department")
  onDepartment(value: DepartmentType) {
    if (value) {
      this.loadAddressData(value)
      if (value.attributes.merged_department_id) {
        this.$store.dispatch("departments/load", {
          id: value.attributes.merged_department_id
        })
      }
      this.billNote = this.department?.attributes?.bill_note_ltd
      this.loadPremiums()
    }
  }

  saveTab(value: number) {
    localStorage.setItem("lastDepartmentTab", value.toString())
  }

  loadAddressData(value: DepartmentType) {
    const departmentAddId = value.attributes.address_id
    const billingAddId = value.attributes.billing_contact_address_id
    const presidentAddId = value.attributes.president_address_id
    const vpAddId = value.attributes.vp_address_id
    const treasurerAddId = value.attributes.treasurer_address_id
    const secretaryAddId = value.attributes.secretary_address_id
    const ltcAddId = value.attributes.ltc_address_id
    const eipAddId = value.attributes.eip_address_id

    const addressIds = [
      departmentAddId,
      billingAddId,
      presidentAddId,
      vpAddId,
      treasurerAddId,
      secretaryAddId,
      ltcAddId,
      eipAddId
    ]
    this.$store.dispatch("addresses/loadBy", { id: addressIds })
  }

  loadPremiums() {
    this.$store.dispatch("premiums/loadBy", {
      premiumable_id: this.id,
      premiumable_type: "Department"
    })
  }

  async loadData(departmentId: string) {
    if (this.department) {
      this.loadAddressData(this.department)
    }
    await this.loadDepartment(departmentId)

    this.$store.dispatch("departmentProducts/loadBy", {
      department_id: departmentId
    })

    this.$store.dispatch("products/loadAll")
  }

  async loadDepartment(departmentId: string) {
    await this.$store.dispatch("departments/load", {
      id: departmentId,
      include: [
        "credit_cards",
        "aches",
        "policy_groups",
        "employer",
        "composite_rates"
      ]
    })
  }

  async created() {
    const lastProdRadio = localStorage.getItem("lastProductType")
    if (lastProdRadio) {
      this.prodRadio = lastProdRadio
    }
    this.pgMembersLTD = []
    this.pgMembersLTC = []
    await this.loadData(this.id)
    this.loadPremiums()
    this.billNote = this.department?.attributes?.bill_note_ltd
    this.dataLoaded = true
  }

  mounted() {
    this.$cable.subscribe({
      channel: "BalanceRecalculatedChannel",
      room: "public"
    })
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    // this.$store.dispatch("departments/load", to.params.id)
    this.dataLoaded = false
    this.pgMembersLTD = []
    this.pgMembersLTC = []
    await this.loadData(String(to.params.id))
    this.dataLoaded = true
    next()
  }

  saveBillNote() {
    this.department.attributes.bill_note_ltd = this.billNote
    this.$store.dispatch("departments/update", this.department)
  }

  saveDepartmentProducts(productIds: Array<number>) {
    productIds.forEach((productId: number) => {
      if (
        !this.departmentProducts.find(
          (departmentProduct: DepartmentProduct) =>
            Number(departmentProduct.attributes.product_id) == productId
        )
      ) {
        const departmentProduct = new DepartmentProduct()
        departmentProduct.attributes.product_id = productId
        departmentProduct.attributes.department_id = parseInt(this.id)
        this.$store.dispatch("departmentProducts/create", departmentProduct)
      }
    })

    this.departmentProducts.forEach((departmentProduct: DepartmentProduct) => {
      if (
        !productIds.find(
          (productId: number) =>
            Number(departmentProduct.attributes.product_id) == productId
        )
      ) {
        this.$store.dispatch("departmentProducts/delete", departmentProduct.id)
      }
    })
  }

  updateMembers() {
    const ref = this.$refs.list as Vue & {
      loadMembers: (page: number, perPage: number) => void
      startingPerPage: number
    }
    ref.loadMembers(1, ref.startingPerPage)
  }

  getCSV(productType: string) {
    let csv = ""
    let productForFileName = ""
    let payrollForFileName = ""
    // options:
    // pgMembersLTD
    // pgMembersLTC
    // pgMembersEIP
    // individualMembersLTC
    // individualMembersEIP

    if (productType == "ltd" || productType == "pgMembersLTD") {
      productForFileName = "LTD"
      payrollForFileName = "payroll"
      csv = this.pgMembersLTD
        .map(el => {
          return [
            el.last_name,
            el.first_name,
            el.premium,
            el.email,
            el.ssn,
            el.phone,
            el.waive,
            el.sworn_safety,
            el.street,
            el.apartment,
            el.city,
            el.state,
            el.zip
          ].join(",")
        })
        .join("\n")
    } else if (productType == "ltc" || productType == "pgMembersLTC") {
      productForFileName = "LTC"
      payrollForFileName = "payroll"
      csv = this.pgMembersLTC
        .map(el => {
          return [
            el.last_name,
            el.first_name,
            el.premium,
            el.email,
            el.ssn,
            el.phone,
            el.waive,
            el.sworn_safety,
            el.street,
            el.apartment,
            el.city,
            el.state,
            el.zip
          ].join(",")
        })
        .join("\n")
    } else if (productType == "pgMembersEIP") {
      productForFileName = "EIP"
      payrollForFileName = "payroll"
      csv = this.pgMembersEIP
        .map(el => {
          return [
            el.last_name,
            el.first_name,
            el.premium,
            el.email,
            el.ssn,
            el.phone,
            el.waive,
            el.sworn_safety
          ].join(",")
        })
        .join("\n")
    } else if (productType == "individualMembersLTC") {
      productForFileName = "LTC"
      payrollForFileName = "individual"
      csv = this.individualMembersLTC
        .map(el => {
          return [
            el.last_name,
            el.first_name,
            el.premium,
            el.email,
            el.ssn,
            el.phone,
            el.waive,
            el.sworn_safety
          ].join(",")
        })
        .join("\n")
    } else if (productType == "individualMembersEIP") {
      productForFileName = "EIP"
      payrollForFileName = "individual"
      csv = this.individualMembersEIP
        .map(el => {
          return [
            el.last_name,
            el.first_name,
            el.premium,
            el.email,
            el.ssn,
            el.phone,
            el.waive,
            el.sworn_safety
          ].join(",")
        })
        .join("\n")
    }

    csv = [
      "last",
      "first",
      "premium",
      "email",
      "ssn",
      "phone",
      "waive",
      `${
        this.department?.attributes?.classification == "fire"
          ? "safety"
          : "sworn"
      }`,
      "street",
      "apartment",
      "city",
      "state",
      "zip"
    ]
      .join(",")
      .concat("\n")
      .concat(csv)

    let fileName = this.department?.attributes?.name.replaceAll(" ", "_")
    fileName = fileName + "_" + productForFileName
    fileName = fileName + "_" + payrollForFileName + ".csv"

    const anchor = document.createElement("a")
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    anchor.target = "_blank"
    anchor.download = fileName
    anchor.click()
  }

  get headersPgMembers() {
    return [
      {
        text: "LAST",
        value: "last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "FIRST",
        value: "first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "PREMIUM",
        value: "premium",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "EMAIL",
        value: "email",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "SSN",
        value: "ssn",
        sortable: true,
        class: "text-left caption",
        width: "150"
      },
      {
        text: "PHONE",
        value: "phone",
        class: "text-left caption",
        width: "175"
      },
      {
        text: "WAIVE",
        value: "waive",
        sortable: true,
        class: "text-left caption",
        width: "100"
      }
    ]
  }
}
