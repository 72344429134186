import { render, staticRenderFns } from "./Letters.vue?vue&type=template&id=4f91feee&scoped=true&"
import script from "./Letters.vue?vue&type=script&lang=ts&"
export * from "./Letters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f91feee",
  null
  
)

export default component.exports