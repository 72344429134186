
import { Component, Prop, Vue } from "vue-property-decorator"
import { Member } from "@/types/Member"
import { Payment } from "@/types/Payment"
import { Product } from "@/types/Product"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import MonthField from "@/components/MonthField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import VueMask from "v-mask"
Vue.use(VueMask)
type removeData = {
  member_id: string
  end_date: Date
  name: string
  showMenu: boolean
}
type addData = {
  member: Member
  start_date: Date
}
@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate,
    MonthField
  }
})
export default class PaymentEntry extends Vue {
  @Prop() private payableType!: string
  @Prop() private payableId!: string
  @Prop() private availableProducts!: Product[]
  @Prop() private paymentType!: string
  @Prop() private eip!: boolean
  private loading = false
  private validPayment = false
  private newPayment = new Payment()
  private isCheck = true
  private paymentMethods = ["Check", "CreditCard", "ACH"]
  private fullPremiumPayment = true
  private askConfirm = false
  private saveConfirmed = false

  created() {
    this.reset()
  }
  reset() {
    this.newPayment = new Payment()
    this.newPayment.attributes.date_received = new Date()
    this.newPayment.attributes.payment_method = "Check"
    this.newPayment.attributes.status = "Paid"
    this.newPayment.attributes.product_type = this.paymentType
    this.newPayment.attributes.eip = this.eip
    this.isCheck = true

    if (this.paymentType == "ltd" && this.productsOfType.length == 1) {
      this.newPayment.attributes.product_id = Number(this.productsOfType[0].id)
    }
  }
  changePaymentMethod(paymentMethod: string) {
    this.isCheck = paymentMethod == "Check"
    this.newPayment.attributes.status = this.isCheck ? "Paid" : "Scheduled"
  }

  firstOfNextMonth() {
    const today = new Date()
    return today.getMonth() + 1 == 12
      ? new Date(today.getFullYear() + 1, 0, 1)
      : new Date(today.getFullYear(), today.getMonth() + 1, 1)
  }
  lastOfCurrentMonth() {
    const today = new Date()
    return new Date(today.getFullYear(), today.getMonth() + 1, 0)
  }

  getSubtitle(value: string) {
    return value?.replace("Member ", "")
  }
  blur(member: removeData) {
    setTimeout(() => {
      member.showMenu = false
    }, 500)
  }

  mirrorPremium() {
    if (this.fullPremiumPayment) {
      this.newPayment.attributes.premium_amount = this.newPayment.attributes.amount
    }
  }
  async save() {
    this.loading = true
    this.newPayment.attributes.payable_type = this.payableType
    this.newPayment.attributes.payable_id = parseInt(this.payableId)
    this.newPayment.attributes.payment_method =
      this.newPayment.attributes.payment_method == "ACH"
        ? (this.newPayment.attributes.payment_method = "Ach")
        : this.newPayment.attributes.payment_method
    if (this.fullPremiumPayment) {
      this.newPayment.attributes.premium_amount = this.newPayment.attributes.amount
    }
    await this.$store.dispatch("payments/create", {
      data: this.newPayment,
      successMessage: "New payment added"
    })

    this.$emit("load-bills-and-payments")
    this.loading = false
    this.reset()
  }

  get oversAmount() {
    const amount = this.newPayment.attributes.amount
      ? Number(this.newPayment.attributes.amount)
      : 0
    const premium_amount = this.newPayment.attributes.premium_amount
      ? Number(this.newPayment.attributes.premium_amount)
      : 0

    return amount - premium_amount
  }

  get saveDisabled() {
    return !this.validPayment
  }

  get ltdProduct() {
    return this.availableProducts.find(
      product => product.attributes.product_type == "ltd"
    )
  }

  get productsOfType() {
    return this.availableProducts.filter(
      product =>
        product.attributes.product_type == this.paymentType &&
        product.attributes.eip == this.eip
    )
  }

  get selectedProduct() {
    return this.productsOfType.find(
      product => Number(product.id) == this.newPayment.attributes.product_id
    )
  }
}
