import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Deposit } from "@/types/Deposit"
import axios from "axios"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Deposit[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Deposit>, RootState> = {
  async build(
    { commit, dispatch, rootGetters }: ActionContext<State<Deposit>, RootState>,
    data: {
      paymentIds: string[]
    }
  ) {
    return axios
      .post(
        "/deposits/build",
        JSON.parse(JSON.stringify(data)),
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {} } }) => {
        commit("set", data && data.data.data)
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async stats(
    { dispatch, rootGetters }: ActionContext<State<Deposit>, RootState>,
    id: string
  ) {
    try {
      const data = await axios.get(
        `deposits/${id}/stats`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async regeneratePDF(
    { dispatch, rootGetters }: ActionContext<State<Deposit>, RootState>,
    id: string
  ) {
    try {
      const data = await axios.post(
        `deposits/${id}/pdf_regen`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<Deposit>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
