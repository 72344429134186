
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"

@Component({
  components: {
    CardTemplate
  }
})
export default class DisbursementTotalsLtc extends Vue {
  @Prop() private disbursement!: number
  @Prop() private death!: number
}
