
import CardTemplate from "@/components/CardTemplate.vue"
import PaymentMethodACH from "@/components/PaymentMethodACH.vue"
import PaymentMethodCreditCard from "@/components/PaymentMethodCreditCard.vue"
import { Component, Prop, Vue } from "vue-property-decorator"
import { Ach } from "@/types/Ach"
import { CreditCard } from "@/types/CreditCard"
import valid from "card-validator"

@Component({
  components: {
    CardTemplate,
    PaymentMethodACH,
    PaymentMethodCreditCard
  }
})
export default class PaymentMethodsList extends Vue {
  @Prop() private creditCards!: [CreditCard]
  @Prop() private aches!: [Ach]
  @Prop() private ownerId!: number
  @Prop() private ownerType!: string

  get newAch() {
    const ach = new Ach()
    ach.attributes.owner_id = this.ownerId
    ach.attributes.owner_type = this.ownerType

    return ach
  }

  editACH(achId: string) {
    ;(this.$refs[`edit-ach-${achId}`] as PaymentMethodACH[])[0].open()
  }

  deleteAch(achId: string) {
    this.$store.dispatch("aches/delete", achId)
  }

  get newCreditCard() {
    const cc = new CreditCard()
    cc.attributes.owner_id = this.ownerId
    cc.attributes.owner_type = this.ownerType

    return cc
  }

  editCreditCard(creditCardId: string) {
    ;(this.$refs[
      `edit-cc-${creditCardId}`
    ] as PaymentMethodCreditCard[])[0].open()
  }

  deleteCreditCard(creditCardId: string) {
    this.$store.dispatch("creditCards/delete", creditCardId)
  }

  cardTypeImagePath(cardNumber: string) {
    if (cardNumber.length > 4) {
      const validator = valid.number(cardNumber)
      const cardType = validator.card ? validator.card.type : "generic"
      return `/credit_cards/${cardType}.svg`
    }
    return "/credit_cards/generic.svg"
  }

  async copyCreditCard(creditCard: CreditCard) {
    await navigator.clipboard.writeText(`${creditCard.attributes.number}
${creditCard.attributes.full_name}
${creditCard.attributes.expiration_month}
${creditCard.attributes.expiration_year}`)
  }

  async copyAch(ach: Ach) {
    await navigator.clipboard.writeText(`${ach.attributes.bank_name}
${ach.attributes.account_number}
${ach.attributes.routing_number}
${ach.attributes.account_type}`)
  }
}
