import { MutationTree, ActionTree } from "vuex"
import { Lien } from "@/types/Lien"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Lien[] } = {
  all: []
}

const getters = {}
const actions: ActionTree<State<Lien>, RootState> = {}
const mutations: MutationTree<State<Lien>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
