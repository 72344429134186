
import { Component, Vue, Watch } from "vue-property-decorator"
import { Search as SearchItem } from "@/types/Search"

@Component
export default class Search extends Vue {
  isLoading = false
  search: string = null
  searchResults: SearchItem[] = []

  icon(item: SearchItem) {
    let sIcon = "mdi-shield-account-outline"

    if (item.target_model == "Department") {
      sIcon = "mdi-shield-home-outline"
    } else if (item.subtitle.includes("Claim")) {
      sIcon = "mdi-cash-usd-outline"
    }

    return sIcon
  }

  goToSelected(item: SearchItem) {
    if (item) {
      this.$router.push(
        {
          name: item.target_model,
          params: { id: item.target_id.toString() }
        },
        () => {
          // success callback
        },
        err => {
          if (!err.message.includes("Avoided redundant navigation")) {
            throw err
          }
        }
      )
    }
  }

  @Watch("search")
  async searchTermChange(value: string) {
    if (value) {
      const split = value.split(":")
      if (
        (split.length > 1 && split[1].trim().length >= 3) ||
        (split.length <= 1 && value.trim().length >= 3)
      ) {
        this.isLoading = true
        const data = await this.$store.dispatch("search/performSearch", value)
        this.searchResults = data ? data.data : []
        this.isLoading = false
      } else {
        this.searchResults = []
      }
    }
  }

  keyListener(e: KeyboardEvent) {
    if (e.key === "b" && (e.ctrlKey || e.metaKey)) {
      const bar: any = this.$refs.searchBar // eslint-disable-line @typescript-eslint/no-explicit-any
      bar.$el.querySelector("input").focus()
    }
  }

  mounted() {
    document.addEventListener("keydown", this.keyListener.bind(this))
  }
  beforeDestroy() {
    document.removeEventListener("keydown", this.keyListener)
  }
}
