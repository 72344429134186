
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import UsersList from "@/components/UsersList.vue"

@Component({
  components: {
    CardTemplate,
    PagedDataTable,
    PageTemplate,
    UsersList
  }
})
export default class Users extends Vue {
  mounted() {
    this.$store.dispatch("users/loadAll")
  }

  get users() {
    const users = this.$store.getters["users/getBy"](null, "last_name")

    return users ? users : []
  }
}
