
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Employer } from "@/types/Employer"
import { Attorney } from "@/types/Attorney"
import { cloneDeep } from "lodash"

@Component({
  components: { CardTemplate, DateField, DialogTemplate }
})
export default class Employers extends Vue {
  @Prop() private employers!: Employer[]
  @Prop() private attorneys!: Attorney[]

  private addEmployer = new Employer()
  private edit = false
  private editEmployer = new Employer()

  editThisEmployer(employer: Employer) {
    this.editEmployer = cloneDeep(employer)
    ;(this.$refs.employerEditDialog as DialogTemplate).open(true)
  }

  get attorneyId() {
    const attorneyId = this.editEmployer.attributes.attorney_id
    return attorneyId ? attorneyId.toString() : undefined
  }

  set attorneyId(newValue: string) {
    this.editEmployer.attributes.attorney_id = parseInt(newValue)
  }

  save() {
    this.$store.dispatch("employers/create", this.addEmployer)
  }

  update() {
    this.$store.dispatch("employers/update", this.editEmployer)
  }
}
