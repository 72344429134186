
import { Component, Vue, Watch } from "vue-property-decorator"
import { mapGetters } from "vuex"
import ErrorAlerts from "@/components/ErrorAlerts.vue"
import SuccessAlerts from "@/components/SuccessAlerts.vue"
import VersionAlert from "@/components/VersionAlert.vue"
import AutoLogoutWarning from "@/components/AutoLogoutWarning.vue"
import Search from "@/components/Search.vue"
import { AuthenticatedUser } from "@/types/Authentication"
import { Notification } from "./types/Notification"
import HelpBubble from "@/components/HelpBubble.vue"
import axios from "axios"
@Component({
  computed: {
    ...mapGetters("authentication", {
      user: "getUser",
      loggedIn: "getLoggedIn"
    }),
    ...mapGetters("config", {
      serverBranch: "getServerBranch",
      serverEnvironment: "getServerEnvironment",
      serverSha: "getServerSha",
      clientBranch: "getClientBranch"
    })
  },
  components: {
    ErrorAlerts,
    HelpBubble,
    SuccessAlerts,
    VersionAlert,
    AutoLogoutWarning,
    Search
  }
})
export default class App extends Vue {
  showVersion = false
  showAutoLogoutWarning = false
  navItems = [
    { name: "Home" },
    { name: "Claims" },
    { name: "Disbursements" },
    { name: "Deposits" },
    {
      name: "Payments",
      items: [
        {
          name: "Delinquent & Exception Policies",
          target: "DelinquentPolicies"
        },
        {
          name: "Orphaned Policies",
          target: "OrphanedPolicies"
        },
        { name: "Check Individual Policy Bill", target: "CheckBill" },
        { name: "Check Payment Entry", target: "CheckPayments" },
        { name: "CC Expiration", target: "CreditCardExpiration" },
        { name: "CC Policy Renewal Notification", target: "CreditCardNotify" },
        { name: "CC Individual Policy Bill", target: "CreditCardBill" },
        { name: "CC Payment Processing", target: "CreditCardPayments" },
        { name: "ACH Pre-Bill", target: "AchBill" },
        { name: "ACH Payment Processing", target: "AchPayments" }
      ]
    },
    {
      name: "Admin",
      items: [
        { name: "Payroll Additions", target: "PayrollAdditions" },
        { name: "Admin Bills", target: "AdminBills" },
        { name: "Billing", target: "BillingGroups" },
        { name: "Products" },
        { name: "Reports" },
        { name: "Advanced Search" },
        { name: "Board Meetings" },
        { name: "IIF Files", target: "Iifs" },
        { name: "Nachas" },
        { name: "LTC Premiums" },
        { name: "Users" },
        { name: "Attorneys" },
        { name: "Enrollers" },
        { name: "Letters" },
        { name: "Paid in Full", target: "PaidInFull" },
        { name: "Member Merge", target: "MemberMerge" }
      ]
    }
  ]

  // sub menu items for 'Payments'

  sidebar = false
  user!: AuthenticatedUser
  loggedIn!: boolean
  serverBranch!: string
  serverEnvironment!: string
  serverSha!: string
  clientBranch!: string
  private username = ""
  private zoom = 100

  @Watch("user")
  onAuthUserChanged(user: AuthenticatedUser) {
    if (user) {
      this.updateAbilities()
      this.loadNotifications()
    }
  }

  @Watch("timeout")
  onTimeoutChanged(isIdle: boolean) {
    if (this.loggedIn && isIdle) {
      this.showAutoLogoutWarning = true
    } else {
      this.showAutoLogoutWarning = false
    }
  }

  async created() {
    this.$store.dispatch("config/loadAbout")
    if (this.user) {
      this.updateAbilities()
      this.loadNotifications()
    }
    const savedZoom = localStorage.getItem("zoom")
    if (savedZoom) {
      this.zoom = parseInt(savedZoom)
    }

    // We ignore the version check for non production environments
    if (process.env.NODE_ENV === "production") {
      const version = await axios("/version.json", {
        headers: { "Cache-Control": "no-cache" }
      })

      const that = this //eslint-disable-line @typescript-eslint/no-this-alias
      const intervalId = setInterval(async function() {
        const newVersion = await axios("/version.json", {
          headers: { "Cache-Control": "no-cache" }
        })
        if (version.data.data.version !== newVersion.data.data.version) {
          that.showVersion = true
          clearInterval(intervalId)
        }
      }, 60000)
    }
  }

  toggleZoom() {
    this.zoom = this.zoom + 10
    if (this.zoom > 150) {
      this.zoom = 100
    }
    localStorage.setItem("zoom", this.zoom.toString())
  }

  get notifications() {
    return this.$store.getters["notifications/getAll"]
  }

  get unreadNotifications() {
    return this.notifications
      ? this.notifications.filter((el: Notification) => !el.attributes.read)
      : []
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      StaleObjectChannel: {
        connected() {
          console.log("StaleObjectChannel connected")
        },
        rejected() {
          console.log("StaleObjectChannel rejected")
        },
        received(data: {
          model_type: string
          id: string
          updated_at: string
          whodunnit: string
        }) {
          setTimeout(() => {
            that.handleStale(data)
          }, 5000)
        }
      },
      TotalsRecalculatedChannel: {
        connected() {
          console.log("TotalsRecalculatedChannel connected")
        },
        rejected() {
          console.log("TotalsRecalculatedChannel rejected")
        },
        received(data: { model_type: string; id: string }) {
          if (that.$store.getters["claims/getById"](data.id)) {
            that.$store.dispatch("claims/load", data.id)
          }
        }
      },
      NotificationsChannel: {
        connected() {
          console.log("Connected to Notifications Channel")
        },
        received(data: { user_id: string }) {
          if (that.user && parseInt(data.user_id) == that.user.id) {
            that.loadNotifications()
          }
        }
      }
    }
  }

  handleStale(data: { model_type: string; id: string; updated_at: string }) {
    const moduleName = data.model_type
    const id = data.id
    const updated_at = data.updated_at

    if (moduleName == "users" && parseInt(id) == this.user.id) {
      this.updateAbilities()
    } else {
      this.$store.commit(`${moduleName}/setDirty`, {
        id: id,
        updated_at: updated_at
      })
    }
  }

  async updateAbilities() {
    const u = await this.$store.dispatch("users/load", this.user.id)
    if (u && u.status == 200 && u.data.data) {
      this.$ability.update(u.data.data.attributes?.roles_list)
      this.username = u.data.data.attributes.first_name
    }
  }

  loadNotifications() {
    this.$store.dispatch("notifications/loadAll")
  }

  mounted() {
    this.$cable.subscribe({ channel: "StaleObjectChannel", room: "public" })
    this.$cable.subscribe({
      channel: "TotalsRecalculatedChannel",
      room: "public"
    })
    this.$cable.subscribe({
      channel: "NotificationsChannel",
      room: "public"
    })
  }

  get timeout() {
    return this.$store.state.idleVue ? this.$store.state.idleVue.isIdle : false
  }

  async logout() {
    await this.$store.dispatch("authentication/logout")
    this.$ability.update([])
    this.$router.go(0)
  }

  get envColor() {
    return this.clientBranch == "n/a"
      ? "#e64900" //orange
      : this.clientBranch == "master"
      ? "#034c9c" //blue
      : "accent"
  }
}
