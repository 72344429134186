import "@/class-component-hooks" // Manually register router hooks before importing any components
import Vue from "vue"
import * as Sentry from "@sentry/vue"
import { Integrations } from "@sentry/tracing"

import App from "@/App.vue"
import ActionCableVue from "actioncable-vue"
import router from "@/router"
import store from "@/store"
import MittPlugin from "@/plugins/MittPlugin"
import vuetify from "@/plugins/vuetify"
import IdleVue from "idle-vue"
import { abilitiesPlugin } from "@casl/vue"
import ability from "./services/ability"
import VueMask from "v-mask"

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from "chart.js"

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

Vue.config.productionTip = false

const timeout = parseFloat(process.env.VUE_APP_IDLE_TIMEOUT)

if (timeout) {
  Vue.use(IdleVue, { store, idleTime: timeout * 1000 })
}
Vue.use(VueMask)

Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "localhost:3000",
        "stage.api.kilo.outsideopen.dev",
        "prod.api.kilo.outsideopen.dev",
        /^\//
      ]
    })
  ],
  tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_SAMPLE_RATE)
})

Vue.prototype.window = window

Vue.use(MittPlugin)
Vue.use(abilitiesPlugin, ability)

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: process.env.VUE_APP_WS_URL
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
