import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Recovery } from "@/types/Recovery"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Recovery[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Recovery>, RootState> = {
  async reject(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<Recovery>, RootState>,
    { id }
  ) {
    try {
      const data = await axios.put(
        `/recoveries/${id}/reject`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getStats({
    dispatch,
    rootGetters
  }: ActionContext<State<Recovery>, RootState>) {
    try {
      const data = await axios.get(
        "/recoveries/stats",
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getRawStats(
    { dispatch, rootGetters }: ActionContext<State<Recovery>, RootState>,
    params: { month: string; classification: string }
  ) {
    try {
      const data = await axios.get(
        `/recoveries/raw_stats?month=${params.month}&classification=${params.classification}`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<Recovery>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
