
import { Component, Vue, Watch } from "vue-property-decorator"
import AccountChip from "@/components/AccountChip.vue"
import DateField from "@/components/DateField.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { cloneDeep } from "lodash"
import CurrencyField from "@/components/CurrencyField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Payment } from "@/types/Payment"
import { CreditCardBatch as BatchType } from "@/types/CreditCardBatch"

@Component({
  components: {
    AccountChip,
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate,
    PagedDataTable,
    PageTemplate
  }
})
export default class CreditCardBatch extends Vue {
  private editBatch: BatchType = new BatchType()
  private selectedPayments: Payment[] = []
  private loading = false
  private totalBatchAmount = "0"
  private totalPaidAmount = "0"
  private totalRejectedAmount = "0"
  private totalScheduledAmount = "0"
  private totalBatchCount = 0
  private totalPaidCount = 0
  private totalRejectedCount = 0
  private totalScheduledCount = 0
  private selectedTotal = "0"
  private paymentToRemove: Payment = null
  private paymentToReject: Payment = null

  get id() {
    return this.$route.params.id
  }

  get batch() {
    return this.$store.getters["creditCardBatches/getById"](this.id)
  }

  get payments() {
    const tempPayments = this.$store.getters["payments/getBy"]({
      credit_card_batch_id: this.id
    })

    return tempPayments
  }

  get sortablePayments() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.payments.map((el: any) => {
      const m = this.paymentMember(el)
      if (m != null) {
        el.member_last = m.attributes.last_name
        el.member_first = m.attributes.first_name
      }
      el.status = el.attributes.status
      return el
    })
  }

  get paymentsAllPaid() {
    return (
      this.payments.length ==
      this.payments.filter((el: Payment) => {
        return (
          el.attributes.status == "Paid" || el.attributes.status == "Rejected"
        )
      }).length
    )
  }

  get title() {
    if (this.batch) {
      return this.batch.attributes.name
    } else {
      return "Unnamed Upload file"
    }
  }

  statusClass(item: Payment) {
    switch (item.attributes.status) {
      case "Rejected":
        return "red"
      case "Scheduled":
        return "blue"
      case "Paid":
        return "green"
      default:
        return ""
    }
  }

  @Watch("batch")
  onBatchChange(value: BatchType) {
    if (value) {
      this.editBatch = cloneDeep(value)
    } else {
      this.editBatch = new BatchType()
    }
  }

  @Watch("selectedPayments")
  onSelectedChange(value: Payment[]) {
    const amounts = value.map((item: Payment) =>
      parseFloat(item.attributes.amount)
    )
    if (amounts.length == 0) {
      this.selectedTotal = "0"
    } else if (amounts.length == 1) {
      this.selectedTotal = amounts[0].toString()
    } else {
      this.selectedTotal = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  @Watch("payments")
  onPaymentsChange(value: Payment[]) {
    const policyIds = value
      .map((el: Payment) => {
        return el.attributes.payable_type == "Policy"
          ? el.attributes.payable_id
          : null
      })
      .filter(n => n)

    if (policyIds.length > 0) {
      this.$store.dispatch("policies/loadBy", {
        id: policyIds,
        include: ["member"]
      })
    }

    const amounts = value.map((payment: Payment) =>
      parseFloat(payment.attributes.amount)
    )
    this.totalBatchCount = amounts.length

    const amountsPaid = value.filter(
      (payment: Payment) => payment.attributes.status == "Paid"
    )
    this.totalPaidCount = amountsPaid.length

    const amountsRejected = value.filter(
      (payment: Payment) => payment.attributes.status == "Rejected"
    )
    this.totalRejectedCount = amountsRejected.length

    const amountsScheduled = value.filter(
      (payment: Payment) => payment.attributes.status == "Scheduled"
    )
    this.totalScheduledCount = amountsScheduled.length

    if (amounts.length == 0) {
      this.totalBatchAmount = "0"
    } else if (amounts.length == 1) {
      this.totalBatchAmount = amounts[0].toString()
    } else {
      this.totalBatchAmount = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }

    this.totalPaidAmount =
      amountsPaid.length == 0
        ? "0"
        : amountsPaid
            .map((payment: Payment) => parseFloat(payment.attributes.amount))
            .reduce((prev: number, cur: number) => {
              return prev + cur
            })
            .toString()

    this.totalScheduledAmount =
      amountsScheduled.length == 0
        ? "0"
        : amountsScheduled
            .map((payment: Payment) => parseFloat(payment.attributes.amount))
            .reduce((prev: number, cur: number) => {
              return prev + cur
            })
            .toString()

    this.totalRejectedAmount =
      amountsRejected.length == 0
        ? "0"
        : amountsRejected
            .map((payment: Payment) => parseFloat(payment.attributes.amount))
            .reduce((prev: number, cur: number) => {
              return prev + cur
            })
            .toString()
  }

  paymentMember(payment: Payment) {
    const payableType = payment.attributes.payable_type
    if (payableType != "Policy") {
      return null
    }

    const payableId = payment.attributes.payable_id

    const policy = this.$store.getters["policies/getById"](payableId)
    if (policy == null) {
      return null
    }
    const member = this.$store.getters["members/getById"](
      policy.attributes.member_id
    )
    return member
  }

  async created() {
    await this.$store.dispatch("creditCardBatches/load", {
      id: this.id,
      include: ["payments"]
    })

    this.editBatch = cloneDeep(this.batch)
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    this.$store.dispatch("creditCardBatches/load", {
      id: to.params.id,
      include: ["payments"]
    })

    next()
  }

  async paySelectedPayments() {
    this.loading = true
    for (let i = 0; i < this.selectedPayments.length; i++) {
      //eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      if (this.$can("update", this.selectedPayments[i])) {
        const tempPayment = cloneDeep(this.selectedPayments[i])
        tempPayment.attributes.status = "Paid"
        tempPayment.attributes.date_received = new Date()
        await this.$store.dispatch("payments/update", tempPayment)
      }
    }
    this.loading = false
  }

  initiateRemove(item: Payment) {
    this.paymentToRemove = item
    ;(this.$refs.dialogRemove as DialogTemplate).open(true)
  }

  initiateReject(item: Payment) {
    this.paymentToReject = item
    ;(this.$refs.dialogReject as DialogTemplate).open(true)
  }

  async removeFromBatch() {
    this.loading = true
    const item = this.paymentToRemove
    item.attributes.credit_card_batch_id = null
    await this.$store.dispatch("payments/update", item)
    this.makeFile()
    this.loading = false
  }

  async rejectPayment() {
    this.loading = true
    const item = this.paymentToReject
    await this.$store.dispatch("payments/reject", {
      id: item.id,
      memo: "Payment unsuccessful at upload"
    })
    this.loading = false
  }

  makeFile() {
    this.$store.dispatch("creditCardBatches/makeFile", { id: this.id })
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  save() {
    this.$store.dispatch("creditCardBatches/update", this.editBatch)
  }

  sendOnToday() {
    this.editBatch.attributes.send_date = new Date()
    this.save()
  }

  get headers() {
    return [
      {
        text: "AMOUNT",
        value: "amount",
        class: "text-left caption"
      },
      {
        text: "DATE",
        value: "date",
        class: "text-left caption"
      },
      {
        text: "LAST",
        value: "member_last",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "FIRST",
        value: "member_first",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "STATUS",
        value: "status",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "",
        value: "reject-action",
        class: "text-left caption",
        sortable: false,
        width: "75px"
      },
      {
        text: "MEMO",
        value: "attributes.memo",
        class: "text-left caption"
      },
      {
        text: "",
        value: "actions",
        class: "text-right",
        sortable: false
      }
    ]
  }
}
