
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import PageTemplateList from "@/components/PageTemplateList.vue"
import ProductsList from "@/components/ProductsList.vue"
import ProductForm from "@/components/ProductForm.vue"
import { Product } from "@/types/Product"

@Component({
  components: {
    CardTemplate,
    PageTemplateList,
    ProductsList,
    ProductForm
  }
})
export default class Products extends Vue {
  private newProduct = new Product()

  handleSave() {
    this.$store.dispatch("products/create", {
      data: this.newProduct,
      successMessage: "New product added"
    })
    this.reset()
  }

  reset() {
    this.newProduct = new Product()
  }

  get products() {
    const products = this.$store.getters["products/getAll"]

    return products ? products : []
  }

  get retiredProducts() {
    return this.products.filter((el: Product) => {
      return el.attributes.retired_on
    })
  }

  get activeProducts() {
    return this.products.filter((el: Product) => {
      return !el.attributes.retired_on
    })
  }

  created() {
    this.$store.dispatch("products/loadAll")
  }
}
