
import { Component, Vue, Prop } from "vue-property-decorator"
import { Recovery } from "@/types/Recovery"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CurrencyField,
    DateField
  }
})
export default class RecoveryDetails extends Vue {
  @Prop() private recovery!: Recovery

  get returnTypeText() {
    const p = this.recovery.attributes.is_overpayment
      ? ""
      : this.recovery.attributes.is_final
      ? "Final "
      : "Partial "
    const r = this.recovery.attributes.is_overpayment
      ? "Non-Recoverable Overpayment"
      : "Recoverable Amount"

    return `${p}Return of ${r}`
  }
}
