
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate
  }
})
export default class ClaimDatesLtc extends Vue {
  @Prop() private claim_start!: string
  @Prop() private claim_end!: string
  @Prop() private elimination_start!: string
  @Prop() private elimination_end!: string
  @Prop() private death_date!: string

  private editClaimStart = ""
  private editClaimEnd = ""
  private editEliminationStart = ""
  private editEliminationEnd = ""
  private editDeathDate = ""

  created() {
    this.editClaimStart = this.claim_start
    this.editClaimEnd = this.claim_end
    this.editEliminationStart = this.elimination_start
    this.editEliminationEnd = this.elimination_end
    this.editDeathDate = this.death_date
  }

  @Watch("claim_start")
  onClaimStartChanged(value: string) {
    this.editClaimStart = value
  }

  @Watch("claim_end")
  onClaimEndChanged(value: string) {
    this.editClaimEnd = value
  }

  @Watch("elimination_start")
  onEliminatioinStartChanged(value: string) {
    this.editEliminationStart = value
  }

  @Watch("elimination_end")
  onEliminatioinEndChanged(value: string) {
    this.editEliminationEnd = value
  }

  @Watch("death_date")
  onDeathDateChanged(value: string) {
    this.editDeathDate = value
  }

  saveDates() {
    this.$emit("save-dates", {
      claimStart: this.editClaimStart,
      claimEnd: this.editClaimEnd,
      eliminationStart: this.editEliminationStart,
      eliminationEnd: this.editEliminationEnd,
      deathDate: this.editDeathDate
    })
  }
}
