
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import NewAttorney from "@/components/NewAttorney.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Attorney } from "@/types/Attorney"

@Component({
  components: {
    CardTemplate,
    NewAttorney,
    PagedDataTable,
    PageTemplate
  }
})
export default class Attorneys extends Vue {
  private loading = false
  private total = 0
  private startingPerPage = 5
  private perPage = 5
  private currentPage = 1

  private attorneys: Attorney[] = []

  private options = {
    sortBy: "attributes.name",
    sortDesc: true,
    mustSort: true
  }

  created() {
    this.loadAttorneys(1, this.startingPerPage)
  }

  getAttorneys(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadAttorneys(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  newAttorneySaved() {
    this.loadAttorneys(1, this.startingPerPage)
  }

  async loadAttorneys(page: number, perPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("attorneys/loadBy", {
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.attorneys = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  goToAttorney(attorney: Attorney) {
    this.$router.push({
      name: "Attorney",
      params: { id: attorney.id.toString() }
    })
  }

  get headers() {
    return [
      {
        text: "NAME",
        value: "attributes.name",
        class: "text-left caption"
      },
      {
        text: "FIRM",
        value: "attributes.firm",
        class: "text-left caption"
      }
    ]
  }
}
