
import { Component, Vue, Prop } from "vue-property-decorator"
import { Payment } from "@/types/Payment"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate
  }
})
export default class Unposted extends Vue {
  @Prop() private payments!: Payment[]

  private cancelPaymentItem: Payment | null = null

  initiateCancel(item: Payment) {
    this.cancelPaymentItem = item
    ;(this.$refs.cancelDialog as DialogTemplate).open(true)
  }

  async cancelPayment() {
    await this.$store.dispatch("payments/update", {
      id: this.cancelPaymentItem.id,
      attributes: { status: "Cancelled" }
    })

    this.$emit("payment-cancelled")
  }
}
