import Model from "./Model"

export class Letter extends Model<Letter> {
  constructor(response?: Letter) {
    super(response as Letter)
  }
  id: string
  attributes!: {
    name: string
    body: string
  }
  static get modelName() {
    return "Letter"
  }
}
