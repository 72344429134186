import { RootState } from "@/types/RootState"
import axios from "axios"
import { ActionContext, Commit, Dispatch } from "vuex"
import modelToMutation from "./modelToMutation"
import errorHandler from "./errorHandler"
import { isEmpty } from "lodash"

async function getData(
  url: string,
  params: { include?: string[] | string; [key: string]: any }, // eslint-disable-line @typescript-eslint/no-explicit-any
  commit: Commit,
  dispatch: Dispatch,
  rootGetters: any // eslint-disable-line @typescript-eslint/no-explicit-any
) {
  try {
    if (Array.isArray(params?.include)) {
      params.include = params.include.join(",")
    }

    const queryParams = !isEmpty(params)
      ? {
          params: params
        }
      : {}

    if (queryParams.params) {
      for (const [key, value] of Object.entries(queryParams.params)) {
        if (!value) {
          url += url.includes("?") ? `%${key}` : `?${key}`
          delete queryParams.params[key]
        }
      }
    }

    const data = await axios.get(
      url,
      Object.assign(queryParams, rootGetters["config/getAPIConfig"])
    )
    commit("set", data && data.data.data)
    commit("setMeta", data && data.data.meta)

    if (data.data.included) {
      data.data.included.forEach((el: { type: string }) => {
        const mutation = modelToMutation(el.type)
        commit(mutation, el, { root: true })
      })
    }

    return data
  } catch (error) {
    errorHandler(dispatch, error)
  }
}

async function upsert(
  method: "create" | "update",
  type: string,
  data: Data,
  commit: Commit,
  dispatch: Dispatch,
  rootGetters: any // eslint-disable-line @typescript-eslint/no-explicit-any
) {
  try {
    const record = "attributes" in data ? data : data.data
    const include = "data" in data ? data.include : null
    const successMessage = "data" in data ? data.successMessage : null
    if (!record.attributes || (method == "update" && !record.id)) return

    const includeString = include
      ? Array.isArray(include)
        ? `?include=${include.join(",")}`
        : `?include=${include}`
      : ""

    let response
    if (method == "update") {
      response = await axios.put(
        `/${type}/${record.id}${includeString}`,
        record.attributes,
        rootGetters["config/getAPIConfig"]
      )
    } else {
      response = await axios.post(
        `/${type}${includeString}`,
        record.attributes,
        rootGetters["config/getAPIConfig"]
      )
    }

    commit("set", response && response.data.data)

    if (response.data.included) {
      response.data.included.forEach((el: { type: string }) => {
        const mutation = modelToMutation(el.type)
        commit(mutation, el, { root: true })
      })
    }

    if (successMessage) {
      dispatch("alerts/addSuccess", successMessage, { root: true })
    }
    return response
  } catch (error) {
    errorHandler(dispatch, error)
  }
}

export default {
  async loadAll(
    { state, commit, dispatch, rootGetters }: ActionContext<any, RootState>, // eslint-disable-line @typescript-eslint/no-explicit-any
    include?: string | string[]
  ) {
    const data = include ? { include: include } : {}
    return getData(`/${state.type}`, data, commit, dispatch, rootGetters)
  },
  async loadBy(
    { state, commit, dispatch, rootGetters }: ActionContext<any, RootState>, // eslint-disable-line @typescript-eslint/no-explicit-any
    data: { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    return getData(`/${state.type}`, data, commit, dispatch, rootGetters)
  },
  async load(
    { state, commit, dispatch, rootGetters }: ActionContext<any, RootState>, // eslint-disable-line @typescript-eslint/no-explicit-any
    data: string | number | { id: string | number; include: string[] }
  ) {
    const include = typeof data == "object" ? { include: data.include } : {}
    const id = typeof data == "object" ? data.id : data

    return getData(
      `/${state.type}/${id}`,
      include,
      commit,
      dispatch,
      rootGetters
    )
  },
  async create(
    { state, commit, dispatch, rootGetters }: ActionContext<any, RootState>, // eslint-disable-line @typescript-eslint/no-explicit-any
    data: Data
  ) {
    return upsert("create", state.type, data, commit, dispatch, rootGetters)
  },
  async update(
    { state, commit, dispatch, rootGetters }: ActionContext<any, RootState>, // eslint-disable-line @typescript-eslint/no-explicit-any
    data: Data
  ) {
    return upsert("update", state.type, data, commit, dispatch, rootGetters)
  },
  async delete(
    { state, commit, dispatch, rootGetters }: ActionContext<any, RootState>, // eslint-disable-line @typescript-eslint/no-explicit-any
    id: number
  ) {
    try {
      await axios.delete(
        `/${state.type}/${id}`,
        rootGetters["config/getAPIConfig"]
      )
      commit("remove", id)
    } catch (error) {
      dispatch("alerts/addAxiosError", error, { root: true })
    }
  }
}

type Model = {
  id: string | number
  attributes: { [key: string]: any } // eslint-disable-line @typescript-eslint/no-explicit-any
}

type Data =
  | Model
  | {
      data: Model
      include?: string | string[]
      successMessage?: string
    }
