
import { Component, Prop, Vue } from "vue-property-decorator"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { AdvancedSearch, TagValue } from "@/types/AdvancedSearch"
import { Tag } from "@/types/Tag"

@Component({
  components: { DialogTemplate }
})
export default class AdvancedSearchQueryDialog extends Vue {
  @Prop() advancedSearch: AdvancedSearch
  @Prop() clearOnSave: false
  @Prop() icon: string
  @Prop() iconSize: string

  private name = ""
  private query = ""
  private tags: string[] = []

  get tagNames() {
    return this.$store.getters["tags/getAll"].map(
      (el: Tag) => el.attributes.name
    )
  }

  mounted() {
    this.name = this.advancedSearch.attributes.name
    this.query = this.advancedSearch.attributes.query
    this.tags = this.advancedSearch?.attributes?.tags?.map(
      (el: TagValue) => el.name
    )
  }

  save() {
    const search = new AdvancedSearch()
    search.id = this?.advancedSearch?.id
    search.attributes.name = this.name
    search.attributes.query = this.query

    this.$emit("save", { search, tags: this.tags })

    if (this.clearOnSave) {
      this.clear()
    }
  }

  clear() {
    this.name = ""
    this.query = ""
    this.tags = []
  }
}
