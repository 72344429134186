import { MutationTree, ActionTree } from "vuex"
import { ClaimForm } from "@/types/ClaimForm"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: ClaimForm[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<ClaimForm>, RootState> = {}

const mutations: MutationTree<State<ClaimForm>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
