
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Iif } from "@/types/Iif"

import { cloneDeep } from "lodash"

@Component({
  components: {
    DialogTemplate
  }
})
export default class IifEditDialog extends Vue {
  @Prop() private iif: Iif

  private editIif: Iif = new Iif(cloneDeep(this.iif))

  @Watch("iif")
  onIifChanged(iif: Iif) {
    this.editIif = new Iif(cloneDeep(iif))
  }

  async save() {
    await this.$store.dispatch("iifs/update", this.editIif)
    this.$emit("reload", this.editIif)
  }

  reset() {
    this.editIif = new Iif(cloneDeep(this.iif))
  }
}
