import { render, staticRenderFns } from "./DepartmentInfoLtd.vue?vue&type=template&id=4024fa1f&scoped=true&"
import script from "./DepartmentInfoLtd.vue?vue&type=script&lang=ts&"
export * from "./DepartmentInfoLtd.vue?vue&type=script&lang=ts&"
import style0 from "./DepartmentInfoLtd.vue?vue&type=style&index=0&id=4024fa1f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4024fa1f",
  null
  
)

export default component.exports