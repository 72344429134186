
import { Component, Vue, Prop, Watch } from "vue-property-decorator"

@Component
export default class CurrencyField extends Vue {
  @Prop() private display!: boolean
  @Prop() private dense!: boolean
  @Prop() private value!: string
  @Prop() private label!: string
  @Prop() private asNeg!: boolean
  @Prop() private required!: boolean
  @Prop() private disabled!: boolean
  private currencyRegex = /[^0-9.-]/g

  private newValue: string = this.value
    ? this.format(
        parseFloat(this.value.replace(this.currencyRegex, "")).toFixed(2)
      )
    : ""
  private selection = 0

  @Watch("display")
  onDisplayChange() {
    this.checkChange()
  }

  @Watch("value")
  onValueChange() {
    this.checkChange()
  }

  checkChange() {
    if (!this.value) {
      this.newValue = ""
    } else if (
      !this.display &&
      this.newValue !=
        this.format(
          parseFloat(this.value.replace(this.currencyRegex, "")).toFixed(2)
        )
    ) {
      this.newValue = this.format(
        parseFloat(this.value.replace(this.currencyRegex, "")).toFixed(2)
      )
    }
  }

  formatDisplay(input: string) {
    input = input ? input.replace(this.currencyRegex, "") : ""

    let s = !isNaN(parseFloat(input))
      ? parseFloat(input).toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        })
      : ""

    const negative = (this.asNeg && s[0] != "-") || (!this.asNeg && s[0] == "-")
    s = s.replace("-", "")
    if (negative) {
      s = "(" + s + ")"
    }
    return s
  }

  format(input: string) {
    const isNeg = input.length > 0 && input.charAt(0) === "-"

    if (isNeg) {
      input = input.substring(1)
    }

    input = input.replace(/[-]{2,}/g, "-")
    input = input.replace(/[^0-9-]/g, "")
    input = input.replace(/^0+/g, "")

    let formatted = "0.00"

    if (input.length == 0) {
      formatted = "0.00"
    } else if (input.length == 1) {
      formatted = "0.0" + input
    } else if (input.length == 2) {
      formatted = "0." + input
    } else {
      input = input.slice(0, -2) + "." + input.slice(-2)
      formatted = parseFloat(input)
        .toLocaleString("en-US", {
          style: "currency",
          currency: "USD"
        })
        .replace("$", "")
    }

    if (isNeg) {
      formatted = "-" + formatted
    }

    return formatted
  }

  updateValue() {
    this.newValue = this.format(this.newValue)

    this.$emit("input", this.newValue.replace(/[,]/g, ""))
  }

  keydown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement

    this.selection =
      target.value != null && target.selectionStart != null
        ? target.value.length - target.selectionStart
        : 0
  }

  keyup(event: KeyboardEvent) {
    this.$emit("keyup", event)
    if ([37, 38, 39, 40].includes(event.keyCode)) {
      return
    }
    const target = event.target as HTMLInputElement

    target.value = target.value.replace(/[^^0-9.,-]/g, "")
    target.selectionEnd = target.value
      ? target.value.length - this.selection
      : 0
  }
}
