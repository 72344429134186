import { Alert } from "@/types/Alert"
import State from "@/types/State"
import { MutationTree, ActionContext } from "vuex"
import { RootState } from "@/types/RootState"
import setDirty from "@/store/modules/dirtyFlag"

function errorMessage(error: {
  message: string
  response: null | {
    status: string
    statusText: string
    data: { message: string } | string
  }
}) {
  if (error.response) {
    if (typeof error.response.data == "string") {
      if (error.response.status == "401") {
        if (
          error.response.data.includes("Signature has expired") ||
          error.response.data.includes("revoked token")
        ) {
          return "You have been logged out due to inactivity"
        }
      }
      return error.response.data
    }
    return error.response.data.message
  } else {
    return error.message
  }
}

const state: { all: Alert[] } = {
  all: []
}

const getters = {
  getAll: (state: State<Alert>) => {
    return state.all
  },

  getSuccesses: (state: State<Alert>) => {
    return state.all.filter(alert => alert.alertType == "success")
  },

  getFailures: (state: State<Alert>) => {
    return state.all.filter(alert => alert.alertType != "success")
  }
}

const actions = {
  async addSuccess(
    { commit }: ActionContext<State<Alert>, RootState>,
    msg: string
  ) {
    commit("set", {
      alertType: "success",
      message: msg,
      UUID: ""
    })
  },
  async addError(
    { commit }: ActionContext<State<Alert>, RootState>,
    msg: string
  ) {
    commit("set", {
      alertType: "error",
      message: msg,
      UUID: ""
    })
  },
  async addAxiosError(
    { commit, getters }: ActionContext<State<Alert>, RootState>,
    error: {
      message: string
      response: null | {
        status: string
        statusText: string
        data: { message: string } | string
      }
    }
  ) {
    const msg = errorMessage(error)

    const failures = getters.getFailures
    const found = failures?.find((err: Alert) => err.message == msg)

    if (!found) {
      commit("set", {
        alertType: "error",
        message: msg,
        UUID: ""
      })
    }
  },
  async addWarning(
    { commit }: ActionContext<State<Alert>, RootState>,
    msg: string
  ) {
    commit("set", {
      alertType: "warning",
      message: msg,
      UUID: ""
    })
  }
}

const mutations: MutationTree<State<Alert>> = {
  setDirty,
  set(state: State<Alert>, payload: Alert) {
    if (payload.UUID.length == 0) {
      let uuid = ""
      const src = "12345abcde"
      for (let i = 0; i < 6; i++) {
        uuid += src[Math.floor(Math.random() * src.length)]
      }
      payload.UUID = uuid
    }
    state.all.push(payload)
  },
  removeAlert(state: State<Alert>, uuid: string) {
    const uuids = state.all.map(el => el.UUID)
    const index = uuids.indexOf(uuid)
    state.all.splice(index, 1)
  }
}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
