
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DateField
  }
})
export default class SeparatorPages extends Vue {
  private fromDate: Date = null
  private toDate: Date = null
  private type = "fire"
  private working = false

  async getPages() {
    this.working = true
    const response = await axios.get(
      "/memberships/separator_pages",
      Object.assign(
        {
          params: {
            type: this.type,
            from_date: this.fromDate,
            to_date: this.toDate
          },
          responseType: "blob"
        },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "separator_pages.pdf")
    document.body.appendChild(fURL)

    fURL.click()
    this.working = false
  }
}
