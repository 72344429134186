
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import PayrollAdditionsProduct from "@/components/PayrollAdditionsProduct.vue"

@Component({
  components: { CardTemplate, PayrollAdditionsProduct }
})
export default class Signatures extends Vue {
  @Prop() private department!: string
  @Prop() private data!: []
  @Prop() private ltd!: boolean
  @Prop() private ltc!: boolean
  @Prop() private eip!: boolean
}
