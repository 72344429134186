
import { Component, Vue } from "vue-property-decorator"

@Component({})
export default class PasswordReset extends Vue {
  welcomeMessage = "Welcome to CPSA-kilo. Please start by creating a password."
  header = "New Account Setup"
  password = ""
  password2 = ""
  showPass = false
  errorMessage = ""
  errorMessage2 = ""

  get token() {
    return this.$route.params.token
  }

  get showForm() {
    return !this.welcomeMessage.includes("expired")
  }

  async created() {
    const valid = await this.$store.dispatch("users/validateToken", this.token)

    if (valid.status != 200 || !valid.data || valid.data.status == "Invalid") {
      this.welcomeMessage =
        "Your token has expired. Please contact an administrator to resend it."
    } else if (valid.data.previous) {
      this.header = "Password Reset"
      this.welcomeMessage = ""
    }
  }

  async submit() {
    this.errorMessage = ""
    this.errorMessage2 = ""
    if (!this.password) {
      this.errorMessage = "Password is required"
    } else if (this.password.length < 8) {
      this.errorMessage = "Minimum 8 characters"
    } else if (this.password.toLowerCase().includes("password")) {
      this.errorMessage = 'Cannot contain the word "password"'
    } else if (this.password != this.password2) {
      this.errorMessage2 = "The passwords don't match"
    } else {
      const result = await this.$store.dispatch("users/setPassword", {
        password: this.password,
        password2: this.password2,
        token: this.token
      })

      if (result && result.status == 200) {
        this.$store.dispatch(
          "alerts/addSuccess",
          "Your password has been set. Use this password to login.",
          { root: true }
        )
        this.$router.push({ path: "/login" })
      }
    }
  }
}
