
import { Component, Vue } from "vue-property-decorator"
import AdminBills from "@/components/AdminBills.vue"
import AdminFees from "@/components/AdminFees.vue"
import EnrollerCommissionReports from "@/components/TrusteeAdminReports.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { SortOrder } from "@/store/utils/genericGetters"

@Component({
  components: {
    AdminBills,
    AdminFees,
    EnrollerCommissionReports,
    PageTemplate
  }
})
export default class AdminBillsView extends Vue {
  get fireAdminFees() {
    return this.$store.getters["adminFees/getBy"](
      { classification: "fire", eip: false },
      "start_date",
      SortOrder.desc
    )
  }

  get lawAdminFees() {
    return this.$store.getters["adminFees/getBy"](
      { classification: "law", eip: false },
      "start_date",
      SortOrder.desc
    )
  }
  get fireAdminFeesEip() {
    return this.$store.getters["adminFees/getBy"](
      { classification: "fire", eip: true },
      "start_date",
      SortOrder.desc
    )
  }

  get lawAdminFeesEip() {
    return this.$store.getters["adminFees/getBy"](
      { classification: "law", eip: true },
      "start_date",
      SortOrder.desc
    )
  }

  mounted() {
    this.$store.dispatch("adminFees/loadAll")
  }
}
