import Model from "./Model"

export class IncomeItem extends Model<IncomeItem> {
  constructor(incomeItem?: IncomeItem) {
    super(incomeItem as IncomeItem)
  }
  id: string
  attributes!: {
    income_worksheet_id: number
    name: string
    amount: string
  }
  static get modelName() {
    return "IncomeItem"
  }
}
