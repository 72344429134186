import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Department extends Model<Department> {
  constructor(response?: Department) {
    super(response as Department)
    if (response) {
      this.attributes.effective_date = DateHelper.stringToDate(
        this.attributes.effective_date
      )
      this.attributes.cancel_date = DateHelper.stringToDate(
        this.attributes.cancel_date
      )
      this.attributes.eip_effective_date = DateHelper.stringToDate(
        this.attributes.eip_effective_date
      )
      this.attributes.ltc_effective_date = DateHelper.stringToDate(
        this.attributes.ltc_effective_date
      )

      this.attributes.whole_date = DateHelper.stringToDate(
        this.attributes.whole_date
      )
    }
  }
  id: string
  attributes!: {
    name: string
    department_type: string
    status: string
    address_id: number
    ltd_balance: string
    ltc_balance: string
    eip_balance: string
    billing_contact_address_id: number
    north_south: string
    label: boolean
    effective_date: Date | string
    billing_name: string
    billing_attn: string
    billing_contact_name_1: string
    billing_contact_name_2: string
    billing_contact_phone_1: string
    billing_contact_phone_2: string
    billing_contact_email_1: string
    billing_contact_email_2: string
    eip_billing_contact_email_1: string
    eip_billing_contact_email_2: string
    eip_billing_contact_phone_1: string
    eip_billing_contact_phone_2: string
    billing_contact_misc: string
    billing_method: string
    billing_salutaion: string
    director_name: string
    director_email_1: string
    director_email_2: string
    director_phone_work: string
    director_phone_mobile: string
    director_phone_home: string
    director_label: boolean
    director_voting: boolean
    bill_note_ltd: string
    president_name: string
    president_phone_work: string
    president_phone_mobile: string
    president_email: string
    president_address_id: number
    vp_name: string
    vp_phone_work: string
    vp_phone_mobile: string
    vp_email: string
    vp_address_id: number
    treasurer_name: string
    treasurer_phone_work: string
    treasurer_phone_mobile: string
    treasurer_email: string
    treasurer_address_id: number
    secretary_name: string
    secretary_phone_work: string
    secretary_phone_mobile: string
    secretary_email: string
    secretary_address_id: number
    updated_at: string
    classification: string
    employer_id: number
    ltc_phone: string
    ltc_fax: string
    ltc_email: string
    ltc_web: string
    ltc_contact: string
    ltc_alt_contact: string
    ltc_alt_email: string
    ltc_alt_phone_1: string
    ltc_alt_phone_2: string
    ltc_how_far: string
    ltc_due: string
    ltc_address_id: number
    ltc_effective_date: Date | string
    merged_department_id: number
    merged_date: Date | string
    group_rate_ltc: boolean
    ltd_billable: boolean
    ltc_billable: boolean
    eip_billable: boolean
    cancel_date: Date | string
    ltc_billing_name: string
    eip_billing_name: string
    eip_contact: string
    eip_address_id: number
    eip_effective_date: Date | string
    prior_coverage: boolean
    prior_coverage_source: string
    live: boolean
    is_child: boolean
    uuid: string
    whole_date: Date | string
    composite_rate: string
  }
  static get modelName() {
    return "Department"
  }
}
