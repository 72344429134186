
import { Component, Prop, Vue } from "vue-property-decorator"
import { Member } from "@/types/Member"
import { Payment } from "@/types/Payment"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import MonthField from "@/components/MonthField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import VueMask from "v-mask"
Vue.use(VueMask)
type removeData = {
  member_id: string
  end_date: Date
  name: string
  showMenu: boolean
}
type addData = {
  member: Member
  start_date: Date
}
@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate,
    MonthField
  }
})
export default class RefundEntry extends Vue {
  @Prop() private payableType!: string
  @Prop() private payableId!: string
  @Prop() private paymentType!: string
  @Prop() private eip!: boolean
  private loading = false
  private validPayment = false
  private newPayment = new Payment()
  private paymentMethods = ["Check", "CreditCard", "ACH"]
  private fullPremiumPayment = true
  private askConfirm = false
  private saveConfirmed = false

  created() {
    this.reset()
  }
  reset() {
    this.newPayment = new Payment()
    this.newPayment.attributes.date_received = new Date()
    this.newPayment.attributes.payment_method = "Check"
    this.newPayment.attributes.status = "Paid"
    this.newPayment.attributes.memo = "Refund"
    this.newPayment.attributes.product_type = this.paymentType
    this.newPayment.attributes.eip = this.eip
  }

  blur(member: removeData) {
    setTimeout(() => {
      member.showMenu = false
    }, 500)
  }

  mirrorPremium() {
    if (this.fullPremiumPayment) {
      this.newPayment.attributes.premium_amount = this.newPayment.attributes.amount
    }
  }
  async save() {
    this.loading = true
    this.newPayment.attributes.payable_type = this.payableType
    this.newPayment.attributes.payable_id = parseInt(this.payableId)
    if (this.fullPremiumPayment) {
      this.newPayment.attributes.premium_amount = this.newPayment.attributes.amount
    }
    this.newPayment.attributes.amount = (
      -1.0 * Number(this.newPayment.attributes.amount)
    ).toString()
    this.newPayment.attributes.premium_amount = (
      -1.0 * Number(this.newPayment.attributes.premium_amount)
    ).toString()
    await this.$store.dispatch("payments/create", {
      data: this.newPayment,
      successMessage: "Refund Added"
    })

    this.$emit("load-bills-and-payments")
    this.loading = false
    this.reset()
  }

  get oversAmount() {
    const amount = this.newPayment.attributes.amount
      ? Number(this.newPayment.attributes.amount)
      : 0
    const premium_amount = this.newPayment.attributes.premium_amount
      ? Number(this.newPayment.attributes.premium_amount)
      : 0

    return amount - premium_amount
  }

  get saveDisabled() {
    return !this.validPayment
  }
}
