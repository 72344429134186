import Model from "./Model"

export class Signature extends Model<Signature> {
  constructor(response?: Signature) {
    super(response as Signature)
  }
  id: string
  attributes!: {
    name: string
    titles: string
    view_doc_url: string
    download_doc_url: string
  }
  static get modelName() {
    return "Signature"
  }
}
