import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Notification } from "@/types/Notification"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Notification[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Notification>, RootState> = {
  async markAsRead(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<Notification>, RootState>,
    notification: Notification
  ) {
    axios
      .put(
        "/notifications/mark_as_read",
        { id: notification.id },
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {} } }) => {
        commit("set", data && data.data.data)
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  }
}
const mutations: MutationTree<State<Notification>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
