
import axios from "axios"
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import PageTemplate from "@/components/PageTemplate.vue"

class PifPolicy {
  policy_id: number
  number: string
  first_name: string
  last_name: string
  next_bill_on: string
  paid_in_full_date: string
  billing_frequency: string
  payment_method_type: string
}

@Component({
  components: {
    CardTemplate,
    DateField,
    PageTemplate
  }
})
export default class PaidInFull extends Vue {
  private policies: PifPolicy[] = []
  private loading = false

  created() {
    this.fetchPolicies()
  }

  async fetchPolicies() {
    this.loading = true
    this.policies = []

    const data = await this.$store.dispatch("policies/fetchPaidInFull")
    this.policies = data.data

    this.loading = false
  }

  clickRow(item: PifPolicy) {
    this.$router.push(`/policies/${item.policy_id}`)
  }

  async getPdf() {
    const response = await axios.get(
      "/policies/paid_in_full_letters",
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "paid-in-full-letters.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }

  get headers() {
    return [
      {
        text: "last",
        value: "last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "first",
        value: "first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "polnum",
        value: "number",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "freq",
        value: "billing_frequency",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "next bill",
        value: "next_bill_on",
        class: "text-right",
        sortable: true
      },
      {
        text: "PIF",
        value: "paid_in_full_date",
        class: "text-right",
        sortable: true
      }
    ]
  }
}
