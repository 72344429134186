
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import { Claim } from "@/types/Claim"
import { DisabilityType } from "@/types/DisabilityType"
import { Policy } from "@/types/Policy"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import { cloneDeep } from "lodash"

@Component({
  components: { DateField, DialogTemplate, CurrencyField }
})
export default class ClaimForm extends Vue {
  @Prop() private claim!: Claim
  @Prop() private memberId!: string
  @Prop() private policy!: Policy
  @Prop() private title: string

  private claimData = new Claim()
  private otherIllnes: null | string = null

  created() {
    this.resetClaim()
    if (this.disabilityTypes.length == 0) {
      this.$store.dispatch("disabilityTypes/loadAll")
    }
  }

  get disabilityTypes() {
    const disabilityTypes = this.$store.getters["disabilityTypes/getAll"]

    return disabilityTypes
      ? disabilityTypes.map((el: DisabilityType) => {
          return { value: Number(el.id), text: el.attributes.name }
        })
      : []
  }

  get categoryTypes() {
    return ["IOD", "NIOD", "DISPUTED", "DEATH (NON SUICIDE)", "DEATH (SUICIDE)"]
  }

  open() {
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  @Watch("claim.id")
  onClaimChange() {
    this.resetClaim()
  }

  @Watch("policy")
  onPolicyChange() {
    this.resetClaim()
  }

  saveClaim() {
    if (this.claim) {
      this.$store.dispatch("claims/update", this.claimData)
    } else {
      this.$store.dispatch("claims/create", {
        data: this.claimData,
        successMessage: "New claim added"
      })

      this.claimData = new Claim()
    }
  }

  cancel() {
    this.resetClaim()
    this.$emit("cancel")
  }

  resetClaim() {
    if (this.claim) {
      this.claimData = cloneDeep(this.claim)
    } else {
      this.claimData = new Claim()
      this.claimData.attributes.policy_id = parseInt(this.policy.id)
      this.claimData.attributes.member_id = parseInt(this.memberId)
      this.claimData.attributes.state = "initial"
      this.claimData.attributes.number = this.policy.attributes.number
    }
  }
}
