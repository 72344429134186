
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DateHelper from "@/components/utils/DateHelper"
import PageTemplate from "@/components/PageTemplate.vue"
import feeHelper from "@/services/feeHelper"

class BillablePolicy {
  policy_id: number
  amount: string
  last: string
  next: string
  member_first_name: string
  member_last_name: string
  member_email: string
  policy_number: string
  billing_frequency: string
  balance: string
  billing_exception: boolean
  paid_in_full_date: string
}

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    PageTemplate
  }
})
export default class AchBill extends Vue {
  private billCoverageDate = this.getFirstDayOfThisMonth()
  private workingCompleteMessage = ""
  private totalPremiums = "0"
  private totalWithBalance = "0"
  private billingReady = false
  private initiateResults = ""
  private resultAlertToggle = false
  private resultAlertType = "info"
  private account = ""

  private policies: BillablePolicy[] = []
  private loading = false
  private working = false
  private workProgress = 0

  private showCoordinatorProgress = false
  private coordinatorPolicies = 0
  private coordinatorProcessed = 0

  async fetchAch() {
    this.loading = true
    let page = 1
    this.policies = []

    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    const classification = accountParts.length > 1 ? accountParts[1] : null

    do {
      const data = await this.$store.dispatch(
        "policies/fetchPoliciesToBillAch",
        { page: page, productType: prodType, classification: classification }
      )
      this.policies.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)

    this.loading = false
  }

  @Watch("account")
  onAccountChange() {
    this.billingReady = false

    this.fetchAch()
  }

  @Watch("policies")
  onChangedChange(value: BillablePolicy[]) {
    const amountsPremium = value.map((item: BillablePolicy) =>
      parseFloat(this.calculateAmountBilled(item))
    )
    const amountsTotal = value.map(
      (item: BillablePolicy) =>
        parseFloat(this.calculateAmountBilled(item)) + parseFloat(item.balance)
    )

    if (amountsPremium.length == 0) {
      this.totalPremiums = "0"
    } else if (amountsPremium.length == 1) {
      this.totalPremiums = amountsPremium[0].toString()
    } else {
      this.totalPremiums = amountsPremium
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }

    if (amountsTotal.length == 0) {
      this.totalWithBalance = "0"
    } else if (amountsTotal.length == 1) {
      this.totalWithBalance = amountsPremium[0].toString()
    } else {
      this.totalWithBalance = amountsTotal
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  async makeBills() {
    if (!this.working) {
      this.working = true

      const accountParts = this.account.split("_")
      const prodType = accountParts[0]
      const classification = accountParts.length > 1 ? accountParts[1] : null

      const payload = {
        classification: classification,
        productType: prodType
      }

      await this.$store.dispatch("billingGroups/initiatePolicyBilling", payload)

      await new Promise(r => setTimeout(r, 500))

      this.policies = []

      this.working = false

      this.workingCompleteMessage =
        "Billing process has been initiated. It will likely take some time to complete. This page will update a few times with status and progress. You will get one or more emails, one of which will notify you of completion. At that point you will should see the NACHA file containing the resulting payments. ** You may leave this page while the process completes without causing problems. **"
    }
  }

  getFirstDayOfThisMonth() {
    const date = new Date()
    return new Date(date.getFullYear(), date.getMonth(), 1)
  }

  calculateAmountBilled(policy: BillablePolicy) {
    const a = Number(policy.amount)
    const m = Math.min(
      this.frequencyAsMonths(policy.billing_frequency),
      this.monthsUntilPif(policy)
    )
    const f = Number(this.achFee())
    const val = m * a + f

    return val.toString()
  }

  achFee() {
    return "0"
  }

  monthsUntilPif(policy: BillablePolicy) {
    if (!policy.paid_in_full_date) {
      return 12 // some number at least as long as the longest billing frequency
    }

    const pif = DateHelper.stringToDate(policy.paid_in_full_date)
    const nextBill = this.billCoverageDate

    let months = (pif.getFullYear() - nextBill.getFullYear()) * 12
    months += pif.getMonth()
    months -= nextBill.getMonth()
    months += 1 // because paid in full date is end of month and bill coverage is beginning of month

    return months <= 0 ? 0 : months
  }

  frequencyAsMonths(billingFrequency: string) {
    return feeHelper.frequencyAsMonths(billingFrequency)
  }

  get progressStatus() {
    const val =
      this.coordinatorPolicies > 0
        ? this.coordinatorProcessed / this.coordinatorPolicies
        : 0
    return (val * 100).toString()
  }

  mounted() {
    this.$cable.subscribe({
      channel: "BillingChannel",
      room: "public"
    })

    this.$cable.subscribe({
      channel: "BillingCoordinatorChannel",
      room: "public"
    })
  }

  getCSV() {
    let csv = ""

    csv = this.policies
      .map(el => {
        return [
          el.member_last_name,
          el.member_first_name,
          el.policy_number,
          el.member_email,
          el.last,
          el.next,
          this.calculateAmountBilled(el),
          el.amount,
          el.billing_frequency,
          this.achFee(),
          el.balance
        ].join(",")
      })
      .join("\n")

    csv = [
      "last",
      "first",
      "policy",
      "email",
      "last",
      "next",
      "amount",
      "premium",
      "freq",
      "fee",
      "balance"
    ]
      .join(",")
      .concat("\n")
      .concat(csv)

    let nameForDownload = ""
    switch (this.account) {
      case "ltc":
        nameForDownload = "LTC-pre-billing.csv"
        break
      case "ltd_0":
        nameForDownload = "EIP-fire-pre-billing.csv"
        break
      case "ltd_1":
        nameForDownload = "EIP-law-pre-billing.csv"
        break
    }

    const anchor = document.createElement("a")
    anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    anchor.target = "_blank"
    anchor.download = nameForDownload
    anchor.click()
  }

  get headers() {
    return [
      {
        text: "",
        value: "billing_exception",
        sortable: true,
        class: "text-left caption",
        width: "75px"
      },
      {
        text: "LAST",
        value: "member_last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "FIRST",
        value: "member_first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "EMAIL",
        value: "member_email",
        class: "text-left caption"
      },
      {
        text: "POLNUM",
        value: "policy_number",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "AMOUNT",
        value: "total",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "PREMIUM",
        value: "amount",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "FREQ",
        value: "billing_frequency",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "FEE",
        value: "fee",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "LAST BILL",
        value: "last",
        class: "text-right",
        sortable: true
      },
      {
        text: "NEXT BILL",
        value: "next",
        class: "text-right",
        sortable: true
      },
      {
        text: "BALANCE",
        value: "balance",
        class: "text-right",
        sortable: true
      }
    ]
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      BillingChannel: {
        connected() {
          console.log("BillingChannel connected")
        },
        rejected() {
          console.log("Billing Channel rejected")
        },
        received(data: string) {
          that.initiateResults = data
          that.resultAlertToggle = true
          that.resultAlertType = "success"
          that.workingCompleteMessage = ""
        }
      },
      BillingCoordinatorChannel: {
        connected() {
          console.log("BillingCoordinatorChannel connected")
        },
        rejected() {
          console.log("BillingCoordinatorChannel Channel rejected")
        },
        received(data: { name: string; policies: number; processed: number }) {
          that.coordinatorPolicies = data.policies
          that.coordinatorProcessed = data.processed
          that.showCoordinatorProgress = data.policies > data.processed
        }
      }
    }
  }
}
