
import { Component, Vue, Prop } from "vue-property-decorator"
import { AdminBill } from "@/types/AdminBill"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"

@Component({
  components: { CardTemplate, CurrencyField, DateField, PagedDataTable }
})
export default class AdminBills extends Vue {
  @Prop() private classification!: string
  @Prop() private eip!: boolean

  private adminBills: AdminBill[] = []
  private loading = false
  private total = 0
  private startingPerPage = 5
  private startingPage = 1

  created() {
    this.loadAdminBills(this.startingPage, this.startingPerPage)
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  getAdminBills(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadAdminBills(details.page, details.itemsPerPage)
    }
  }

  async loadAdminBills(page: number, itemsPerPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("adminBills/loadBy", {
      classification: this.classification,
      eip: this.eip.toString(),
      page: page,
      per_page: itemsPerPage,
      order: "start_date",
      order_direction: "DESC"
    })

    this.adminBills = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  get headers() {
    return [
      {
        text: "Name",
        value: "attributes.name",
        sortable: false,
        class: "text-left caption js-header-name"
      },
      {
        text: "first bill",
        value: "start_date",
        sortable: false,
        class: "text-left caption js-header-start-date"
      },
      {
        text: "last bill",
        value: "end_date",
        sortable: false,
        class: "text-left caption js-header-end-date"
      },
      {
        text: "amount",
        value: "amount",
        sortable: false,
        class: "text-left caption js-header-amount"
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-left caption js-header-actions"
      }
    ]
  }
}
