import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Benefit extends Model<Benefit> {
  constructor(response?: Benefit) {
    super(response as Benefit)
    if (response) {
      this.attributes.start_date = DateHelper.stringToDate(
        this.attributes.start_date
      )
      this.attributes.end_date = DateHelper.stringToDate(
        this.attributes.end_date
      )
    }
  }
  id: string
  attributes!: {
    claim_id: number
    start_date: Date
    end_date: Date
    days: number
    benefit_type: string
    amount: string
    cola_description: string
    cola_amount: string
    offset_description: string
    offset_amount: string
    total: number
  }
  static get modelName() {
    return "Benefit"
  }
}
