
import { Address as AddressType } from "@/types/Address"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { cloneDeep } from "lodash"
import statesList from "states-us"
import { Member } from "@/types/Member"

@Component({
  components: {
    CardTemplate,
    DialogTemplate
  }
})
export default class Address extends Vue {
  @Prop() private address!: AddressType
  @Prop() private member!: Member
  @Prop() private displayOnly!: false

  private edit = false
  private editAddress = new AddressType()
  private valid = false

  @Watch("address", { deep: true })
  onAddressChange(value: AddressType) {
    if (value) {
      this.editAddress = cloneDeep(value)
    }
  }

  get preventEdit() {
    const canEdit =
      this.member &&
      (this.member.attributes.address_id == undefined ||
        (this.editAddress.id != undefined &&
          Number(this.editAddress.id) == this.member.attributes.address_id))

    return !canEdit
  }

  get lineOne() {
    let s = this.address.attributes.street ? this.address.attributes.street : ""
    if (
      this.address.attributes.apartment &&
      this.address.attributes.apartment.length > 0
    ) {
      s += this.address.attributes.apartment
        ? s.length
          ? ", " + this.address.attributes.apartment
          : this.address.attributes.apartment
        : ""
    }
    return s
  }
  get lineTwo() {
    let s = this.address.attributes.city ? this.address.attributes.city : ""
    s += this.address.attributes.state
      ? s.length
        ? ", " + this.address.attributes.state
        : this.address.attributes.state
      : ""
    s += this.address.attributes.zip
      ? s.length
        ? " " + this.address.attributes.zip
        : this.address.attributes.zip
      : ""
    return s
  }

  get states() {
    return statesList.filter(x => !x.territory)
  }

  async save() {
    const editMember = cloneDeep(this.member)

    let action = ""
    if (
      (!this.address || !this.address.id) &&
      this.editAddress &&
      this.editAddress.attributes.street
    ) {
      action = "create"
    } else if (this.address && this.address.id) {
      action = "update"
    }

    if (action) {
      const data = await this.$store.dispatch(
        "addresses/" + action,
        this.editAddress
      )

      if (
        data.status == 200 &&
        action == "create" &&
        editMember.id &&
        this.member.attributes.address_id != data.data.data.id
      ) {
        editMember.attributes.address_id = data.data.data.id
        this.$store.dispatch("members/update", editMember)
      }

      this.edit = !this.edit
    }
  }

  copyToClipboard() {
    const a = [
      `${this.member.attributes.first_name} ${this.member.attributes.last_name}`,
      this.lineOne,
      this.lineTwo
    ]
    navigator.clipboard.writeText(a.join("\n"))
  }

  cancel() {
    this.edit = false
    this.editAddress = cloneDeep(this.address)
  }
}
