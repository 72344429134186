
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import { Notification } from "@/types/Notification"

@Component({
  components: {
    CardTemplate
  }
})
export default class NotificationsWidget extends Vue {
  @Prop() private notifications!: Array<Notification>

  get unread() {
    return this.notifications.filter(el => !el.attributes.read)
  }
}
