import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

/* eslint-disable @typescript-eslint/no-explicit-any */
const state: { all: Record<string, Record<string, any>>[]; meta: {} } = {
  all: [],
  meta: {}
}

const getters = {}

const actions: ActionTree<
  State<Record<string, Record<string, any>>>,
  RootState
> = {
  async loadByFilters(
    {
      dispatch,
      rootGetters
    }: ActionContext<State<Record<string, Record<string, any>>>, RootState>,
    {
      conditions,
      page,
      per_page,
      order,
      order_direction
    }: {
      conditions: []
      page: number
      per_page: number
      order: string
      order_direction: string
    }
  ) {
    const url = "members_view/view_results"

    try {
      const data = await axios.post(
        url,
        {
          conditions: conditions,
          page: page,
          per_page: per_page,
          order: order,
          order_direction: order_direction
        },
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<
  Record<string, Record<string, Record<string, any>>>
>> = {
  clearResults(
    state: State<Record<string, Record<string, Record<string, any>>>>
  ) {
    state.all.splice(0, state.all.length)
  }
}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
