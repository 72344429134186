
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { BillingGroup } from "@/types/BillingGroup"

class ProgressObject {
  billing_group_id: number
  name: string
  complete: boolean
  items: number
  processed: number
  classification: string
}

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate,
    PagedDataTable,
    PageTemplate
  }
})
export default class BillingGroups extends Vue {
  private classification: string = null
  private startingPerPage = 5
  private startingPage = 1
  private currentPage = 1
  private total = 0
  private startingPerPageLtc = 5
  private startingPageLtc = 1
  private currentPageLtc = 1
  private totalLtc = 0
  private startingPerPageAch = 5
  private startingPageAch = 1
  private currentPageAch = 1
  private totalAch = 0
  private startingPerPageEip = 5
  private startingPageEip = 1
  private currentPageEip = 1
  private totalEip = 0
  private startingPerPageEipPayroll = 5
  private startingPageEipPayroll = 1
  private currentPageEipPayroll = 1
  private totalEipPayroll = 0
  private billingGroupsLtdPayroll: BillingGroups[] = []
  private billingGroupsLtcPayroll: BillingGroups[] = []
  private billingGroupsEipPayroll: BillingGroups[] = []
  private billingGroupsLtcMember: BillingGroups[] = []
  private billingGroupsEipMember: BillingGroups[] = []
  private loading = false
  private loadingAch = false
  private loadingEip = false
  private loadingEipPayroll = false
  private loadingLtc = false
  private initiateResults = ""
  private alertToggle = false
  private alertType = "info"
  private running: BillingGroups[] = []
  private progressData: Array<ProgressObject> = []

  created() {
    this.loadLtdPayrollBillingGroups(this.startingPage, this.startingPerPage)
    this.loadLtcPayrollBillingGroups(
      this.startingPageLtc,
      this.startingPerPageLtc
    )
    this.loadEipPayrollBillingGroups(
      this.startingPageEipPayroll,
      this.startingPerPageEipPayroll
    )
    this.loadLtcAchBillingGroups(this.startingPageAch, this.startingPerPageAch)
    this.loadEipAchBillingGroups(this.startingPageEip, this.startingPerPageEip)
  }

  getLtdPayrollBillingGroups(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadLtdPayrollBillingGroups(details.page, details.itemsPerPage)
    }
  }

  getLtcPayrollBillingGroups(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadLtcPayrollBillingGroups(details.page, details.itemsPerPage)
    }
  }

  getEipPayrollBillingGroups(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadEipPayrollBillingGroups(details.page, details.itemsPerPage)
    }
  }

  getEipAchBillingGroups(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadEipAchBillingGroups(details.page, details.itemsPerPage)
    }
  }

  getLtcAchBillingGroups(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadLtcAchBillingGroups(details.page, details.itemsPerPage)
    }
  }

  async loadRunning() {
    const data = await this.$store.dispatch("billingGroups/running")
    this.running = data
    console.log(data.length)

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    const intervalId = setInterval(async function() {
      const data = await that.$store.dispatch("billingGroups/running")
      that.running = data
      console.log(data.length)
      if (data.length == 0) {
        clearInterval(intervalId)
      }
    }, 60000)
  }

  async loadLtdPayrollBillingGroups(page: number, itemsPerPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("billingGroups/loadBy", {
      page: page,
      per_page: itemsPerPage,
      order: "created_at",
      order_direction: "DESC",
      classification: ["fire", "law"]
    })

    this.billingGroupsLtdPayroll = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  async loadLtcPayrollBillingGroups(page: number, itemsPerPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("billingGroups/loadBy", {
      page: page,
      per_page: itemsPerPage,
      order: "created_at",
      order_direction: "DESC",
      classification: "ltc_payroll"
    })

    this.billingGroupsLtcPayroll = data?.data?.data
    this.totalLtc = data?.data?.meta?.pagination?.count
    this.loadingLtc = false
  }

  async loadEipPayrollBillingGroups(page: number, itemsPerPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("billingGroups/loadBy", {
      page: page,
      per_page: itemsPerPage,
      order: "created_at",
      order_direction: "DESC",
      classification: ["eip_payroll_fire", "eip_payroll_law"]
    })

    this.billingGroupsEipPayroll = data?.data?.data
    this.totalEipPayroll = data?.data?.meta?.pagination?.count
    this.loadingEipPayroll = false
  }

  dialogTitle() {
    if (this.classification == "ltc_payroll") {
      return "LTC Payroll Bill Group"
    } else if (this.classification == "eip_payroll_fire") {
      return "EIP (FIRE) Payroll Bill Group"
    } else if (this.classification == "eip_payroll_law") {
      return "EIP (LAW) Payroll Bill Group"
    } else {
      return `LTD (${this.classification}) Payroll Bill Group`
    }
  }

  async loadEipAchBillingGroups(page: number, itemsPerPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("billingGroups/loadBy", {
      page: page,
      per_page: itemsPerPage,
      order: "created_at",
      order_direction: "DESC",
      classification: ["eip_ach", "eip_check"]
    })

    this.billingGroupsEipMember = data?.data?.data
    this.totalEip = data?.data?.meta?.pagination?.count
    this.loadingEip = false
  }

  async loadLtcAchBillingGroups(page: number, itemsPerPage: number) {
    this.loadingAch = true

    const data = await this.$store.dispatch("billingGroups/loadBy", {
      page: page,
      per_page: itemsPerPage,
      order: "created_at",
      order_direction: "DESC",
      classification: ["ltc_ach", "ltc_check"]
    })

    this.billingGroupsLtcMember = data?.data?.data
    this.totalAch = data?.data?.meta?.pagination?.count
    this.loadingAch = false
  }

  async makeGroup() {
    this.alertToggle = true
    const result = await this.$store.dispatch(
      "billingGroups/initiate",
      this.classification
    )

    if (result.status == "200") {
      this.initiateResults = result.data
      this.alertType = "info" // "success" is green for when done.
    } else {
      this.$store.dispatch("alerts/addError", "Error initiating billing!")
      this.alertToggle = false
    }
  }

  initiatePayroll(classification: string) {
    this.classification = classification
    ;(this.$refs.dialogLtdPayroll as DialogTemplate).open(true)
  }

  downloadZip(billingGroup: BillingGroup) {
    this.$store.dispatch("billingGroups/zip", { billingGroup: billingGroup })
  }

  get headers() {
    return [
      {
        text: "",
        class: "overline",
        value: "attributes.classification",
        sortable: false
      },
      {
        text: "",
        class: "overline",
        value: "method",
        sortable: false
      },
      {
        text: "Date",
        class: "overline",
        value: "attributes.date",
        sortable: false
      },
      {
        text: "Name",
        class: "overline",
        value: "attributes.name",
        sortable: false
      },
      {
        text: "Invoices",
        class: "overline",
        value: "attributes.zip",
        sortable: false
      }
    ]
  }

  nextMonth() {
    const d = new Date()
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
    let nm = null
    if (d.getMonth() == 11) {
      nm = new Date(d.getFullYear() + 1, 0, 1)
    } else {
      nm = new Date(d.getFullYear(), d.getMonth() + 1, 1)
    }

    return monthNames[nm.getMonth()] + ", " + nm.getFullYear()
  }

  groupPlan(billingGroup: BillingGroup) {
    let plan = ""
    const classification = billingGroup.attributes.classification

    switch (classification) {
      case "fire":
      case "eip_payroll_fire":
        plan = "CAPF"
        break
      case "law":
      case "eip_payroll_law":
        plan = "CLEA"
        break
      case "eip":
      case "eip_check":
      case "eip_ach":
      case "eip_cc":
        plan = "EIP"
        break
      case "ltc_payroll":
      case "ltc_check":
      case "ltc_ach":
      case "ltc_cc":
        plan = "NPFBA"
        break
      default:
        plan = "N/A"
    }

    return plan
  }

  groupColor(billingGroup: BillingGroup) {
    let color = "secondary"
    const classification = billingGroup.attributes.classification

    switch (classification) {
      case "fire":
      case "eip_payroll_fire":
        color = "red"
        break
      case "law":
      case "eip_payroll_law":
        color = "blue"
        break
      case "eip":
      case "eip_check":
        color = "blue"
        break
      case "eip_ach":
        color = "purple"
        break
      case "eip_cc":
        color = "pink"
        break
      case "ltc_check":
        color = "green"
        break
      case "ltc_cc":
        color = "indigo"
        break
      case "ltc_ach":
        color = "teal"
        break
      case "ltc_payroll":
        color = "teal darken-2"
        break
      default:
        color = "secondary"
    }
    return color
  }

  groupPaymentMethod(billingGroup: BillingGroup) {
    let method = "Check"
    const classification = billingGroup.attributes.classification

    switch (classification) {
      case "ltc_cc":
        method = "CC"
        break
      case "fire":
      case "law":
      case "eip":
      case "eip_payroll_fire":
      case "eip_payroll_law":
      case "ltc_payroll":
      case "ltc_check":
      case "eip_check":
        method = "Check"
        break
      case "ltc_ach":
      case "eip_ach":
        method = "ACH"
        break
      default:
        method = "Check"
    }

    return method
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      BillingChannel: {
        connected() {
          console.log("BillingChannel connected")
        },
        rejected() {
          console.log("Billing Channel rejected")
        },
        received(data: string) {
          that.initiateResults = data
          that.alertToggle = true
          that.alertType = "success"
          that.loadLtdPayrollBillingGroups(
            that.startingPage,
            that.startingPerPage
          )
        }
      },
      BillingCoordinatorChannel: {
        connected() {
          console.log("BillingCoordinatorChannel connected")
          that.loadRunning()
        },
        rejected() {
          console.log("BillingCoordinatorChannel Channel rejected")
        },
        received(data: ProgressObject) {
          const existing = that.progressData.filter((el: ProgressObject) => {
            return el.billing_group_id == data.billing_group_id
          })

          if (existing.length > 0) {
            existing[0].processed = data.processed
            existing[0].items = data.items
            existing[0].complete = data.complete
          } else {
            that.progressData.push(data)
          }
        }
      }
    }
  }
  mounted() {
    this.$cable.subscribe({
      channel: "BillingChannel",
      room: "public"
    })

    this.$cable.subscribe({
      channel: "BillingCoordinatorChannel",
      room: "public"
    })
  }
}
