import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { BillingGroup } from "@/types/BillingGroup"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: BillingGroup[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<BillingGroup>, RootState> = {
  async zip(
    { dispatch, rootGetters }: ActionContext<State<BillingGroup>, RootState>,
    { billingGroup }
  ) {
    try {
      const data = await axios.get(`/billing_groups/${billingGroup.id}/zip`, {
        ...rootGetters["config/getAPIConfig"],
        ...{ responseType: "blob" }
      })
      if (data.status == 200) {
        const filename = billingGroup.attributes.name.replace(" ", "_") + ".zip"
        const fileURL = window.URL.createObjectURL(new Blob([data.data]))

        const fileLink = document.createElement("a")

        fileLink.href = fileURL
        fileLink.setAttribute("download", filename)
        document.body.appendChild(fileLink)

        fileLink.click()
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  initiate(
    { dispatch, rootGetters }: ActionContext<State<BillingGroup>, RootState>,
    classification
  ) {
    return axios
      .post(
        "/billing_groups/initiate",
        {
          classification: classification
        },
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async initiatePolicyBatch(
    { dispatch, rootGetters }: ActionContext<State<BillingGroup>, RootState>,
    params: {
      startDate: string
      policyIds: string
      label: string
      classification: string
      prodType: string
    }
  ) {
    return axios
      .post(
        "/billing_groups/initiate_policy_batch",
        {
          coverage_start_date: params.startDate,
          policy_ids: params.policyIds,
          label: params.label,
          classification: params.classification,
          prod_type: params.prodType
        },
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async initiatePolicyBilling(
    { dispatch, rootGetters }: ActionContext<State<BillingGroup>, RootState>,
    params: { productType: string; classification: string }
  ) {
    return axios
      .post(
        "/billing_groups/initiate_policy_billing",
        {
          classification: params.classification,
          product_type: params.productType
        },
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async running({
    dispatch,
    rootGetters
  }: ActionContext<State<BillingGroup>, RootState>) {
    const d = await axios
      .get("/billing_groups/running", rootGetters["config/getAPIConfig"])
      .then((data: { data: { data: BillingGroup[] } }) => {
        return data.data.data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
        return []
      })

    return d
  }
}

const mutations: MutationTree<State<BillingGroup>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
