
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { BoardMeeting } from "@/types/BoardMeeting"

@Component({
  components: {
    DateField,
    DialogTemplate
  }
})
export default class BoardMeetingDialog extends Vue {
  @Prop() private boardMeeting!: BoardMeeting
  @Prop() private btnLabel!: string
  @Prop() private editClassification!: boolean
  @Prop() private title!: string

  private editMeeting = new BoardMeeting()

  get hideOpener() {
    return !this.btnLabel
  }

  @Watch("boardMeeting", { deep: true })
  onMeetingChange(value: BoardMeeting) {
    if (value) {
      this.editMeeting = cloneDeep(value)
    }
  }

  created() {
    if (this.boardMeeting) {
      this.editMeeting = cloneDeep(this.boardMeeting)
    }
  }

  open() {
    this.editMeeting = cloneDeep(this.boardMeeting)
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  cancel() {
    this.editMeeting = cloneDeep(this.boardMeeting)
  }

  async save() {
    if (this.boardMeeting.id) {
      await this.$store.dispatch("boardMeetings/update", this.editMeeting)
      this.$emit("save")
      this.editMeeting = cloneDeep(this.boardMeeting)
    } else {
      await this.$store.dispatch("boardMeetings/create", this.editMeeting)
      this.$emit("save")
      this.editMeeting = cloneDeep(this.boardMeeting)
    }
  }
}
