import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Policy extends Model<Policy> {
  constructor(response?: Policy) {
    super(response as Policy)
    if (response) {
      this.attributes.start_date = DateHelper.stringToDate(
        this.attributes.start_date
      )
      this.attributes.end_date = DateHelper.stringToDate(
        this.attributes.end_date
      )
      this.attributes.last_billed_on = DateHelper.stringToDate(
        this.attributes.last_billed_on
      )
      this.attributes.next_bill_on = DateHelper.stringToDate(
        this.attributes.next_bill_on
      )
      this.attributes.iss_date = DateHelper.stringToDate(
        this.attributes.iss_date
      )
      this.attributes.app_date = DateHelper.stringToDate(
        this.attributes.app_date
      )
      this.attributes.whole_date = DateHelper.stringToDate(
        this.attributes.whole_date
      )
      this.attributes.ls_date = DateHelper.stringToDate(this.attributes.ls_date)
      this.attributes.ls_thru = DateHelper.stringToDate(this.attributes.ls_thru)
    }
  }
  id: string
  attributes!: {
    name: string
    member_id: number
    product_id: number
    start_date: Date
    end_date: Date
    status: string
    number: string
    billing_frequency: string
    billing_exception: boolean
    last_billed_on: Date
    next_bill_on: Date
    policy_group_id: number
    payment_method_id: number
    payment_method_type: string
    reversed: boolean
    term: string
    iss_date: Date
    app_date: Date
    whole_date: Date
    is_composit_rate: boolean
    is_mod_rate: boolean
    reduced_benefits: boolean
    paid_in_full_date: Date
    classification: string
    ltd_balance: string
    ltc_balance: string
    eip_balance: string
    paid: string
    billed: string
    fees: string
    legacy_billed: string
    legacy_paid: string
    legacy_fees: string
    last_legacy_date: string
    rating: string
    department_id: string
    rider: boolean | null
    enroller_id: number
    rate_lock_override: boolean | null
    accident_only: boolean | null
    rate_increases: boolean | null
    apply_interest: boolean | null
    increase_modified_premium: boolean | null
    auth_designee_name: string
    auth_designee_phone: string
    auth_designee_address_id: string
    ls_date: Date
    ls_amount: string
    ls_thru: Date
    last_balanced_at: string
  }
  static get modelName() {
    return "Policy"
  }
}
