
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Policy } from "@/types/Policy"
import { Member } from "@/types/Member"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

class SupplementalOptions {
  policyId: number | string
  start_date: string
  end_date: string
  memo: string
  isAdd: boolean
}

@Component({
  components: {
    DateField,
    DialogTemplate
  }
})
export default class SupplementalBillEntry extends Vue {
  @Prop() private policies!: Policy[]
  @Prop() private member!: Member

  private supplementalOptions = new SupplementalOptions()

  created() {
    this.supplementalOptions.memo = `Supplemental bill for ${this.member.attributes.first_name} ${this.member.attributes.last_name}`
    this.supplementalOptions.isAdd = true
  }

  policyDisplayText(policy: Policy) {
    return `${policy.attributes.name} ${
      policy.attributes.end_date ? " *cancelled*" : ""
    }`
  }

  async addSupplemental() {
    await this.$store.dispatch("bills/generateSupplemental", {
      policy_id: this.supplementalOptions.policyId,
      start_date: this.supplementalOptions.start_date,
      end_date: this.supplementalOptions.end_date,
      is_add: this.supplementalOptions.isAdd,
      memo: this.supplementalOptions.memo
    })
    this.$emit("fetch-coverage-data")
  }

  monthsDelta(start_date: string, end_date: string) {
    const d1 = new Date(start_date)
    const d2 = new Date(end_date)

    let months = (d2.getUTCFullYear() - d1.getUTCFullYear()) * 12
    months -= d1.getUTCMonth()
    months += d2.getUTCMonth()

    return months + 1
  }

  @Watch("member", { deep: true })
  onMemberChange(value: Member) {
    if (value) {
      this.supplementalOptions.memo = `Supplemental bill for ${value.attributes.first_name} ${value.attributes.last_name}`
    }
  }
}
