
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { CompositeRate } from "@/types/CompositeRate"
import { cloneDeep } from "lodash"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate
  }
})
export default class CompositeRateHistory extends Vue {
  @Prop() private departmentId!: number
  @Prop() private compositeRates!: CompositeRate[]

  private newCompositeRate = new CompositeRate()
  private editCompositeRate = new CompositeRate()

  save() {
    this.newCompositeRate.attributes.department_id = this.departmentId

    this.$store.dispatch("compositeRates/create", this.newCompositeRate)
    this.newCompositeRate = new CompositeRate()
  }

  open(compositeRate: CompositeRate) {
    this.editCompositeRate = cloneDeep(compositeRate)
  }

  update() {
    this.$store.dispatch("compositeRates/update", this.editCompositeRate)
  }

  asQuarterly(value: string) {
    return value
  }

  get current() {
    const today = new Date()
    const compositeRate = this.compositeRates.find(compositeRate => {
      return compositeRate.attributes.start_date <= today
    })

    return compositeRate?.id
  }
}
