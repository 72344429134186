
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField
  }
})
export default class PremiumHistory extends Vue {
  @Prop() private premiumableId!: number
  @Prop() private premiumableType!: string

  private date = new Date().toISOString().split("T")[0]
  private rateIncrease = 10.0
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private data: null | any = null

  async report() {
    const data = await axios.get(
      `/policies/${this.premiumableId}/rate_increase_report`,
      Object.assign(
        { params: { date: this.date, rate_increase: this.rateIncrease } },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    this.data = data.data
  }
}
