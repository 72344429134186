import Model from "./Model"

export class Lien extends Model<Lien> {
  constructor(response?: Lien) {
    super(response as Lien)
  }
  id: string
  attributes!: {
    lien_type: string
    claim_id: number
    amount: string
    amount_description: string
    living_expenses: boolean
    group_disability_benefits: boolean
    dwc_form_1_attached: boolean
    copy_sent_to_all_parties: boolean
    download_doc_url: string
    view_doc_url: string
    signature_id: number
    created_at: string
    updated_at: string
  }
  static get modelName() {
    return "Lien"
  }
}
