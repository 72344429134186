
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { User } from "@/types/User"
import NewUser from "@/components/NewUser.vue"
import UserInitials from "@/components/UserInitials.vue"

@Component({
  components: { CardTemplate, DialogTemplate, NewUser, UserInitials }
})
export default class UsersList extends Vue {
  @Prop() private users!: User[]

  async reset(user: User) {
    const response = await this.$store.dispatch("users/requestReset", {
      email: user.attributes.email,
      type: "welcome"
    })

    if (
      !response ||
      response.status != 200 ||
      !response.data.includes(user.attributes.email)
    ) {
      this.$store.dispatch("alerts/addError", response.data)
    } else {
      this.$store.dispatch("alerts/addSuccess", "Success")
    }
  }

  get someDirty() {
    return this.users?.some(user => {
      //eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      return this.$can("view", new User(user)) && user.dirty
    })
  }

  reloadUser(user: User) {
    this.$store.dispatch("users/load", user.id)
  }

  avatarColor(user: User) {
    return user.attributes.avatar_color
      ? user.attributes.avatar_color
      : "indigo"
  }
}
