
import { Component, Vue, Watch } from "vue-property-decorator"
import { ClaimForm } from "@/types/ClaimForm"
import { Member } from "@/types/Member"
import { Claim as ClaimType } from "@/types/Claim"
import { cloneDeep } from "lodash"
import Benefits from "@/components/Benefits.vue"
import BenefitsSnapshot from "@/components/BenefitsSnapshot.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimAdmin from "@/components/ClaimAdmin.vue"
import ClaimAttorney from "@/components/ClaimAttorney.vue"
import ClaimCheckDates from "@/components/ClaimCheckDates.vue"
import ClaimDatesLtc from "@/components/ClaimDatesLtc.vue"
import ClaimDetails from "@/components/ClaimDetails.vue"
import ClaimEstimatedPayable from "@/components/ClaimEstimatedPayable.vue"
import ClaimEstimatedPayableList from "@/components/ClaimEstimatedPayableList.vue"
import ClaimMover from "@/components/ClaimMover.vue"
import ClaimPaymentMethod from "@/components/ClaimPaymentMethod.vue"
import ClaimStepper from "@/components/ClaimStepper.vue"
import ClaimFormsList from "@/components/ClaimFormsList.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import LaserficheList from "@/components/LaserficheList.vue"
import LiensList from "@/components/LiensList.vue"
import ClaimOptions from "@/components/ClaimOptions.vue"
import NotesList from "@/components/NotesList.vue"
import MonthlyIncome from "@/components/MonthlyIncome.vue"
import DisbursementsTable from "@/components/DisbursementsTable.vue"
import DisbursementTotals from "@/components/DisbursementTotals.vue"
import DisbursementTotalsLtc from "@/components/DisbursementTotalsLtc.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import UserInitials from "@/components/UserInitials.vue"
import { EstimatedPayable } from "@/types/EstimatedPayable"
import { Benefit } from "@/types/Benefit"

@Component({
  components: {
    Benefits,
    BenefitsSnapshot,
    CardTemplate,
    ClaimAdmin,
    ClaimAttorney,
    ClaimCheckDates,
    ClaimDatesLtc,
    ClaimDetails,
    ClaimMover,
    ClaimPaymentMethod,
    ClaimStepper,
    ClaimEstimatedPayable,
    ClaimEstimatedPayableList,
    ClaimFormsList,
    DialogTemplate,
    LiensList,
    LaserficheList,
    ClaimOptions,
    MonthlyIncome,
    NotesList,
    DisbursementsTable,
    DisbursementTotals,
    DisbursementTotalsLtc,
    PageTemplate,
    UserInitials
  }
})
export default class Claim extends Vue {
  claimId: number = null
  private dataLoaded = false
  private showInfoContent = false
  private showBenefitsDetails = false

  get id() {
    return this.$route.params.id
  }

  get claim() {
    return this.$store.getters["claims/getById"](this.id)
  }

  get policy() {
    return this.claim
      ? this.$store.getters["policies/getById"](this.claim.attributes.policy_id)
      : null
  }

  get product() {
    return this.policy
      ? this.$store.getters["products/getById"](
          this.policy.attributes.product_id
        )
      : null
  }

  get beneficiaries() {
    return this.policy
      ? this.$store.getters["beneficiaries/getBy"]({
          policy_id: this.policy.id
        })
      : []
  }

  get attorney() {
    return this.claim && this.claim.attributes.attorney_id
      ? this.$store.getters["attorneys/getById"](
          this.claim.attributes.attorney_id
        )
      : null
  }

  get estimatedPayables() {
    function compare(a: EstimatedPayable, b: EstimatedPayable) {
      return a.attributes.date > b.attributes.date ? -1 : 1
    }
    if (this.claim) {
      const estimates = this.$store.getters["estimatedPayables/getBy"]({
        claim_id: this.claim.id
      }).sort(compare)
      if (estimates && estimates.length > 0) {
        return estimates
      } else {
        return []
      }
    } else {
      return []
    }
  }

  get defenseAttorney() {
    return this.claim && this.claim.attributes.defense_attorney_id
      ? this.$store.getters["attorneys/getById"](
          this.claim.attributes.defense_attorney_id
        )
      : null
  }

  get admin() {
    if (this.claim && this.claim.attributes.admin_id) {
      return this.$store.getters["users/getById"](
        this.claim.attributes.admin_id
      )
    } else {
      return null
    }
  }

  get member() {
    return this.claim
      ? this.$store.getters["members/getById"](this.claim.attributes.member_id)
      : new Member()
  }

  get memberDepartment() {
    let d = null
    if (this.claim && this.policy && this.policy.attributes.department_id) {
      d = this.$store.getters["departments/getById"](
        this.policy.attributes.department_id
      )
    } else if (
      this.claim &&
      this.member &&
      this.member.attributes.last_department_id
    ) {
      d = this.$store.getters["departments/getById"](
        this.member.attributes.last_department_id
      )
    }

    return d
  }

  get memberName() {
    return this.member
      ? this.member.attributes.first_name +
          " " +
          this.member.attributes.last_name
      : ""
  }

  get claimForms() {
    return this.claim
      ? this.$store.getters["claimForms/getBy"]({
          claim_id: this.claim.id
        }).sort((a: ClaimForm, b: ClaimForm) => {
          return a.attributes.name.toUpperCase() >
            b.attributes.name.toUpperCase()
            ? 1
            : -1
        })
      : []
  }

  get memberEmployer() {
    return this.$store.getters["employers/getById"](
      this.memberDepartment?.attributes?.employer_id
    )
  }
  get liens() {
    return this.$store.getters["liens/getBy"]({
      claim_id: parseInt(this.id)
    })
  }

  get benefits() {
    const allBenefits = this.$store.getters["benefits/getBy"]({
      claim_id: parseInt(this.id)
    })

    const sorted = allBenefits.sort((a: Benefit, b: Benefit) => {
      const first = a.attributes.start_date
      const second = b.attributes.start_date

      return first < second ? 1 : -1
    })

    return sorted.slice(0, 3)
  }

  async generateNumber() {
    await this.$store.dispatch("claims/generateNumber", this.id)
    this.reloadClaim(this.id)
  }

  incomeWorksheetAdded() {
    this.reloadClaim(this.claim.id)
  }

  async reloadClaim(claim_id: string | number) {
    const claim = await this.$store.dispatch("claims/load", {
      id: claim_id,
      include: [
        "policy",
        "policy.member",
        "policy.product",
        "claim_forms",
        "attorney",
        "liens",
        "estimated_payables",
        "policy.beneficiaries",
        "benefits"
      ]
    })

    const member = this.$store.getters["members/getById"](
      this.policy.attributes.member_id
    )

    const departmentId = this.policy.attributes.department_id
      ? this.policy.attributes.department_id
      : member.attributes.department_id
      ? member.attributes.department_id
      : member.attributes.last_department_id

    await this.$store.dispatch("departments/load", {
      id: departmentId,
      include: ["employer"]
    })

    return claim
  }

  async addEstimatedPayable(estimate: EstimatedPayable) {
    if (estimate.id) {
      await this.$store.dispatch("estimatedPayables/update", {
        data: estimate
      })
    } else {
      estimate.attributes.claim_id = parseInt(this.id)
      await this.$store.dispatch("estimatedPayables/create", {
        data: estimate
      })
    }

    this.reloadClaim(this.id)
  }

  async saveLtcDates(data: {
    claimStart: string
    claimEnd: string
    eliminationStart: string
    eliminationEnd: string
    deathDate: string
  }) {
    const c = cloneDeep(this.claim)
    c.attributes.claim_start = data.claimStart
    c.attributes.claim_end = data.claimEnd
    c.attributes.elimination_start = data.eliminationStart
    c.attributes.elimination_end = data.eliminationEnd
    c.attributes.death_date = data.deathDate

    await this.$store.dispatch("claims/update", c)
    this.reloadClaim(this.id)
  }

  async saveCheckDates(data: {
    firstCheckNonRecoverable: string
    lastCheckNonRecoverable: string
    firstCheckRecoverable: string
    lastCheckRecoverable: string
    firstCheckDeath: string
    lastCheckDeath: string
  }) {
    const c = cloneDeep(this.claim)
    c.attributes.first_check_non_recoverable = data.firstCheckNonRecoverable
    c.attributes.last_check_non_recoverable = data.lastCheckNonRecoverable
    c.attributes.first_check_recoverable = data.firstCheckRecoverable
    c.attributes.last_check_recoverable = data.lastCheckRecoverable
    c.attributes.first_check_death = data.firstCheckDeath
    c.attributes.last_check_death = data.lastCheckDeath

    await this.$store.dispatch("claims/update", c)
    this.reloadClaim(this.id)
  }

  async deleteEstimatedPayable(estimate: EstimatedPayable) {
    await this.$store.dispatch("estimatedPayables/delete", estimate.id)
    this.reloadClaim(this.id)
  }

  reloadDisbursements() {
    const ref = this.$refs.disbursements as Vue & {
      loadDisbursements: (page: number, perPage: number) => void
    }
    ref.loadDisbursements(1, 5)
  }

  async created() {
    const c = await this.reloadClaim(this.id)

    if (c && c.data?.data?.attributes?.admin_id) {
      await this.$store.dispatch("users/load", {
        id: c.data.data.attributes.admin_id
      })
    }

    this.dataLoaded = true
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    this.dataLoaded = false
    await this.reloadClaim(to.params.id)
    this.dataLoaded = true
    next()
  }

  @Watch("claim")
  onClaimChanged(value: ClaimType) {
    if (value) {
      this.$store.dispatch("attorneys/loadBy", {
        id: value.attributes.defense_attorney_id
      })
    }
  }

  @Watch("member")
  onMemberChanged(value: Member) {
    if (value) {
      this.$store.dispatch(
        "departments/load",
        value.attributes.last_department_id
      )
    }
  }

  onScroll() {
    this.showInfoContent = window.scrollY > 50
  }

  async getDisbursementsPdf() {
    const response = await this.$store.dispatch(
      "claims/fetchDisbursementsPdf",
      this.id
    )

    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "disbursements.pdf")
    document.body.appendChild(fURL)

    fURL.click()
  }
}
