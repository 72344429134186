
import { Component, Watch, Vue } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import { mapGetters } from "vuex"
import { Product as ProductType } from "@/types/Product"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PremiumHistory from "@/components/PremiumHistory.vue"
import ProductForm from "@/components/ProductForm.vue"
import { SortOrder } from "@/store/utils/genericGetters"
import { DepartmentProduct } from "@/types/DepartmentProduct"
import { Department } from "@/types/Department"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    PageTemplate,
    PremiumHistory,
    ProductForm
  },
  computed: { ...mapGetters("products", ["getById"]) }
})
export default class Product extends Vue {
  private clonedProduct = new ProductType()

  @Watch("product", { deep: true })
  onProductChange() {
    this.reset()
    this.loadPremiums()
  }

  get id() {
    return this.$route.params.id
  }

  getById!: (id: string) => ProductType

  get product() {
    return this.getById(this.id)
  }

  created() {
    if (this.getById(this.id) == null) {
      this.loadProduct()
    }
    this.loadPremiums()
    this.loadDepartmentProducts()
    this.reset()
  }

  loadPremiums() {
    this.$store.dispatch("premiums/loadBy", {
      premiumable_id: this.id,
      premiumable_type: "Product"
    })
  }

  loadDepartmentProducts() {
    this.$store.dispatch("departmentProducts/loadBy", {
      product_id: this.id,
      include: ["department"]
    })
  }

  get departments() {
    const dps = this.$store.getters["departmentProducts/getBy"]({
      product_id: this.id
    })

    const depts = dps
      .map((el: DepartmentProduct) => {
        return this.$store.getters["departments/getById"](
          el.attributes.department_id
        )
      })
      .sort((a: Department, b: Department) => {
        return a.attributes.name.toUpperCase() > b.attributes.name.toUpperCase()
          ? 1
          : -1
      })

    return depts
  }

  get premiums() {
    return this.$store.getters["premiums/getBy"](
      {
        premiumable_id: this.id,
        premiumable_type: "Product"
      },
      "start_date",
      SortOrder.desc
    )
  }

  async handleSave() {
    await this.$store.dispatch("products/update", {
      data: this.clonedProduct,
      successMessage: "Product updated"
    })
    this.reset()
  }

  reset() {
    this.clonedProduct = this.product
      ? cloneDeep(this.product)
      : new ProductType()
  }

  loadProduct() {
    this.$store.dispatch("products/load", this.id)
  }
}
