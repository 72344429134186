
import { Component, Prop, Vue } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Product } from "@/types/Product"

@Component({
  components: { CurrencyField, DateField, DialogTemplate }
})
export default class ProductForm extends Vue {
  @Prop() private product!: Product
  private premiumAmount: number = null

  async save() {
    const action = this.product.id ? "update" : "create"
    const newProduct = await this.$store.dispatch(
      `products/${action}`,
      this.product
    )

    if (this.premiumAmount) {
      await this.$store.dispatch("premiums/create", {
        attributes: {
          amount_billed: this.premiumAmount,
          product_id: newProduct.data.data.id,
          start_date: new Date()
        }
      })
      this.$store.dispatch("products/loadBy", { id: newProduct.data.data.id })
    }
    this.reset()
  }

  reset() {
    this.premiumAmount = null
    this.$emit("reset")
  }

  get productTypes() {
    return [
      { text: "LTD", value: "ltd" },
      { text: "LTC", value: "ltc" }
    ]
  }
}
