import Model from "./Model"

export class Tag extends Model<Tag> {
  id: string
  attributes!: {
    name: string
    taggings_count: number
  }
  static get modelName() {
    return "Tag"
  }
}
