
import { Component, Prop, Vue } from "vue-property-decorator"
import { Bar } from "vue-chartjs/legacy"

import CardTemplate from "@/components/CardTemplate.vue"

import axios from "axios"
import moment from "moment"

interface MemberCountData {
  date: string
  count_by_policy: number
  count_by_bill: number
}

@Component({
  components: { Bar, CardTemplate }
})
export default class DepartmentMemberCount extends Vue {
  @Prop() private departmentId: number
  private loading = true

  private data: [MemberCountData] = null

  async created() {
    const m = moment().add(1, "month")

    const data = await axios.get(
      `/departments/${this.departmentId}/member_count`,
      Object.assign(
        { params: { date: m.format("YYYY-MM-DD") } },
        this.$store.getters["config/getAPIConfig"]
      )
    )

    this.data = data.data.data
    this.loading = false
  }

  get chartData() {
    const data = {
      labels: this.labels,
      datasets: [
        {
          label: "Members Billed",
          backgroundColor: "#f9aa33",
          data: this.countBilled
        },
        {
          label: "Active Policies",
          backgroundColor: "#FFE0B2",
          data: this.countPolicies
        }
      ]
    }
    return data
  }
  get chartOptions() {
    return {
      animation: false,
      plugins: { legend: { display: false } },
      responsive: false,
      scales: {
        x: {
          grid: {
            display: false
          }
        },
        y: {
          grid: { display: false },
          ticks: {
            precision: 0
          }
        }
      }
    }
  }

  get labels() {
    if (this.data) {
      return this.data.map((el: MemberCountData) => {
        const d = new Date(el.date)
        return `${d.getMonth() + 1}/${d.getFullYear()}`
      })
    } else {
      return []
    }
  }
  get countBilled() {
    if (this.data) {
      return this.data.map((el: MemberCountData) => el.count_by_bill)
    } else return []
  }
  get countPolicies() {
    if (this.data) {
      return this.data.map((el: MemberCountData) => el.count_by_policy)
    } else return []
  }
}
