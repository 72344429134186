
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Letter } from "@/types/Letter"

@Component({
  components: {
    CardTemplate,
    DialogTemplate,
    PagedDataTable,
    PageTemplate
  }
})
export default class Letters extends Vue {
  private loading = false
  private total = 0
  private startingPerPage = 25
  private perPage = 5
  private currentPage = 1
  private newLetter = new Letter()

  private letters: Letter[] = []

  private options = {
    sortBy: "attributes.name",
    sortDesc: false,
    mustSort: true
  }

  created() {
    this.loadLetters(1, this.startingPerPage)
  }

  getLetters(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadLetters(details.page, details.itemsPerPage)
      this.perPage = details.itemsPerPage
      this.currentPage = details.page
    }
  }

  async loadLetters(page: number, perPage: number) {
    this.loading = true
    const data = await this.$store.dispatch("letters/loadBy", {
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.letters = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  goToLetter(letter: Letter) {
    this.$router.push({
      name: "Letter",
      params: { id: letter.id.toString() }
    })
  }

  async createLetter() {
    await this.$store.dispatch("letters/create", {
      data: this.newLetter
    })
    this.loadLetters(1, this.startingPerPage)
  }

  get headers() {
    return [
      {
        text: "Name",
        value: "attributes.name"
      }
    ]
  }
}
