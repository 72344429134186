
import { Component, Vue, Prop } from "vue-property-decorator"
import { Disbursement } from "@/types/Disbursement"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CurrencyField,
    DateField
  }
})
export default class DisbursementDetails extends Vue {
  @Prop() private disbursement!: Disbursement
}
