
import { Component, Vue } from "vue-property-decorator"
import PageTemplate from "@/components/PageTemplate.vue"

@Component({
  components: {
    PageTemplate
  }
})
export default class Payments extends Vue {}
