
import { Address as AddressType } from "@/types/Address"
import AddressDepartment from "@/components/AddressDepartment.vue"
import DateField from "@/components/DateField.vue"
import { Department } from "@/types/Department"
import DepartmentSection from "@/components/DepartmentSection.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { cloneDeep } from "lodash"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    AddressDepartment,
    DateField,
    DepartmentSection,
    DialogTemplate,
    PhoneNumber
  }
})
export default class DepartmentInfoLtc extends Vue {
  @Prop() private department!: Department

  private editDepartment = cloneDeep(this.department)
  private parentAddressAttributeName = "eip_address_id"

  @Watch("department", { deep: true })
  onDepartmentChange(value: Department) {
    if (value) {
      this.resetDepartment()
    }
  }

  async save() {
    const addressHasValues = Object.values(this.eipEditAddress.attributes).some(
      el => {
        return el
      }
    )

    if (addressHasValues) {
      let action = ""
      if (this.eipEditAddress.id) {
        action = "update"
      } else {
        action = "create"
      }

      const data = await this.$store.dispatch(
        "addresses/" + action,
        this.eipEditAddress
      )

      if (data.status == 200 && action == "create") {
        this.editDepartment.attributes.eip_address_id = data.data.data.id
      }
    }

    if (this.editDepartment.attributes.status == undefined)
      this.editDepartment.attributes.status = ""
    await this.$store.dispatch("departments/update", this.editDepartment)
    this.resetDepartment()
  }

  cancel() {
    this.resetDepartment()
  }

  resetDepartment() {
    this.editDepartment = cloneDeep(this.department)
  }

  clone(addr: AddressType | null) {
    if (addr) {
      return cloneDeep(addr)
    } else {
      return new AddressType()
    }
  }

  get eipAddress(): AddressType {
    console.log("eip add id: ", this.department.attributes.eip_address_id)
    if (this.department && this.department.attributes.eip_address_id) {
      return this.$store.getters["addresses/getById"](
        this.department.attributes.eip_address_id
      )
    }
    return new AddressType()
  }

  get eipEditAddress() {
    return this.clone(this.eipAddress)
  }
}
