var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CardTemplate',{attrs:{"id":"js-monthly-income","title":"Monthly Income","icon":"mdi-cash","iconColor":"secondary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
        _vm.$can('update', 'Claim') &&
          _vm.claim.attributes.income_worksheet_id == null
      )?_c('DialogTemplate',{ref:"incomeDialog",attrs:{"title":"New Income Worksheet","btnLabel":"new worksheet","wide":true},on:{"save":_vm.addWorksheet}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('CurrencyField',{staticClass:"js-base-salary",attrs:{"label":"Base Salary"},model:{value:(_vm.baseSalary),callback:function ($$v) {_vm.baseSalary=$$v},expression:"baseSalary"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"base salary desc/notes"},model:{value:(_vm.baseNotes),callback:function ($$v) {_vm.baseNotes=$$v},expression:"baseNotes"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('CurrencyField',{staticClass:"js-holiday",attrs:{"label":"Holiday"},model:{value:(_vm.holiday),callback:function ($$v) {_vm.holiday=$$v},expression:"holiday"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"holiday desc/notes"},model:{value:(_vm.holidayNotes),callback:function ($$v) {_vm.holidayNotes=$$v},expression:"holidayNotes"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('CurrencyField',{staticClass:"js-shift-differential",attrs:{"label":"Shift Differential"},model:{value:(_vm.shiftDifferential),callback:function ($$v) {_vm.shiftDifferential=$$v},expression:"shiftDifferential"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('CurrencyField',{staticClass:"js-uniform-allowance",attrs:{"label":"Uniform Allowance"},model:{value:(_vm.uniformAllowance),callback:function ($$v) {_vm.uniformAllowance=$$v},expression:"uniformAllowance"}})],1),_c('v-col',{attrs:{"cols":"3","offset":"3"}},[_c('v-select',{staticClass:"js-pay-periods",attrs:{"items":[
              { value: 12, text: '12' },
              { value: 24, text: '24' },
              { value: 26, text: '26 ' }
            ],"label":"Pay Periods","rules":[v => !!v || 'Pay Periods is Required']},model:{value:(_vm.payPeriods),callback:function ($$v) {_vm.payPeriods=$$v},expression:"payPeriods"}})],1)],1)],1):_c('div',[_c('v-btn',{attrs:{"id":"js-view-worksheet","x-small":"","color":"secondary","to":{
          name: 'IncomeWorksheet',
          params: { id: _vm.claim.attributes.income_worksheet_id }
        }}},[_vm._v("view worksheet")])],1)]},proxy:true}])},[_c('span',{attrs:{"id":"js-claim-income-worksheet"}},[_c('CurrencyField',{staticClass:"green--text display-2",attrs:{"display":true,"value":_vm.claim.attributes.monthly_income}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }