
import { Component, Vue, Watch } from "vue-property-decorator"
import DisbursementsTable from "@/components/DisbursementsTable.vue"
import PageTemplateList from "@/components/PageTemplateList.vue"
import { User } from "@/types/User"

@Component({
  components: { DisbursementsTable, PageTemplateList }
})
export default class Disbursements extends Vue {
  private tab = 0
  private pType = "all"
  private totals = {}
  private selectedSupervisorId = 0

  created() {
    this.tab = this.status
      ? this.tabs.findIndex(el => el.label == this.status)
      : 0

    this.pType = (this.$route.query.type
      ? this.$route.query.type
      : "all") as string

    this.calculateTotals()
  }

  @Watch("status")
  onStatusChange(value: string) {
    this.tab = value ? this.tabs.findIndex(el => el.label == value) : 0
  }

  @Watch("pType")
  typeChange() {
    this.calculateTotals()
  }

  get status() {
    return (this.$route.query ? this.$route.query.status : "all") as string
  }

  get classification() {
    return this.pType.includes("Fire") || this.pType.includes("Law")
      ? this.pType.includes("Fire")
        ? "0"
        : "1"
      : null
  }

  get tabs() {
    return [
      { label: "All", route: "all" },
      { label: "Pending", route: "Pending" },
      { label: "Approved", route: "Approved" },
      { label: "Paid", route: "Paid" }
    ]
  }

  get claimSupervisors() {
    let users = this.$store.getters["users/getAll"]
    // from kilo-api
    // CLAIM_MANAGER      = 32
    // CLAIM_SUPERVISOR   = 64
    users = users
      ? users
          .filter((el: User) => {
            return (el.attributes.roles & 32) + (el.attributes.roles & 64) > 0
          })
          .map((el: User) => {
            return { value: Number(el.id), text: el.name }
          })
      : []

    users.push({ value: 0, text: "All Disbursements" })

    return users
  }

  filter(status: string) {
    const filter: {
      "product.product_type"?: string
      status?: string | string[]
      "department.classification"?: string
    } = {}

    if (this.pType && this.pType != "all") {
      const t = this.pType.includes("ltd") ? "ltd" : "ltc"
      const c =
        this.pType.includes("Fire") || this.pType.includes("Law")
          ? this.pType.includes("Fire")
            ? "0"
            : "1"
          : null
      filter["product.product_type"] = t
      if (c !== null) {
        filter["department.classification"] = c
      }
    } else {
      delete filter["product.product_type"]
    }

    filter.status = status

    return filter
  }

  async calculateTotals() {
    this.totals = {}
    const tabs = ["Pending", "Approved"]
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i]
      const data = await this.$store.dispatch("disbursements/loadBy", {
        page: 1,
        per_page: 1,
        ...this.filter(tab)
      })

      Vue.set(this.totals, tab, data?.data?.meta?.pagination?.count)
    }
  }

  goType(route: string) {
    if (this.$route.query.type != route) {
      const t = route.includes("ltd") ? "ltd" : "ltc"
      const c =
        route.includes("Fire") || route.includes("Law")
          ? route.includes("Fire")
            ? "0"
            : "1"
          : null

      const path = {
        path: "/disbursements",
        query: { status: this.status, type: t, classification: c }
      }
      this.$router.push(path)
    }
  }

  go(route: string) {
    if (this.status != route) {
      const t = this.pType.includes("ltd") ? "ltd" : "ltc"
      const c =
        this.pType.includes("Fire") || this.pType.includes("Law")
          ? this.pType.includes("Fire")
            ? "0"
            : "1"
          : null

      const path = {
        path: "/disbursements",
        query: { status: route, type: t, classification: c }
      }
      this.$router.push(path)
    }
  }
}
