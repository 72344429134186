
import { Claim } from "@/types/Claim"
import { Policy } from "@/types/Policy"
import { Product } from "@/types/Product"
import { DisabilityType } from "@/types/DisabilityType"
import CardTemplate from "@/components/CardTemplate.vue"
import ClaimForm from "@/components/ClaimForm.vue"
import ClaimFormLtc from "@/components/ClaimFormLtc.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import moment from "moment"

@Component({
  components: {
    CardTemplate,
    ClaimForm,
    ClaimFormLtc,
    CurrencyField,
    DateField
  }
})
export default class ClaimDetails extends Vue {
  @Prop() private claim!: Claim
  @Prop() private policy!: Policy
  @Prop() private product!: Product

  editMode = false
  claimData = new Claim()

  @Watch("claim", { deep: true })
  onClaimChange(value: Claim) {
    if (!value.dirty) {
      this.setEditableCopy(value)
    }
  }

  created() {
    if (this.disabilityTypes.length == 0) {
      this.$store.dispatch("disabilityTypes/loadAll")
    }
    this.setEditableCopy(this.claim)
  }

  get selectedDisabilityType() {
    const type = this.$store.getters["disabilityTypes/getById"](
      this.claim.attributes.disability_type_id
    )
    return type?.attributes.name
  }

  get disabilityTypes() {
    const disabilityTypes = this.$store.getters["disabilityTypes/getAll"]

    return disabilityTypes.map((el: DisabilityType) => {
      return { value: Number(el.id), text: el.attributes.name }
    })
  }

  moment() {
    return moment()
  }

  saveClaim() {
    this.$store.dispatch("claims/update", {
      data: this.claimData,
      successMessage: "Claim updated"
    })
  }

  cancelEdit() {
    this.setEditableCopy(this.claim)
  }

  setEditableCopy(claim: Claim) {
    this.claimData = cloneDeep(claim)
  }

  get isLTC() {
    return this.product?.attributes?.product_type == "ltc"
  }

  get isLTD() {
    return this.product?.attributes?.product_type == "ltd"
  }
}
