
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import Following from "@/components/Following.vue"

@Component({ components: { Following } })
export default class PageTemplate extends Vue {
  @Prop() private recordId!: string
  @Prop() private type!: string
  @Prop() private subtitle!: string
  @Prop() private name!: string
  @Prop() private icon!: string
  @Prop() private dirty!: boolean
  @Prop() private dataLoading!: boolean
  @Prop() private width!: number
  @Prop() private scrollPosition!: { type: number; default: 50 }
  private spin = ""
  private showScrollHeader = false

  @Watch("name")
  updateTitle() {
    document.title = this.name ? this.name : "CPSA"
  }

  created() {
    document.title = this.name ? this.name : "CPSA"
  }

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    document
      ?.querySelector("#js-icon")
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ?.addEventListener("click", function(e: any) {
        if (e.detail == 3 && !that.spin) {
          that.spin = "mdi-spin"
          setTimeout(() => {
            that.spin = ""
          }, 2000)
        }
      })
  }

  get titleCaseType() {
    return this.type?.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  onScroll() {
    this.showScrollHeader = window.scrollY > Number(this.scrollPosition)
  }
}
