
import DateField from "@/components/DateField.vue"
import DepartmentSection from "@/components/DepartmentSection.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import HelpBubble from "@/components/HelpBubble.vue"
import DepartmentSectionMixin from "@/mixins/DepartmentSectionMixin"
import { Employer } from "@/types/Employer"
import { Component, Prop, Vue } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import { Department } from "@/types/Department"

@Component({
  components: {
    DateField,
    DepartmentSection,
    DialogTemplate,
    HelpBubble
  },
  mixins: [DepartmentSectionMixin]
})
export default class DepartmentGeneralInfo extends Vue {
  @Prop() private employer!: Employer
  private editEmployer = new Employer()
  private showEmployerEdit = false
  private employersList: Employer[] = []
  private employerDisplayName = ""
  private statuses = [
    "F = Fire",
    "F = Fire EIP",
    "P = Police",
    "P = Police EIP",
    "NF = Non-Board Fire",
    "NF25 = Non-Board Fire",
    "NP = Non-Board Police",
    "CL = Cancel",
    "CF = Cancel",
    "CLM = Cancel-Merged",
    "CFM = Cancel-Merged"
  ]

  // editDepartment and department are part of DepartmentSectionMixin. local version is needed to make
  // typescript happy  when it is referenced in function and is merged with
  // mixin version.
  private editDepartment!: Department
  private department!: Department

  created() {
    this.employerDisplayName = this.employer
      ? this.employer.attributes.name
        ? this.employer.attributes.name
        : "unset"
      : "unset"
  }

  async showEmployerEditList() {
    await this.$store.dispatch("employers/loadAll")
    this.employersList = this.employers
      ? cloneDeep(this.employers).sort((a: Employer, b: Employer) => {
          return a.attributes.name > b.attributes.name ? 1 : -1
        })
      : [Employer]

    this.showEmployerEdit = !this.showEmployerEdit
  }

  async selectEmployer(newEmployer: Employer) {
    this.editDepartment.attributes.employer_id = parseInt(newEmployer.id)
    this.editEmployer.id = newEmployer.id
    this.employerDisplayName = newEmployer.attributes.name
    this.showEmployerEdit = !this.showEmployerEdit
  }

  get employers() {
    return this.$store.getters["employers/getAll"]
  }

  get mergedDepartment(): Department {
    if (this.department && this.department.attributes.merged_department_id) {
      return this.$store.getters["departments/getById"](
        this.department.attributes.merged_department_id
      )
    }

    return null
  }

  isChildToString(isChild: boolean) {
    if (isChild) {
      return "Yes"
    }
    return "No"
  }

  effectiveInLast12Months() {
    let months = 0

    const d1 = new Date(this.department.attributes.effective_date)
    const d2 = new Date()

    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth()
    months += d2.getMonth()

    return months < 12
  }
}
