import Model from "./Model"

export class Note extends Model<Note> {
  constructor(response?: Note) {
    super(response as Note)
    this.attributes.updated_at = new Date(this.attributes.updated_at)
    this.attributes.created_at = new Date(this.attributes.created_at)
  }
  id: string
  attributes!: {
    notable_type: string
    notable_id: number
    text: string
    user_id: number
    updated_at: Date
    created_at: Date
  }
  static get modelName() {
    return "Note"
  }
}
