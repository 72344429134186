
import { Component, Vue, Prop } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import { Payment } from "@/types/Payment"

@Component({
  components: {
    CurrencyField,
    DateField
  }
})
export default class PaymentDetails extends Vue {
  @Prop() private payment!: Payment

  get productName() {
    const product = this.$store.getters["products/getById"](
      this.payment.attributes.product_id
    )
    return product ? product.attributes.name : "** ERROR **"
  }
}
