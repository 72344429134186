
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate
  }
})
export default class BillingLabels extends Vue {
  private working = false

  async getLabel() {
    this.working = true
    const response = await axios.get(
      "/labels",
      Object.assign(
        { responseType: "blob" },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fURL = document.createElement("a")

    fURL.href = fileURL
    fURL.setAttribute("download", "billing_labels.pdf")
    document.body.appendChild(fURL)

    fURL.click()
    this.working = false
  }
}
