import { render, staticRenderFns } from "./ReinstatePolicy.vue?vue&type=template&id=0730f1d5&"
import script from "./ReinstatePolicy.vue?vue&type=script&lang=ts&"
export * from "./ReinstatePolicy.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports