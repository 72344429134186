
import { Component, Prop, Vue } from "vue-property-decorator"
import { Member } from "@/types/Member"
import { Payment } from "@/types/Payment"
import { Product } from "@/types/Product"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import MonthField from "@/components/MonthField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import VueMask from "v-mask"
Vue.use(VueMask)
type removeData = {
  member_id: string
  end_date: Date
  name: string
  showMenu: boolean
}
type addData = {
  member: Member
  start_date: Date
}
type paymentItem = {
  amount: number
  product_id: string
  memo: string
  period_covered: Date
}

@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate,
    MonthField
  }
})
export default class MultiPaymentEntry extends Vue {
  @Prop() private payableType!: string
  @Prop() private payableId!: string
  @Prop() private availableProducts!: Product[]
  @Prop() private paymentType!: string
  @Prop() private eip!: boolean
  private loading = false
  private isSaving = false
  private validPayment = true
  private newPayment = new Payment()
  private isCheck = true
  private paymentMethods = ["Check", "CreditCard", "ACH"]
  private fullPremiumPayment = true
  private askConfirm = false
  private saveConfirmed = false

  private paymentMethod = "Check"
  private checkNumber = ""
  private checkDate = new Date()
  private receivedDate = new Date()
  private paymentItems: paymentItem[] = []
  private updateProgress = 0

  created() {
    this.reset()
  }
  reset() {
    this.isCheck = true

    this.paymentItems = []

    this.addItemRow()
  }
  changePaymentMethod(paymentMethod: string) {
    this.isCheck = paymentMethod == "Check"
    this.newPayment.attributes.status = this.isCheck ? "Paid" : "Scheduled"
  }

  addItemRow() {
    const item = {
      amount: 0,
      product_id: "",
      memo: "",
      period_covered: this.firstOfCurrentMonth()
    }

    if (this.productsOfType.length == 1 && this.paymentType == "ltd") {
      item.product_id = this.productsOfType[0].id
    }
    this.paymentItems.push(item)
  }

  removeItemRow(index: number) {
    this.paymentItems.splice(index, 1)
  }

  firstOfCurrentMonth() {
    const today = new Date()
    return new Date(today.getFullYear(), today.getMonth(), 1)
  }

  getSubtitle(value: string) {
    return value?.replace("Member ", "")
  }

  blur(member: removeData) {
    setTimeout(() => {
      member.showMenu = false
    }, 500)
  }

  mirrorPremium() {
    if (this.fullPremiumPayment) {
      this.newPayment.attributes.premium_amount = this.newPayment.attributes.amount
    }
  }

  async save() {
    this.isSaving = true

    let i = 1
    for (const item of this.paymentItems) {
      console.log("payment item", item.amount)

      const payment = new Payment()

      payment.attributes.date_received = this.receivedDate
      payment.attributes.check_date = this.checkDate
      payment.attributes.payment_method = this.paymentMethod
      payment.attributes.status = "Paid"
      payment.attributes.product_type = this.paymentType
      payment.attributes.eip = this.eip
      payment.attributes.payable_type = this.payableType
      payment.attributes.payable_id = parseInt(this.payableId)
      payment.attributes.product_id = parseInt(item.product_id)

      payment.attributes.memo = item.memo
      payment.attributes.period_covered = item.period_covered
      payment.attributes.amount = item.amount.toString()
      if (this.fullPremiumPayment) {
        payment.attributes.premium_amount = item.amount.toString()
      }
      payment.attributes.check_number = this.checkNumber

      payment.attributes.payment_method =
        payment.attributes.payment_method == "ACH"
          ? (payment.attributes.payment_method = "Ach")
          : payment.attributes.payment_method

      await this.$store.dispatch("payments/create", {
        data: payment,
        successMessage: `Payment ${i.toString()} of ${
          this.paymentItems.length
        } added`
      })

      await new Promise(r => setTimeout(r, 1000))
      this.updateProgress = (i / this.paymentItems.length) * 100

      i += 1
    }

    await new Promise(r => setTimeout(r, 500))
    this.$emit("load-bills-and-payments")
    this.isSaving = false
    this.reset()
  }

  get itemsAmountTotal() {
    return this.paymentItems
      .map(item => {
        return Number(item.amount)
      })
      .reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
        0
      )
      .toString()
  }

  get oversAmount() {
    const amount = this.newPayment.attributes.amount
      ? Number(this.newPayment.attributes.amount)
      : 0
    const premium_amount = this.newPayment.attributes.premium_amount
      ? Number(this.newPayment.attributes.premium_amount)
      : 0

    return amount - premium_amount
  }

  get saveDisabled() {
    return !this.validPayment
  }

  get ltdProduct() {
    return this.availableProducts.find(
      product => product.attributes.product_type == "ltd"
    )
  }

  get productsOfType() {
    return this.availableProducts.filter(
      product =>
        product.attributes.product_type == this.paymentType &&
        product.attributes.eip == this.eip
    )
  }
}
