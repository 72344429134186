import Model from "./Model"

export class Employer extends Model<Employer> {
  constructor(employer?: Employer) {
    super(employer as Employer)
  }
  id: string
  attributes!: {
    name: string
    attention: string
    address: string
    carrier: string
    carrier_attention: string
    carrier_address: string
    adjuster: string
    attorney_id: number
  }
  static get modelName() {
    return "Employer"
  }
}
