
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate
  }
})
export default class MembershipChanges extends Vue {
  private fromDate: Date = null
  private toDate: Date = null
  private type = "fire"
  private working = false
  private changeResults: string[] = []
  @Prop() departmentId: string

  async getReport() {
    this.working = true
    if (this.departmentId) {
      const response = await axios.get(
        `/departments/${this.departmentId}/changes`,
        Object.assign(
          {
            params: {
              from_date: this.fromDate,
              to_date: this.toDate
            }
          },
          this.$store.getters["config/getAPIConfig"]
        )
      )
      this.changeResults = response.data
      ;(this.$refs.membershipResults as DialogTemplate).open(true)
    } else {
      const response = await axios.get(
        "/memberships/changes",
        Object.assign(
          {
            params: {
              type: this.type,
              from_date: this.fromDate,
              to_date: this.toDate
            },
            responseType: "blob"
          },
          this.$store.getters["config/getAPIConfig"]
        )
      )
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fURL = document.createElement("a")

      fURL.href = fileURL
      fURL.setAttribute("download", "membership_changes.csv")
      document.body.appendChild(fURL)

      fURL.click()
      this.working = false
    }
  }
}
