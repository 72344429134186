
import { Component, Vue } from "vue-property-decorator"
import VersionsList from "@/components/VersionsList.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { SortOrder } from "@/store/utils/genericGetters"

@Component({
  components: {
    PageTemplate,
    VersionsList
  }
})
export default class Versions extends Vue {
  get id() {
    return this.$route.query.id
  }

  get model() {
    return this.$route.query.modelName
  }

  get description() {
    return this.$route.query.description
  }

  get versions() {
    return this.$store.getters["versions/getBy"](
      {
        item_id: this.id,
        item_type: this.model
      },
      "created_at",
      SortOrder.desc
    )
  }

  created() {
    this.loadVersions(this.id.toString(), this.model.toString())
  }

  beforeRouteUpdate(
    to: { query: { id: string; modelName: string } },
    from: { query: { id: string; modelName: string } },
    next: CallableFunction
  ) {
    this.loadVersions(to.query.id, to.query.modelName)
    next()
  }

  loadVersions(id: string, model: string) {
    this.$store.dispatch("versions/loadBy", {
      item_id: id,
      item_type: model
    })
  }
}
