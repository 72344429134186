export default class DateHelper {
  static stringToDate(date: Date | string) {
    if (typeof date == "object") return date
    const parts = date && typeof date == "string" ? date.split("-") : null
    return parts
      ? new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]))
      : null
  }

  static sortByDate(a: Date, b: Date) {
    return a > b ? 1 : -1
  }

  static shortFormat(d: Date | null) {
    if (d == null) {
      return null
    }
    const month = d.toLocaleString("default", {
      month: "short",
      timeZone: "UTC"
    })
    const year = d.toLocaleString("default", {
      year: "2-digit",
      timeZone: "UTC"
    })
    return `${month}. '${year}`
  }

  static dateRange(d1: Date, d2: Date) {
    let s = ""
    const s1 = DateHelper.shortFormat(d1)

    const s2 = DateHelper.shortFormat(d2)

    if (
      d1.getUTCFullYear() == d2.getUTCFullYear() &&
      d1.getUTCMonth() == d2.getUTCMonth()
    ) {
      s = s1
    } else {
      s = s1 + " - " + s2
    }
    return s
  }
}
