
import { Component, Vue } from "vue-property-decorator"
import { mapGetters } from "vuex"
import { Alert } from "@/types/Alert"

@Component({
  computed: {
    ...mapGetters("alerts", ["getFailures"])
  }
})
export default class ErrorAlerts extends Vue {
  getFailures!: () => Alert[]

  dismissAlert(uuid: string) {
    this.$store.commit("alerts/removeAlert", uuid)
  }

  get showBanner() {
    return !!this.getFailures.length
  }
}
