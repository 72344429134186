
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Payment } from "@/types/Payment"
import feeHelper from "@/services/feeHelper"

class PayableItem {
  policy_id: number
  amount: string
  pemium: string
  last: string
  next: string
  member_first_name: string
  member_last_name: string
  member_email: string
  policy_number: string
  billing_frequency: string
  check_number: string
  check_date: string
  check_amount: string
  check_memo: string
  check_received: string
}

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    PageTemplate
  }
})
export default class CheckPayments extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selected: any[] = []
  private policies: PayableItem[] = []
  private loading = false
  private working = false
  private workProgress = 0
  private workingCompleteMessage = ""
  private selectedTotal = "0"
  private account = ""

  async fetchPayable() {
    this.loading = true
    let page = 1

    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    // const classification = accountParts.length > 1 ? accountParts[1] : null

    this.policies = []

    do {
      const data = await this.$store.dispatch(
        "policies/fetchPoliciesToPayCheck",
        { page: page, productType: prodType }
      )
      this.policies.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)

    this.loading = false
  }

  @Watch("account")
  onAccountChange() {
    this.selectedTotal = "0"
    this.selected = []
    this.fetchPayable()
  }

  @Watch("selected")
  onSelectedDisbursementsChange(value: PayableItem[]) {
    const amounts = value.map((item: PayableItem) =>
      parseFloat(item.check_amount)
    )
    if (amounts.length == 0) {
      this.selectedTotal = "0"
    } else if (amounts.length == 1) {
      this.selectedTotal = amounts[0].toString()
    } else {
      this.selectedTotal = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  async makePayments() {
    this.working = true
    const accountParts = this.account.split("_")
    const prodType = accountParts[0]

    let i = 0
    const updatesToWaitFor = this.selected.map((selectedItem: PayableItem) => {
      this.workProgress = 100 * (i / 3)

      if (
        selectedItem.check_amount &&
        selectedItem.check_date &&
        selectedItem.check_received &&
        selectedItem.check_number
      ) {
        const newPayment = new Payment()
        newPayment.attributes.payable_type = "Policy"
        newPayment.attributes.payable_id = selectedItem.policy_id
        newPayment.attributes.payment_method = "Check"
        newPayment.attributes.status = "Paid"
        newPayment.attributes.amount = selectedItem.check_amount
        newPayment.attributes.premium_amount = selectedItem.check_amount
        newPayment.attributes.check_number = selectedItem.check_number
        newPayment.attributes.date_received = new Date(
          selectedItem.check_received
        )
        newPayment.attributes.check_date = new Date(selectedItem.check_date)
        newPayment.attributes.memo = selectedItem.check_memo
        const checkAmount = Number(selectedItem.check_amount)
        const thisFee = Number(this.fee(selectedItem.billing_frequency))
        newPayment.attributes.premium_amount = (
          checkAmount - thisFee
        ).toString()
        newPayment.attributes.product_type = prodType
        if (prodType == "ltd") {
          newPayment.attributes.eip = true
        }

        i++
        return this.$store.dispatch("payments/create", {
          data: newPayment
        })
      }
    })

    this.workProgress = 100

    if (updatesToWaitFor.length > 0) {
      await Promise.all(updatesToWaitFor)
    }

    await new Promise(r => setTimeout(r, 200))

    this.selected = []
    this.working = false
    this.fetchPayable()
    this.workingCompleteMessage =
      "Checks should be entered. But in some cases the robots working behind the scenes may take longer to clean up. Try waiting a few minutes and refreshing this page if this list contains policies that you expected to be billed."
  }

  fee(billingFrequency: string) {
    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    return feeHelper.fee(billingFrequency, prodType == "ltd")
  }

  get tableHeight() {
    return window.innerHeight - 430
  }

  get headers() {
    return [
      {
        text: "LAST",
        value: "member_last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "FIRST",
        value: "member_first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "POLNUM",
        value: "policy_number",
        class: "text-left caption",
        sortable: true,
        width: "275px"
      },
      {
        text: "BALANCE",
        value: "balance",
        class: "text-left caption",
        sortable: true,
        width: "120px"
      },
      {
        text: "CHECK #",
        value: "check_number",
        class: "text-left caption",
        sortable: false,
        width: "195px"
      },
      {
        text: "CHECK DATE",
        value: "check_date",
        class: "text-left caption",
        sortable: false,
        width: "150px"
      },
      {
        text: "RECEIVED",
        value: "check_received",
        class: "text-left caption",
        sortable: false,
        width: "150px"
      },
      {
        text: "CHECK AMOUNT",
        value: "check_amount",
        class: "text-left caption",
        sortable: false,
        width: "150px"
      },
      {
        text: "MEMO",
        value: "check_memo",
        class: "text-left caption",
        sortable: false
      }
    ]
  }
}
