
import { Component, Vue, Watch } from "vue-property-decorator"
import ClaimsTable from "@/components/ClaimsTable.vue"
import PageTemplateList from "@/components/PageTemplateList.vue"
import { User } from "@/types/User"

@Component({
  components: { ClaimsTable, PageTemplateList }
})
export default class Claims extends Vue {
  private tab = 0
  private pType = "all"
  private totals = {}
  private selectedSupervisorId = 0

  created() {
    this.pType = (this.$route.query.type
      ? this.$route.query.type
      : "all") as string

    this.calculateTotals()
  }

  @Watch("state")
  onTabChange(value: string) {
    this.tab = value ? this.tabs.findIndex(el => el == value) : 0
  }

  @Watch("tabs")
  async onTabsChange() {
    this.tab = this.state ? this.tabs.findIndex(el => el == this.state) : 0

    this.calculateTotals()
  }

  @Watch("pType")
  async typeChange() {
    this.calculateTotals()
  }

  get state() {
    return (this.$route.query.state ? this.$route.query.state : "") as string
  }

  get tabs() {
    const claimStates = new Set(["active"])

    const states: string[] = this.$store.getters["config/getActiveStates"]

    if (states) {
      states.forEach(el => {
        if (el.startsWith("lt")) {
          el = this.removePrefix(el)
        }
        claimStates.add(el.replace("_", " "))
      })
    }

    return Array.from(claimStates)
  }

  get claimSupervisors() {
    let users = this.$store.getters["users/getAll"]
    // from kilo-api
    // CLAIM_MANAGER      = 32
    // CLAIM_SUPERVISOR   = 64
    users = users
      ? users
          .filter((el: User) => {
            return (el.attributes.roles & 32) + (el.attributes.roles & 64) > 0
          })
          .map((el: User) => {
            return { value: Number(el.id), text: el.name }
          })
      : []

    users.push({ value: 0, text: "All Claims" })

    return users
  }

  getPrintableList() {
    this.$store
      .dispatch("claims/getPayables", {
        adminId: this.selectedSupervisorId
      })
      .then(data => {
        const fileURL = window.URL.createObjectURL(new Blob([data.data]))
        const fURL = document.createElement("a")

        const downloadFileName = "payables.csv"

        fURL.href = fileURL

        fURL.setAttribute("download", downloadFileName)
        document.body.appendChild(fURL)

        fURL.click()
      })
  }

  removePrefix(str: string) {
    return str.split(/_(.+)/)[1]
  }

  filter(state: string) {
    const filter: {
      "product.product_type"?: string
      aasm_state?: string | string[]
    } = {}

    if (this.pType && this.pType != "all") {
      filter["product.product_type"] = this.pType
    }

    if (!state || state == "active") {
      const allStates: string[] = this.$store.getters["config/getActiveStates"]
      const states: string[] = []
      allStates?.forEach(s => {
        if (!this.pType || this.pType == "all" || s.includes(this.pType)) {
          states.push(s.replace(" ", "_"))
        }
      })
      filter.aasm_state = states
    } else if (state) {
      if (this.pType && this.pType != "all") {
        filter.aasm_state = `${this.pType}_${state.replace(" ", "_")}`
      } else {
        const states = [
          `ltd_${state.replace(" ", "_")}`,
          `ltc_${state.replace(" ", "_")}`
        ]
        filter.aasm_state = states
      }
    }

    return filter
  }

  async calculateTotals() {
    this.totals = {}
    if (this.tabs.length > 1) {
      for (let i = 0; i < this.tabs.length; i++) {
        const tab = this.tabs[i]
        const data = await this.$store.dispatch("claims/loadBy", {
          page: 1,
          per_page: 1,
          ...this.filter(tab)
        })

        Vue.set(this.totals, tab, data?.data?.meta?.pagination?.count)
      }
    }
  }

  goType(route: string) {
    if (this.$route.query.type != route) {
      const path = {
        path: "/claims",
        query: { state: this.state, type: route }
      }
      this.$router.push(path)
    }
  }

  go(route: string) {
    if (this.state != route) {
      const path = {
        path: "/claims",
        query: { state: route, type: this.pType }
      }
      this.$router.push(path)
    }
  }
}
