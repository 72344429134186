
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DialogTemplate
  }
})
export default class UpdatePolicyAttributeToggle extends Vue {
  @Prop() private title!: string
  @Prop() private id!: number
  @Prop() private toggleSelector!: string
  @Prop() private status!: boolean | null
  @Prop() private attributeName!: string

  private changedStatus = false

  mounted() {
    this.changedStatus = this.status
  }

  async update() {
    await axios.put(
      `/policies/${this.id}`,
      { [this.attributeName]: this.changedStatus },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
