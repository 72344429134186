
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import AccountChip from "@/components/AccountChip.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DateHelper from "@/components/utils/DateHelper"
import PagedDataTable from "@/components/PagedDataTable.vue"
import { Payment } from "@/types/Payment"
import { Policy } from "@/types/Policy"

@Component({
  components: {
    AccountChip,
    CurrencyField,
    DateField,
    PagedDataTable
  }
})
export default class PaymentsList extends Vue {
  @Prop() private depositId!: string
  @Prop() private productType!: string
  @Prop() private classification!: string
  @Prop() private selectable!: { type: boolean; default: false }
  @Prop() private excludeRejected!: { type: boolean; default: false }

  private loading = false
  private total = 0
  private startingPerPage = 5
  private payments: Payment[] = []
  private payableMemberIds: number[] = []
  private payablePolicyIds: number[] = []
  private totalAmount = "0"

  created() {
    this.loadPayments(1, this.startingPerPage)
  }

  @Watch("payments")
  async onPayments(value: Payment[]) {
    const policyIds = value
      .filter(el => el.attributes.payable_type == "Policy")
      .map(el => el.attributes.payable_id)
    const departmentIds = value
      .filter(el => el.attributes.payable_type == "Department")
      .map(el => el.attributes.payable_id)

    if (policyIds.length > 0) {
      await this.$store.dispatch("policies/loadBy", {
        id: [...new Set(policyIds)]
      })
      this.payablePolicyIds = policyIds
    }

    if (departmentIds.length > 0) {
      await this.$store.dispatch("departments/loadBy", {
        id: [...new Set(departmentIds)]
      })
    }
  }

  @Watch("payableMemberIds")
  onPayableMemberIds(value: number[]) {
    if (value.length > 0) {
      this.$store.dispatch("members/loadBy", {
        id: [...new Set(value)]
      })
    }
  }

  @Watch("payablePolicyIds")
  onPayablePolicyIds(value: number[]) {
    if (value.length > 0) {
      const payablePolicies = value.map(el =>
        this.$store.getters["policies/getById"](el)
      )
      if (payablePolicies) {
        const departmentIds = payablePolicies.map(
          (el: Policy) => el.attributes.department_id
        )

        this.payableMemberIds = payablePolicies.map(
          (el: Policy) => el.attributes.member_id
        )

        if (departmentIds.length > 0) {
          this.$store.dispatch("departments/loadBy", {
            id: [...new Set(departmentIds)]
          })
        }
      }
    }
  }

  formatCoveragePeriod(payment: Payment) {
    if (payment.attributes.period_covered) {
      return DateHelper.shortFormat(payment.attributes.period_covered)
    } else {
      return ""
    }
  }

  paymentSelection(value: Payment[]) {
    const selectedIds = value.map(payment => payment.id)
    const map = value.map(payment => parseFloat(payment.attributes.amount))
    if (map.length == 0) {
      this.totalAmount = "0"
    } else if (map.length == 1) {
      this.totalAmount = map[0].toString()
    } else {
      this.totalAmount = map
        .reduce((prev, cur) => {
          return prev + cur
        })
        .toString()
    }
    this.$emit("input", selectedIds)
  }

  payableName(payment: Payment) {
    let name = ""
    if (payment.attributes.payable_type == "Policy") {
      const policy = this.$store.getters["policies/getById"](
        payment.attributes.payable_id
      )

      const member = policy
        ? this.$store.getters["members/getById"](policy.attributes.member_id)
        : null

      name = member
        ? member.attributes.first_name + " " + member.attributes.last_name
        : ""
    } else {
      const payable = this.$store.getters["departments/getById"](
        payment.attributes.payable_id
      )
      name = payable ? payable.attributes.name : ""
    }
    return name
  }

  payableClassification(payment: Payment) {
    if (payment.attributes.product_type == "ltc") {
      return "ltc"
    }

    let classification = ""
    if (payment.attributes.payable_type == "Policy") {
      const policy = this.$store.getters["policies/getById"](
        payment.attributes.payable_id
      )

      const department = policy
        ? this.$store.getters["departments/getById"](
            policy.attributes.department_id
          )
        : null

      classification = department ? department.attributes.classification : ""
    } else {
      const payable = this.$store.getters["departments/getById"](
        payment.attributes.payable_id
      )
      classification = payable ? payable.attributes.classification : ""
    }
    return classification
  }

  async loadPayments(page: number, perPage: number) {
    this.loading = true

    const filter: {
      page: number
      per_page: number
      payment_method: string
      order: string
      order_direction: string
      deposit_id: string
      product_type?: string
      status?: string
    } = {
      page: page,
      per_page: perPage,
      payment_method: "Check",
      order: "check_date",
      order_direction: "DESC",
      deposit_id: this.depositId || null
    }
    if (this.productType) {
      filter.product_type = this.productType
    }
    if (this.excludeRejected) {
      filter.status = "!Rejected"
    }

    const data = await this.$store.dispatch("payments/loadBy", filter)

    this.payments = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  getPayments(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadPayments(details.page, details.itemsPerPage)
    }
  }

  get headers() {
    return [
      {
        text: "CHECK DATE",
        value: "attributes.check_date",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "COVERED",
        value: "attributes.period_covered",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "PLAN",
        value: "attributes.plan",
        sortable: false,
        class: "caption"
      },
      {
        text: "PAID BY",
        value: "attributes.paid_by",
        sortable: false,
        class: "caption"
      },
      {
        text: "CHECK #",
        value: "attributes.check_number",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "AMOUNT",
        value: "attributes.amount",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "PREMIUM",
        value: "attributes.premium_amount",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "MEMO",
        value: "attributes.memo",
        sortable: false,
        class: "text-left caption"
      }
    ]
  }
}
