
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import { User } from "@sentry/types"
import { Claim } from "@/types/Claim"
import { Disbursement } from "@/types/Disbursement"

@Component({
  components: {
    CardTemplate,
    CurrencyField
  }
})
export default class MyClaims extends Vue {
  @Prop() user!: User
  @Prop() claims!: Claim[]

  private today = new Date()

  claimDisbursementCount(claim: Claim) {
    const disbursements = this.claimDisbursements(claim)
    return disbursements.length
  }

  claimDisbursementLastMonth(claim: Claim) {
    const disbursements = this.claimDisbursements(claim).filter(
      (el: Disbursement) => {
        const disbursementDate = new Date(el.attributes.date)

        const lastMonthNumber =
          this.today.getMonth() == 0 ? 11 : this.today.getMonth() - 1
        const lastMonthYearNumber =
          this.today.getMonth() == 0
            ? this.today.getUTCFullYear() - 1
            : this.today.getUTCFullYear()

        return (
          lastMonthNumber == disbursementDate.getMonth() &&
          lastMonthYearNumber == disbursementDate.getUTCFullYear()
        )
      }
    )
    return disbursements
  }

  claimDisbursementThisMonth(claim: Claim) {
    const disbursements = this.claimDisbursements(claim).filter(
      (el: Disbursement) => {
        const disbursementDate = new Date(el.attributes.date)
        return (
          this.today.getMonth() == disbursementDate.getMonth() &&
          this.today.getUTCFullYear() == disbursementDate.getUTCFullYear()
        )
      }
    )
    return disbursements
  }

  claimDisbursementNextMonth(claim: Claim) {
    const disbursements = this.claimDisbursements(claim).filter(
      (el: Disbursement) => {
        const disbursementDate = new Date(el.attributes.date)
        const nextMonthNumber =
          this.today.getMonth() == 11 ? 0 : this.today.getMonth() + 1
        const nextMonthYearNumber =
          this.today.getMonth() == 11
            ? this.today.getUTCFullYear() + 1
            : this.today.getUTCFullYear()
        return (
          nextMonthNumber == disbursementDate.getMonth() &&
          nextMonthYearNumber == disbursementDate.getUTCFullYear()
        )
      }
    )
    return disbursements
  }

  disbursementsAmount(disbursements: Disbursement[]) {
    let total = "0"
    const amounts = disbursements.map((disbursement: Disbursement) =>
      parseFloat(disbursement.attributes.amount)
    )
    if (amounts.length == 0) {
      total = "0"
    } else if (amounts.length == 1) {
      total = amounts[0].toString()
    } else {
      total = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
    return total
  }

  claimDisbursements(claim: Claim) {
    return this.$store.getters["disbursements/getBy"]({ claim_id: claim.id })
  }

  gotoClaim(value: Claim) {
    this.$router.push({
      path: `/claims/${value.id}`
    })
  }

  private monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]

  get sortableClaims() {
    return this.claims.map((el: Claim) => {
      return {
        ...el,
        sTotal: this.disbursementsAmount(this.claimDisbursements(el)),
        sLastMonth: this.disbursementsAmount(
          this.claimDisbursementLastMonth(el)
        ),
        sThisMonth: this.disbursementsAmount(
          this.claimDisbursementThisMonth(el)
        ),
        sNextMonth: this.disbursementsAmount(
          this.claimDisbursementNextMonth(el)
        )
      }
    })
  }

  get headers() {
    return [
      {
        text: "",
        value: "attributes.number",
        sortable: true,
        class: "overline"
      },
      {
        text: "total",
        value: "sTotal",
        sortable: true,
        class: "overline"
      },
      {
        text: `${this.monthNames[this.today.getMonth() - 1]}`,
        value: "sLastMonth",
        sortable: true,
        class: "overline"
      },
      {
        text: `${this.monthNames[this.today.getMonth()]}`,
        value: "sThisMonth",
        sortable: true,
        class: "overline"
      },
      {
        text: `${this.monthNames[this.today.getMonth() + 1]}`,
        value: "sNextMonth",
        sortable: true,
        class: "overline"
      }
    ]
  }
}
