
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import { cloneDeep } from "lodash"
import CurrencyField from "@/components/CurrencyField.vue"
import { IncomeWorksheet as IncomeWorksheetType } from "@/types/IncomeWorksheet"
import { IncomeItem } from "@/types/IncomeItem"
import PageTemplate from "@/components/PageTemplate.vue"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    PageTemplate
  }
})
export default class IncomeWorksheet extends Vue {
  private editMode = false
  private editWorksheet = new IncomeWorksheetType()
  private editItems = [new IncomeItem()]
  private addItemName = ""
  private addItemValue = ""

  get id() {
    return this.$route.params.id
  }

  get worksheet() {
    const o = this.$store.getters["incomeWorksheets/getById"](this.id)
    return o ? o : new IncomeWorksheetType()
  }

  get items() {
    return this.$store.getters["incomeItems/getBy"]({
      income_worksheet_id: this.id
    })
  }

  created() {
    this.loadWorksheet(this.id)
  }

  async loadWorksheet(id: string) {
    await this.$store.dispatch("incomeWorksheets/load", {
      id: id,
      include: ["income_items"]
    })

    this.editWorksheet = cloneDeep(this.worksheet)
    this.editItems = cloneDeep(this.items)
  }

  async saveWorksheet() {
    this.editItems.forEach(async (item: IncomeItem) => {
      await this.$store.dispatch("incomeItems/update", item)
    })

    await this.$store.dispatch("incomeWorksheets/update", this.editWorksheet)

    this.loadWorksheet(this.id)
    this.editMode = false
  }

  cancelEdit() {
    this.editWorksheet = cloneDeep(this.worksheet)
    this.editMode = false
  }

  async deleteItem(id: string) {
    await this.$store.dispatch("incomeItems/delete", id)

    this.loadWorksheet(this.id)
    this.editMode = false
  }

  async addItem() {
    const newItem = new IncomeItem()
    newItem.attributes.name = this.addItemName
    newItem.attributes.amount = this.addItemValue
    newItem.attributes.income_worksheet_id = this.worksheet.id

    await this.$store.dispatch("incomeItems/create", newItem)

    this.addItemName = ""
    this.addItemValue = ""

    this.loadWorksheet(this.id)
  }

  beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    this.loadWorksheet(String(to.params.id))
    next()
  }
}
