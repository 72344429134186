
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import { Ach } from "@/types/Ach"
import { Address } from "@/types/Address"
import { Beneficiary } from "@/types/Beneficiary"
import BeneficiaryFields from "@/components/BeneficiaryFields.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"

@Component({
  components: {
    BeneficiaryFields,
    CardTemplate,
    DateField,
    DialogTemplate,
    PhoneNumber
  }
})
export default class BeneficiaryList extends Vue {
  @Prop() private ach!: Ach
  @Prop() private address!: Address
  @Prop() private beneficiary!: Beneficiary
  @Prop() private policyId!: string
  @Prop() private type!: string
  @Prop({ default: false }) private viewOnly!: boolean

  private editAddress = this.address ? cloneDeep(this.address) : new Address()
  private editBeneficiary = this.beneficiary
    ? cloneDeep(this.beneficiary)
    : new Beneficiary()
  private editAch = this.ach ? cloneDeep(this.ach) : new Ach()
  private achValid = true

  @Watch("address", { deep: true })
  onAddressChange(value: Address) {
    this.editAddress = value ? cloneDeep(value) : new Address()
  }

  @Watch("beneficiary", { deep: true })
  onDepartmentChange(value: Beneficiary) {
    this.editBeneficiary = value ? cloneDeep(value) : new Beneficiary()
  }

  @Watch("ach", { deep: true })
  onAchChange(value: Ach) {
    this.editAch = value ? cloneDeep(value) : new Ach()
  }

  async save() {
    if (this.editBeneficiary.id) {
      if (this.editAddress.id) {
        await this.$store.dispatch("addresses/update", this.editAddress)
      } else if (this.addressPresent()) {
        const address = await this.$store.dispatch(
          "addresses/create",
          this.editAddress
        )
        this.editBeneficiary.attributes.address_id = address.data.data.id
      }

      if (this.editAch.id) {
        await this.$store.dispatch("aches/update", this.editAch)
      } else if (this.achPresent()) {
        this.editAch.attributes.owner_id = parseInt(this.editBeneficiary.id)
        this.editAch.attributes.owner_type = "Beneficiary"
        const ach = await this.$store.dispatch("aches/create", this.editAch)
        this.editBeneficiary.attributes.ach_id = ach.data.data.id
      }

      await this.$store.dispatch("beneficiaries/update", this.editBeneficiary)
    } else {
      if (this.type == "Contingent") {
        this.editBeneficiary.attributes.contingent = true
      }
      this.editBeneficiary.attributes.policy_id = parseInt(this.policyId)
      let beneficiary = { beneficiary: this.editBeneficiary.attributes }

      if (this.addressPresent()) {
        beneficiary = Object.assign(beneficiary, {
          address: this.editAddress.attributes
        })
      }

      if (this.achPresent()) {
        beneficiary = Object.assign(beneficiary, {
          ach: this.editAch.attributes
        })
      }

      await this.$store.dispatch("beneficiaries/build", beneficiary)
    }
    this.resetRecords()
  }

  resetRecords() {
    this.editAddress = this.address ? cloneDeep(this.address) : new Address()
    this.editBeneficiary = this.beneficiary
      ? cloneDeep(this.beneficiary)
      : new Beneficiary()
  }

  achPresent() {
    return this.editAch
      ? this.editAch.attributes.account_number ||
          this.editAch.attributes.routing_number ||
          this.editAch.attributes.account_type ||
          this.editAch.attributes.bank_name
      : false
  }

  addressPresent() {
    return this.editAddress
      ? this.editAddress.attributes.street ||
          this.editAddress.attributes.city ||
          this.editAddress.attributes.state ||
          this.editAddress.attributes.zip
      : false
  }

  achValidEvent(value: boolean) {
    this.achValid = value
  }

  archiveNow() {
    this.editBeneficiary.attributes.archived_on = new Date()
    this.$store.dispatch("beneficiaries/update", this.editBeneficiary)
  }

  makePayable() {
    this.editBeneficiary.attributes.archived_on = null
    this.$store.dispatch("beneficiaries/update", this.editBeneficiary)
  }

  get nullBeneficiary() {
    return this.editBeneficiary
      ? !this.editBeneficiary.attributes.first_name &&
          !this.editBeneficiary.attributes.last_name &&
          !this.editBeneficiary.attributes.phone &&
          !this.editBeneficiary.attributes.relationship &&
          !this.editBeneficiary.attributes.trust_name &&
          !this.addressPresent() &&
          !this.editBeneficiary.id
      : true
  }
  get incompleteAch() {
    const returnValue = this.achPresent() ? !this.achValid : false
    return returnValue
  }
}
