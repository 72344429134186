import { MutationTree, ActionTree } from "vuex"
import { Laserfiche } from "@/types/Laserfiche"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Laserfiche[] } = {
  all: []
}

const getters = {}
const actions: ActionTree<State<Laserfiche>, RootState> = {}
const mutations: MutationTree<State<Laserfiche>> = {
  setAll(state: State<Laserfiche>, laserfiches: Array<Laserfiche>) {
    laserfiches.forEach(laserfiche => {
      const foundItem = state.all.find(el => el.id == laserfiche.id)
      if (foundItem) {
        Object.assign(foundItem, new Laserfiche(laserfiche))
      } else {
        state.all.push(new Laserfiche(laserfiche))
      }
    })
  }
}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
