
import { Component, Vue, Watch } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DateHelper from "@/components/utils/DateHelper"
import PageTemplate from "@/components/PageTemplate.vue"
import { CreditCardNotification } from "@/types/CreditCardNotification"
import feeHelper from "@/services/feeHelper"

class BillablePolicy {
  policy_id: number
  amount: string
  last: string
  next: string
  member_first_name: string
  member_last_name: string
  policy_number: string
  billing_frequency: string
  balance: string
  payment_method_id: string
}

@Component({
  components: {
    CurrencyField,
    DateField,
    PageTemplate
  }
})
export default class CreditCardNotify extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selected: any[] = []
  private policies: BillablePolicy[] = []
  private loading = false
  private working = false
  private workingCompleteMessage = ""
  private notifications: CreditCardNotification[] = []
  private account = ""

  async fetchCCExpiring() {
    this.loading = true
    this.policies = []
    let page = 1

    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    const classification = accountParts.length > 1 ? accountParts[1] : null

    do {
      const data = await this.$store.dispatch(
        "policies/fetchPoliciesExpiringCreditCard",
        { page: page, productType: prodType, classification: classification }
      )
      this.policies.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)

    const cc_ids = this.policies.map(el => el.payment_method_id)

    if (cc_ids.length > 0) {
      await this.$store.dispatch("creditCards/loadBy", {
        id: cc_ids
      })
    }

    this.loading = false
  }

  async fetchNotifications() {
    const accountParts = this.account.split("_")
    const classification = accountParts.length > 1 ? accountParts[1] : 2

    const filter = {
      use_case: "expiration",
      classification: classification,
      page: 1,
      per_page: 5,
      order: "date",
      order_direction: "DESC"
    }

    const data = await this.$store.dispatch(
      "creditCardNotifications/loadBy",
      filter
    )

    this.notifications = data.data.data
  }

  @Watch("account")
  onAccountChange() {
    this.selected = []
    this.fetchCCExpiring()
    this.fetchNotifications()
  }

  get sortablePolicies() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortable: any[] = this.policies ? this.policies : []
    sortable.forEach(policy => {
      const creditCard = this.policyCC(policy.payment_method_id)

      policy.cc_number = creditCard ? creditCard.attributes.number : ""
      policy.cc_expiration = creditCard
        ? `${
            creditCard.attributes.expiration_month
          }/${creditCard.attributes.expiration_year.toString().slice(-2)}`
        : ""
    })

    return sortable
  }

  policyCC(creditCardId: number | null) {
    const d = creditCardId
      ? this.$store.getters["creditCards/getById"](creditCardId)
      : null

    return d
  }

  async makeNotification() {
    const policyIds = this.selected.map(el => el.policy_id)
    const accountParts = this.account.split("_")
    const classification =
      accountParts.length > 1 ? ["fire", "law"][Number(accountParts[1])] : "ltc"

    this.working = true

    const payload = {
      name: `CC Expirations ${classification.toUpperCase()} (${DateHelper.shortFormat(
        new Date()
      )})`,
      use_case: "expiration",
      classification: classification,
      policyIds: policyIds.join(","),
      date: new Date()
    }

    await this.$store.dispatch("creditCardNotifications/initiate", payload)

    await new Promise(r => setTimeout(r, 2000))
    this.working = false
    this.workingCompleteMessage =
      "Notification package has been created. Do not expect a change to this list of policies."
    this.fetchNotifications()
  }

  chipDate(date: Date) {
    return DateHelper.shortFormat(date)
  }

  calculateTotal(policy: BillablePolicy) {
    const a = Number(policy.amount)
    const m = this.frequencyAsMonths(policy.billing_frequency)
    const f = Number(this.creditCardFee(policy.billing_frequency))
    const b = Number(policy.balance)
    const val = m * a + f + b

    return val.toString()
  }

  creditCardFee(billingFrequency: string) {
    const accountParts = this.account.split("_")
    const prodType = accountParts[0]
    return feeHelper.fee(billingFrequency, prodType == "ltd")
  }

  frequencyAsMonths(billingFrequency: string) {
    return feeHelper.frequencyAsMonths(billingFrequency)
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }
  get headers() {
    return [
      {
        text: "last",
        value: "member_last_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "first",
        value: "member_first_name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "email",
        value: "member_email",
        class: "text-left caption"
      },
      {
        text: "polnum",
        value: "policy_number",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "expiration",
        value: "cc_expiration",
        class: "text-left caption",
        sortable: true
      },
      {
        text: "freq",
        value: "billing_frequency",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "next bill",
        value: "next",
        class: "text-right",
        sortable: true
      }
    ]
  }
}
