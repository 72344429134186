
import { Component, Vue } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { Letter as LetterType } from "@/types/Letter"

@Component({
  components: {
    CardTemplate,
    DialogTemplate,
    PageTemplate,
    PhoneNumber
  }
})
export default class Letter extends Vue {
  private editLetter = new LetterType()

  get id() {
    return this.$route.params.id
  }

  get letter() {
    return this.$store.getters["letters/getById"](this.id)
  }

  save() {
    this.$store.dispatch("letters/update", this.editLetter)
  }

  cancel() {
    this.editLetter = cloneDeep(this.letter)
  }

  async created() {
    await this.$store.dispatch("letters/load", {
      id: this.id
    })

    this.editLetter = cloneDeep(this.letter)
  }

  async beforeRouteUpdate(
    to: { params: { id: number } },
    from: { params: { id: number } },
    next: CallableFunction
  ) {
    await this.$store.dispatch("letters/load", {
      id: to.params.id
    })
    this.editLetter = cloneDeep(this.letter)

    next()
  }
}
