
import { Component, Prop, Vue } from "vue-property-decorator"
import DialogTemplate from "@/components/DialogTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import axios from "axios"

@Component({
  components: { DialogTemplate, CurrencyField, DateField }
})
export default class MakeWhole extends Vue {
  private newPremium: number | null = null
  private wholeDate: Date | null = null

  @Prop() private id!: number

  async makeWhole() {
    await axios.put(
      `/policies/${this.id}/make_whole`,
      { new_premium: this.newPremium, effective_date: this.wholeDate },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
