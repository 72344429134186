import axios from "axios"
import { MutationTree, ActionContext } from "vuex"
import Config from "@/types/Config"
import { RootState } from "@/types/RootState"

export const state: {
  activeStates: string[]
  baseURL: string
  wsURL: string
  token: string
  clientBranch: string
  clientEnvironment: string
  clientSha: string
  serverBranch: string
  serverEnvironment: string
  serverSha: string
  ltc_claim_states: string[]
  ltd_claim_states: string[]
  ltc_claim_transitions: {}
  ltd_claim_transitions: {}
  user_roles: {}
} = {
  activeStates: [],
  baseURL: process.env.VUE_APP_API_URL,
  wsURL: process.env.VUE_APP_WS_URL,
  token: localStorage.getItem("token"),
  clientBranch: process.env.VUE_APP_GIT_BRANCH,
  clientEnvironment: process.env.NODE_ENV,
  clientSha: process.env.VUE_APP_GIT_SHA,
  serverBranch: "n/a",
  serverEnvironment: "n/a",
  serverSha: "n/a",
  ltc_claim_states: [],
  ltd_claim_states: [],
  ltc_claim_transitions: {},
  ltd_claim_transitions: {},
  user_roles: {}
}

export const getters = {
  getActiveStates: (state: Config): string[] => state.activeStates,
  getAPIConfig: (state: Config): object => {
    return {
      baseURL: state.baseURL,
      cableURL: `${state.wsURL}/cable`,
      headers: state.token ? { Authorization: `Bearer ${state.token}` } : {}
    }
  },
  getBaseUrl: (state: Config): string => state.baseURL,
  getWsUrl: (state: Config): string => state.wsURL,
  getToken: (state: Config): string => state.token,
  getClientBranch: (state: Config): string => state.clientBranch,
  getClientEnvironment: (state: Config): string => state.clientEnvironment,
  getClientSha: (state: Config): string => state.clientSha,
  getServerBranch: (state: Config): string => state.serverBranch,
  getServerEnvironment: (state: Config): string => state.serverEnvironment,
  getServerSha: (state: Config): string => state.serverSha,
  getLTCClaimStates: (state: Config): string[] => state.ltc_claim_states,
  getLTDClaimStates: (state: Config): string[] => state.ltd_claim_states,
  getLTCClaimTransitions: (state: Config): object =>
    state.ltc_claim_transitions,
  getLTDClaimTransitions: (state: Config): object =>
    state.ltd_claim_transitions,
  getUserRoles: (state: Config): object => state.user_roles
}

export const actions = {
  async loadAbout({ commit, rootGetters }: ActionContext<Config, RootState>) {
    const about = await axios.get("/", rootGetters["config/getAPIConfig"])

    commit("setActiveStates", about.data.active_states)
    commit("setServerBranch", about.data.branch)
    commit("setServerEnvironment", about.data.environment)
    commit("setServerSha", about.data.sha)
    commit("setLTCClaimStates", about.data.ltc_claim_states)
    commit("setLTDClaimStates", about.data.ltd_claim_states)
    commit("setLTCClaimTransitions", about.data.ltc_claim_transitions)
    commit("setLTDClaimTransitions", about.data.ltd_claim_transitions)
    commit("setUserRoles", about.data.user_roles)
  }
}
export const mutations: MutationTree<Config> = {
  setActiveStates(state: Config, activeStates: string[]) {
    state.activeStates = activeStates
  },
  setToken(state: Config, token: string) {
    if (token) {
      localStorage.setItem("token", token)
    } else {
      localStorage.removeItem("token")
    }
    state.token = token
  },
  setServerBranch(state: Config, branch: string) {
    state.serverBranch = branch
  },
  setServerEnvironment(state: Config, environment: string) {
    state.serverEnvironment = environment
  },
  setServerSha(state: Config, sha: string) {
    state.serverSha = sha
  },
  setLTCClaimStates(state: Config, states: [string]) {
    state.ltc_claim_states = states
  },
  setLTDClaimStates(state: Config, states: [string]) {
    state.ltd_claim_states = states
  },
  setLTCClaimTransitions(state: Config, transitions: object) {
    state.ltc_claim_transitions = transitions
  },
  setLTDClaimTransitions(state: Config, transitions: object) {
    state.ltd_claim_transitions = transitions
  },
  setUserRoles(state: Config, roles: object) {
    state.user_roles = roles
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
