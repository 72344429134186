import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { User } from "@/types/User"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: User[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<User>, RootState> = {
  async validateToken(
    { dispatch, rootGetters }: ActionContext<State<User>, RootState>,
    token: string
  ) {
    return axios
      .get(
        "/users/validate_token",
        Object.assign(
          { params: { token: token } },
          rootGetters["config/getAPIConfig"]
        )
      )
      .then(
        (data: {
          data: { status: string } | { status: string; previous: boolean }
        }) => {
          return data
        }
      )
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async setPassword(
    { dispatch, rootGetters }: ActionContext<State<User>, RootState>,
    data: {
      password: string
      password2: string
      token: string
    }
  ) {
    return axios
      .put("/users/set_password", data, rootGetters["config/getAPIConfig"])
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async requestReset(
    { dispatch, rootGetters }: ActionContext<State<User>, RootState>,
    input: { email: string; type: string }
  ) {
    return axios
      .post("/users/request_reset", input, rootGetters["config/getAPIConfig"])
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  }
}
const mutations: MutationTree<State<User>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
