
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import { PolicyProduct } from "@/types/PolicyProduct"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CardTemplate,
    DateField
  }
})
export default class PolicyProducts extends Vue {
  @Prop() private policyProducts!: PolicyProduct[]

  product(id: number) {
    return this.$store.getters["products/getById"](id)
  }
}
