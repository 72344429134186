
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import DateField from "@/components/DateField.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DialogTemplate,
    DateField
  }
})
export default class PayInFull extends Vue {
  @Prop() private id!: number

  private date: Date = null

  async payInFull() {
    await axios.put(
      `/policies/${this.id}`,
      { paid_in_full_date: this.date },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
