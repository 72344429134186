import { render, staticRenderFns } from "./ClaimDetails.vue?vue&type=template&id=61436a03&scoped=true&"
import script from "./ClaimDetails.vue?vue&type=script&lang=ts&"
export * from "./ClaimDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ClaimDetails.vue?vue&type=style&index=0&id=61436a03&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61436a03",
  null
  
)

export default component.exports