
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate
  }
})
export default class AltRateAgeOut extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private data: null | any = null

  private async fetchData() {
    const data = await axios.get(
      `/policies/rate_convert_data_age_out`,
      this.$store.getters["config/getAPIConfig"]
    )
    this.data = data.data
  }

  created() {
    this.fetchData()
  }

  get headers() {
    return [
      { text: "Policy", value: "number" },
      { text: "First", value: "first_name" },
      { text: "Last", value: "last_name" },
      { text: "Age", value: "age" }
    ]
  }
}
