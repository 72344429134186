
import { Component, Vue } from "vue-property-decorator"
import { Department } from "@/types/Department"
import { Search } from "@/types/Search"
import DialogTemplate from "@/components/DialogTemplate.vue"

@Component({
  components: { DialogTemplate }
})
export default class NewDepartment extends Vue {
  private newDepartment: Department = new Department()
  private file: File = null
  private loading = false
  private confirm = false
  private dialog = false
  private searchResults: Search[] = []

  reset() {
    this.dialog = false
    this.newDepartment = new Department()
    this.loading = false
    this.confirm = false

    this.searchResults = []
  }

  async saveDepartment() {
    if (!this.file && !this.newDepartment.attributes.name) {
      return
    }

    this.loading = true
    if (this.file) {
      const result = await this.$store.dispatch("departments/upload", this.file)

      if (!result || result.status != 200) {
        this.loading = false
        return
      }
    } else {
      if (!this.confirm) {
        const data = await this.$store.dispatch("search/performSearch", {
          q: `d:${this.newDepartment.attributes.name}`,
          field: "name"
        })
        this.searchResults = data.data
        if (this.searchResults && this.searchResults.length) {
          this.confirm = true
          return
        }
      }

      const result = await this.$store.dispatch(
        "departments/create",
        this.newDepartment
      )
      this.loading = false
      if (!result || result.status != 200) {
        this.$store.dispatch(
          "alerts/addError",
          "Something went wrong. Please try again later"
        )
        return
      }

      this.$router.push({
        name: "Department",
        params: { id: result.data.data.id }
      })
    }
  }

  fieldChange(field: string) {
    if (this.file && this.newDepartment.attributes.name) {
      if (field == "file") {
        this.newDepartment.attributes.name = ""
      } else {
        this.file = null
      }
    }
  }

  get channels() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    return {
      DepartmentUploadChannel: {
        connected() {
          console.log("DepartmentUploadChannel connected")
        },
        rejected() {
          console.log("DepartmentUploadChannel rejected")
        },
        received(data: { message: string; id: string; user_id: string }) {
          if (that.userId == data.user_id) {
            that.loading = false
            if (data.message) {
              that.$store.dispatch("alerts/addError", data.message)
            } else if (data.id) {
              that.$store.dispatch(
                "alerts/addSuccess",
                "Data successfully uploaded"
              )
              that.$router.push({
                name: "Department",
                params: { id: data.id }
              })
            }
          }
        }
      }
    }
  }

  mounted() {
    this.$cable.subscribe({
      channel: "DepartmentUploadChannel",
      room: "public"
    })
  }

  get userId() {
    const user = this.$store.getters["authentication/getUser"]
    return user ? user.id : null
  }
}
