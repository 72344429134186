import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class ClaimForm extends Model<ClaimForm> {
  constructor(response?: ClaimForm) {
    super(response as ClaimForm)
    if (response) {
      this.attributes.received_on = DateHelper.stringToDate(
        this.attributes.received_on
      )
      this.attributes.sent_on = DateHelper.stringToDate(this.attributes.sent_on)
    }
  }
  id: string
  attributes!: {
    name: string
    claim_id: number
    sent_on: Date
    received_on: Date
    delivery_method: string
    received_method: string
    updated_at: string
  }
  static get modelName() {
    return "ClaimForm"
  }
}
