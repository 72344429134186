
import axios from "axios"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"

interface EventData {
  day: number
  count: number
}

@Component({
  components: {
    CardTemplate
  }
})
export default class UserEvents extends Vue {
  @Prop() private userId!: string

  private events: EventData[] = []

  dayWidth = 60
  daySpace = 4
  weekHeight = 60
  weekSpace = 4

  @Watch("userId")
  onIdChange(value: string) {
    if (value) {
      this.fetchEvents(value)
    }
  }

  mounted() {
    if (this.userId) {
      this.fetchEvents(this.userId)
    }
  }

  get days() {
    return Array.from({ length: 31 }, (_, i) => i + 1)
  }

  async fetchEvents(id: string) {
    const data = await axios.get(
      `/users/${id}/heatmap`,
      this.$store.getters["config/getAPIConfig"]
    )
    this.events = data.data
  }
  arrayStartingAtZero(length: number) {
    return Array.from({ length }, (_, i) => i)
  }

  maxCount() {
    return Math.max(...this.events.map(e => e.count))
  }

  dayColor(value: number) {
    const event = this.events.find(e => e.day == value)
    if (event != undefined) {
      const color = 255 - Math.round((event.count / this.maxCount()) * 255)
      return `rgb(255, ${color.toString()},  ${color.toString()})`
    } else {
      return "#ffffff"
    }
  }

  dayBorderColor(value: number) {
    const d = this.dayColor(value)
    return d == "#ffffff" ? "#eaeaea" : d
  }
}
