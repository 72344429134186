
import { Component, Vue, Prop } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { DepartmentProduct } from "@/types/DepartmentProduct"
import { CreditCard } from "@/types/CreditCard"
import { Ach } from "@/types/Ach"
import { Product } from "@/types/Product"
import moment from "moment"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    DialogTemplate
  }
})
export default class PolicyLeavePayroll extends Vue {
  @Prop() private policyId!: number
  @Prop() private memberId!: number
  @Prop() private creditCards!: CreditCard[]
  @Prop() private aches!: Ach[]
  private effectiveDate = new Date()
  private billingFrequency = "Monthly"
  private billingFrequencyOptions = [
    "Monthly",
    "Quarterly",
    "Semi-Annually",
    "Annually"
  ]
  private newPremium = 0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private selectedPaymentMethod: any = "Check"

  initiateLeave() {
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  created() {
    this.$store.dispatch("products/loadBy", {
      member_paid: true
    })
  }

  async save() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = {
      policyId: this.policy.id,
      newProductId: this.changeToProduct.id,
      effectiveDate: this.effectiveDate,
      billingFrequency: this.billingFrequency
    }

    if (this.convertRequired) {
      params.newPremium = this.newPremium
    }

    if (this.selectedPaymentMethod != "Check") {
      if (this.selectedPaymentMethod.type == "ach") {
        params.paymentMethodType = "Ach"
        params.paymentMethodId = this.selectedPaymentMethod.id
      }
      if (this.selectedPaymentMethod.type == "credit_card") {
        params.paymentMethodType = "CreditCard"
        params.paymentMethodId = this.selectedPaymentMethod.id
      }
    }

    await this.$store.dispatch("policies/removeFromPolicyGroup", params)

    this.$emit("save")
  }

  get policy() {
    return this.$store.getters["policies/getById"](this.policyId)
  }

  get convertRequired() {
    return (
      this.policy &&
      this.policy.attributes.is_mod_rate &&
      this.ageToday != null &&
      this.ageToday >= 55 &&
      !this.policy.attributes.increase_modified_premium
    )
  }

  get policyGroup() {
    if (this.policy && this.policy.attributes.policy_group_id) {
      return this.$store.getters["policyGroups/getById"](
        this.policy.attributes.policy_group_id
      )
    } else {
      return null
    }
  }

  get department() {
    if (this.policyGroup && this.policyGroup.attributes.department_id) {
      return this.$store.getters["departments/getById"](
        this.policyGroup.attributes.department_id
      )
    } else {
      return null
    }
  }

  get policyProduct() {
    if (this.policy) {
      return this.$store.getters["products/getById"](
        this.policy.attributes.product_id
      )
    } else {
      return null
    }
  }

  get member() {
    return this.memberId
      ? this.$store.getters["members/getById"](this.memberId)
      : null
  }

  get ageToday() {
    if (!this.member.attributes.dob) {
      return null
    }
    return moment(new Date()).diff(
      moment(new Date(this.member.attributes.dob)),
      "years"
    )
  }

  get changeToProduct() {
    if (this.policyProduct) {
      const payrollProductName = this.policyProduct.attributes.name
      const newProductName = payrollProductName.replace(/- payroll/g, "").trim()
      const newProductOptions = this.$store.getters["products/getBy"]({
        name: newProductName
      })
      if (newProductOptions.length > 0) {
        return newProductOptions[0]
      } else {
        return null
      }
    } else {
      return null
    }
  }

  get availableProducts() {
    if (this.department) {
      const dps = this.$store.getters["departmentProducts/getBy"]({
        department_id: this.department.id
      })

      const productIds = dps.map((el: DepartmentProduct) =>
        el.attributes.product_id.toString()
      )

      if (productIds && productIds.length > 0) {
        const ps = this.$store.getters["products/getByIds"](productIds).filter(
          (el: Product) => {
            return (
              el.attributes.member_paid &&
              el.attributes.product_type ==
                this.policyGroup.attributes.product_type
            )
          }
        )
        return ps.map((el: Product) => {
          return { text: el.attributes.name, value: el.id }
        })
      } else {
        return []
      }
    } else {
      return []
    }
  }
}
