import { MutationTree, ActionTree } from "vuex"
import { Product } from "@/types/Product"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: Product[] } = {
  all: []
}

const getters = {
  getByIds: (state: State<Product>) => (ids: string[]) => {
    return state.all.filter((el: Product) => ids.includes(el.id))
  }
}

const actions: ActionTree<State<Product>, RootState> = {}
const mutations: MutationTree<State<Product>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
