
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { cloneDeep } from "lodash"
import DialogTemplate from "@/components/DialogTemplate.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import { BoardMeetingAttendee } from "@/types/BoardMeetingAttendee"
import { Department } from "@/types/Department"

@Component({
  components: {
    DialogTemplate,
    PhoneNumber
  }
})
export default class BoardMeetingAttendeeDialog extends Vue {
  @Prop() private attendee!: BoardMeetingAttendee
  @Prop() private btnLabel!: string
  @Prop() private title!: string
  @Prop() private boardMeetingId!: number

  private editAttendee = new BoardMeetingAttendee()
  private departments: Department[] = []

  private name = ""
  private phoneW = ""
  private phoneH = ""
  private phoneM = ""
  private email1 = ""
  private email2 = ""
  private street = ""
  private city = ""
  private state = ""
  private zip = ""
  private apartment = ""
  private comments = ""
  private roleValue = 0

  get hideOpener() {
    return !this.btnLabel
  }

  get boardMeeting() {
    return this.$store.getters["boardMeetings/getById"](this.boardMeetingId)
  }

  set role(val: number[]) {
    if (val.length == 0) {
      this.editAttendee.attributes.role = null
    } else {
      const sum = val.reduce(
        (accumulator: number, currentValue: number) =>
          accumulator + currentValue,
        0
      )
      this.roleValue = sum
    }
  }
  get role() {
    const res = []
    let selectedRoles = this.roleValue
    let roles = 16
    while (roles >= 1) {
      if (selectedRoles / roles >= 1) {
        res.push(roles)
        selectedRoles = selectedRoles % roles
      }
      roles = roles / 2
    }
    return res
  }

  get items() {
    const departments = this.$store.getters["departments/getAll"]

    return departments
      .filter((department: Department) => {
        return this.boardMeeting
          ? department.attributes.classification ==
              this.boardMeeting.attributes.classification
          : true
      })
      .map((department: Department) => {
        return {
          text: department.attributes.name,
          value: parseInt(department.id)
        }
      })
      .sort(
        (
          a: { text: string; value: number },
          b: { text: string; value: number }
        ) => {
          if (a.text < b.text) {
            return -1
          }
          if (a.text > b.text) {
            return 1
          }
          return 0
        }
      )
  }

  created() {
    this.$store.dispatch("departments/loadAll")
    if (this.attendee) {
      this.editAttendee = cloneDeep(this.attendee)
    }
  }

  @Watch("boardMeetingId")
  onboardMeetingIdChanged(id: number) {
    if (id) {
      if (this.attendee) {
        this.editAttendee = cloneDeep(this.attendee)
      }
    }
  }

  open() {
    this.editAttendee = cloneDeep(this.attendee)

    this.name = this.attendee.attributes.name
    this.phoneW = this.attendee.attributes.phone_work
    this.phoneH = this.attendee.attributes.phone_home
    this.phoneM = this.attendee.attributes.phone_mobile
    this.email1 = this.attendee.attributes.email_1
    this.email2 = this.attendee.attributes.email_2
    this.street = this.attendee.attributes.street
    this.city = this.attendee.attributes.city
    this.state = this.attendee.attributes.state
    this.zip = this.attendee.attributes.zip
    this.apartment = this.attendee.attributes.apartment
    this.roleValue = this.attendee.attributes.role
    this.comments = this.attendee.attributes.comments
    ;(this.$refs.dialog as DialogTemplate).open(true)
  }

  cancel() {
    this.editAttendee = cloneDeep(this.attendee)
  }

  fillDirectorInfo() {
    if (this.editAttendee.attributes.department_id) {
      const department = this.$store.getters["departments/getById"](
        this.editAttendee.attributes.department_id.toString()
      )
      if (department) {
        this.name = department.attributes.director_name
        this.phoneW = department.attributes.director_phone_work.replaceAll(
          "-",
          ""
        )
        this.phoneH = department.attributes.director_phone_home.replaceAll(
          "-",
          ""
        )
        this.phoneM = department.attributes.director_phone_mobile.replaceAll(
          "-",
          ""
        )
        this.email1 = department.attributes.director_email_1
        this.email2 = department.attributes.director_email_2

        const voting = department.attributes.director_voting

        if (voting) {
          this.roleValue = 1
          console.log("voting", voting, this.role)
        }

        if (department.attributes.address_id) {
          this.$store
            .dispatch("addresses/loadBy", {
              id: department.attributes.address_id
            })
            .then(data => {
              console.log("address", data.data.data)
              if (data) {
                this.street = data.data.data[0].attributes.street
                this.city = data.data.data[0].attributes.city
                this.state = data.data.data[0].attributes.state
                this.zip = data.data.data[0].attributes.zip
                this.apartment = data.data.data[0].attributes.apartment
              }
            })
        }
      }
    }
  }

  async save() {
    this.editAttendee.attributes.name = this.name
    this.editAttendee.attributes.phone_work = this.phoneW
    this.editAttendee.attributes.phone_home = this.phoneH
    this.editAttendee.attributes.phone_mobile = this.phoneM
    this.editAttendee.attributes.email_1 = this.email1
    this.editAttendee.attributes.email_2 = this.email2
    this.editAttendee.attributes.street = this.street
    this.editAttendee.attributes.city = this.city
    this.editAttendee.attributes.state = this.state
    this.editAttendee.attributes.zip = this.zip
    this.editAttendee.attributes.apartment = this.apartment
    this.editAttendee.attributes.role = this.roleValue
    this.editAttendee.attributes.comments = this.comments

    if (this.attendee.id) {
      await this.$store.dispatch(
        "boardMeetingAttendees/update",
        this.editAttendee
      )
      this.$emit("save")
      this.editAttendee = cloneDeep(this.attendee)
    } else {
      await this.$store.dispatch(
        "boardMeetingAttendees/create",
        this.editAttendee
      )
      this.$emit("save")
      this.editAttendee = cloneDeep(this.attendee)
    }

    this.name = ""
    this.phoneW = ""
    this.phoneH = ""
    this.phoneM = ""
    this.email1 = ""
    this.email2 = ""
    this.street = ""
    this.city = ""
    this.state = ""
    this.zip = ""
    this.apartment = ""
    this.comments = ""
    this.roleValue = 0
  }
}
