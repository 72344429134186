
import { Component, Vue, Watch } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { AgePremium } from "@/types/AgePremium"
import moment from "moment"
import { Parser } from "json2csv"
import axios from "axios"

@Component({
  components: {
    DateField,
    PageTemplate
  }
})
export default class AgePremiumTable extends Vue {
  private dates: Date[] = []
  private date: Date = null

  @Watch("date")
  onValueChange() {
    this.loadAgePremiums()
  }

  get headers() {
    return [
      { text: "Age", value: "attributes.age" },
      { text: "25 Year", value: "attributes.premium_25_year" },
      { text: "30 Year", value: "attributes.premium_30_year" },
      { text: "35 Year", value: "attributes.premium_35_year" },
      { text: "40 Year", value: "attributes.premium_40_year" },
      { text: "45 Year", value: "attributes.premium_45_year" },
      { text: "Lifetime", value: "attributes.premium_lifetime" }
    ]
  }

  get agePremiums() {
    return this.$store.getters["agePremiums/getBy"]({
      start_date: this.date,
      day_rate: this.dayRate
    })
  }

  get dateItems() {
    return this.dates.map(el => {
      return { value: el, text: this.localizeDate(el) }
    })
  }

  get dayRate() {
    return this.$route.params.day_rate.replace(/- payroll/g, "").trim()
  }

  localizeDate(date: Date) {
    return moment(date, "YYYY-MM-DD")
      .toDate()
      .toLocaleDateString("en-US")
  }

  async created() {
    const response = await axios.get(
      "/age_premiums/unique_sets",
      Object.assign(
        {
          params: {
            day_rate: this.dayRate
          }
        },
        this.$store.getters["config/getAPIConfig"]
      )
    )

    this.dates = response.data.map(
      (el: { date: Date; day_rate: string }) => el.date
    )
    this.date = this.dates[0]
  }

  loadAgePremiums() {
    this.$store.dispatch("agePremiums/loadBy", {
      start_date: this.date,
      day_rate: this.dayRate
    })
  }

  createCSV() {
    const fields = [
      "start_date",
      "day_rate",
      "age",
      "premium_25_year",
      "premium_30_year",
      "premium_35_year",
      "premium_40_year",
      "premium_45_year"
    ]
    const data = this.agePremiums.map((agePremium: AgePremium) => {
      return {
        start_date: agePremium.attributes.start_date,
        day_rate: agePremium.attributes.day_rate,
        age: agePremium.attributes.age,
        premium_25_year: agePremium.attributes.premium_25_year,
        premium_30_year: agePremium.attributes.premium_30_year,
        premium_35_year: agePremium.attributes.premium_35_year,
        premium_40_year: agePremium.attributes.premium_40_year,
        premium_45_year: agePremium.attributes.premium_45_year
      }
    })
    const parser = new Parser({ fields })
    const csv = parser.parse(data)
    const csvBlob = new Blob([csv], { type: "text/csv" })

    const link = document.createElement("a")
    link.href = URL.createObjectURL(csvBlob)
    link.download = `ltc_premiums_${this.date}_${this.dayRate}`
    link.click()
    URL.revokeObjectURL(link.href)
  }
}
