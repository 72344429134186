import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Membership extends Model<Membership> {
  constructor(response?: Membership) {
    super(response as Membership)
    if (response) {
      this.attributes.start_date = DateHelper.stringToDate(
        this.attributes.start_date
      )
      this.attributes.end_date = DateHelper.stringToDate(
        this.attributes.end_date
      )
      this.attributes.hire_date = DateHelper.stringToDate(
        this.attributes.hire_date
      )
    }
  }
  id: string
  attributes!: {
    department_id: number
    member_id: number
    start_date: Date
    end_date: Date
    reversed: boolean
    hire_date: Date
  }
  static get modelName() {
    return "Membership"
  }
}
