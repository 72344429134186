import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Department } from "@/types/Department"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Department[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Department>, RootState> = {
  async upload(
    { dispatch, rootGetters }: ActionContext<State<Department>, RootState>,
    file: File
  ) {
    const formData = new FormData()
    formData.append("file", file)
    return axios
      .post("/departments/upload", formData, rootGetters["config/getAPIConfig"])
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async fetchMembers(
    { dispatch, rootGetters }: ActionContext<State<Department>, RootState>,
    params: { departmentId: number; productType: string; page: number }
  ) {
    try {
      const data = await axios.get(
        `departments/${params.departmentId}/individual_members?page=${params.page}&product_type=${params.productType}`,
        rootGetters["config/getAPIConfig"]
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async merge(
    { dispatch, rootGetters }: ActionContext<State<Department>, RootState>,
    {
      old_department_id,
      new_department_id,
      merge_date,
      product_map
    }: {
      old_department_id: string
      new_department_id: string
      merge_date: Date
      product_map: Map<string, string>
    }
  ) {
    return axios
      .post(
        "/departments/merge",
        {
          old_department_id: old_department_id,
          new_department_id: new_department_id,
          merge_date: merge_date,
          product_map: JSON.stringify(Array.from(product_map.entries()))
        },
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: string }) => {
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async fetchClaimsReport(
    { dispatch, rootGetters }: ActionContext<State<Department>, RootState>,
    params: { id: number; productType: string; eip: boolean }
  ) {
    try {
      const data = await axios.get(
        `departments/${params.id}/claims_report?product_type=${params.productType}&eip=${params.eip}`,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<Department>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
