
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
@Component
export default class AutoLogoutWarning extends Vue {
  @Prop(Boolean) readonly show: boolean

  secondsRemaining = -1
  interval: any // eslint-disable-line @typescript-eslint/no-explicit-any

  get showDialog() {
    if (this.show) {
      this.secondsRemaining = 60
      this.interval = setInterval(() => {
        this.secondsRemaining -= 1
        if (this.secondsRemaining <= 0) {
          this.logout()
        }
      }, 1000)
    } else {
      clearInterval(this.interval)
    }
    return this.show
  }

  set showDialog(show) {
    this.$emit("logout-show", show)
  }

  logout() {
    clearInterval(this.interval)
    this.$emit("logout-show", false)
    this.$emit("logout")
  }
}
