import { Address as AddressType } from "@/types/Address"
import { Department } from "@/types/Department"
import { Vue, Component, Prop } from "vue-property-decorator"

@Component
export default class DepartmentSectionMixin extends Vue {
  @Prop() private department!: Department
  @Prop() private editDepartment!: Department
  @Prop() private address!: AddressType
  @Prop() private editAddress!: AddressType
  @Prop() private parentAddressAttributeName!: string

  edit: false

  save() {
    this.$emit("save", {
      address: this.editAddress,
      parentAddressAttributeName: this.parentAddressAttributeName
    })
    this.edit = false
  }

  cancel() {
    this.edit = false
    this.$emit("cancel")
  }
}
