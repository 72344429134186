
import CardTemplate from "@/components/CardTemplate.vue"
import { Claim } from "@/types/Claim"
import { User } from "@/types/User"
import DialogTemplate from "@/components/DialogTemplate.vue"
import UserInitials from "@/components/UserInitials.vue"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {
    CardTemplate,
    DialogTemplate,
    UserInitials
  }
})
export default class ClaimDetails extends Vue {
  @Prop() private claim!: Claim

  private selectedSupervisorId = this.claim.attributes.admin_id

  mounted() {
    this.$store.dispatch("users/loadAll")
  }

  get adminName() {
    const a = this.admin

    if (a) {
      return a.name
    } else {
      return "No Claim Administrator Set"
    }
  }

  get admin() {
    if (this.claim.attributes.admin_id) {
      return this.$store.getters["users/getById"](
        this.claim.attributes.admin_id
      )
    } else {
      return null
    }
  }

  saveClaim() {
    this.claim.attributes.admin_id = this.selectedSupervisorId
    this.$store.dispatch("claims/update", {
      data: this.claim
    })
  }

  cancelClaimAdmin() {
    this.selectedSupervisorId = this.claim.attributes.admin_id
  }

  get claimSupervisors() {
    const users = this.$store.getters["users/getAll"]
    // from kilo-api
    // CLAIM_MANAGER      = 32
    // CLAIM_SUPERVISOR   = 64
    return users
      ? users
          .filter((el: User) => {
            return (el.attributes.roles & 32) + (el.attributes.roles & 64) > 0
          })
          .map((el: User) => {
            return { value: Number(el.id), text: el.name }
          })
      : []
  }
}
