
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Policy } from "@/types/Policy"
import DialogTemplate from "@/components/DialogTemplate.vue"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    DateField,
    DialogTemplate
  }
})
export default class EndPolicy extends Vue {
  @Prop() private policyId!: string
  private endDate: Date = new Date()
  private cancelReason = ""
  private isLastDayOfMonth = false

  open() {
    const form = this.$refs["dialog"] as DialogTemplate
    if (this.policy && this.policy.attributes.next_bill_on) {
      this.endDate = new Date(
        this.policy.attributes.next_bill_on.getFullYear(),
        this.policy.attributes.next_bill_on.getMonth(),
        this.policy.attributes.next_bill_on.getDate() - 1
      )
    } else {
      const today = new Date()
      this.endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
    }

    form.open(true)
  }

  @Watch("endDate")
  onEndDateChange(value: string) {
    this.updateLastMonth(value.toString())
  }

  updateLastMonth(value: string) {
    if (value == "") {
      this.isLastDayOfMonth = false
    } else {
      const d1 = new Date(value)
      const endDatePlusOne = new Date(
        d1.getUTCFullYear(),
        d1.getUTCMonth(),
        d1.getUTCDate() + 1
      )
      this.isLastDayOfMonth = d1.getUTCMonth() != endDatePlusOne.getUTCMonth()
    }
  }

  get policy() {
    return this.$store.getters["policies/getById"](this.policyId)
  }

  get product() {
    return this.policy
      ? this.$store.getters["products/getById"](
          this.policy.attributes.product_id
        )
      : null
  }

  get cancelReasons() {
    return [
      "CLOP - POLICY CANCELLED BY PLAN/CASI",
      "CLOC - POLICY CANCELLED BY MEMBER",
      "CLOD - POLICY CANCELLED DUE TO DEATH",
      "CLOW - POLICY CANCELLED 10 YR WITHDRAWL"
    ]
  }

  save() {
    const policy = new Policy()
    policy.id = this.policyId
    policy.attributes.end_date = this.endDate
    if (this.cancelReason && this.cancelReason.length > 0) {
      policy.attributes.status = this.cancelReason
    } else {
      policy.attributes.status = "inactive"
    }
    this.$store.dispatch("policies/update", policy)
  }
}
