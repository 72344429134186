
import { Component, Vue } from "vue-property-decorator"
import DateField from "@/components/DateField.vue"
import ConfirmationTemplate from "@/components/ConfirmationTemplate.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Notification } from "@/types/Notification"

@Component({
  components: { DateField, ConfirmationTemplate, PageTemplate }
})
export default class Notifications extends Vue {
  private showDialogRead = false
  private showDialogDelete = false

  get notifications() {
    function compare(a: Notification, b: Notification) {
      return a.attributes.created_at > b.attributes.created_at ? -1 : 1
    }

    return [...this.$store.getters["notifications/getAll"]].sort(compare)
  }

  notificationRowClasses(notification: Notification) {
    return notification.attributes.read
      ? "clickable-row read-row"
      : "clickable-row unread-row"
  }

  markAsRead(notification: Notification) {
    if (!notification.attributes.read) {
      this.$store.dispatch("notifications/markAsRead", notification)
    }
  }

  confirmReject() {
    this.showDialogRead = false
    this.showDialogDelete = false
  }

  confirmMarkAsRead() {
    this.notifications.forEach(el => {
      this.$store.dispatch("notifications/markAsRead", el)
    })
    this.showDialogRead = false
  }

  confirmDelete() {
    console.log("do the deleting")

    this.notifications.forEach(el => {
      this.$store.dispatch("notifications/delete", el.id)
    })

    this.showDialogDelete = false
  }
}
