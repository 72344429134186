
import { Component, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import PageTemplate from "@/components/PageTemplate.vue"
import { Policy as PolicyType } from "@/types/Policy"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField,
    PageTemplate
  }
})
export default class OrphanedPolicies extends Vue {
  private policies: PolicyType[] = []
  private loading = false
  private account = ""

  async fetchOrphaned() {
    this.policies = []

    if (this.account == "") {
      return
    }

    this.loading = true

    const actionString = "policies/fetchOrphaned"

    const data = await this.$store.dispatch(actionString, {
      productType: this.account
    })
    console.log(data.data.data)
    this.policies = data.data.data

    this.loading = false
  }

  @Watch("account")
  onAccountChange() {
    this.fetchOrphaned()
  }

  @Watch("cancelled")
  onCancelledChange() {
    this.fetchOrphaned()
  }

  goToPolicy(item: PolicyType) {
    this.$router.push({
      name: "Policy",
      params: { id: item.id.toString() }
    })
  }

  get headers() {
    return [
      {
        text: "",
        value: "attributes.billing_exception",
        class: "text-left caption",
        sortable: true,
        width: "50px"
      },
      {
        text: "polnum",
        value: "attributes.number",
        class: "text-left caption",
        sortable: true,
        width: "275px"
      },
      {
        text: "status",
        value: "attributes.status",
        class: "text-left caption",
        sortable: true,
        width: "275px"
      },
      {
        text: "last billed",
        value: "attributes.last_billed_on",
        class: "text-left caption",
        sortable: true,
        width: "175px"
      },
      {
        text: "next billed",
        value: "attributes.next_bill_on",
        class: "text-left caption",
        sortable: true,
        width: "175px"
      }
    ]
  }
}
