import Model from "./Model"

export interface TagValue {
  id: string
  name: string
  taggings_count: number
}

export class AdvancedSearch extends Model<AdvancedSearch> {
  constructor(advancedSearch?: AdvancedSearch) {
    super(advancedSearch as AdvancedSearch)
    this.attributes.created_at = new Date(this.attributes.created_at)
    this.attributes.updated_at = new Date(this.attributes.updated_at)
  }
  id: string
  attributes!: {
    name: string
    query: string
    tags: TagValue[]
    created_at: Date
    updated_at: Date
  }
  static get modelName() {
    return "AdvancedSearch"
  }
}
