import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Nacha } from "@/types/Nacha"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Nacha[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Nacha>, RootState> = {
  async makeFile(
    { commit, dispatch, rootGetters }: ActionContext<State<Nacha>, RootState>,
    { id }
  ) {
    try {
      const data = await axios.put(
        `/nachas/${id}/make_file`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async payAllPayments(
    { commit, dispatch, rootGetters }: ActionContext<State<Nacha>, RootState>,
    { id }
  ) {
    try {
      console.log("pay all for nacha ", id)
      const data = await axios.put(
        `/nachas/${id}/pay_all`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async fetchPayments(
    { dispatch, rootGetters }: ActionContext<State<Nacha>, RootState>,
    params: {
      page: number | string
      id: number | string
    }
  ) {
    try {
      const url = `nachas/${params.id}/payments?page=${params.page}`

      const data = await axios.get(url, rootGetters["config/getAPIConfig"])

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<Nacha>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
