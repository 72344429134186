import Model from "./Model"

export class Search extends Model<Search> {
  id = 0
  title = ""
  subtitle = ""
  target_id = ""
  target_model = ""
  department_id: number = null
}
