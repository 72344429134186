import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Enroller extends Model<Enroller> {
  constructor(enroller?: Enroller) {
    super(enroller as Enroller)
    if (enroller) {
      this.attributes.exp = DateHelper.stringToDate(this.attributes.exp)
    }
  }
  id: string
  attributes!: {
    first_name: string
    last_name: string
    company: string
    id_num: string
    ssn: string
    tax_id: string
    street: string
    city: string
    state: string
    zip: string
    work_phone: string
    mobile_phone: string
    fax: string
    home_phone: string
    email: string
    lic_num: string
    exp: Date | string
    twenty_year: boolean
  }
  static get modelName() {
    return "Enroller"
  }
}
