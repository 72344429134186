
import { Component, Vue, Watch } from "vue-property-decorator"
import BoardMeetingDialog from "@/components/BoardMeetingDialog.vue"
import DateField from "@/components/DateField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import { BoardMeeting } from "@/types/BoardMeeting"
import DateHelper from "./utils/DateHelper"

@Component({
  components: {
    BoardMeetingDialog,
    DateField,
    PagedDataTable
  }
})
export default class BoardMeetings extends Vue {
  private boardMeetings: BoardMeetings[] = []

  private loading = false
  private total = 0
  private perPage = 5
  private page = 1

  private options = {
    sortBy: "attributes.date",
    sortDesc: true,
    mustSort: true
  }

  @Watch("options", { deep: true })
  optionsChanged() {
    this.loadMeetings(1, this.perPage)
  }

  get headers() {
    return [
      {
        text: "DATE",
        value: "attributes.date",
        sort: DateHelper.sortByDate,
        class: "text-left caption"
      },
      {
        text: "BOARD",
        value: "attributes.classification",
        class: "text-left caption"
      },
      {
        text: "LOCATION",
        value: "attributes.location",
        class: "text-left caption"
      },
      { text: "", value: "menu", sortable: false, class: "text-left caption" }
    ]
  }

  get newBoardMeeting() {
    return new BoardMeeting()
  }

  created() {
    this.loadMeetings(this.page, this.perPage)
  }

  meetingType(type: string) {
    switch (type) {
      case "fire":
        return "CAPF"
      case "law":
        return "CLEA"
    }
  }

  clickRow(value: BoardMeeting) {
    this.$router.push({
      path: `/board-meetings/${value.id}`
    })
  }

  loadPage(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadMeetings(details.page, details.itemsPerPage)
    }
  }

  async loadMeetings(page: number, perPage: number) {
    this.loading = true
    const data = await this.$store.dispatch("boardMeetings/loadBy", {
      page: page,
      per_page: perPage,
      order: this.options.sortBy.replace("attributes.", ""),
      order_direction: this.options.sortDesc ? "DESC" : "ASC"
    })

    this.page = page
    this.perPage = perPage
    this.boardMeetings = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  editMeeting(meeting: BoardMeeting) {
    const dialog = this.$refs[
      `edit-meeting-${meeting.id}`
    ] as BoardMeetingDialog
    dialog.open()
  }
}
