
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Component, Prop, Vue, Watch } from "vue-property-decorator"

@Component({
  components: {
    CardTemplate,
    DateField,
    DialogTemplate
  }
})
export default class ClaimCheckDates extends Vue {
  @Prop() private first_check_non_recoverable!: string
  @Prop() private last_check_non_recoverable!: string
  @Prop() private first_check_recoverable!: string
  @Prop() private last_check_recoverable!: string
  @Prop() private first_check_death!: string
  @Prop() private last_check_death!: string

  private editFirstCheckNonRecoverable = ""
  private editLastCheckNonRecoverable = ""
  private editFirstCheckRecoverable = ""
  private editLastCheckRecoverable = ""
  private editFirstCheckDeath = ""
  private editLastCheckDeath = ""

  created() {
    this.editFirstCheckNonRecoverable = this.first_check_non_recoverable
    this.editLastCheckNonRecoverable = this.last_check_non_recoverable
    this.editFirstCheckRecoverable = this.first_check_recoverable
    this.editLastCheckRecoverable = this.last_check_recoverable
    this.editFirstCheckDeath = this.first_check_death
    this.editLastCheckDeath = this.last_check_death
  }

  @Watch("first_check_non_recoverable")
  onFirstCheckNonRecoverableChanged(value: string) {
    this.editFirstCheckNonRecoverable = value
  }

  @Watch("last_check_non_recoverable")
  onLastCheckNonRecoverableChanged(value: string) {
    this.editLastCheckNonRecoverable = value
  }

  @Watch("first_check_recoverable")
  onFirstCheckRecoverableChanged(value: string) {
    this.editFirstCheckRecoverable = value
  }

  @Watch("last_check_recoverable")
  onLastCheckRecoverableChanged(value: string) {
    this.editLastCheckRecoverable = value
  }

  @Watch("first_check_death")
  onFirstCheckDeathChanged(value: string) {
    this.editFirstCheckDeath = value
  }

  @Watch("last_check_death")
  onLastCheckDeathChanged(value: string) {
    this.editLastCheckDeath = value
  }

  saveDates() {
    this.$emit("save-dates", {
      firstCheckNonRecoverable: this.editFirstCheckNonRecoverable,
      lastCheckNonRecoverable: this.editLastCheckNonRecoverable,
      firstCheckRecoverable: this.editFirstCheckRecoverable,
      lastCheckRecoverable: this.editLastCheckRecoverable,
      firstCheckDeath: this.editFirstCheckDeath,
      lastCheckDeath: this.editLastCheckDeath
    })
  }
}
