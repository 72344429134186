
import Vue from "vue"
import Component from "vue-class-component"
import { mapGetters } from "vuex"
import { Alert } from "@/types/Alert"

// Define the component in class-style
@Component({
  computed: mapGetters("authentication", ["getLoggedIn"])
})
export default class Counter extends Vue {
  email = ""
  password = ""
  getLoggedIn!: boolean
  emailError = ""
  passwordError = ""
  loginError = ""

  async login() {
    this.emailError = ""
    this.passwordError = ""
    if (!this.email) {
      this.emailError = "Please enter an email"
    } else if (!this.validEmail(this.email)) {
      this.emailError = "Please enter a valid email"
    }
    if (!this.password) {
      this.passwordError = "Please enter a password"
    }

    if (!this.emailError && !this.passwordError) {
      const m = await this.$store.dispatch("authentication/login", {
        email: this.email,
        password: this.password
      })

      if (this.getLoggedIn) {
        this.$store.dispatch("config/loadAbout")
        const lastRouteVisited = localStorage.getItem("lastRouteVisited")
        this.removeAlerts()
        if (lastRouteVisited) {
          this.$router.push(
            { path: lastRouteVisited },
            () => {
              // success callback
            },
            err => {
              err.message.includes("Avoided redundant navigation")
            }
          )
        } else {
          this.$router.push(
            { name: "Home" },
            () => {
              // success callback
            },
            err => {
              err.message.includes("Avoided redundant navigation")
            }
          )
        }
      } else {
        this.loginError = m
      }
    }
  }

  validEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  removeAlerts() {
    const errorAlerts = this.$store.getters["alerts/getFailures"]
    errorAlerts.forEach((alert: Alert) => {
      if (alert.message == "You've been logged out due to inactivity")
        this.$store.commit("alerts/removeAlert", alert.UUID)
    })
  }
}
