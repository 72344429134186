import Vue from "vue"
import VueRouter from "vue-router"
import AchBill from "@/views/AchBill.vue"
import AchPayments from "@/views/AchPayments.vue"
import Admin from "@/views/Admin.vue"
import AdminBills from "@/views/AdminBills.vue"
import AdvancedSearches from "@/views/AdvancedSearches.vue"
import AgePremiumTable from "@/components/AgePremiumTable.vue"
import Attorney from "@/views/Attorney.vue"
import Attorneys from "@/views/Attorneys.vue"
import BillingGroups from "@/views/BillingGroups.vue"
import CheckBill from "@/views/CheckBill.vue"
import CheckPayments from "@/views/CheckPayments.vue"
import CreditCardBatch from "@/views/CreditCardBatch.vue"
import CreditCardBill from "@/views/CreditCardBill.vue"
import CreditCardExpiration from "@/views/CreditCardExpiration.vue"
import CreditCardNotify from "@/views/CreditCardNotify.vue"
import CreditCardPayments from "@/views/CreditCardPayments.vue"
import Claim from "@/views/Claim.vue"
import Claims from "@/views/Claims.vue"
import DelinquentPolicies from "@/views/DelinquentPolicies.vue"
import Department from "@/views/Department.vue"
import Departments from "@/views/Departments.vue"
import Deposit from "@/views/Deposit.vue"
import Deposits from "@/views/Deposits.vue"
import Disbursement from "@/views/Disbursement.vue"
import Disbursements from "@/views/Disbursements.vue"
import Enroller from "@/views/Enroller.vue"
import Enrollers from "@/views/Enrollers.vue"
import ForgotPassword from "@/views/ForgotPassword.vue"
import Home from "@/views/Home.vue"
import Iif from "@/views/Iif.vue"
import Iifs from "@/views/Iifs.vue"
import IncomeWorksheet from "@/views/IncomeWorksheet.vue"
import Letter from "@/views/Letter.vue"
import Letters from "@/views/Letters.vue"
import Login from "@/components/Login.vue"
import Member from "@/views/Member.vue"
import MemberMerge from "@/views/MemberMerge.vue"
import NotFound from "@/views/NotFound.vue"
import Nacha from "@/views/Nacha.vue"
import Nachas from "@/views/Nachas.vue"
import Notifications from "@/views/Notifications.vue"
import OrphanedPolicies from "@/views/OrphanedPolicies.vue"
import PasswordReset from "@/views/PasswordReset.vue"
import Payments from "@/views/Payments.vue"
import PaidInFull from "@/views/PaidInFull.vue"
import Policy from "@/views/Policy.vue"
import PolicyGroup from "@/views/PolicyGroup.vue"
import Products from "@/views/Products.vue"
import AgePremiums from "@/views/AgePremiums.vue"
import Product from "@/views/Product.vue"
import Reports from "@/views/Reports.vue"
import User from "@/views/User.vue"
import Users from "@/views/Users.vue"
import Versions from "@/views/Versions.vue"
import BoardMeetings from "@/views/BoardMeetings.vue"
import BoardMeeting from "@/views/BoardMeeting.vue"
import PayrollAdditions from "@/views/PayrollAdditions.vue"

import axios from "axios"
import store from "@/store"
import jwtDecode from "jwt-decode"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin
  },
  { path: "/attorneys/:id", name: "Attorney", component: Attorney },
  { path: "/attorneys", name: "Attorneys", component: Attorneys },
  {
    path: "/departments",
    name: "Departments",
    component: Departments
  },
  {
    path: "/departments/:id",
    name: "Department",
    component: Department
  },
  {
    path: "/enrollers",
    name: "Enrollers",
    component: Enrollers
  },
  {
    path: "/enrollers/:id",
    name: "Enroller",
    component: Enroller
  },
  {
    path: "/members/:id",
    name: "Member",
    component: Member
  },
  {
    path: "/member_merge",
    name: "MemberMerge",
    component: MemberMerge
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/advanced-search",
    name: "Advanced Search",
    component: AdvancedSearches
  },
  {
    path: "/claims",
    name: "Claims",
    component: Claims
  },
  {
    path: "/income-worksheets/:id",
    name: "IncomeWorksheet",
    component: IncomeWorksheet
  },
  {
    path: "/claims/:id",
    name: "Claim",
    component: Claim
  },
  {
    path: "/disbursements",
    name: "Disbursements",
    component: Disbursements
  },
  {
    path: "/disbursements/:id",
    name: "Disbursement",
    component: Disbursement
  },
  {
    path: "/iifs",
    name: "Iifs",
    component: Iifs
  },
  {
    path: "/iifs/:id",
    name: "Iif",
    component: Iif
  },
  {
    path: "/letters",
    name: "Letters",
    component: Letters
  },
  {
    path: "/letters/:id",
    name: "Letter",
    component: Letter
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/products",
    name: "Products",
    component: Products
  },
  {
    path: "/products/:id",
    name: "Product",
    component: Product
  },
  {
    path: "/payments",
    name: "Payments",
    component: Payments
  },
  {
    path: "/check-bill",
    name: "CheckBill",
    component: CheckBill
  },
  {
    path: "/orphaned-policies",
    name: "OrphanedPolicies",
    component: OrphanedPolicies
  },
  {
    path: "/paid-in-full",
    name: "PaidInFull",
    component: PaidInFull
  },
  {
    path: "/delinquent-policies",
    name: "DelinquentPolicies",
    component: DelinquentPolicies
  },
  {
    path: "/check-payments",
    name: "CheckPayments",
    component: CheckPayments
  },
  {
    path: "/cc-expire",
    name: "CreditCardExpiration",
    component: CreditCardExpiration
  },
  {
    path: "/cc-notify",
    name: "CreditCardNotify",
    component: CreditCardNotify
  },
  {
    path: "/cc-batch/:id",
    name: "CreditCardBatch",
    component: CreditCardBatch
  },
  {
    path: "/cc-bill",
    name: "CreditCardBill",
    component: CreditCardBill
  },
  {
    path: "/cc-payments",
    name: "CreditCardPayments",
    component: CreditCardPayments
  },
  {
    path: "/ach-bill",
    name: "AchBill",
    component: AchBill
  },
  {
    path: "/ach-payments",
    name: "AchPayments",
    component: AchPayments
  },
  {
    path: "/policies/:id",
    name: "Policy",
    component: Policy
  },
  {
    path: "/policy_groups/:id",
    name: "PolicyGroup",
    component: PolicyGroup
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/users/:id",
    name: "User",
    component: User
  },
  {
    path: "/users",
    name: "Users",
    component: Users
  },
  {
    path: "/versions",
    name: "Versions",
    component: Versions
  },
  {
    path: "/setup/forgotPassword",
    name: "Forgot Password",
    component: ForgotPassword
  },
  {
    path: "/setup/:token",
    name: "Reset",
    component: PasswordReset
  },
  {
    path: "/nachas",
    name: "Nachas",
    component: Nachas
  },
  {
    path: "/nachas/:id",
    name: "Nacha",
    component: Nacha
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications
  },
  {
    path: "/deposits",
    name: "Deposits",
    component: Deposits
  },
  {
    path: "/deposits/:id",
    name: "Deposit",
    component: Deposit
  },
  {
    path: "/board-meetings",
    name: "Board Meetings",
    component: BoardMeetings
  },
  {
    path: "/board-meetings/:id",
    name: "BoardMeeting",
    component: BoardMeeting
  },
  {
    path: "/age-premiums",
    name: "LTC Premiums",
    component: AgePremiums
  },
  {
    path: "/age-premiums/:day_rate",
    name: "AgePremiumTable",
    component: AgePremiumTable
  },
  {
    path: "/billing-groups",
    name: "BillingGroups",
    component: BillingGroups
  },
  {
    path: "/admin-bills",
    name: "AdminBills",
    component: AdminBills
  },
  {
    path: "/payroll-additions",
    name: "PayrollAdditions",
    component: PayrollAdditions
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound
  }
]

const router = new VueRouter({
  routes
})

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (
        error.response.status == "401" &&
        (error.response.data.includes("Signature has expired") ||
          (error.response.data &&
            error.response.data.includes("revoked token")))
      ) {
        store.dispatch("authentication/logout")
        if (router.currentRoute.path != "/login") {
          router.push({ name: "Login" })
        }
      }
    }
    return Promise.reject(error)
  }
)

router.beforeEach((to, from, next) => {
  const publicPages = ["/about", "/login"]
  const authRequired =
    !publicPages.includes(to.path) && !to.path.includes("/setup")
  const loggedIn = localStorage.getItem("token")

  if (process.env.NODE_ENV === "production") {
    const token = localStorage.getItem("token")
    const jwt: { id: number } | null = token ? jwtDecode(token) : null

    if (jwt && to.path != from.path && from.name != null) {
      const payload = {
        user_id: jwt.id,
        path_to: to.path,
        path_from: from.path
      }

      axios
        .post("/user_events", payload, store.getters["config/getAPIConfig"])
        .catch(error => {
          console.log("error sending user event")
          console.log(error)
        })
    }
  }

  if (!to.name) document.title = "cpsa"
  else if (!to.params.id) document.title = to.name

  if (authRequired && !loggedIn) {
    return next("/login")
  } else next()
})

router.afterEach(to => {
  if (to.name != "Login" && !to.path.includes("/setup")) {
    localStorage.setItem("lastRouteVisited", to.path)
  }
})

export default router
