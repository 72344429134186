
import { Component, Prop, Vue } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import ProductForm from "@/components/ProductForm.vue"
import ProductsListDialog from "@/components/ProductsListDialog.vue"
import { Product } from "@/types/Product"

@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate,
    ProductForm,
    ProductsListDialog
  }
})
export default class ProductsList extends Vue {
  @Prop() private products!: [Product]
  @Prop() private justify!: string

  get someDirty() {
    return this.products?.some(product => product.dirty)
  }

  reloadProduct(product: Product) {
    this.$store.dispatch("products/load", product.id)
  }
}
