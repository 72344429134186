import Model from "./Model"

export class Address extends Model<Address> {
  id: string
  attributes!: {
    street: string
    apartment: string
    city: string
    state: string
    zip: string
  }
  static get modelName() {
    return "Address"
  }
}
