import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Claim } from "@/types/Claim"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Claim[] } = {
  all: []
}

const getters = {
  getActiveByStateAndProductType: (state: State<Claim>) => ({
    aasm_state,
    product_type
  }: {
    aasm_state: string
    product_type: string
  }) => {
    let claims = state.all

    if (!aasm_state || aasm_state == "active") {
      if (product_type != "all") {
        claims = state.all.filter(el => {
          return el.attributes.product_type == product_type
        })
      } else {
        return state.all
      }
    } else {
      if (product_type != "all") {
        claims = state.all.filter(el => {
          return (
            el.attributes.state == aasm_state &&
            el.attributes.product_type == product_type
          )
        })
      } else {
        claims = state.all.filter(el => {
          return el.attributes.state == aasm_state
        })
      }
    }
    return claims
  }
}

const actions: ActionTree<State<Claim>, RootState> = {
  async loadActiveWithMembers({
    commit,
    dispatch,
    rootGetters
  }: ActionContext<State<Claim>, RootState>) {
    let activeStates = rootGetters["config/getActiveStates"]

    if (typeof activeStates == "undefined" || activeStates.length == 0) {
      await this.dispatch("config/loadAbout")
      activeStates = await rootGetters["config/getActiveStates"]
    }

    await axios
      .get(
        `/claims?aasm_state[]=${activeStates.join(
          "&aasm_state[]="
        )}&include=policy.member`,
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {}; included: [] } }) => {
        commit("set", data && data.data.data)
        if (data.data.included) {
          data.data.included.forEach((el: { type: string }) => {
            if (el.type == "member") {
              commit("members/set", el, { root: true })
            } else if (el.type == "policy") {
              commit("policies/set", el, { root: true })
            }
          })
        }
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async getPayables(
    { dispatch, rootGetters }: ActionContext<State<Claim>, RootState>,
    params: { adminId: string }
  ) {
    try {
      const data = await axios.get(
        `/claims/${params.adminId}/payables`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async getOutstandingEstimatedPayables(
    { dispatch, rootGetters }: ActionContext<State<Claim>, RootState>,
    previousQuarters: string
  ) {
    try {
      const data = await axios.get(
        `/claims/outstanding_estimated_payables?previous=${previousQuarters}`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },

  async stateChange(
    { commit, dispatch, rootGetters }: ActionContext<State<Claim>, RootState>,
    { id, event }
  ) {
    await axios
      .put(
        `/claims/${id}/state_change?event=${event}`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {} } }) => {
        commit("set", data && data.data.data)
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async generateNumber(
    { commit, dispatch, rootGetters }: ActionContext<State<Claim>, RootState>,
    id: string
  ) {
    await axios
      .put(
        `/claims/${id}/generate_number`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {} } }) => {
        commit("set", data && data.data.data)
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async fetchDisbursementsPdf(
    { dispatch, rootGetters }: ActionContext<State<Claim>, RootState>,
    id: string
  ) {
    try {
      const data = await axios.get(
        `/claims/${id}/disbursements_pdf`,
        Object.assign(
          { responseType: "blob" },
          rootGetters["config/getAPIConfig"]
        )
      )

      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<Claim>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
