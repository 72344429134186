
import { User } from "@/types/User"
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  components: {}
})
export default class UserInitials extends Vue {
  @Prop() private user!: User
  @Prop() private color!: string
  @Prop() private size!: number

  get initials() {
    if (this.user) {
      const firstInitial = this.user.attributes.first_name
        ? this.user.attributes.first_name.charAt(0).toUpperCase()
        : ""
      const lastInitial = this.user.attributes.last_name
        ? this.user.attributes.last_name.charAt(0).toUpperCase()
        : ""

      const initials = `${firstInitial}${lastInitial}`
      return initials.length > 0 ? initials : "XX"
    } else {
      return ""
    }
  }

  get userAvatarColor() {
    return this.user.attributes.avatar_color
      ? this.user.attributes.avatar_color
      : "indigo"
  }
}
