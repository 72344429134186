import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import { Membership } from "@/types/Membership"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import errorHandler from "../utils/errorHandler"

const state: { all: Membership[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<Membership>, RootState> = {
  async reverse(
    {
      commit,
      dispatch,
      rootGetters
    }: ActionContext<State<Membership>, RootState>,
    id: string
  ) {
    try {
      const url = `/memberships/${id}/reverse`
      const data = await axios.put(url, {}, rootGetters["config/getAPIConfig"])
      if (data.status == 200) {
        commit("set", data && data.data.data)
      }
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}

const mutations: MutationTree<State<Membership>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
