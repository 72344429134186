import { MutationTree } from "vuex"
import { Version } from "@/types/Version"
import State from "@/types/State"

const state: { all: Version[] } = {
  all: []
}

const getters = {}

const actions = {}

const mutations: MutationTree<State<Version>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
