import { MutationTree, ActionTree } from "vuex"
import { CompositeRate } from "@/types/CompositeRate"
import State from "@/types/State"
import { RootState } from "@/types/RootState"

const state: { all: CompositeRate[] } = {
  all: []
}

const getters = {}

const actions: ActionTree<State<CompositeRate>, RootState> = {}
const mutations: MutationTree<State<CompositeRate>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
