
import { Component, Vue, Prop, Watch } from "vue-property-decorator"
import DialogTemplate from "@/components/DialogTemplate.vue"
import { Ach } from "@/types/Ach"

@Component({
  components: { DialogTemplate }
})
export default class ClaimPaymentMethodDialog extends Vue {
  @Prop() private aches!: Ach

  @Prop() private selected!: number

  private selectedPaymentMethod = 0

  created() {
    this.selectedPaymentMethod = this.selected
  }

  @Watch("selected")
  onClaimChange(value: number) {
    this.selectedPaymentMethod = value
  }

  save() {
    if (this.selectedPaymentMethod == 0) {
      this.$emit("save", null)
    } else {
      this.$emit("save", this.selectedPaymentMethod)
    }
  }

  cancel() {
    this.selectedPaymentMethod = this.selected
    this.$emit("cancel")
  }
}
