
import { Component, Prop, Vue } from "vue-property-decorator"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"

import { Claim } from "@/types/Claim"
import { Department } from "@/types/Department"
import { Employer } from "@/types/Employer"
import { Lien } from "@/types/Lien"
import { Signature } from "@/types/Signature"

@Component({
  components: {
    CurrencyField,
    DateField,
    DialogTemplate
  }
})
export default class LienDialog extends Vue {
  @Prop() private claim: Claim
  @Prop() private department: Department
  @Prop() private employer: Employer

  private lien = new Lien()
  private signatureId: number | null = null

  created() {
    this.$store.dispatch("signatures/loadAll")
  }

  get signatures() {
    const signatures = this.$store.getters["signatures/getAll"]

    if (!signatures) {
      return []
    }

    const items = signatures.map((el: Signature) => {
      return { text: el.attributes.name, value: el.id }
    })

    return items
  }
  get employerExists() {
    return !!this.employer
  }

  get departmentName() {
    return this.department?.attributes?.name
  }

  get employerName() {
    return this.employer?.attributes?.name
  }

  get attention() {
    return this.employer?.attributes?.attention
  }

  get employerAddress() {
    return this.employer?.attributes?.address
  }

  get insuranceCarrier() {
    return this.employer?.attributes?.carrier
  }

  get adjustingAgency() {
    return this.employer?.attributes?.adjuster
  }

  get insuranceCarrierAttention() {
    return this.employer?.attributes?.carrier_attention
  }

  get insuranceCarrierAddress() {
    return this.employer?.attributes?.carrier_address
  }

  async save() {
    this.lien.attributes.claim_id = parseInt(this.claim.id)
    this.lien.attributes.signature_id = this.signatureId
    await this.$store.dispatch("liens/create", this.lien)
  }
}
