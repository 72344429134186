import Model from "./Model"

export class BoardMeetingAttendee extends Model<BoardMeetingAttendee> {
  constructor(response?: BoardMeetingAttendee) {
    super(response as BoardMeetingAttendee)
  }
  id: string
  attributes!: {
    board_meeting_id: number
    name: string
    street: string
    city: string
    state: string
    apartment: string
    zip: string
    email_1: string
    email_2: string
    phone_work: string
    phone_home: string
    phone_mobile: string
    attended: boolean
    reimbursed_hotel: boolean
    reimbursed_expenses: boolean
    role: number
    department_id: number
    comments: string
  }
  static get modelName() {
    return "BoardMeetingAttendee"
  }
}
