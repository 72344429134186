
import { Component, Prop, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"

@Component({
  components: {
    CardTemplate
  }
})
export default class MemberCoverageGraphic extends Vue {
  @Prop() private data!: [{ year: string; months: [number] }]

  month_labels = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"]
  monthWidth = 30
  monthSpace = 3
  yearHeight = 16
  yearSpace = 4

  monthColor(value: number) {
    switch (true) {
      case value < 0:
        return "white"
      case value == 0:
        return "white"
      case value == 1:
        return "#f9aa33"
      case value > 1:
        return "#ff0000"
    }
  }

  monthBorderColor(value: number) {
    switch (true) {
      case value < 0:
        return "#0000ff"
      case value == 0:
        return "#f9aa33"
      case value == 1:
        return "#f9aa33"
      case value > 1:
        return "#ff0000"
    }
  }
}
