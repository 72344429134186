
import { Component, Vue } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DateField from "@/components/DateField.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import { Report } from "@/types/Report"

@Component({
  components: { CardTemplate, CurrencyField, DateField, PagedDataTable }
})
export default class EnrollerCommissionReports extends Vue {
  private reports: Report[] = []
  private loading = false
  private total = 0
  private startingPerPage = 5
  private startingPage = 1

  created() {
    this.loadReports(this.startingPage, this.startingPerPage)
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }

  getReports(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadReports(details.page, details.itemsPerPage)
    }
  }

  async loadReports(page: number, itemsPerPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("reports/loadBy", {
      report_type: "trustee_admin",
      page: page,
      per_page: itemsPerPage,
      order: "created_at",
      order_direction: "DESC"
    })

    this.reports = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  get headers() {
    return [
      {
        text: "NAME",
        value: "attributes.name",
        sortable: false,
        class: "text-left caption js-header-name"
      },
      {
        text: "DATE",
        value: "attributes.created_at",
        class: "text-left caption"
      },
      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
        class: "text-left caption js-header-actions"
      }
    ]
  }
}
