import Model from "./Model"

export class PolicyChangeEvent extends Model<PolicyChangeEvent> {
  constructor(ach?: PolicyChangeEvent) {
    super(ach as PolicyChangeEvent)
  }
  id: string
  attributes!: {
    policy_id: number
    event_type: string
    updated_at: string
    status_to: string
    status_from: string
  }
  static get modelName() {
    return "PolicyChangeEvent"
  }
}
