import { Dispatch } from "vuex"
import { captureException } from "@sentry/browser"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function(dispatch: Dispatch, error: Record<string, any>) {
  let alertSentry = true

  if (typeof error === "object" && error !== null && "isAxiosError" in error) {
    dispatch("alerts/addAxiosError", error, { root: true })

    if (
      error.response &&
      error.response.status === 401 &&
      (error.response.data.includes("Signature has expired") ||
        error.response.data.includes("revoked token"))
    ) {
      alertSentry = false
    }
  } else {
    dispatch("alerts/addError", error, { root: true })
  }

  if (alertSentry) {
    captureException(error)
  }
}
