import Model from "./Model"
import DateHelper from "@/components/utils/DateHelper"

export class Member extends Model<Member> {
  constructor(response?: Member) {
    super(response as Member)
    if (response) {
      this.attributes.dob = DateHelper.stringToDate(this.attributes.dob)
    }
  }
  id: string
  attributes!: {
    first_name: string
    middle_name: string
    last_name: string
    suffix: string
    dob: Date
    ssn: string
    email: string
    phone: string
    status: string
    address_id: number
    department_id: number
    last_department_id: number
    is_spouse: boolean
    spouse_id: number
    sworn_safety: boolean
    updated_at: string
    waive_ltd: boolean
    waive_reason_ltd: string
    waive_ltc: boolean
    waive_reason_ltc: string
    enrollment_card: boolean
    ltc_balance: number
    ltd_balance: number
    rider_ltd: boolean
    deleted: boolean
    gender: string
    married_status: string
    number: string
  }
  static get modelName() {
    return "Member"
  }
}
