
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import DialogTemplate from "@/components/DialogTemplate.vue"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    DialogTemplate
  }
})
export default class UpdatePolicyRatingStatus extends Vue {
  @Prop() private id!: number
  @Prop() private rating!: string | null

  private changedRating = ""

  private ratingOptions = ["Preferred", "Standard 60", "Standard 90", "Unknown"]

  @Watch("rating")
  onRatingChange(value: string) {
    if (value) {
      this.changedRating = value
    }
  }

  mounted() {
    this.changedRating = this.rating
  }

  async update() {
    const val = this.changedRating == "Unknown" ? null : this.changedRating

    await axios.put(
      `/policies/${this.id}`,
      { rating: val },
      this.$store.getters["config/getAPIConfig"]
    )
    this.$emit("update-policy")
  }
}
