
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Ach } from "@/types/Ach"
import { Address } from "@/types/Address"
import { Beneficiary } from "@/types/Beneficiary"
import DateField from "@/components/DateField.vue"
import PhoneNumber from "@/components/PhoneNumber.vue"
import statesList from "states-us"
import VueMask from "v-mask"

Vue.use(VueMask)

@Component({
  components: {
    DateField,
    PhoneNumber
  }
})
export default class BeneficiaryFields extends Vue {
  @Prop() private ach!: Ach
  @Prop() private address!: Address
  @Prop() private beneficiary!: Beneficiary
  @Prop() private contingent!: boolean
  @Prop() private allowUseMemberAddress!: boolean

  private useMemberAddress = false

  @Watch("ach", { deep: true })
  validateAch() {
    const isValid = (this.$refs.entryForm as Vue & {
      validate: () => boolean
    }).validate()
    // const isValid = this.$refs.entryForm.validate()
    this.$emit("ach-valid", isValid)
  }

  @Watch("useMemberAddress")
  useMemberAddressChanged(value: boolean) {
    this.$emit("use-member-address", value)
  }

  get type() {
    return this.contingent ? "Contingent" : "Beneficiary"
  }
  get states() {
    return statesList.filter(x => !x.territory)
  }

  get percent() {
    if (!this.beneficiary.attributes.percent) {
      return null
    }
    return this.beneficiary.attributes.percent.toString()
  }

  set percent(value: string) {
    this.beneficiary.attributes.percent = parseInt(value)
  }

  archiveNow() {
    this.$emit("archive-now")
  }
  unarchiveNow() {
    this.$emit("unarchive-now")
  }

  get achPresent() {
    const ach = this.ach
      ? !!this.ach.attributes.account_number ||
        !!this.ach.attributes.routing_number ||
        !!this.ach.attributes.account_type
      : false

    return ach
  }
}
