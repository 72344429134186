import Vue from "vue"
import Vuex from "vuex"

import aches from "./modules/aches"
import addresses from "./modules/addresses"
import adjustments from "./modules/adjustments"
import adminBills from "./modules/adminBills"
import adminFees from "./modules/adminFees"
import advancedSearches from "./modules/advancedSearches"
import agePremiums from "./modules/agePremiums"
import alerts from "./modules/alerts"
import attorneys from "./modules/attorneys"
import authentication from "./modules/authentication"
import beneficiaries from "./modules/beneficiaries"
import benefits from "./modules/benefits"
import billingGroups from "./modules/billingGroups"
import billMembers from "./modules/billMembers"
import boardMeetings from "./modules/boardMeetings"
import boardMeetingAdmins from "./modules/boardMeetingAdmins"
import boardMeetingAttendees from "./modules/boardMeetingAttendees"
import claims from "./modules/claims"
import claimFormDefinitions from "./modules/claimFormDefinitions"
import claimForms from "./modules/claimForms"
import config from "./modules/config"
import compositeRates from "./modules/compositeRates"
import creditCardBatches from "./modules/creditCardBatches"
import creditCardNotifications from "./modules/creditCardNotifications"
import creditCards from "./modules/creditCards"
import departments from "./modules/departments"
import departmentProducts from "./modules/departmentProducts"
import deposits from "./modules/deposits"
import disabilityTypes from "./modules/disabilityTypes"
import disbursements from "./modules/disbursements"
import disbursementTypes from "./modules/disbursementTypes"
import employers from "./modules/employers"
import enrollers from "./modules/enrollers"
import estimatedPayables from "./modules/estimatedPayables"
import bills from "./modules/bills"
import followings from "./modules/followings"
import grandAlerts from "./modules/grandAlerts"
import iifs from "./modules/iifs"
import incomeItems from "./modules/incomeItems"
import incomeWorksheets from "./modules/incomeWorksheets"
import laserfiches from "./modules/laserfiches"
import letters from "./modules/letters"
import liens from "./modules/liens"
import signatures from "./modules/signatures"
import membersView from "./modules/membersView"
import members from "./modules/members"
import memberships from "./modules/memberships"
import nachas from "./modules/nachas"
import notes from "./modules/notes"
import notifications from "./modules/notifications"
import payments from "./modules/payments"
import policies from "./modules/policies"
import policyChangeEvents from "./modules/policyChangeEvents"
import policyGroups from "./modules/policyGroups"
import policyProducts from "./modules/policyProducts"
import premiums from "./modules/premiums"
import products from "./modules/products"
import reports from "./modules/reports"
import recoverableAdjustments from "./modules/recoverableAdjustments"
import recoveries from "./modules/recoveries"
import search from "./modules/search"
import tags from "./modules/tags"
import users from "./modules/users"
import versions from "./modules/versions"
import genericActions from "@/store/utils/genericActions"
import genericGetters from "./utils/genericGetters"
import genericMutations from "@/store/utils/genericMutations"
import { snakeCase } from "lodash"

Vue.use(Vuex)

const modules = {
  aches,
  addresses,
  adjustments,
  adminBills,
  adminFees,
  advancedSearches,
  agePremiums,
  attorneys,
  beneficiaries,
  benefits,
  bills,
  billingGroups,
  billMembers,
  boardMeetings,
  boardMeetingAdmins,
  boardMeetingAttendees,
  claims,
  claimFormDefinitions,
  claimForms,
  compositeRates,
  creditCardBatches,
  creditCardNotifications,
  creditCards,
  departments,
  departmentProducts,
  deposits,
  disabilityTypes,
  disbursements,
  disbursementTypes,
  employers,
  enrollers,
  estimatedPayables,
  followings,
  grandAlerts,
  iifs,
  incomeItems,
  incomeWorksheets,
  laserfiches,
  letters,
  liens,
  membersView,
  members,
  memberships,
  nachas,
  notes,
  notifications,
  payments,
  policies,
  policyChangeEvents,
  policyGroups,
  policyProducts,
  premiums,
  products,
  recoverableAdjustments,
  reports,
  recoveries,
  signatures,
  tags,
  users,
  versions
}

const genericGetterKeys = Object.keys(genericGetters)
const genericActionKeys = Object.keys(genericActions)
const genericMutationKeys = Object.keys(genericMutations)

for (const [key, value] of Object.entries(modules)) {
  const moduleGetters = Object.keys(value.getters)
  const moduleActions = Object.keys(value.actions)
  const moduleMutations = Object.keys(value.mutations)
  if (
    moduleGetters.length + genericGetterKeys.length !=
    new Set([...moduleGetters, ...genericGetterKeys]).size
  ) {
    throw `The ${key} module has duplicated getters`
  }
  if (
    moduleActions.length + genericActionKeys.length !=
    new Set([...moduleActions, ...genericActionKeys]).size
  ) {
    throw `The ${key} module has duplicated actions`
  }
  if (
    moduleMutations.length + genericMutationKeys.length !=
    new Set([...moduleMutations, ...genericMutationKeys]).size
  ) {
    throw `The ${key} module has duplicated mutations`
  }

  //eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  value.state = { ...value.state, type: snakeCase(key) }
  value.getters = { ...value.getters, ...genericGetters }
  value.actions = { ...value.actions, ...genericActions }
  value.mutations = { ...value.mutations, ...genericMutations }
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: { ...modules, alerts, authentication, config, search }
})
