import Model from "./Model"

export class CreditCard extends Model<CreditCard> {
  constructor(creditCard?: CreditCard) {
    super(creditCard as CreditCard)
  }
  id: string
  attributes!: {
    full_name: string
    number: string
    expiration_month: number
    expiration_year: number
    // cvv: string
    owner_id: number
    owner_type: string
  }
  static get modelName() {
    return "CreditCard"
  }
}
