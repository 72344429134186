import axios from "axios"
import { MutationTree, ActionTree, ActionContext } from "vuex"
import modelToMutation from "../utils/modelToMutation"
import { Member } from "@/types/Member"
import State from "@/types/State"
import { RootState } from "@/types/RootState"
import { Membership } from "@/types/Membership"
import { Address } from "@/types/Address"
import { Beneficiary } from "@/types/Beneficiary"
import { Product } from "@/types/Product"
import errorHandler from "../utils/errorHandler"

const state: { all: Member[]; meta: {} } = {
  all: [],
  meta: {}
}

const getters = {}

const actions: ActionTree<State<Member>, RootState> = {
  async build(
    { commit, dispatch, rootGetters }: ActionContext<State<Member>, RootState>,
    data: {
      other: {
        address: Address["attributes"]
        membership: Membership["attributes"]
        products: Product[]
        beneficiaries: Record<string, Beneficiary | Address | string>[]
      }
      member: Member["attributes"]
    }
  ) {
    const combinedData = { ...data.member, ...data.other }

    return axios
      .post(
        "/members/build_member",
        JSON.parse(JSON.stringify(combinedData)),
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {}; meta: {} } }) => {
        commit("set", data && data.data.data)
        commit("setMeta", data && data.data.meta)
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async merge(
    { commit, dispatch, rootGetters }: ActionContext<State<Member>, RootState>,
    params: {
      sourceMemberId: string
      destinationMemberId: string
    }
  ) {
    return axios
      .post(
        "/members/merge",
        {
          source_member_id: params.sourceMemberId,
          destination_member_id: params.destinationMemberId
        },
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {}; meta: {} } }) => {
        commit("set", data && data.data.data)
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },

  async retroWaive(
    { commit, dispatch, rootGetters }: ActionContext<State<Member>, RootState>,
    {
      memberId,
      waiveDate,
      waiveState,
      waiveReason,
      productType
    }: {
      memberId: string
      waiveDate: string
      waiveState: boolean
      waiveReason: string
      productType: string
    }
  ) {
    return axios
      .put(
        `/members/${memberId}/retro_waive?waive_date=${waiveDate}&waive_state=${waiveState}&product_type=${productType}&waive_reason=${waiveReason}`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {}; included: [] } }) => {
        commit("set", data && data.data.data)

        if (data.data.included) {
          data.data.included.forEach((el: { type: string }) => {
            const mutation = modelToMutation(el.type)
            commit(mutation, el, { root: true })
          })
        }
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async updateStatus(
    { commit, dispatch, rootGetters }: ActionContext<State<Member>, RootState>,
    id: number
  ) {
    return axios
      .put(
        `/members/${id}/update_status`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {}; included: [] } }) => {
        commit("set", data && data.data.data)

        if (data.data.included) {
          data.data.included.forEach((el: { type: string }) => {
            const mutation = modelToMutation(el.type)
            commit(mutation, el, { root: true })
          })
        }
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async endMembershipPolicy(
    { commit, dispatch, rootGetters }: ActionContext<State<Member>, RootState>,
    {
      member_id,
      end_date,
      membership_id,
      include
    }: {
      member_id: string
      end_date: string
      membership_id: string
      include: string[]
    }
  ) {
    const includeString = include
      ? Array.isArray(include)
        ? `&include=${include.join(",")}`
        : `&include=${include}`
      : ""

    return axios
      .put(
        `/members/${member_id}/end_membership_policy?end_date=${end_date}&membership_id=${membership_id}${includeString}`,
        {},
        rootGetters["config/getAPIConfig"]
      )
      .then((data: { data: { data: {}; included: [] } }) => {
        commit("set", data && data.data.data)

        if (data.data.included) {
          data.data.included.forEach((el: { type: string }) => {
            const mutation = modelToMutation(el.type)
            commit(mutation, el, { root: true })
          })
        }
        return data
      })
      .catch(error => {
        dispatch("alerts/addAxiosError", error, { root: true })
      })
  },
  async doesSSNExist(
    { dispatch, rootGetters }: ActionContext<State<Member>, RootState>,
    { ssn }: { ssn: string }
  ) {
    try {
      const data = await axios.get(
        `members?ssn=${ssn}`,
        rootGetters["config/getAPIConfig"]
      )
      return data.status == 200 && data.data.data.length
    } catch (error) {
      errorHandler(dispatch, error)
    }
  },
  async coverage(
    { dispatch, rootGetters }: ActionContext<State<Member>, RootState>,
    id: string
  ) {
    try {
      const data = await axios.get(
        `members/${id}/coverage`,
        rootGetters["config/getAPIConfig"]
      )
      return data
    } catch (error) {
      errorHandler(dispatch, error)
    }
  }
}
const mutations: MutationTree<State<Member>> = {}

export { getters, actions, mutations }

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
