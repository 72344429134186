
import { Component, Vue, Prop } from "vue-property-decorator"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import { Policy } from "@/types/Policy"
import axios from "axios"

@Component({
  components: {
    CardTemplate,
    CurrencyField,
    DateField
  }
})
export default class PremiumHistory extends Vue {
  @Prop() private policy!: Policy

  private date = this.firstOfNextMonth()
    .toISOString()
    .split("T")[0]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private data: null | any = null

  async report() {
    const data = await axios.get(
      `/policies/${this.policy.id}/rate_convert_data`,
      Object.assign(
        { params: { date: this.date } },
        this.$store.getters["config/getAPIConfig"]
      )
    )
    this.data = data.data
  }

  firstOfNextMonth() {
    const today = new Date()
    return today.getMonth() + 1 == 12
      ? new Date(today.getFullYear() + 1, 0, 1)
      : new Date(today.getFullYear(), today.getMonth() + 1, 1)
  }
}
