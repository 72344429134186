
import { Component, Vue, Watch } from "vue-property-decorator"
import AccountChip from "@/components/AccountChip.vue"
import CardTemplate from "@/components/CardTemplate.vue"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"
import PagedDataTable from "@/components/PagedDataTable.vue"
import PageTemplateList from "@/components/PageTemplateList.vue"
import PaymentsList from "@/components/PaymentsList.vue"
import { Deposit } from "@/types/Deposit"
import { Payment } from "@/types/Payment"

@Component({
  components: {
    AccountChip,
    CardTemplate,
    CurrencyField,
    DateField,
    PagedDataTable,
    PageTemplateList,
    PaymentsList
  }
})
export default class Deposits extends Vue {
  private selectedLtcIds: string[] = []
  private selectedLtdSameAccount = true
  private loading = false
  private total = 0
  private startingPerPage = 5
  private depositList: Deposit[] = []
  private account = "law"
  private payments: {
    policy_id: number
    amount: string
    premium_amount: string
    check_number: string
    check_date: string
    classification: string
    memo: string
    name: string
    payable_id: number
    payable_type: string
    payment_id: number
    period_covered: string
  }[] = []
  private selected: {
    policy_id: number
    amount: string
    premium_amount: string
    check_number: string
    check_date: string
    classification: string
    memo: string
    name: string
    payable_id: number
    payable_type: string
    payment_id: number
    period_covered: string
  }[] = []
  private selectedTotal = "0"
  private positiveOnly = true

  async created() {
    this.loadDeposits(1, this.startingPerPage)
    await this.fetchPayments()
  }

  createNewDeposit() {
    this.loading = true
    const ids = this.selected.map(el => el.payment_id)
    this.$store
      .dispatch("deposits/build", {
        paymentIds: ids,
        account: this.account
      })
      .then(() => {
        this.selected = []
        this.loadDeposits(1, this.startingPerPage)
        this.fetchPayments()
      })
  }

  itemDisplayWithoutName(deposit: Deposit) {
    if (deposit.attributes.name) {
      return deposit.attributes.name
    } else {
      return `Deposit - ${deposit.attributes.deposit_date.toLocaleString([], {
        day: "numeric",
        month: "numeric",
        year: "numeric"
      })}`
    }
  }

  async fetchPayments() {
    this.loading = true
    let page = 1
    this.payments = []

    do {
      const data = await this.$store.dispatch("payments/fetchDepositable", {
        page: page,
        classification: this.account,
        positiveOnly: this.positiveOnly
      })
      this.payments.push(...data.data.data)
      page = data.data.meta.next_page
    } while (page)

    this.loading = false
  }

  async loadDeposits(page: number, perPage: number) {
    this.loading = true

    const data = await this.$store.dispatch("deposits/loadBy", {
      account: this.account,
      page: page,
      per_page: perPage,
      order: "deposit_date",
      order_direction: "DESC"
    })

    this.depositList = data?.data?.data
    this.total = data?.data?.meta?.pagination?.count
    this.loading = false
  }

  getDeposits(details: {
    itemsLength: number
    itemsPerPage: number
    page: number
    pageCount: number
    pageStart: number
    pageStop: number
  }) {
    if (details) {
      this.loadDeposits(details.page, details.itemsPerPage)
    }
  }

  @Watch("account")
  async onAccountChange() {
    this.loadDeposits(1, this.startingPerPage)
    await this.fetchPayments()
  }

  @Watch("positiveOnly")
  async onPositiveOnlyChange() {
    await this.fetchPayments()
  }

  @Watch("selected")
  onSelectionChange(value: { amount: string }[]) {
    const amounts = value.map((payment: { amount: string }) =>
      parseFloat(payment.amount)
    )
    if (amounts.length == 0) {
      this.selectedTotal = "0"
    } else if (amounts.length == 1) {
      this.selectedTotal = amounts[0].toString()
    } else {
      this.selectedTotal = amounts
        .reduce((prev: number, cur: number) => {
          return prev + cur
        })
        .toString()
    }
  }

  payableClassification(payment: Payment) {
    if (payment.attributes.product_type == "ltc") {
      return "ltc"
    }

    let classification = ""
    if (payment.attributes.payable_type == "Member") {
      const member = this.$store.getters["members/getById"](
        payment.attributes.payable_id
      )

      const department = member
        ? this.$store.getters["departments/getById"](
            member.attributes.last_department_id
          )
        : null

      classification = department ? department.attributes.classification : ""
    } else {
      const payable = this.$store.getters["departments/getById"](
        payment.attributes.payable_id
      )
      classification = payable ? payable.attributes.classification : ""
    }
    return classification
  }

  get headers() {
    return [
      {
        text: "DEPOSIT",
        value: "attributes.name",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "DATE",
        value: "attributes.deposit_date",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "ACCOUNT",
        value: "attributes.account",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "ACTIONS",
        value: "actions",
        sortable: false,
        class: "text-left caption"
      }
    ]
  }

  get headers2() {
    return [
      {
        text: "NAME",
        value: "name",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "CHECK",
        value: "check_number",
        sortable: true,
        class: "text-left caption"
      },
      {
        text: "AMOUNT",
        value: "amount",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "PREMIUM",
        value: "premium_amount",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "PERIOD",
        value: "period_covered",
        sortable: false,
        class: "text-left caption"
      },
      {
        text: "MEMO",
        value: "memo",
        sortable: false,
        class: "text-left caption"
      }
    ]
  }

  get baseUrl() {
    return this.$store.getters["config/getBaseUrl"]
  }
}
