
import { Component, Vue, Prop } from "vue-property-decorator"
import { Bill } from "@/types/Bill"
import CurrencyField from "@/components/CurrencyField.vue"
import DateField from "@/components/DateField.vue"

@Component({
  components: {
    CurrencyField,
    DateField
  }
})
export default class BillDetails extends Vue {
  @Prop() private bill!: Bill
}
